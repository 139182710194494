import React from "react";
import {
  MacProfiList,
  BreadCrumbs,
  Page,
  RepairMacMiniBanner,
  RepairMacMiniText,
  RepairMacMiniTitle,
} from "../index";

const RepairMacMiniPage = () => {
  return (
    <main className="repair-mac-mini__page">
      <Page title="Срочный ремонт Mac Mini в сервисном центре Мак Профи">
        <MacProfiList />
        <BreadCrumbs />
        <RepairMacMiniBanner />
        <RepairMacMiniTitle />
        <RepairMacMiniText />
      </Page>
    </main>
  );
};

export default RepairMacMiniPage;
