import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone6.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone6 = [
  {
    id: 1,
    title: "Этапы работ по замене микрофона iPhone 6 в нашем сервисном центре",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Столкнувшись с подобной неисправностью, вы должны как можно скорее
          найти достойного исполнителя, способного решить проблему быстро и
          качественно, по разумной цене. Именно такой подход обеспечивается
          сервисным центром Мак Профи, который всегда готов помочь своему
          клиенту. Компания работает уже много лет, ремонтируя современное
          высокотехнологичное оборудование, и обеспечивая высочайшее качество
          выполнения этих работ. Отличная репутация, разумная ценовая политика,
          гарантии и официальная работа – все это становится преимуществами
          данного исполнителя. Кроме того, мы занимаемся выполнением работ любой
          срочности, и при необходимости можем выполнить ремонт экстренно.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мастерская имеет самое современное оснащение, ориентированное на
          работу с Айфонами и реализацию задач любой сложности, в таких условиях
          мастер может работать не только оперативно, но и с достижением
          высочайшего качества, и потому нам по плечу практически любые задачи.
          В наличии всегда есть детали, вам не придется ждать их поставки – все
          они имеют заводское происхождение, они оригинальны и на них дается
          отдельная гарантия. Также стоит отметить, что работают у нас только
          опытные и грамотные специалисты с профильным образованием, которые
          знают об Айфонах буквально все, прекрасно ориентируются во всех
          возможных неполадках и их причинах. Все это создает солидный базис для
          успеха.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым делом мы проведем экспресс-диагностику, которая осуществляется
          бесплатно и очень быстро. После этого вы сможете узнать причины
          поломки, стоимость ремонта, задать вопросы. При вашем согласии ремонт
          будет начат немедленно, и в ближайшие часы вы сможете увидеть
          результат.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels6 = () => {
  return (
    <Page title="Профессиональная замена микрофона iPhone 6 в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 6"
          text="Поломка микрофона – это распространенное явление, с которым нередко сталкиваются все специалисты, которые занимаются ремонтом Айфонов. Это действительно часто встречающееся явление, и по счастью, ремонт при возникновении таких проблем вполне осуществим."
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="1600 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Для того, чтобы добиться успеха, необходимо главным образом
                обеспечить профессиональные условия ремонта, обратиться в
                мастерскую, а не пытаться решить проблему своими силами. Как
                работа неквалифицированных мастеров, так и дилетантский подход к
                ремонту приводят обычно только к необходимости еще более
                серьезных и дорогостоящих работ, либо вовсе к замене девайса на
                другой в силу невозможности его отремонтировать. Айфон – это
                тонкая и чувствительная техника, которая требует
                соответствующего обращения, и в том числе, при ремонте. Но как
                же проявляет себя данная поломка? На практике она выражается
                обычно следующими симптомами:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полная невозможность услышать вас собеседником,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Посторонние шумы при разговоре, которые слышит вторая
                    сторона,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Слишком тихий звук без возможности его улучшить.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Если замечаются именно такие проблемные явления, практически
                наверняка вам потребуется замена микрофона iPhone 6. Но почему
                же происходят такие поломки? Мастера отмечают такие причины –
                падение техники и ее механические повреждения, удары. Также
                микрофон может отказать из-за попадания воды, влаги, от износа
                или других факторов – иногда владельцы гаджета даже не могут
                точно сказать, что вызвало поломку и после чего она произошла.
                Но она устранима в любом случае, и потому паниковать не стоит.
              </p>
              <p className="repair-iphone-display__paragraph">
                Зато стоит знать о некоторых мерах, которые позволят исключить
                эти и другие поломки в будущем. Так, необходимо пользоваться
                специальными защитными чехлами, которые значительно уменьшают
                риски для вашего оборудования. Стоит приучить себя к аккуратному
                использованию высокотехнологичного девайса – тогда и проблем
                будет гораздо меньше. Однако что же делать, если проблема уже
                произошла, и стал необходим ремонт?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone6.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels6;
