import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIpadBanner,
  RepairIpadInfo,
  RepairIpadPrice,
  RepairIpadAboutUs,
  RepairIpadModelLinks,
  RepairIpadText,
  RepairIpadServiceLinks,
  RepairIpadModelsLinksMobile,
} from "../index";
import { useMobileScreen } from "../../utils/useMedia";

function PageModelLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIpadModelsLinksMobile />;
  }
  return <RepairIpadModelLinks />;
}

const RepairIpadPage = () => {
  return (
    <main className="repair-ipad__page">
      <Page title="Ремонт iPad в Москве, срочный ремонт Айпад.">
        <MacProfiList />
        <BreadCrumbs />
        <RepairIpadBanner />
        <RepairIpadInfo />
        <RepairIpadPrice />
        <RepairIpadAboutUs />
        {PageModelLinks()}
        <RepairIpadText />
        <RepairIpadServiceLinks />
      </Page>
    </main>
  );
};

export default RepairIpadPage;
