import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  SelectForIphoneServiceLinks,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/repair-ipadCase.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";

const optionsIpadCase = [
  { value: "iPad", label: "iPad", price: "2400" },
  { value: "iPad 2", label: "iPad 2", price: "1900" },
  { value: "iPad 3", label: "iPad 3", price: "1600" },
  { value: "iPad 4", label: "iPad 4", price: "1900" },
  { value: "iPad mini", label: "iPad mini", price: "1900" },
  { value: "iPad Air", label: "iPad Air", price: "1900" },
  { value: "iPad mini 2", label: "iPad mini 2", price: "2350" },
  { value: "iPad Air 2", label: "iPad Air 2", price: "2350" },
  { value: "iPad mini 3", label: "iPad mini 3", price: "2500" },
  { value: "iPad Pro 12.9", label: "iPad Pro 12.9", price: "1900" },
  { value: "iPad mini 4", label: "iPad mini 4", price: "2080" },
  { value: "iPad Pro 9.7", label: "iPad Pro 9.7", price: "4450" },
  { value: "iPad 5", label: "iPad 5", price: "1900" },
  { value: "iPad Pro 2 10.5", label: "iPad Pro 2 10.5", price: "1900" },
  { value: "iPad Pro 2 12.9", label: "iPad Pro 2 12.9", price: "1900" },
  { value: "iPad 6 (2018)", label: "iPad 6 (2018)", price: "1900" },
  { value: "iPad Pro 11 (2018)", label: "iPad Pro 11 (2018)", price: "1900" },
  {
    value: "iPad Pro 12.9 (2018)",
    label: "iPad Pro 12.9 (2018)",
    price: "1900",
  },
  { value: "iPad Air 3 (2019)", label: "iPad Air 3 (2019)", price: "1600" },
  { value: "iPad mini 5", label: "iPad mini 5", price: "1900" },
  { value: "iPad 7", label: "iPad 7", price: "1900" },
  { value: "iPad 8", label: "iPad 8", price: "1900" },
  { value: "iPad Air 4 (2020)", label: "iPad Air 4 (2020)", price: "1900" },
  { value: "iPad Pro 11 (2021)", label: "iPad Pro 11 (2021)", price: "1900" },
  {
    value: "iPad Pro 12.9 (2021)",
    label: "iPad Pro 12.9 (2021)",
    price: "1900",
  },
  { value: "iPad 9", label: "iPad 9", price: "1900" },
  { value: "iPad mini 6", label: "iPad mini 6", price: "1900" },
];

const RepairIpadServiceLinkCase = () => {
  return (
    <Page title="Замена корпуса iPad (Айпад) в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена корпуса iPad"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                В нашем сервисном центре вы можете произвести быструю замену
                корпуса iPad на новый с гарантией на корпус и услугу по замене.
              </p>
              <p className="repair-iphone-display__paragraph">
                Необходимость в замене корпуса iPad - это вторая по популярности
                причина, приводящая обладателей данных планшетов в ремонтные
                мастерские, пальму первенства она уступает только разбитому
                экрану. Это и не удивительно. Корпуса Айпэд, как его "внешняя"
                деталь, один из первых страдает при различных падениях, ударах,
                царапинах и прочих последствиях неосторожного обращения с
                планшетом. Нужда в замене корпуса iPad может возникнуть у его
                владельца по, так сказать, эстетическим соображениям, когда
                различные небольшие сколы или же царапины портят внешний вид
                устройства и хочется вернуть ему былую привлекательность. В этом
                отношении алюминиевый сплав, применяемые Apple, даже проигрывает
                более обычному пластику. Да, аллюминиевый корпус выглядит более
                богато и выигрышно, но зато на нём и более заметны все дефекты.
                Трещины же корпуса, не только нарушают эстетическую
                привлекательность устройства, но отражаются и на его
                функциональности - "начинка" iPad теперь фактически беззащитна
                перед попаданием влаги, пыли и мусора. Помимо того, серьёзные
                нарушения геометрии корпуса могут быть только внешним
                проявлением более серьёзных повреждений в вашем устройстве.
                Всесторонняя диагностика тут будет совсем не излишней. Кстати,
                данная услуга в наших салон всегда и по умолчанию бесплатная.
              </p>
              <p className="repair-iphone-display__paragraph">
                Замена корпуса Айпад кажется операцией бесхитростной, и многие
                владельцы порываются сделать её самостоятельно. Настоятельно
                рекомендуем, всё же, доверится профессионалам. Во-первых, Эппл
                при сборке своих планшетов активно использует проприетарный
                крепёж - каждый раз заново изобретая новые разновидности винтов.
                Снять корпус iPad вы сможете только обладая специальной
                отвёрткой. Так как последняя изделие штучное - для каждой из
                моделей iPad существует своя отвёртка, то запалить за неё вам
                придётся весьма ощутимую сумму, превышающую стоимость ремонта.
                Да и понять - какая именно отвёртка подойдёт к вашей конкретной
                модели iPad дело непростое. К тому же, в абсолютном большинстве
                моделей купертиновских планшетов, при сборке активно
                используется специальный клей, и их разборка возможна только с
                применением специально фена. Который не только должен быть в
                наличии, но еще его обладатель должен обладать навыками
                обращения с ним.
              </p>
            </>
          }
        />
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 105px",
            marginTop: 55,
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Цены на замену корпуса iPad
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsIpadCase}
              text="корпуса/задней крышки"
            />
          </div>
        </Section>
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCase;
