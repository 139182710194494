import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/repair-ipadAir.jpeg";

const priceIpadAir = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "3400 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "5900 P",
  },
  {
    id: 3,
    option: "Замена корпуса/задней крышки",
    price: "7900 P",
  },
  {
    id: 4,
    option: "Замена аккумулятора",
    price: "3450 P",
  },
  {
    id: 5,
    option: "Замена микрофона",
    price: "2350 P",
  },
  {
    id: 6,
    option: "Замена динамика",
    price: "2450 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "1450 P",
  },
  {
    id: 8,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1950 P",
  },
  {
    id: 9,
    option: "Замена кнопки включения",
    price: "2100 P",
  },
  {
    id: 10,
    option: "Замена антенны",
    price: "2100 P",
  },
  {
    id: 11,
    option: "Ремонт Wi-Fi модуля",
    price: "5500 P",
  },
  {
    id: 12,
    option: "Замена датчика приближения",
    price: "2100 P",
  },
  {
    id: 13,
    option: "Замена сим-держателя",
    price: "950 P",
  },
  {
    id: 14,
    option: "Замена основной камеры",
    price: "2400 P",
  },
  {
    id: 15,
    option: "Замена передней камеры",
    price: "1900 P",
  },
  {
    id: 16,
    option: "Замена разъема зарядки",
    price: "2400 P",
  },
  {
    id: 17,
    option: "Замена разъема наушников",
    price: "2400 P",
  },
];

const textForBanner = [
  {
    id: 1,
    text: "Работа квалифицированных и умудрённых опытом мастеров,",
    number: "01",
  },
  {
    id: 2,
    text: "Ремонт с использованием только профессиональных инструментов,",
    number: "02",
  },
  {
    id: 3,
    text: "Большой запас, практически любых из необходимых, запчастей на складе,",
    number: "03",
  },
  {
    id: 4,
    text: "Бесплатная диагностика.",
    number: "04",
  },
];

const textOfIpadAir = [
  {
    id: 1,
    title: "Специфика ремонта iPad Air.",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Создавая iPad Air мастера и дизайнеры из Купертино, безусловно, денно
          и нощно думали об удобстве и комфорте пользователя. О чем же они не
          подумали, к сожалению, так это об удобстве ремонта своего продукта.
          Безусловно, прежде чем попасть на рынок, вся продукция Apple проходит
          через целый ряд суровых проверок. Поэтому заводской брак практически
          исключён. Столь же дотошно "яблочники" подходят и к цифровому
          наполнению своих планшетов. Однако, исключить элементарную
          неосторожность при обращении iPad Air никак нельзя. Падение и прочие
          бытовые неопрятности всё равно сделают ремонт планшета неизбежным. Вот
          тут и придётся столкнутся мастерам с хитростями сборки Айпэд Эйр.
        </p>
        <p className="repair-iphone-display__paragraph">
          Для крепления фронтальной панели и части других запчастей был щедро
          использован специальный клей. Как результат - добраться до начинки
          возможно только с использованием паяльного фена. При этом используются
          высокие температуры, и такой фен в неумелых руках может повредить как
          материнскую плату, так и другие части Айпэда.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          Вывод прост: разборка, как и ремонт iPad Air дело, которое ну никак не
          назовёшь простым, и его в никоем случае нельзя доверять кустарям, и уж
          тем более - пытаться осуществить самостоятельно.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          К счастью, помимо различных сомнительных ремонтников, есть и настоящие
          профессионалы. Такие как те, что работают в наших центрах.{" "}
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Последовательность ремонта iPad Air у нас. ",
    text: "После того как вы обратитесь к нам за помощью, ваш айпэд попадает на осмотр наших специалистов, задача которых — выявить все неисправности. Как уже говорилось раньше, диагностика у нас бесплатная. Далее, обсудив  с вами необходимые работы и их стоимость, мы приступаем к исправлению всех поломок. Работа выполняется как правило за 20 минут, и по её окончанию вам даётся гарантия.",
  },
];

const RepairIpadModelPagesIpadAir = () => {
  return (
    <Page title={"Ремонт iPad Air в Москве. Цены на ремонт Айпада Эир!"}>
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPad Air"
          text={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="Наша кампания специализируется на профессиональном устранении поломок продуктов Apple. В том числе и на ремонте iPad Air. Обратившись к нам, вы уже сегодня получите ваш исправный айпад на руки, в кратчайшие сроки и по разумной цене."
                paragraphUp2="Преимущества ремонта Айпэд Эйр у нас, это: "
                array={textForBanner}
              />
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpadAir}
            title="Цены на ремонт Айпад Эир"
            text="* Все цены указаны в рублях. Стоимость включает комплектующее и работу по установки комплектующего."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadAir.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadAir;
