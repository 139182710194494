import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera6splus.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera6sPlus = [
  {
    id: 1,
    title: "Когда нужна замена камеры iPhone 6s Plus?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          О проблеме владелец узнает благодаря определённым признакам, которые
          возникают в процессе эксплуатации устройства. Если не удаётся включить
          камеру или же снимки утратили былую чёткость и насыщенность оттенков,
          то это обычно происходит по следующим причинам:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Механические повреждения;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Влага или конденсат, скопившийся под корпусом;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Сбой, вызванный установкой постороннего ПО.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Более того, к нарушению работы камеры может привести и деформация
          корпуса. В этом случае недостаточно заменить исключительно
          поврежденную деталь, нужно ещё и отрихтовать или же полностью обновить
          корпус. Мы предоставим необходимые услуги, а расценки приятно порадуют
          даже самых требовательных клиентов.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки замены камеры Айфон 6s Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Это один из ключевых параметров для наших посетителей, ведь
          современный человек заинтересован в том, чтобы ни минуты времени не
          было потрачено впустую. Именно поэтому, нас столь важно обеспечить не
          только качественный, но и быстрый ремонт.
        </p>
        <p className="repair-iphone-display__paragraph">
          В сервисе трудится целая команда мастеров, а в наличии всегда есть
          достаточное количество деталей. Всего 20-30 минут, и вы сможете
          покинуть центр с восстановленным смартфоном. А для тех, кто не имеет
          возможности обратиться в Мак Профи самостоятельно, действует услуга
          курьерской доставки.
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: "Стоимость замены камеры iPhone 6s Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Для нас важна честность и открытость в отношении с клиентами. Именно
          поэтому информация о расценках на основные услуги представлены на
          сайте. Мы также строго учитываем пожелания владельца устройства.
          Поэтому, если вам потребуются дополнительные услуги, общая сумма может
          измениться - окончательная стоимость будет озвучена на основании
          информации, полученной в процессе диагностики устройства.
        </p>
        <p className="repair-iphone-display__paragraph">
          Приезжайте в сервисный центр Мак Профи, и мы позаботимся о том, чтобы
          замена камеры iPhone 6s Plus была выполнена качественно и аккуратно, и
          вы вновь могли эксплуатировать устройство в нормальном режиме.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels6sPlus = () => {
  return (
    <Page title="Замена камеры iPhone 6s Plus">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 6s Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 6s Plus в сервисном центре Мак Профи в
                Москве. Только качественные оригинальные запчасти,
                профессиональный ремонт, курьерская доставка.
              </p>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 6s Plus должна выполняться в сервисном
                центре. Заведение, специализирующееся на оказании определенного
                пакета услуг, оборудовано необходимым оборудованием, а в штате –
                квалифицированные мастера, которые пользуются фирменными
                инструментами. Мак Профи успешно соответствует всем требованиям
                – мы занимаемся ремонтом всех видов техники Apple. Поэтому, если
                вам необходима профессиональная помощь и вы не желаете
                переплачивать – у нас вы получите полный комплекс услуг на
                лояльных условиях.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="2900 P"
          priceDown="2200 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera6sPlus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels6sPlus;
