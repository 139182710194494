import React from "react";
import { MacProfiList, BreadCrumbs, RepairMacProBanner, Page, RepairMacProPrice } from '../index';

const RepairMacProPage = () => {
  return(
    <main className='repair-mb__page'>
      <Page title='Ремонт Mac Pro в Москве'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairMacProBanner />
        <RepairMacProPrice />
      </Page>
    </main>
  )
};

export default RepairMacProPage;