import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIwatchModeslLinks,
} from "../index";
import banner from "../../images/banners/iwatch1.jpeg";

const listOfTrouble = [
  {
    id: 1,
    text: "Замена ремешка – очень простая процедура, на которую будет потрачено всего несколько минут. В наличии доступны различные виды ремешков, а потому вы с легкостью подберете подходящий при посещении сервисного центра.",
    number: "01",
  },
  {
    id: 2,
    text: "Замена стекла – разбить дисплей не так уж просто, но все-таки на 100% защитить его от разбития невозможно. Чтобы удалить испорченную поверхность, необходимо нагреть устройство до заданной температуры, а также избавиться от излишков клея и мусора, что в домашних условиях сделать не так уж просто.",
    number: "02",
  },
  {
    id: 3,
    text: "Замена аккумулятора – батарея рассчитана на определенное количество циклов заряда, после чего предстоит установить новый элемент питания.",
    number: "03",
  },
  {
    id: 4,
    text: "Ремонт кнопок – если часы не включаются, то виновата в этом может быть кнопка Power. Наши мастера заменят её всего за 15-20 минут, после чего устройство возобновит нормальную работу.",
    number: "04",
  },
];

const textIwatch1 = [
  {
    id: 1,
    title: "Основные виды ремонта Apple Watch Series 1",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Безусловно, предугадать какой из элементов системы выйдет из строя невозможно, но существует наиболее распространенные поломки, с которым к нам чаще всего и обращаются клиенты. Мастера регулярно выполняют следующий ремонт Apple Watch Series 1:"
          array={listOfTrouble}
          paragraphDown="Это базовые услуги, которые требуются чаще других, но наши мастера готовы к проведению и более сложного ремонта. В сервисе всегда есть достаточный запас комплектующих, а также все необходимые расходные материалы, инструменты и оборудование – поручите нам эту задачу, и мы выполним её на высшем уровне."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки и стоимость ремонта Apple Watch Series 1",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Длительность процедуры зависит от характера повреждений. Если нужно
          просто заменить ремешок, то мастеру достаточно выполнить пару
          простейших действий, чтобы клиент ушел в обновленных часах. При
          наличии серьезных механических повреждений, длительность ремонта будет
          несколько увеличена – потребуется полностью разобрать устройство,
          чтобы установить новый экран или отрихтовать корпус устройства.
          Поэтому точные сроки, как стоимость работ, будут названы
          непосредственно при посещении центра.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          Но вы можете не сомневаться, что наши цены достаточно лояльны – мы
          работаем ежедневно, в том числе в праздники и выходные, а потому вы
          можете посетить сервис Мак Профи в любой удобный день, оценив все
          преимущества нашего предложения.
        </p>
      </>
    ),
  },
];

const RepairIwatchModelLink1 = () => {
  return (
    <Page title="Срочный ремонт Apple Watch Series 1">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Ремонт Apple Watch&nbsp;Series&nbsp;1</>}
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ремонт Apple Watch Series 1 в Москве: быстро, ответственно,
                недорого. Только лучшие запчасти для стабильной и долгой работы
                вашего устройства.
              </p>
              <p className="repair-iphone-display__paragraph">
                Apple Watch Series 1 – многофункциональное устройство, которое в
                полной мере оценят те, кто отдает предпочтение активному образу
                жизни. Часы удобны благодаря широкому ремешку, а также огромному
                спектру возможностей, открывающихся перед владельцем. Вот только
                по разным причинам работа гаджета может быть нарушена – если это
                произошло, обратитесь в сервис Мак Профи, и мы отремонтируем
                устройство.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIwatch1.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIwatchModeslLinks />
      </main>
    </Page>
  );
};

export default RepairIwatchModelLink1;
