import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "../NoteExpertReasonsLinks/NoteExpertInsidePage.module.css";

const callbackText = [
  "Windows различных версий (8, 7, XP, Vista) являются наиболее распространенным вариантом, к которому обращаются пользователи ноутбуков различных марок, когда речь идет об установке или переустановке операционной системы на свой портативный ПК.",
  "Установка Windows на новый ноутбук, грамотно и корректно выполненная компетентными специалистами Note Expert, значительно повысит вероятность стабильной работы вашего мобильного устройства и избавит вас от многих проблем во время его эксплуатации.",
  "Точно так же можно заставить работать ноутбук, потерявший свою работоспособность или производительность после продолжительного использования.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Услуги по установке Windows XP, 7 или 8 на ноутбуки различных марок, которые профессионально оказывают специалисты сервисных центров, обязательно сопровождаются их полной настройкой и оптимизацией под нужды пользователя. В Note Expert после установки системы на ноутбук вам непременно подберут, инсталлируют все необходимые драйвера, утилиты последних версий и протестируют мобильное устройство в рабочем режиме.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "У наших экспертов, обладающих приличным опытом и высокой квалификацией, эта работа укладывается в самый короткий интервал времени.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Казалось бы, установить ОС на свой ноутбук вполне можно самостоятельно, а значит, бесплатно.",
  },
  {
    icon: priceIcon2,
    text: "Однако, кроме того, что у неопытного пользователя это займет достаточно много времени, некорректная установка Windows 7, 8, XP может привести к потере важных данных и полной дестабилизации работы ноутбука.",
  },
  {
    icon: priceIcon3,
    text: "Стоит ли рисковать, учитывая, что профессиональная установка и настройка Windows разных версий в нашем сервисе осуществляется по весьма комфортным ценам. Самые популярные услуги из этой категории – установка Windows XP и Windows 7, цены на которые одни из лучших в Москве.",
  },
];

const prices = [
  {
    text: "Программная диагностика",
    price: "БЕСПЛАТНО",
  },
  {
    text: "Инсталяция XP Home",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Инсталяция Vista Home",
    price: "2500 P",
  },
  {
    text: "Инсталяция 7 Home",
    price: "3500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Инсталяция Windows 8",
    price: "4500 P",
  },
  {
    text: "Настройка Windows",
    price: "300 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Одна из самых востребованных услуг нашего центра – установка Windows на новые ноутбуки, включая те, которые уже оснащены другими ОС (например, Linux).",
  "Кроме этого, загрузить на ноутбук ОС Windows подходящей версии может потребоваться при нестабильной работе или участившихся сбоях ее текущей версии (зависаниях, самопроизвольной перезагрузке, медленной работе, др.), обнаружении вирусов, ошибок, делающих ее использование невозможным, или при отсутствии на ноутбуке рабочей версии ОС.",
];

function NoteExpertGeneralPageServiceWindows() {
  return (
    <Page title="Установка ОС Windows на ноутбуке от 300 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Установка Windows в сервисе Note Expert"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Особенности профессиональной установки ОС"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Установка Windows на ноутбук – стоимость"
          subtitle="Стоимость установки Windows"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Когда и для чего нужна установка/ переустановка Windows"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPageServiceWindows;
