import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone5c.jpeg";

const textOfModel5c = [
  {
    id: 1,
    title: "Особенности ремонта Айфон 5c",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Среди владельцев айфонов распространён миф, что раз уж они обладают
          одним из наиболее совершенных девайсов современного техномира, то
          возможность поломки - это не про их смартфон. К сожалению это не так.
          Конечно, большинство программных ошибок, и уж, тем более, возможность
          заводского брака, для айфонов, в отличие от их дешёвых китайских
          собратьев, исключены. Но и только. Раз вы оказались на нашем сайте,
          можем у уверенностью предположить - на деле вы убедились, что iPhone
          5c так же подвержен механическим повреждениям, боится влаги, у него
          может барахлить аккумулятор и износиться шлейф, как и у других
          смартфонов.
        </p>
        <p className="repair-iphone-display__paragraph">
          Специфика ремонта iPhone 5c связана с его конструктивными
          особенностями и технологией сборки. Так как передняя панель и
          сенсорный экран надёжно склеены между собой, разбитое стекло заменить
          в одиночку, к сожалению, не возможно. В стоимость ремонта надо
          закладывать замену всего дисплейного модуля. При сборке на разъемы
          антенн и батарею специалисты Apple также не пожалели специального
          клея. Это значит: при разборке и замене соответствующих деталей
          понадобится специальный фен. Его наличие и умение с ним обращаться -
          минимально необходимые условия, чтобы залазить внутрь Айфон 5с. И то и
          другое есть у наших специалистов - так что обратиться за ремонтом
          iPhone 5c лучше именно к ним, а не пытаться решить проблемы в домашних
          условиях.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Последовательность ремонта Айфон 5c у нас",
    text: (
      <>
        Диагностика. С неё все начинается. Причём у нас она бесплатная. Мы
        выявляем все неисправности и обсуждаем с вами виды и окончательную
        стоимость ремонта. Далее начинается сам ремонт. Стандартные "болячки"
        iPhone 5c мы можем излечить за 25 минут. Вы получаете ваш исправный
        телефон назад на руки и можете проверить его. Собственно и всё. Вместо
        маленькой трагедии поломка вашего Айфона, благодаря нам, превращается в
        малозначимый эпизод в его жизни, о котором вы можете благополучно
        забыть!
      </>
    ),
  },
];

const price5c = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage5c = () => {
  return (
    <Page title="Ремонт iPhone 5C в Москве - цены, сроки, гарантия">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone 5c"
          text={
            <>
              Если вам необходим ремонт iPhone 5c быстро, качественно и недорого
              вы можете получить его у нас. Производим ремонт любого уровня
              сложности.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price5c}
            title="Цены на ремонт iPhone 5c"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModel5c.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage5c;
