import React, { useState } from "react";
import Select, { components } from "react-select";
import vector from "../../images/icons/Vector.svg";
import Section from "../Section/Section";
import { RepairIphonePriceList } from "../index";
import {
  iphone4PriceGeneralPage,
  iphone4sPriceGeneralPage,
  iphone5PriceGeneralPage,
  iphone5cPriceGeneralPage,
  iphone5sPriceGeneralPage,
  iphoneSEPriceGeneralPage,
  iphone6PriceGeneralPage,
  iphone6sPriceGeneralPage,
  iphone6PlusPriceGeneralPage,
  iphone6sPlusPriceGeneralPage,
  iphone7PriceGeneralPage,
  iphone7PlusPriceGeneralPage,
  iphone8PriceGeneralPage,
  iphone8PlusPriceGeneralPage,
  iphoneXPriceGeneralPage,
  iphoneXSPriceGeneralPage,
  iphoneXSMaxPriceGeneralPage,
  iphone11PriceGeneralPage,
  iphone11ProPriceGeneralPage,
  iphone11ProMaxPriceGeneralPage,
  iphoneSE2020PriceGeneralPage,
  iphone12PriceGeneralPage,
  iphone12miniPriceGeneralPage,
  iphone12ProPriceGeneralPage,
  iphone12ProMaxPriceGeneralPage,
  iphone13PriceGeneralPage,
  iphone13miniPriceGeneralPage,
  iphone13ProPriceGeneralPage,
  iphone13ProMaxPriceGeneralPage,
} from "../../utils/iphoneModels";
import { Carousel } from "react-responsive-carousel";
import { useMobileScreen } from "../../utils/useMedia";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img alt="" src={vector} className="repair-iphone__price-padding-img" />
  </components.IndicatorsContainer>
);

function ChangePriceList(value) {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return value ? (
      <div
        style={{
          maxWidth: 274,
          display: "flex",
          backgroundColor: "transparent",
        }}
      >
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(0, 6)} />
          </div>

          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(6, 12)} />
          </div>

          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(12)} />
          </div>
        </Carousel>
      </div>
    ) : null;
  }
  return value ? (
    <div className="repair-iphone__pricelist-container">
      <ul className="repair-iphone__pricelist-list">
        <RepairIphonePriceList list={value.list} />
      </ul>
      <div className="repair-iphone__pricelist-border"></div>
      <p className="repair-iphone__pricelist-paragraph">
        * Все цены указаны в рублях РФ.<br></br>
        Цена включает комплектующее и услугу по установке.
      </p>
    </div>
  ) : null;
}

const RepairIphonePrice = () => {
  const [value, setValue] = useState("");

  const handleSelectChange = (options) => {
    console.log(options.value);
    setValue(options);
  };

  const options = [
    { value: "iPhone 4", label: "iPhone 4", list: iphone4PriceGeneralPage },
    { value: "iPhone 4s", label: "iPhone 4s", list: iphone4sPriceGeneralPage },
    { value: "iPhone 5", label: "iPhone 5", list: iphone5PriceGeneralPage },
    { value: "iPhone 5c", label: "iPhone 5c", list: iphone5cPriceGeneralPage },
    { value: "iPhone 5s", label: "iPhone 5s", list: iphone5sPriceGeneralPage },
    { value: "iPhone SE", label: "iPhone SE", list: iphoneSEPriceGeneralPage },
    { value: "iPhone 6", label: "iPhone 6", list: iphone6PriceGeneralPage },
    { value: "iPhone 6s", label: "iPhone 6s", list: iphone6sPriceGeneralPage },
    {
      value: "iPhone 6 Plus",
      label: "iPhone 6 Plus",
      list: iphone6PlusPriceGeneralPage,
    },
    {
      value: "iPhone 6s Plus",
      label: "iPhone 6s Plus",
      list: iphone6sPlusPriceGeneralPage,
    },
    { value: "iPhone 7", label: "iPhone 7", list: iphone7PriceGeneralPage },
    {
      value: "iPhone 7 Plus",
      label: "iPhone 7 Plus",
      list: iphone7PlusPriceGeneralPage,
    },
    { value: "iPhone 8", label: "iPhone 8", list: iphone8PriceGeneralPage },
    {
      value: "iPhone 8 Plus",
      label: "iPhone 8 Plus",
      list: iphone8PlusPriceGeneralPage,
    },
    { value: "iPhone X", label: "iPhone X", list: iphoneXPriceGeneralPage },
    { value: "iPhone XS", label: "iPhone XS", list: iphoneXSPriceGeneralPage },
    {
      value: "iPhone XS Max",
      label: "iPhone XS Max",
      list: iphoneXSMaxPriceGeneralPage,
    },
    { value: "iPhone 11", label: "iPhone 11", list: iphone11PriceGeneralPage },
    {
      value: "iPhone 11 Pro",
      label: "iPhone 11 Pro",
      list: iphone11ProPriceGeneralPage,
    },
    {
      value: "iPhone 11 Pro Max",
      label: "iPhone 11 Pro Max",
      list: iphone11ProMaxPriceGeneralPage,
    },
    {
      value: "iPhone SE 2020",
      label: "iPhone SE 2020",
      list: iphoneSE2020PriceGeneralPage,
    },
    { value: "iPhone 12", label: "iPhone 12", list: iphone12PriceGeneralPage },
    {
      value: "iPhone 12 mini",
      label: "iPhone 12 mini",
      list: iphone12miniPriceGeneralPage,
    },
    {
      value: "iPhone 12 Pro",
      label: "iPhone 12 Pro",
      list: iphone12ProPriceGeneralPage,
    },
    {
      value: "iPhone 12 Pro Max",
      label: "iPhone 12 Pro Max",
      list: iphone12ProMaxPriceGeneralPage,
    },
    { value: "iPhone 13", label: "iPhone 13", list: iphone13PriceGeneralPage },
    {
      value: "iPhone 13 mini",
      label: "iPhone 13 mini",
      list: iphone13miniPriceGeneralPage,
    },
    {
      value: "iPhone 13 Pro",
      label: "iPhone 13 Pro",
      list: iphone13ProPriceGeneralPage,
    },
    {
      value: "iPhone 13 Pro Max",
      label: "iPhone 13 Pro Max",
      list: iphone13ProMaxPriceGeneralPage,
    },
  ];

  return (
    <Section className="repair-iphone__price">
      <div className="repair-iphone__price-container">
        <h2 className="repair-iphone__price-title">Цены на ремонт iPhone</h2>
        <p className="repair-iphone__price-text">
          Для уточнения стоимости услуг по ремонту, выберите вашу модель iPhone:
        </p>
        <Select
          components={{ IndicatorsContainer }}
          options={options}
          onChange={handleSelectChange}
          placeholder="Выберите модель"
          styles={{
            container: (styles) => ({
              ...styles,
              width: 543,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "31px",
              color: "#88898D;",
              boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
              margin: "auto",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 370,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 274,
                fontWeight: "400",
                fontSize: "12px",
              },
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingLeft: 32,
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                paddingLeft: 21,
              },
            }),
            control: (styles) => ({
              ...styles,
              borderRadius: 19,
              borderColor: null,
              border: "none",
              boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
              "&:hover": null,
            }),
            option: (styles, state) => ({
              ...styles,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#88898D",
              borderRadius: 6,
              marginLeft: 7,
              marginRight: 7,
              width: 440,
              backgroundColor: state.isSelected
                ? "rgba(2, 143, 232, 0.06)"
                : styles.backgroundColor,
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 320,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 255,
              },
            }),
            menuList: (styles) => ({
              ...styles,
              width: 472,
              backgroundColor: "#fff",
              borderRadius: 20,
              maxHeight: 232,
              overflow: "auto",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 274,
              },
            }),
            menu: (styles) => ({
              ...styles,
              width: 472,
              maxHeight: 232,
              borderRadius: 6,
              boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 270,
              },
            }),
          }}
        />
        {ChangePriceList(value)}
      </div>
    </Section>
  );
};

export default RepairIphonePrice;
