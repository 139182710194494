import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpad3.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Механические воздействия – падения или удары опасны не только внешними повреждениями (стекло, корпус), но и внутренними, которые могут проявиться не сразу.",
    number: "01",
  },
  {
    id: 2,
    text: "Проникновение жидкости внутрь устройства – если вы залили планшет водой, лучше сразу обратиться в сервисный центр, чтобы не допустить короткого замыкания.",
    number: "02",
  },
  {
    id: 3,
    text: "Использование планшета при низких температурах – аккумуляторы очень «не любят» холод, начинают быстрее разряжаться и могут выйти из строя",
    number: "03",
  },
];

const listOfRecommendations = [
  {
    id: 1,
    text: "Установят качественные элементы питания, соответствующие техническим характеристикам планшета",
    number: "01",
  },
  {
    id: 2,
    text: "Предоставят гарантии на используемые запчасти и предоставленные услуги",
    number: "02",
  },
  {
    id: 3,
    text: "Выполнят поставленную задачу быстро и аккуратно, не оставив следов на корпусе устройства.",
    number: "03",
  },
];

const textOfBattery3 = [
  {
    id: 1,
    title: "Замена аккумулятора iPad 3: основные причины",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="В большинстве случаев замена аккумулятора iPad 3 требуется по причине естественного износа элемента питания – он не вечен и рассчитан лишь на определенное количество циклов заряда. Но иногда ремонт требуется и по вине владельцев, что связано со следующими ситуациями:"
          array={listOfTrouble}
          paragraphDown="Это лишь основные причины, из-за которых значительно сокращается период автономной работы iPad, но на самом деле их гораздо больше. Именно поэтому, специалисты Мак Профи изначально проводят диагностику, определяют причину возникновения неисправности, а также оценивают техническое состояние устройства."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Можно ли заменить аккумулятор iPad 3 самостоятельно",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чтобы заменить аккумулятор, потребуется практически полностью разобрать устройство. Более того, для открытия корпуса необходимо растопить клей, что требует использования специального оборудования. Поэтому приниматься за решение этой задачи самостоятельно не стоит – возможные риски не оправдывают сомнительную экономию. Гораздо дешевле доверить работу квалифицированным мастерам, которые:"
          array={listOfRecommendations}
          paragraphDown="При необходимости ремонт может быть выполнен в срочном порядке – замена аккумулятора iPad 3 займет меньше часа. Свяжитесь с нами для уточнения сроков и стоимость, либо сразу приезжайте в сервисный центр для диагностики и восстановления устройства."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModel3 = () => {
  return (
    <Page title="Замена аккумулятора iPad 3 в Москве по доступной цене в Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad 3"
          text="Сразу после покупки iPad 3 радует поистине безупречной работой, а емкость аккумулятора позволяет наслаждаться работой или развлечениями, забыв о подзарядке. Вот только со временем, этот показатель начинает ухудшаться, а батарея разряжается всё быстрее – если раньше её было достаточно на день, то теперь этот период сократился до нескольких часов. Чтобы исправить подобную проблему – обратитесь в сервисный центр Мак Профи, и наши мастера предоставят полный комплекс услуг, начиная от диагностики и заканчивая передачей обновленного устройства владельцу."
        />
        <RepairApplePrice text="Замена аккумулятора" price="2850 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery3.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModel3;
