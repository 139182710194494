import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Причиной выхода из строя материнской платы, самой важной и самой дорогой части ноутбука, могут быть перепады напряжения, перегрев чипсетов, механические повреждения внутренних компонентов и т.д.",
  "Специалисты Note Expert выявят неисправности с помощью компьютерной диагностики и устранят их за короткое время: произведут замену чипов, портов, разъемов, выполнят ремонт северного/ южного мостов, дорожек, цепи питания, любые другие операции, необходимые для восстановления материнской платы и ее рабочих характеристик. ",
  "В том случае, если выявленные при диагностике устройства неисправности носят фатальный характер, вам будет предложена замена материнской платы, которую в нашем центре выполняют так же качественно и оперативно.",
];

const advantages = [
  {
    title: "ВЫСОКОТОЧНАЯ ДИАГНОСТИКА",
    text: "Ремонт материнской платы ноутбука – технически сложная задача, требующая проведения высокоточной предварительной диагностики для обнаружения конкретного виновника поломки среди ее многочисленных компонентов.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "В нашем центре работают специалисты с опытом и квалификацией, позволяющими им выполнять ремонт материнских плат любого уровня сложности. Для этого у нас есть все необходимое ремонтно-диагностическое оборудование, программное обеспечение, полный ассортимент запчастей в наличии на собственном складе.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Любой сбой в работе ноутбука, компоненты которого расположены в тесном соседстве и взаимосвязи, способен повлечь за собой цепь новых, еще более серьезных проблем.",
  },
  {
    icon: priceIcon2,
    text: "Выполнить ремонт материнской платы быстро – в ваших интересах!",
  },
  {
    icon: priceIcon3,
    text: "Мы предлагаем все виды ремонтных работ для ее качественного восстановления по самым оптимальным ценам, итоговая стоимость определяется после проведения диагностики, которая осуществляется бесплатно.",
  },
];

const prices = [
  {
    text: "Аппаратная диагностика ноутбука",
    price: "Бесплатно",
  },
  {
    text: "Ремонт цепи питания",
    price: "от 2500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Перепрошивка BIOS",
    price: "от 2000 P",
  },
  {
    text: "Замена/реболл мостов",
    price: "от 3500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Ремонт/заменаразъемов",
    price: "от 700 P",
  },
  {
    text: "Замена/реболл видеочипа",
    price: "от 3000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Вам стоит принести свой ноутбук в Note Expert и показать его нашим специалистам, если он демонстрирует хотя бы один из следующих признаков: не реагирует на нажатие кнопки «Пуск», в работе появились сбои, есть проблемы со звуком или изображением, не обращается к жесткому диску, перестали функционировать внутренние устройства (Bluetooth, Wi-Fi, USB, др.), не работают touchpad, клавиатура, порты, греются или повреждены разъемы.",
  "Эти и некоторые другие симптомы свидетельствуют о выходе из строя материнской платы и о том, что ей срочно нужен ремонт или замена.",
  "Не затягивайте с решением вопроса, если проблема уже дала о себе знать! Свяжитесь с нашим экспертом по телефону прямо сейчас. Или закажите обратный звонок на сайте, и мы сами перезвоним вам в самое ближайшее время.",
];

function NoteExpertNoteSettingMotherboard() {
  return (
    <Page title="Диагностика и ремонт материснкой платы ноутбука от 700 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Ремонт материнской платы в Note Expert"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Особенности ремонта материнских плат на ноутбуках"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Цены на ремонт материнской платы ноутбука в Note Expert"
          subtitle="Цены на ремонт материнской платы"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Симптомы неисправности материнской платы"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingMotherboard;
