import React from "react";
import { NavLink } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleTextWithCheckMark,
  Section,
  RepairIMacLinks,
  RepairAppleTableMobile,
} from "../index";
import banner from "../../images/banners/imac-hard.jpeg";
import { useMedia } from "../../utils/useMedia";

const textBanner = [
  {
    id: 1,
    text: "Недостаточно объема для сохранения информации. При увеличении количества контента часто не хватает места для его размещения. Замена жесткого диска на iMac на диск большего объема решит данную проблему.",
    number: "01",
  },
  {
    id: 2,
    text: (
      <>
        Жесткий диск в iMac вышел из строя. Характерная неисправность с
        симптомами: щелчки, зависания iMac в процессе работы, не грузится,
        жесткий диск отсутствует в списке устройств при выборе загрузочных
        устройств при старте iMac через кнопку ALT. В данном случае наряду с
        заменой жесткого диска, мы также можем вам предложить и{" "}
        <NavLink
          to="/remont-apple/remont-macbook/vosstanovlenie_dannyh_na_MacBook_iMac"
          className="repair-mb__group-links-array"
        >
          восстановление данных с HDD и SSD на iMac.
        </NavLink>
      </>
    ),
    number: "02",
  },
  {
    id: 3,
    text: "Необходимо установить высокоскоростной диск SSD. В данном случае мы рекомендуем ставить SSD вторым жестким диском в optibay (оптибэй). Установка диска в оптибэй поможет сохранить вам старый жесткий диск на котором у вас хранится информация и добавить второй высокоскоростной диск SSD как основной, на котором установлена операционная система Mac OS.",
    number: "03",
  },
];

const iMacDiskPrice1 = [
  {
    service: "Замена жесткого диска iMac",
    price: "7800 р.",
    time: "	2 часа",
  },
];
const iMacDiskPrice2 = [
  {
    service: "Замена жесткого диска iMac",
    price: "11200 р.",
    time: "	2 часа",
  },
];
const iMacDiskPrice3 = [
  {
    service: "Замена жесткого диска iMac",
    price: "14200 р.",
    time: "	2 часа",
  },
];
const iMacDiskPrice4 = [
  {
    service: "Замена жесткого диска iMac",
    price: "16700 р.",
    time: "	2 часа",
  },
];
const iMacDiskPrice5 = [
  {
    service: "Установка второго жесткого диска iMac (Необходим optibay)",
    price: "3000 + стоимость диска SSD/HDD на выбор",
    time: "	2 часа",
  },
];

const hardDiskOptions = [
  {
    value: "500 GB.",
    label: "500 GB.",
    list: iMacDiskPrice1,
  },
  {
    value: "1 TB.",
    label: "1 TB.",
    list: iMacDiskPrice2,
  },
  {
    value: "1.5 TB.",
    label: "1.5 TB.",
    list: iMacDiskPrice3,
  },
  {
    value: "2 TB.",
    label: "2 TB.",
    list: iMacDiskPrice4,
  },
  {
    value: "Установка второго жесткого диска iMac",
    label: "Установка второго жесткого диска iMac",
    list: iMacDiskPrice5,
  },
];

function ChangeTableIMac() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={hardDiskOptions}
        serviceText="Наименование услуги"
      />
    );
  }
  return (
    <table>
      <thead>
        <tr className="mb-matrix__table-title">
          <th>НАИМЕНОВАНИЕ УСЛУГИ</th>
          <th>Объем диска</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена жесткого диска iMac</td>
          <td>500 GB.</td>
          <td>7800 р.</td>
          <td>2 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена жесткого диска iMac</td>
          <td>1 TB.</td>
          <td>11200 р.</td>
          <td>2 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена жесткого диска iMac</td>
          <td>1,5 TB.</td>
          <td>14200 р</td>
          <td>2 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена жесткого диска iMac</td>
          <td>2 TB.</td>
          <td>16700 р.</td>
          <td>2 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка второго жесткого диска iMac</td>
          <td>Необходим оптибэй (optibay)</td>
          <td>3000 + стоимость диска SSD/HDD на выбор</td>
          <td>2 часа</td>
        </tr>
      </thead>
    </table>
  );
}

const RepairIMacLinkHardDisk = () => {
  return (
    <Page title="Замена жесткого диска на iMac в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена жесткого диска на iMac в Москве"
          text={
            <RepairAppleTextWithCheckMark
              paragraphUp={
                <>
                  Предоставляем услугу по замене жесткого диска на iMac всех
                  моделей и годов выпуска. По какой причине стоит менять жесткий
                  диск в iMac:
                </>
              }
              array={textBanner}
            />
          }
        />
        <Section className="section_group" style={{ paddingBottom: 18 }}>
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену жесткого диска iMac
            </h2>
            {ChangeTableIMac()}
            <p className="repair-mb__group-links-paragraph">
              Стоимость диска для iMac постоянно меняется в меньшую сторону,
              ввиду этого уточняйте точную стоимость у оператора по телефону.
            </p>
          </div>
        </Section>
        <RepairIMacLinks />
      </main>
    </Page>
  );
};

export default RepairIMacLinkHardDisk;
