import React from "react";
import {
  AboutUs,
  AboutUsMobile,
  Advantages,
  Banner,
  Feedbacks,
  Notification,
  OurCompanies,
  OurCompaniesMobile,
  Partners,
  BreadCrumbs,
  AdvantagesMobile,
  FeedbacksMobile,
  PartnersMobile,
  Page,
} from "../index";
import useMedia from "use-media";
import Contacts from "../Contacts/contacts";
import { Helmet } from "react-helmet";

const Main = ({ setIsOpen }) => {
  const isWideMobile = useMedia({ minWidth: 500 });
  const isWideMidle = useMedia({ minWidth: 850 });
  const ourCompanies = useMedia({ minWidth: 600 });

  return (
    <main className="main">
      <Page title="Oh!MyGadget! — ремонт телефонов, планшетов, ноутбуков, моноблоков">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Хотите знать, что такое Oh!MyGadget!? Это просто. Oh!MyGadget! - современная служба быстрого восстановления любых гаджетов."
          />
        </Helmet>
        <Notification />
        <Banner />
        <BreadCrumbs />
        {isWideMidle ? <AboutUs /> : <AboutUsMobile />}
        {ourCompanies ? <OurCompanies /> : <OurCompaniesMobile />}
        {isWideMidle ? <Advantages /> : <AdvantagesMobile />}
        {isWideMobile ? <Feedbacks /> : <FeedbacksMobile />}
        {isWideMobile ? <Partners /> : <PartnersMobile />}
        <Contacts />
      </Page>
    </main>
  );
};

export default Main;
