import React, { useEffect, useState } from "react";
import { SectionTitle, Slider, FeedbackCard, Section } from "../index";
import PopupAddFeedback from "../ui/PopupAddFeedback/PopupAddFeedback";
import { feedbacksApi } from "../../utils/api/index";
import usePopup from "../../hooks/usePopup";

const Feedbacks = () => {
  const [approvedFeedbacks, setApprovedFeedbacks] = useState([]);
  const [isError, setIsError] = useState(false);
  const { isPopupOpen, togglePopup, closePopup } = usePopup();

  useEffect(() => {
    const fetchApprovedFeedbacks = async () => {
      try {
        const approvedFeedbacks = await feedbacksApi.getFeedbacks({
          approved: true,
          limit: 25,
        });
        setApprovedFeedbacks(approvedFeedbacks.results);
      } catch (error) {
        setIsError(true);
      }
    };

    fetchApprovedFeedbacks();
  }, []);

  return (
    <>
      {!isError && (
        <Section id="otzyvy" className="feedbacks">
          <div className="feedbacks__container">
            <SectionTitle title="Отзывы" className="feedbacks__title" />
            <h4 className="feedbacks__subtitle">
              Мы стремимся выполнять свою работу качественно и несем полную
              ответственность за каждое свое действие. Поэтому с благодарностью
              принимаем как положительные отзывы, так и критические замечания.
            </h4>
            <Slider>
              {approvedFeedbacks.map((feedback) => {
                return <FeedbackCard key={feedback._id} feedback={feedback} />;
              })}
            </Slider>
            <button className="feedbacks__button" onClick={togglePopup}>
              Разместить отзыв
            </button>
          </div>
          <PopupAddFeedback isOpen={isPopupOpen} onClose={closePopup} />
        </Section>
      )}
    </>
  );
};

export default Feedbacks;
