import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery7plus.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery7Plus = [
  {
    id: 1,
    title: "Из-за чего возникают проблемы с аккумулятором iPhone 7 Plus?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Аккумулятор, используемый в Айфон 7 Плюс, быстро заряжается и долго
          держит заряд. Вот только при активной эксплуатации устройства батарея
          теряет свою емкость, что приводит к её постепенному выходу из строя.
          Поломка может произойти и по другим причинам, начиная от попадания
          влаги так и сбоев в работе программного обеспечения. Именно поэтому
          специалисты нашего центра проведут комплексную бесплатную диагностику
          – это позволит определить истинную причину поломки.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена аккумулятора iPhone 7 Plus: когда нужна новая батарея?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Вам необходима срочная замена аккумулятора на iPhone 7 Plus, при
          появлении одного из следующих признаков:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Телефон неожиданно выключается, например, во время входящих
              вызовов или других действий
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Устройство перегревается в процессе использования или зарядка
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Смартфон очень медленно заряжается
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Айфон быстро заряжается, но и быстро теряет зарядку
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Для устранения возникшей поломки потребуются специальные инструменты,
          которые помогут аккуратно извлечь испорченную батарею и заменить её
          новым аккумулятором. Мы постоянно поддерживаем достаточный запас
          комплектующих в наличии, благодаря чему вам не приходится тратить
          время на ожидание – все работы будут завершены в кратчайшие сроки.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels7Plus = () => {
  return (
    <Page title="Доступная замена батареи (аккумулятора) на iPhone 7 Plus за 35 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора на iPhone 7 Plus"
          text="Стандартный Li-ion аккумулятор рассчитан на срок около 2-х лет. Вот только из-за ряда негативных факторов, таких как резкие температурные перепады, избыточная влажность, периодические полные разряды – период эксплуатации батареи значительно снижается. Чтобы не испытывать неудобств из-за того, что телефон постоянно выключается, и необходимость постоянно «жить на подзарядке» – обратитесь в сервисный центр «Мак Профи» в Москве. Наши мастера быстро устранят возникшую поломку – вам понадобится меньше часа, чтобы полностью восстановить устройство."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery7Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена батареи на iPhone 7 Plus?"
          text="Замена аккумулятора"
          price="2400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                На нашем сайте вы можете ознакомиться с базовыми расценками на
                все виды услуг. Окончательная стоимость зависит от того, что
                послужило причиной поломки. Кроме того, в процессе диагностики
                могут быть выявлены и другие повреждения, например, если
                устройство было залито жидкостью или же повреждено из-за
                сильного удара.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Чтобы узнать подробнее о стоимости и сроках замены батареи на
                iPhone 7 Plus, свяжитесь с нами уже сегодня или приезжайте в
                сервисный центр. Работаем ежедневно, с понедельника по
                воскресенье, чтобы каждый клиент мог получить необходимые услуги
                в удобное время и по честным ценам. Обращайтесь к нам, чтобы
                получить высокий уровень сервиса и необходимые гарантии
                качества.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels7Plus;
