import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadAir.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Профессиональный подход – работаем быстро, но аккуратно, чтобы в кратчайшие сроки восстановить устройство.",
    number: "01",
  },
  {
    id: 2,
    text: "Бесплатная диагностика – протестируем iPad Air с помощью специального оборудования, чтобы убедиться в его работоспособности и отсутствии скрытых повреждений.",
    number: "02",
  },
  {
    id: 3,
    text: "Прозрачная ценовая политика – мы не завышаем стоимость, устанавливая честные и выгодные расценки на все услуги.",
    number: "03",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Замена стекла iPad Air – тонкая, ювелирная работа",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Среди отличительных особенностей новой модели, пришедшей на смену iPad
          4, стал уменьшенный вес и размер (как по толщине, так и по ширине).
          Это позволило получить легкий, удобный планшет, не лишенный главного
          недостатка – любое падение или сдавливание может повредить хрупкое
          стекло.
        </p>
        <p className="repair-iphone-display__paragraph">
          Самостоятельно устранить возникший дефект довольно сложно, ведь сделав
          устройство компактным, производитель практически не оставил свободного
          места под корпусом. Действовать нужно очень аккуратно, что делает
          замену стекла iPad Air практически ювелирной работой – и наши мастера
          успешно справятся с этой задачей.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Преимущества замены стекла iPad Air в Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="За годы работы в этой сфере мы заслужили безупречную репутацию – нам доверяют, рекомендуя сервисный центр Мак Профи друзьям и знакомым. Потому что именно у нас:"
          array={listOfTrouble}
          paragraphDown="Если вы не можете обратиться в сервис самостоятельно, воспользуйтесь услугой курьерской доставки – и мы в удобное для заказчика время прибудем по указанному адресу, чтобы забрать устройство."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelAir = () => {
  return (
    <Page title="Замена стекла iPad Air (Айпад Эйр) в сервисе Мак Профи в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Замена стекла iPad&nbsp;Air</>}
          text="Ежедневно к нам обращаются клиенты с просьбой заменить стекло на их устройстве – это одна из самых частых причин посещения сервисного центра. Увеличенная площадь экрана приводит к тому, что даже легкого удара достаточно для его повреждения. А в результате, трещины и сколы мешают комфортной работы – постепенно они лишь увеличиваются, а потому обойтись без помощи мастеров не удастся. В сервисном центре Мак Профи вы сможете получить все необходимые услуги по доступным ценам – для постоянных клиентов действуют специальные условия."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена стекла iPad Air"
          text="Замена сенсорного стекла (тачскрина)"
          price="3400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Цена замены стекла iPad Air несколько возросла, в сравнении с
                предыдущими моделями. Это связано с тем, что все элементы
                конструкции настолько миниатюрны, что работа требует повышенной
                аккуратности – справиться с ней сможет только опытный
                квалифицированный мастер. Доверив нам планшет, вам не придется
                беспокоиться о его сохранности, к тому же на все виды работ
                действует долгосрочная гарантия.
              </p>
              <p className="repair-iphone-display__paragraph">
                Позвоните нам, чтобы узнать подробнее о ценах, сроках и других
                условиях, или же сразу приезжайте в сервис. Наши двери открыты
                для вас 7 дней в неделю – замена стекла iPad Air будет выполнена
                в кратчайшие сроки.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelAir;
