import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker8plus.png";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfSpeaker8Plus = [
  {
    id: 1,
    title:
      "Что делать и что не делать самому при замене динамика iPhone 8 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если проблема заявила о себе, она может иметь как программное, так и
          механическое происхождение. Иногда банальная перезагрузка аппаратуры
          позволяет получить необходимый эффект, и точно так же может помочь
          чистка микрофона. Однако стоит помнить, что даже чистка должна
          выполняться в профессиональных условиях, поскольку Айфоны – это
          чувствительная техника, и поверхностный подход к ней стоит исключить.
          Если вы не можете добиться восстановления нормальной работы устройства
          посредством простейших манипуляций – не стоит пытаться своими силами
          разбирать устройство, выполнять замену, потому как даже вскрытие
          прибора необходимо производить в профессиональных условиях.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена динамика iPhone 8 Plus в сервисном центре Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с проблемами динамика, переживать не стоит – в
          большинстве случаев ремонт в этом случае оказывается вполне выполнимым
          и целесообразным. Наш сервисный центр Мак Профи годами занимается
          выполнением подобных задач, и мы успешно справляемся с проблемами
          любой сложности, гарантируя нашим клиентам прекрасный результат даже в
          самых запущенных ситуациях. Сотрудничество с нами дает немало
          преимуществ, и в первую очередь стоит отметить, что с нами вы можете
          быть уверены в результате. Многолетний опыт и масса положительных
          отзывов – все это свидетельствует о том, что обращение к нам
          гарантирует результат.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы придерживаемся политики доступных цен и обеспечиваем клиентам
          удобные условия во всех отношениях. Гарантия предоставляется не только
          на сами услуги, но и на запчасти – они используются только фирменные,
          высококачественные, и кроме того, детали всегда есть в наличии.
          Поломка любого рода и любой сложности не окажется проблемой, если вы
          доверите ее устранение именно нашим специалистам! Замена пострадавшей
          детали займет всего несколько часов, а в срочном порядке будет
          выполнена немедленно и при вашем присутствии!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModels8Plus = () => {
  return (
    <Page title="Надежная замена динамика на iPhone 8 Plus за 40 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена динамика iPhone 8 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Несмотря на то, что Айфоны считаются качественным и надежным
                оборудованием, поломки с ними все же случаются. Пользователи
                обращаются в сервисные центры с самыми разными проблемами, и
                порой источником хлопот становятся даже новые модели текущего
                года выпуска. К слову стоит сказать, что заводской брак или
                некачественное исполнение редко становятся причиной проблем, и в
                основном новые Айфоны страдают от неаккуратного обращения и
                различных случайностей, старые же – от износа. Если говорить о
                такой необходимости, как замена динамика iPhone 8 Plus, тот тут
                можно отметить, что техника страдает в основном от:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Ударов и падений, которые могут принести самый неожиданный
                    результат, включая и такие последствия,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадания воды – влага также может нанести непредсказуемый
                    ущерб,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Нелепых случайностей любого рода, заводского брака.
                  </p>
                </li>
                <p className="repair-iphone-display__paragraph">
                  Среди симптомов, которые имеет данная поломка, можно отметить
                  такие:
                </p>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы при попытке прослушать музыку, посторонние шумы,
                    особенно на низких частотах,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Невозможность услышать собеседника, или шумы при попытке
                    услышать, что он говорит,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полное отсутствие возможности чего-либо слышать.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Если при этом звука нет и на видео, или наблюдаются
                дополнительные проблемы, указывающие на их источник как динамик,
                то локализация проблемы становится очевидной даже для
                дилетантов. Впрочем, если у вас возникли проблемы с динамиком,
                не стоит переживать – на практике они вполне устранимы.
                Обращение в профессиональный сервис, исключение дилетантского
                подхода к ремонту позволит избавиться от рисков и гарантировать
                оптимальный результат ремонта при минимальных вложениях.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="3500 P"
          priceDown="3600 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeaker8Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels8Plus;
