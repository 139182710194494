import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIwatchBanner,
  RepairIwatchTitle,
  RepairIwatchText,
  RepairIwatchModeslLinks,
  RepairIwatchModelsLinksMobile,
} from "../index";
import { useMobileScreen } from "../../utils/useMedia";

// function PageModelLinks() {
//   const isMobile = useMobileScreen();
//   if (isMobile) {
//     return <RepairIwatchModelsLinksMobile />;
//   }
//   return <RepairIwatchModeslLinks />;
// }

const RepairIwatchPage = () => {
  return (
    <main className="repair-iwatch__page">
      <Page title="Ремонт часов Apple Watch в Москве">
        <MacProfiList />
        <BreadCrumbs />
        <RepairIwatchBanner />
        <RepairIwatchTitle />
        <RepairIwatchText />
        <RepairIwatchModeslLinks />
      </Page>
    </main>
  );
};

export default RepairIwatchPage;
