import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphonese.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophoneSE = [
  {
    id: 1,
    title: "Замена микрофона iPhone se силами специалистов нашего центра",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          В этом случае надо предпринять все усилия для поиска достойных
          специалистов, готовых выполнить полный спектр работ быстро и грамотно.
          Услуги нашего сервисного центра включают ремонт высокотехнологичного
          оборудования, и мы специализируемся на выполнении таких работ уже
          много лет, имеем прекрасную репутацию и богатый опыт. Масса
          положительных отзывов и хорошая репутация компании наглядно
          свидетельствует о нашем успехе в данном направлении.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы работаем официально, с подписанием всех документов и гарантиями,
          которые тщательно исполняем. А еще мы радуем клиентов выгодной ценовой
          политикой. У нас всегда есть необходимые запчасти – официального
          заводского производства, с отдельной гарантией на них, по выгодной
          цене. Это ускоряет и упрощает выполнение работ, которые могут
          реализовываться не только в штатном, но и в срочном порядке.
        </p>
        <p className="repair-iphone-display__paragraph">
          Также мастерская имеет современное оснащение и все необходимое рабочее
          оборудование, каждое место специалиста адаптировано под выполнение
          сложнейших задач, и это тоже становится одним из факторов успеха. К
          работам допускаются только грамотные и компетентные лица, имеющие
          профильное образование и соответствующий опыт. Все эти моменты
          обеспечивают нам успешное выполнение работ.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если произошла поломка, обращайтесь к нам, и первое, что сделают наши
          специалисты – это диагностика. Она проводится бесплатно и за считанные
          минуты, и после нее вы сможете узнать причину проблем, стоимость
          ремонта, задать мастеру любые вопросы. Если вас полностью устроят наши
          условия, мы немедленно начнем ремонт и быстро вернем аппаратуру в
          норму. На все работы будет дана долгосрочная гарантия, и вы сможете
          без проблем пользоваться устройством на протяжении долгого времени.
          Обращайтесь, и ваш Айфон снова заработает исправно!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModelsSE = () => {
  return (
    <Page title="Сервисный центр: замена микрофона iPhone SE">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone SE"
          text="Проблемы с Айфонами могут возникать довольно разные, и, несмотря на то, что эта техника считается надежной и качественной, поломки все же случаются. Одной из наиболее распространенных проблем является поломка микрофона устройства – именно о ней можно говорить, если собеседники вас совершенно не слышат. Также именно о такой проблеме идет речь, если вас слышат плохо, речь тихая, есть шумы. Иногда справиться с шумами и глухим звуком помогает чистка, которая, впрочем, тоже требует профессионального вмешательства. Но в большинстве других случаев актуальной становится замена микрофона iPhone se, и для выполнения таких работ необходимо обращаться к специалистам."
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="1500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Причин для возникновения таких проблем может быть немало. Среди
                таковых стоит отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Неаккуратное обращение, падение Айфона, удары,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадание воды или влаги, окисление,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Износ по причине долгой эксплуатации и выработки ресурса.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Какой бы ни была причина возникновения такой поломки, ситуация в
                любом случае окажется исправимой, если доверить работу грамотным
                мастерам. Дилетантский подход и попытки ремонта сложной
                современной техники своими руками нередко заканчиваются полным
                фиаско с необходимостью покупки нового Айфона, или появлением
                более сложных проблем, которые потребуют еще больше времени и
                средств в профессиональном устранении.
              </p>
              <p className="repair-iphone-display__paragraph">
                Самостоятельно вы можете только принять некоторые меры для того,
                чтобы не допускать более никаких поломок. Для этого следует
                пользоваться качественными чехлами с хорошей защитой и
                фиксацией, а кроме того, стремиться к аккуратному обращению с
                устройством. Тогда и его эксплуатация будет возможна на
                протяжении многих лет. Однако что же предпринять, если поломка
                уже произошла, и необходимо ее как-то устранить?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophoneSE.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModelsSE;
