import React from "react";
import Section from "../Section/Section";
import iMacBanner from "../../images/banners/banner-imac.svg";

const RepairIMacBanner = () => {
  return (
    <Section>
      <img className="repair-imac__banner" src={iMacBanner} alt="баннер" />
    </Section>
  );
};

export default RepairIMacBanner;
