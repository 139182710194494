import React from "react";
import { slide1, slide2, slide3, slide4 } from "../../images/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Section } from "../index";

const Banner = () => {
  return (
    <Section>
      <div className="banner__content">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div>
            <img
              className="banner__image"
              alt="ремонт телефонов"
              title="ремонт телефонов"
              src={slide1}
            />
          </div>
          <div>
            <img alt="ремонт ноутбуков" title="ремонт ноутбуков" src={slide2} />
          </div>
          <div>
            <img
              className="banner__image"
              alt="ремонт моноблоков"
              title="ремонт моноблоков"
              src={slide3}
            />
          </div>
          <div>
            <img
              className="banner__image"
              alt="ремонт планшетов"
              title="ремонт планшетов"
              src={slide4}
            />
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default Banner;
