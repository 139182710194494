import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadPro9.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Современное оборудование – гарантируем быструю диагностику и проведем ремонт всех типов устройств",
    number: "01",
  },
  {
    id: 2,
    text: "Квалифицированные специалисты – все сотрудники регулярно пополняют базу знаний",
    number: "02",
  },
  {
    id: 3,
    text: "Прозрачная ценовая политика – честные расценки на работу и низкая стоимость комплектующих",
    number: "03",
  },
  {
    id: 4,
    text: "Широкий спектр услуг – мастера выполнят ремонт любой сложности",
    number: "04",
  },
  {
    id: 5,
    text: "Курьерская доставка – мы самостоятельно доставим устройство в сервисный центр, а после завершения ремонт вернем его владельцу.",
    number: "05",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: "Замена стекла iPad Pro 9.7: 5 причин обратиться в Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Если вы ищете, где в Москве можно заменить стекло iPad Pro 9.7, обратитесь в сервисный центр Мак Профи. Ведь именно у нас:"
          array={listOfTrouble}
          paragraphDown="Индивидуально работаем с каждым клиентом, обсуждая будущие действия с заказчиком. После замены стекла, наш специалист протестирует устройство и продемонстрирует его стабильную работу – и вы сможете в полной мере оценить результат."
          paragraphDown2="Позвоните нам, чтобы выбрать удобный день для посещения, либо сразу приезжайте в сервисный центр. Мак Профи работает ежедневно, а все запчасти уже в наличии – любые поломки будут устранены настолько быстро, насколько позволяют технические возможности."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelPro9 = () => {
  return (
    <Page title="Доступная замена стекла на iPad Pro 9.7, стоимость замены стекла на Айпад Про 9.7">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла на iPad Pro 9.7 "
          text="iPad Pro 9.7 – универсальный планшет, который идеально подойдет и для игр, и для работы, а потому станет прекрасной заменой ноутбуку и ПК. Устройство отличается высокой производительностью и быстродействием, вот только любые повреждения экрана мешают комфортно пользоваться этой моделью. Мастера сервисного центра Мак Профи оперативно выполнят замену стекла iPad Pro 9.7 – и ваш планшет вновь порадует безупречной работой."
        />
        <RepairApplePrice
          subtitle="Тонкости замены стекла iPad Pro 9.7"
          text="Замена сенсорного стекла (тачскрина)"
          price="13900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Конструкция устройства такова, что добраться до основных
                элементов системы довольно сложно. Прежде чем поменять дисплей,
                нужно аккуратно выкрутить основные винты и снять несколько плат
                и разъемов, практически разобрав весь планет. Более того,
                экранный модуль приклеен к основанию, соответственно, для его
                удаления потребуется специальное оборудование
              </p>
              <p className="repair-iphone-display__paragraph">
                Помимо этого необходимо аккуратно избавиться от остатков клея,
                битого стекла, пыли – и при этом не повредить поверхность. Если
                в результате падения был поврежден корпус, важно провести
                рихтовку, чтобы восстановить первоначальную геометрию
                конструкции. Самостоятельно все эти работы не провести – для
                этого потребуются не только теоретические знания, но и
                достаточный практический опыт. Доверьтесь нашим специалистам,
                чтобы получить гарантию на все виды работ и не сомневаться в
                качественном выполнении поставленной задачи.{" "}
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelPro9;
