import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "В сервисном центре Note Expert, специализирующемся на ремонте и обслуживании ноутбуков различных марок, ваше мобильное компьютерное устройство ожидает профессиональный уход и качественный сервис.",
  "Опытные специалисты выполняют профилактическую чистку ноутбуков от пыли и грязи (внутреннюю, внешнюю, включая чистку клавиатуры), срочную химическую чистку после залития, замену термоинтерфейсов.",
  "Выполненные своевременно, эти процедуры существенно продлят срок службы вашего устройства, сохранив не только его работоспособность, но и его производительность.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛЫ СВОЕГО ДЕЛА",
    text: "Выполнить чистку ноутбука от пыли в Москве можно самостоятельно (если вы обладаете необходимыми для этой процедуры знаниями, навыками, инструментами) или с помощью специалистов сервисного центра Note Expert, которые сделают это на профессиональном уровне и по всем правилам, не забыв об обязательной замене термопасты на микросхемах ноутбука или смазке кулера.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Наши мастера используют для проведения этой операции профессиональные инструменты, качественную термопасту и другие расходные материалы. Процедура проводится в несколько этапов и занимает в случае ее осуществления профессионалами Note Expert не более 1 дня.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Чтобы ноутбук работал долго и стабильно, проводить его профилактическую чистку внутри от пыли необходимо с периодичностью не реже, чем 1 раз в год.",
  },
  {
    icon: priceIcon2,
    text: "Стоимость чистки от пыли ноутбука – одна из самых невысоких в нашем прайс-листе.",
  },
  {
    icon: priceIcon3,
    text: "Намного дешевле стоимости его ремонта в случае выхода из строя по причине перегрева одного-двух или сразу нескольких внутренних компонентов (видеочипа, северного моста, процессора, других важных элементов).",
  },
];

const prices = [
  {
    text: "Аппаратная диагностика",
    price: "Бесплатно",
  },
  {
    text: "Чистка от пыли с заменой термоинтерфейсов",
    price: "2000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Чистка от пыли",
    price: "1700 P",
  },
  {
    text: "Восстановление дорожек материнской платы",
    price: "от 4000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Чистка ноутбуков производится владельцами как в форс-мажорных обстоятельствах (после залития жидкостью или отключения в результате перегрева), так и в профилактических целях, чтобы этого перегрева просто не допустить.",
  "Последний вариант является наиболее правильным подходом к эксплуатации портативного ПК, детали которого вынуждены располагаться очень близко друг к другу и очень легко нагреваются до критичной температуры, если система охлаждения устройства забита пылью и плохо функционирует. ",
  "В свою очередь, перегрев процессора, чипсета, видеокарты и других внутренних элементов грозит их выходом из строя.",
];

function NoteExpertNoteSettingClean() {
  return (
    <Page title="Чистка ноутбука от пыли за 1700 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Профессиональная чистка ноутбуков в Москве"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Грамотный подход к профилактике"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Чистка ноутбука от пыли: цена услуги в Note Expert"
          subtitle="Стоимость чистки ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Когда и почему ноутбуку требуется чистка"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingClean;
