import React from "react";
import { SectionTitle, BlockTitle, Section } from "../index";
import phone from "../../images/img.jpg";

const AboutUs = () => {
  return (
    <Section id="omg">
      <div className="about__container">
        <SectionTitle title="Сломался гаджет?" />
        <div className="about__text-content">
          <BlockTitle title="Почему?" />
          <p className="about__paragraph">
            Потому что «Oh!MyGadget!» быстро решит вашу проблему, и вы снова
            услышите родное «Бзззз!» или «Дзынь!»
          </p>
        </div>
        <img
          className="about__image"
          src={phone}
          alt="изображение разбитого смартфона"
        />
        <article className="about__content">
          <BlockTitle title="Хотите знать, что&nbsp;такое&nbsp;«Oh!MyGadget!»?" />
          <ul className="about__wrapper">
            <li className="about__item">
              <p className="about__text">
                Это просто. «Oh!MyGadget!» – современная служба быстрого
                восстановления любых гаджетов. Это быстро. Работа в
                «Oh!MyGadget!» устроена так, что мы восстанавливаем любой гаджет
                в срок от 10 минут. Это инновационно. В «Oh!MyGadget!» входит
                целая сеть технокампусов: технических центров быстрого
                реагирования.
              </p>
            </li>
            <li className="about__item">
              <p className="about__text">
                Каждый кампус работает в соответствии с&nbsp;инновационными
                стандартами восстановления и&nbsp;последующей точной настройки
                современных гаджетов. У нас заняты лучшие специалисты, а наши
                клиенты пользуются самыми удобными сервисами
                для&nbsp;отслеживания выполнения своих заказов в&nbsp;режиме
                реального времени. «Oh!MyGadget!». Такого еще не было.
              </p>
            </li>
          </ul>
        </article>
      </div>
    </Section>
  );
};

export default AboutUs;
