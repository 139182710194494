import React from "react";
import Section from "../Section/Section";
import mackBookBanner from "../../images/banners/repair-mb-banner.svg";

const RepairMacBookBanner = () => {
  return (
    <Section>
      <img
        src={mackBookBanner}
        alt="баннер страницы"
        className="repair-mb__banner"
      />
    </Section>
  );
};

export default RepairMacBookBanner;
