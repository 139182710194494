import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePageBanner.module.css";

function NoteExpertInsidePageBanner({ banner }) {
  return (
    <Section>
      <img src={banner} alt="баннер" className={styles.banner} />
    </Section>
  );
}

export default NoteExpertInsidePageBanner;
