import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import sonyBanner from "../../../images/banners/mobile-sony-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "КАЧЕСТВЕННЫЙ СЕРВИС",
    text: "Наши сотрудники прикладывают массу усилий, чтобы ремонт смартфона Sony Xperia и других моделей доставлял владельцам только положительные моменты, а время, проведенное без телефона, было минимальным.",
  },
  {
    img: icon2,
    title: "ПРОФЕССИОНАЛЬНАЯ ГАРАНТИЯ",
    text: "На все виды услуг в нашем центре действует гарантия. Срок, в течение которого выполняются гарантийные обязательства, зависит от типа ремонта и установленных комплектующих.",
  },
  {
    img: icon3,
    title: "ДОСТАВКА",
    text: "Пользователи, которым необходим ремонт телефонов Sony в Москве, имеют возможность отправить устройство в наш центр, не выходя из дома. Для этого можно воспользоваться услугами нашего курьера, который заберет ваш смартфон в ремонт, оставив вам соответствующий пакет документов, и доставит его обратно сразу после восстановления.",
  },
  {
    img: icon4,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "В рабочие время в пределах МКАД доставка осуществляется на бесплатной основе. В другое время, а также при доставке на расстояние дальше, чем 20 км от МКАД, стоимость услуг курьера оговаривается отдельно. Более подробно можно узнать, позвонив по телефону +7 (495) 540-43-19.",
  },
];

const mobiles = [
  { name: "Xperia 1 IV", pricelist: desireHD },
  { name: "Xperia 10 IV", pricelist: desire400 },
  { name: "Xperia 5 IV", pricelist: desireHD },
  { name: "Xperia Ace III", pricelist: desire400 },
  { name: "Xperia 1 III", pricelist: desireHD },
  { name: "Xperia 10 III", pricelist: desire400 },
  { name: "Xperia 10 III Lite", pricelist: desireHD },
  { name: "Xperia 5 III", pricelist: desire400 },
  { name: "Xperia Pro-I", pricelist: desireHD },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairMobileSony() {
  return (
    <Page title="Сервисные услуги по ремонту смартфонов Sony от специалистов «Центр Мобайл» (Москва)">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={sonyBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ СМАРТФОНОВ SONY"
          text="Необходим ремонт смартфона Sony? Обращайтесь к нашим специалистам в «Центр Мобайл». Качественное обслуживание, надежные комплектующие и эффективный ремонт в короткие сроки гарантированы каждому устройству, попавшему в руки квалифицированных мастеров нашего центра."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт SONY"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            SONY:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА ТЕЛЕФОНОВ SONY В «ЦЕНТР МОБАЙЛ»"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ В «ЦЕНТР МОБАЙЛ»?"
          text="Отдать в ремонт телефон Sony можно тремя способами: привезти самостоятельно, вызвать курьера для доставки или договориться о приезде мастера на дом (ремонт в домашних условиях возможен при поломках и сбоях, не требующих применения стационарного оборудования и длительного ремонта). Сотрудничество с «Центр Мобайл» – это всегда удобно и выгодно!"
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairMobileSony;
