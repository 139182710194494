import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleLinksText,
  RepairIphoneModelLink,
  SelectForIphoneServiceLinks,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/repair-ipadDisplay.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";

const textServiceLinlDisplay = [
  {
    id: 1,
    title: "Замена стекла на Айпад - особенности ремонта",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Сколь бы не были специалисты из Apple мастерами своего дела, а сам
          iPad -- шедевром инженерной мысли, уберечь планшет от всех
          превратностей и поломок, конечно же, невозможно. Разбитое стекло или
          же различные сколы, трещины и царапины, в следствии которых требуется
          поменять стекло на iPad -- это наиболее распространенная причина
          обращения в ремонтные мастерские. По заверениям самих Apple, которым у
          нас нет причин не верить, стекло планшета, изготовленное по особой
          технологии, отличается особой прочностью и должно безболезненно
          перенести более двухсот млн. пользовательских нажатий. Так что если
          экран "украсила" густая сеть трещин, или, даже, просто небольшой скол
          - это произошло по вине неосторожного обращения с планшетом.
        </p>
        <p className="repair-iphone-display__paragraph">
          Есть несколько вариантов возможных повреждений, после которых
          потребуется замена стекла на Айпад. Наиболее удачным можно считать
          падение плашмя, после которого образуется одна большая трещина экрана.
          Заменить стекло в таком случае не составит особого труда. Более
          сложные случаи это различные трещины ближе к углу корпуса, т.к. при
          снятии стекло начинает крошится, усложняя работу и "жизнь"
          ремонтирующим специалистам. Наиболее неприятные повреждения связаны с
          одновременно разбитым стеклом и различными нарушениями геометрии
          корпуса. В таком случае, как не трудно понять, помимо самой замены
          стекла iPad, потребуется поменять или же восстановить еще и корпус,
          что отразится и на сложности ремонта, и на его стоимости.
        </p>
      </>
    ),
  },
];

const optionsIpadDisplay = [
  { value: "iPad", label: "iPad", price: "2400", price2: "1900" },
  { value: "iPad 2", label: "iPad 2", price: "1900", price2: "1900" },
  { value: "iPad 3", label: "iPad 3", price: "1600", price2: "1900" },
  { value: "iPad 4", label: "iPad 4", price: "1900", price2: "1900" },
  { value: "iPad mini", label: "iPad mini", price: "1900", price2: "1900" },
  { value: "iPad Air", label: "iPad Air", price: "1900", price2: "1900" },
  { value: "iPad mini 2", label: "iPad mini 2", price: "2350", price2: "1950" },
  { value: "iPad Air 2", label: "iPad Air 2", price: "2350", price2: "1900" },
  { value: "iPad mini 3", label: "iPad mini 3", price: "2500", price2: "1950" },
  {
    value: "iPad Pro 12.9",
    label: "iPad Pro 12.9",
    price: "1900",
    price2: "1900",
  },
  { value: "iPad mini 4", label: "iPad mini 4", price: "2080", price2: "2080" },
  {
    value: "iPad Pro 9.7",
    label: "iPad Pro 9.7",
    price: "4450",
    price2: "4450",
  },
  { value: "iPad 5", label: "iPad 5", price: "1900", price2: "1900" },
  {
    value: "iPad Pro 2 10.5",
    label: "iPad Pro 2 10.5",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 2 12.9",
    label: "iPad Pro 2 12.9",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad 6 (2018)",
    label: "iPad 6 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 11 (2018)",
    label: "iPad Pro 11 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 12.9 (2018)",
    label: "iPad Pro 12.9 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Air 3 (2019)",
    label: "iPad Air 3 (2019)",
    price: "1600",
    price2: "1600",
  },
  { value: "iPad mini 5", label: "iPad mini 5", price: "1900", price2: "1900" },
  { value: "iPad 7", label: "iPad 7", price: "1900", price2: "1900" },
  { value: "iPad 8", label: "iPad 8", price: "1900", price2: "1900" },
  {
    value: "iPad Air 4 (2020)",
    label: "iPad Air 4 (2020)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 11 (2021)",
    label: "iPad Pro 11 (2021)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 12.9 (2021)",
    label: "iPad Pro 12.9 (2021)",
    price: "1900",
    price2: "1900",
  },
  { value: "iPad 9", label: "iPad 9", price: "1900", price2: "1900" },
  { value: "iPad mini 6", label: "iPad mini 6", price: "1900", price2: "1900" },
];

const RepairIpadServiceLinkDisplay = () => {
  return (
    <Page title="Замена стекла iPad в Москве. Стоимость замены стекла Айпад.">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла на iPad"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена стекла iPad производится в нашем сервисном центре
                высококвалифицированными техническими специалистами имеющими
                стаж работы от 5-и лет.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если вам нужна замена стекла на iPad в Москве, наиболее
                оперативный, недорогой и безболезненный для дальнейшей
                электронной жизни вашего планшета способ сделать это -
                обратиться в Мак Профи. Наши специалисты выполняли подобную
                "операцию" бессчётное количество раз, приобретя тот опыт и
                навык, которые гарантируют удачный ремонт стекла iPad и в вашем
                конкретном случае. Все работы выполняются исключительно на
                профессиональном оборудовании, все детали, подлежащие замене -
                всегда в наличии, а по завершению ремонта выдаётся гарантия.
              </p>
            </>
          }
        />
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 105px",
            marginTop: 55,
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Стоимость замены стекла на iPad
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsIpadDisplay}
              text="сенсорного стекла (тачскрина)"
              text2="дисплея"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textServiceLinlDisplay.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplay;
