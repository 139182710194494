import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIpodModelLinks,
} from "../index";
import banner from "../../images/banners/ipodNano6.jpeg";

const listOfTrouble = [
  {
    id: 1,
    text: "Поврежден дисплей или тачскрин – на стекле появились трещины, сенсор работает некорректно, подсветка отсутствует;",
    number: "01",
  },
  {
    id: 2,
    text: "Ресурс аккумулятора исчерпан – устройство практически не держит заряд, неожиданно выключается, либо и вовсе не заряжается;",
    number: "02",
  },
  {
    id: 3,
    text: "Не работают кнопки – при нажатии Power или Home не происходит никаких действий;",
    number: "03",
  },
  {
    id: 4,
    text: "Проблемы со звуком – повреждены разъемы для наушников или же испорчен динамик.",
    number: "04",
  },
];

const textIpodNano6 = [
  {
    id: 1,
    title: "Профессиональный ремонт iPod Nano 6G в Москве",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Если вы обнаружили нарушения в работе устройства стоит обратиться в Мак Профи – московский сервисный центр, расположенный в шаговой доступности от станции метро Белорусская. За годы труда в этой сфере, мы заслужили безупречную репутацию, что само по себе становится главной гарантией качества. Чаще всего нам приходится выполнять следующие виды ремонта iPod Nano 6G:"
          array={listOfTrouble}
          paragraphDown="И это далеко не полный список проблем, которые смогут решить инженеры сервиса Мак Профи. Кроме того, если основная неисправность проявилась в результате падения или сильного удара, то может потребоваться легкая рихтовка или полная замена корпуса – и ваш Айпод вновь будет безупречен."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки ремонта iPod Nano 6G",
    text: "Чтобы разобрать устройство и поставить новые запчасти вместо испорченных, специалисту в среднем требуется около 20 минут. Основная часть неисправностей успешно устраняется за это время – нам удалось достичь этого показателя благодаря постоянному пополнению запаса комплектующих. В некоторых случаях срок ремонта iPod Nano 6G может быть увеличен, поэтому точное время восстановления устройства мы сможем назвать только после проведения диагностики.",
  },
  {
    id: 3,
    title: "Стоимость ремонта iPod Nano 6G",
    text: "Полный прайс с указанием самых распространенных услуг представлен на сайте – вы можете с ним ознакомиться перед посещением центра. Чтобы отдельно уточнить стоимость комплектующих, свяжитесь с нами по телефонам, указанным в верхней части сайта. Мак Профи работает ежедневно – мы всегда ждем посетителей и готовы оказать квалифицированную помощь.",
  },
];

const RepairIpodModelLinkNano6 = () => {
  return (
    <Page title="Ремонт iPod Nano 6G по низким ценам в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPod Nano 6G"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Срочный, качественный ремонт iPod Nano 6G в Москве: полный
                комплекс услуг от ведущих специалистов Мак Профи. Бесплатно
                проводим диагностику перед восстановлением. Выдаем гарантийный
                талон.
              </p>
              <p className="repair-iphone-display__paragraph">
                iPod Nano 6G – красивый и удобный плеер, который может быть
                использован не только для комфортного прослушивания музыки, а и
                целого ряда других задач. Как и другая техника Apple, устройство
                отличается хорошим качеством – основная часть обращений в
                сервисный центр происходит по вине владельца гаджета. Но вне
                зависимости от того, что произошло, мы исправим проблему –
                предоставляем полный цикл услуг от замены неисправных запчастей
                до проведения сложного ремонта, неизменно обеспечивая высокий
                уровень сервиса и удерживая цены на лояльном уровне.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIpodNano6.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpodModelLinks />
      </main>
    </Page>
  );
};

export default RepairIpodModelLinkNano6;
