import React from "react";
import Section from "../Section/Section";
import iWatchBanner from "../../images/banners/repaip-iphone-banner.svg";

const RepairIwatchBanner = () => {
  return (
    <Section>
      <img className="repair-iwatch__banner" src={iWatchBanner} alt="баннер" />
    </Section>
  );
};

export default RepairIwatchBanner;
