import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone6s.jpeg";

const textOfModel6s = [
  {
    id: 1,
    title: "Порядок ремонта Айфон 6с в Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Сначала бесплатная диагностика: мы выявим все неисправности и известим
          о них вас. Вы выбираете список работ, которые хотите произвести в
          нашем центре. После того как ремонт сделан - он, кстати, как правило,
          не отнимает у наших специалистов более 20 минут, вы сможете убедится в
          исправности вашего телефона. И только после этого производится оплата.
          Т.е. никаких неожиданных платежей или предоплаты. Вы получаете на руки
          гарантийный талон и можете снова наслаждаться всем богатым
          функционалом вашего iPhone 6S в полную силу.
        </p>
        <p className="repair-iphone-display__paragraph">
          На все виды работ и детали предоставляется гарантия (сроком до года).
          Наши офисы расположены в Москве. А при необходимости забрать ваш
          телефон и привезти его после починки назад может наш курьер.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады видеть вас в наших центрах и разрешить любые проблемы
          вашего iPhone 6S! Также, вы можете позвонить по телефонам, указанным
          на нашем сайте, и наши менеджеры проконсультируют вас по любым
          вопросам, связанным с ремонтом Айфона 6с.
        </p>
      </>
    ),
  },
];

const price6s = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",

    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage6s = () => {
  return (
    <Page title="Ремонт iPhone 6s по привлекательным ценам в Москве.">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone 6S"
          text={
            <>
              Ремонт iPhone 6S дело, мягко говоря, не простое. Тут тебе и
              знаменитый проприетарный крепёж - проще говоря, инженеры Apple
              каждый раз заново изобретают хитросделанные винты, требующие
              каждый раз новых отвёрток; и громодьё сложных деталей на минимуме
              пространства. Одним словом, ремонт Айфона 6с представляет собой
              что-то среднее между работой ювелира, художника и инженера,
              компетенции не ниже НАСАвской. Говорим мы это, так как гордимся
              работой наших специалистов, которым по плечу исправить любую
              поломку вашего телефона и вернуть вам ваш любимый iPhone 6S
              практически неотличимым от нового, после любой возможной
              неприятности. Замена кнопок, ремонт сенсорного экрана,
              аккумулятора, перепрошивка или обновление - все эти виды работ вы
              можете совершить в нашем центре за максимально короткое время и
              без лишних денежных трат. Вообщем, если вам понадобился ремонт
              iPhone 6S в Москве - вам к нам!
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price6s}
            title="Цены на ремонт iPhone 6S"
            text="* Цены указаны в рублях РФ. В стоимость входит деталь и услуга по установки."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel6s.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage6s;
