import React from "react";
import Section from "../Section/Section";

const AboutCompanyMacProfiMobile = () => {
  return (
    <Section>
      <div className="about-company-mobile__contant">
        <h1 className="about-company-mobile__title">О КОМПАНИИ Oh!MyGadget!</h1>
        <h4 className="about-company__subtitle">
          Добро пожаловать в Oh!MyGadget! — ваш надежный партнер в мире ремонта
          электроники с 2012 года.
        </h4>
        <div className="about-company-mobile__border-title"></div>
        <ul className="about-company-mobile__list">
          <li className="about-company-mobile__item">
            <p className="about-company-mobile__paragraph">
              Сервисный центр работает с 2006 года. Мы располагаем штатом
              сертифицированных специалистов, которые производят ремонт любой
              степени сложности. Профессиональное оборудование позволяет
              производить точную диагностику и ремонт Вашей техники с высокой
              эффективностью. Наш сервисный центр удобно расположен в центре
              Москвы, что позволяет нашим клиентам в кратчайшие сроки добраться
              в сервисный центр, даже в условия загруженности города пробками.
              Сервисный центр Мак Профи производит ремонт всей линейки техники
              Apple.
            </p>
            <div className="about-company-mobile__border"></div>
          </li>
          <li className="about-company-mobile__item">
            <p className="about-company-mobile__paragraph">
              За годы работы, наш сервисный центр произвел ремонт более 350000
              тысяч устройств Apple, что дает нам право говорить о том, что мы
              действительно профессионалы своего дела. Менеджеры нашей компании
              всегда вежливы с клиентами, мы не затягиваем сроки ремонта, всегда
              предоставляем нашим клиентам несколько вариантов ремонта техники.
              Мы производим действительно бесплатную диагностику, даже если Вы
              не продолжаете ремонт в нашем сервисном центре, вы ничего не
              оплачиваете.
            </p>
            <div className="about-company-mobile__border"></div>
          </li>
          <li className="about-company-mobile__item">
            <p className="about-company-mobile__paragraph">
              На платные услуги в нашем сервисном центре предоставляется
              гарантия, мы несем ответственность за устройства, которые
              ремонтируем. В отличии от кустарных сервисов, мы не пропадем
              завтра, мы работаем уже более 9-и лет.
            </p>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default AboutCompanyMacProfiMobile;
