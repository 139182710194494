import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpadMini3.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Полная разрядка. Не стоит пользоваться планшетом пока он полностью не разрядиться, и уж тем более, важно не оставлять разряженное устройство на длительный период времени. В противном случае, в дальнейшем даже при подключении к зарядному устройству, оно может уже не включиться без помощи мастера.",
    number: "01",
  },
  {
    id: 2,
    text: "Использование посторонней зарядки. Важно применять только оригинальные аксессуары, а также ни в коем случае не подключать iPhone к зарядному устройству от iPad.",
    number: "02",
  },
  {
    id: 3,
    text: "Перепады температуры. Если планшет длительное время находился на холоде, то нельзя ставить его на зарядку. Изначально устройство должно не меньше 30-60 минут находиться при комнатной температуре, и лишь после этого можно подключать зарядное устройство. В противном случае значительно возрастает вероятность короткого замыкания.",
    number: "03",
  },
];

const listOfRecommendations = [
  {
    id: 1,
    text: "Быстрый ремонт. Процедура занимает около двух часов, но в сложных случаях вы можете оставить мастерам планшет на сутки. При необходимости, все манипуляции будут проведены в срочном порядке непосредственно при клиенте – около получаса, и ваше устройство будет полностью готово к дальнейшей работе.",
    number: "01",
  },
  {
    id: 2,
    text: "Полный комплекс услуг. Вы можете доверить нам не только замену аккумулятора iPad Mini 3, но и другие виды ремонт устройства. При невозможности обратиться в сервисный центр, воспользуйтесь услугой курьерской доставки – наш сотрудник прибудет в указанное время и заберет планшет.",
    number: "02",
  },
  {
    id: 3,
    text: "Выгодные расценки. Наши условия лояльны и честны, к тому же обращаясь к специалистам, вы получаете гарантии качества, что подтверждает профессиональное выполнение поставленной задачи.",
    number: "03",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: <>Причины раннего износа аккумулятора iPad&nbsp;Mini&nbsp;3</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Существует множество причин, по которым требуется замена батареи – нередко сам владелец нарушает правила эксплуатации устройства. Это происходит следующим образом:"
          array={listOfTrouble}
          paragraphDown="Даже при условии строго соблюдения правил эксплуатации, рано или поздно потребуется замена аккумулятора iPad Mini 3. Не откладывайте эту процедуру на неопределенный срок, чтобы батарея не вздулась и не стала причиной выхода из строя иных элементов."
        />
      </>
    ),
  },
  {
    id: 2,
    title: <>Преимущества замены аккумулятора iPad Mini&nbsp;3</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="В сервисном центре Мак Профи работают только квалифицированные специалисты, которые готовы предоставить подробную консультацию и помочь в решении возникших проблем. Обращаясь к нам, вы получаете высокий уровень сервиса и другие преимущества:"
          array={listOfRecommendations}
          paragraphDown="Мы работаем ежедневно – приезжайте к нам в удобное время. Мы позаботимся об оперативной замене батареи iPad Mini 3, и предложим специальные условия каждому клиенту."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelMini3 = () => {
  return (
    <Page title="Замена аккумулятора (батареи) iPad Mini 3 (Айпад Мини 3): быстрый ремонт с гарантией качества">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad Mini 3"
          text="Необходимость замены аккумулятора iPad Mini 3 возникает спустя определенный период эксплуатации устройства. И это нормально, ведь ни одна батарея не может работать вечно – у каждой детали есть свой срок службы, составляющий несколько сотен циклов заряда. В сервисном центре Мак Профи работают профессионалы, которые помогут восстановить нормальную работу планшета и увеличить период его автономной работы."
        />
        <RepairApplePrice text="Замена аккумулятора" price="2900 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelMini3;
