import react from "react";
import Section from "../Section/Section";
import banner from "../../images/banners/about_banner.svg";

const AboutCompanyBanner = () => {
  return (
    <Section>
      <img
        src={banner}
        alt="Информация о компании О Май Гаджет"
        title="Информация о компании О Май Гаджет"
        className="about-comp__banner"
      />
    </Section>
  );
};

export default AboutCompanyBanner;
