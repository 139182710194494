import React from "react";
import {
  CooperationAbout,
  CooperationBanner,
  CooperationContacts,
  CooperationInfo,
  CooperationOffers,
  CooperationWithUs,
  CooperationOffersMobile,
  CooperationWithUsMobile,
  Page,
} from "../index";
import useMedia from "use-media";
import CooperationAdditionalAdvantages from "../CooperationAdditionalAdvantages/CooperationAdditionalAdvantages";
import { Helmet } from "react-helmet";

const CooperationPage = () => {
  const isWide = useMedia({ minWidth: 500 });

  return (
    <main className="cooperation-page">
      <Page title="Cотрудничество с сервисным центром О Май Гаджет">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Предлагаем сотрудничество с сервисным центром О Май Гаджет, эффективный ремонт на выгодных для вас условиях."
          />
        </Helmet>
        <CooperationBanner />
        <CooperationInfo />
        {isWide ? <CooperationOffers /> : <CooperationOffersMobile />}
        <CooperationAdditionalAdvantages />
        {isWide ? <CooperationWithUs /> : <CooperationWithUsMobile />}
        {/* <CooperationAbout /> */}
        <CooperationContacts />
      </Page>
    </main>
  );
};

export default CooperationPage;
