import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

const navList = [
  {
    id: 1,
    href: "omg",
    text: "Омайгаджет",
  },
  {
    id: 2,
    href: "otzyvy",
    text: "Отзывы",
  },
  {
    id: 3,
    href: "clients",
    text: "Нам можно доверять",
  },
];

const AncorLinksMobile = ({ setActive }) => {
  return (
    <>
      {navList.map((item) => {
        return (
          <li key={item.id} className="burger-menu__item">
            <Link
              className="burger-menu__link"
              to={item.href}
              smooth={true}
              duration={2000}
              offset={50}
              delay={200}
              onClick={() => setActive(false)}
            >
              {item.text}
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default AncorLinksMobile;
