import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera8.png";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera8 = [
  {
    id: 1,
    title: "Замена камеры iPhone 8 в Москве – профессиональная помощь!",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Работа по устранению проблем с камерой Айфона всегда требует
          профессионального подхода и точной диагностики. На практике случается,
          что сложности со вспышкой и некоторые другие проблемы, связанные,
          казалось бы, с камерой, решаются ремонтом материнской платы и
          происходят из-за ее сбоев. Профессиональный подход позволит с
          точностью выявить источник проблем и быстро устранить их, обеспечив
          гарантию на долгий срок.
        </p>
        <p className="repair-iphone-display__paragraph">
          Наш сервисный центр Мак Профи уже не первый год успешно решает
          проблемы с Айфонами, не исключая и самых новых их моделей –
          профессионалы, работающие у нас, знают об этой технике все, и успешно
          решают проблемы любой сложности. Множество положительных отзывов,
          многолетняя официальная практика и рекомендации наглядно
          свидетельствуют о нашем успехе в данном направлении. Мы
          специализируемся именно на современном высокотехнологичном
          оборудовании, и успешно справляемся со всеми обязательствами, которые
          берем на себя. Все работы выполняются оперативно, за несколько часов,
          с гарантией на долгий срок. При необходимости вы можете присутствовать
          в мастерской во время ремонта.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым делом у нас выполняется бесплатная диагностика, которая
          позволяет за считанные минуты изучить суть проблемы и детализировать
          поломку. Услуги ремонта выполняются только после диагностики и общения
          с клиентом, после его согласия на осуществление ремонта при всех
          существующих условиях, подписания договора с окончательной суммой,
          которая уже не будет меняться в дальнейшем. Мы не затягиваем сроков,
          все необходимые запчасти всегда имеются в наличии, как и свободные
          руки, и потому вам не придется долго ждать результата. После
          выполнения работ устройство протестируют, предоставят длительную
          гарантию, как на ремонтные работы, так и на запчасти.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы применяем только высококачественные запчасти официального
          происхождения. Работы проводятся в профессиональных условиях, в
          сервисе, оснащенному по последнему слову техники, на специально
          организованных рабочих местах. К работам допускается только
          квалифицированный персонал с опытом и соответствующим образованием.
          Все это становится слагаемыми нашего успеха. Обратившись к нам, вы
          сможете получить ремонт на простых и выгодных условиях, быстро и
          качественно! Звоните, приезжайте к нам, чтобы получить помощь на
          оптимальных условиях!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels8 = () => {
  return (
    <Page title="Замена камеры iPhone 8, не работает камера Айфон 8">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 8"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Все владельцы Айфонов могут наслаждаться качественными
                фотографиями, ведь эти устройства действительно имеют немало
                преимуществ. Однако камера не вечна, и при определенных
                обстоятельствах она вполне может выйти из строя, и с такой
                проблемой в сервисные центры действительно обращаются не редко.
                Существует целый ряд симптомов, свидетельствующих о том, что
                необходима замена камеры iPhone 8, и среди таковых стоит
                отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Битые пиксели на изображениях,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Невозможность включения этого элемента телефона,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Отказ автофокусировки.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Также на проблемы камеры наглядно указывает низкое качество
                отснятого материала, и ряд других подобных явлений. Произойти же
                поломка может как внезапно, совершенно спонтанно на первый
                взгляд, так и после падений, механических повреждений. Радует,
                что подобные поломки оказываются в большинстве случаев вполне
                исправимыми, и если обратиться к профессионалам, уже вскоре
                можно будет вновь наслаждаться эксплуатацией устройства. Если
                камера вашего Айфона пострадала, и необходимо вернуть ему
                возможность запечатлевать приятные моменты, обращайтесь в нашу
                мастерскую, и мы выполним все необходимые для этого работы!
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="5500 P"
          priceDown="3700 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera8.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels8;
