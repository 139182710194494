import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display6plus.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay6splus = [
  {
    id: 1,
    title: "Как выполняется замена дисплея Айфон 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Откладывая решение проблемы, владелец лишь усугубляет текущую ситуацию
          – со временем трещина будет только увеличиваться, что начнет мешать
          полноценному использованию устройства. Мастера же сразу приступят к
          работе, благодаря чему на устранение дефекта потребуется 1-2 часа. За
          это время будут выполнены следующие действия:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Откручены винты на корпусе
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отключены все шлейфы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Изъяты все необходимые элементы, мешающие удалению дисплея
              (датчики, подсветка, разъемы).
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          {" "}
          Чтобы снять поврежденную поверхность, нужно нагреть её – это позволит
          аккуратно удалить стекло. Остается лишь избавиться от остатков клея,
          после чего можно приступать к креплению нового дисплея. Самостоятельно
          приниматься за ремонт конструкции нежелательно – это прямой путь
          окончательно испортить дорогостоящее устройство, а потому лучше
          обратиться к профессионалам.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Преимущества профессиональной замены дисплея iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы знаем, как восстановить неисправный смартфон – замена дисплея Айфон
          6 Plus производится непосредственно в присутствии клиента. Вам не
          придется терять время на ожидание, ведь в наличии есть все необходимые
          запчасти, а наши специалисты используют в работе профессиональные
          инструменты и оборудование. И это далеко не единственное преимущество,
          которое наш центр готов предложить клиентам, ведь у нас:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Долгосрочная гарантия – позаботимся о том, чтобы изображение еще
              долго радовало владельца насыщенностью оттенков
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Быстрый ремонт – позвольте себе отдохнуть, перекусив в ближайшем
              кафе, а за это время мы восстановим ваш смартфон
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Обязательное тестирование устройства – обязательно убедимся в том,
              что iPhone работает безупречно, в полной мере выполняя все
              функции.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          В нашем сервисном центре работают квалифицированные мастера,
          обладающие многолетним опытом ремонта смартфонов. Свяжитесь с нами или
          приезжайте в удобное время по указанному адресу, чтобы восстановить
          поврежденное устройство – мы подберем для вас подходящие запчасти и
          предложим действительно выгодные условия.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels6plus = () => {
  return (
    <Page title="Быстрая замена дисплея iPhone 6 Plus, заменим дисплей Айфон 6 Plus за 35 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 6 Plus"
          text="Замена дисплея iPhone 6 Plus может потребоваться по разным причинам, но чаще всего проблема возникает из-за механических повреждений устройства. Даже небольшие дефекты могут существенно помешать комфортному использованию устройства, а потому стоит обязательно обратиться в специализированный сервисный центр, в котором будут предоставлены необходимые услу"
        />
        <RepairApplePrice
          subtitle="Сколько стоит замена дисплея iPhone 6 Plus"
          text="Замена дисплея"
          price="3000 P"
          paragraph="Все цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay6splus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels6plus;
