import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleTextWithCheckMark,
  Section,
  RepairAppleLinksText,
  RepairIMacLinks,
} from "../index";
import banner from "../../images/banners/imac-display.jpeg";

const textBanner = [
  {
    id: 1,
    text: "Разбили стекло на iMac. Самая распространенная проблема. Стекло iMac достаточно хрупкая деталь, в результате удара, падения стекло разбивается. В данном случае требуется замена стекла на новое.",
    number: "01",
  },
  {
    id: 2,
    text: "Треснуло стекло на iMac. Причина – механическое повреждение. Треснувшее стекло также рекомендуется менять на новое, есть вероятность при дальнейшем повреждении стекла повредить матрицу iMac.",
    number: "02",
  },
  {
    id: 3,
    text: "Разбили стекло на тонком iMac (модели от 2013 года). К сожалению, на новом поколении iMac стекло отдельно от матрицы не меняется, ввиду этого требуется замена матрицы со стеклом целиком.",
    number: "03",
  },
];

const text = [
  {
    id: 1,
    title: "Как мы меняем стекло на iMac",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Сервисный центр имеет все стекла на iMac 21, 24, 27” в наличии. Стекло
          меняется достаточно оперативно, в течении 1 часа. В процессе замены
          специалист убирает старое стекло, а также производит полную чистку
          матрицы от пыли и остатков стекла. Также, если поврежден корпус,
          производится рихтовка и выпрямление корпуса на стенде, после чего
          стекло становится в свои пазы корректно.
        </p>
        <p className="repair-iphone-display__paragraph">
          После чистки, производится установка нового стекла на iMac. Установка
          стекла занимает 10 минут. После установки проверяется полностью вся
          геометрия, корректность зазоров между стеклом и корпусом. После
          установки стекла, iMac поступает в отдел контроля качества, где
          специалист повторно проверяет корректность установки и отправляет iMac
          на выдачу.
        </p>
      </>
    ),
  },
];

const textDown = [
  {
    id: 1,
    title: "Какую гарантию мы предоставляем на установку стекла iMac",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          В зависимости от модели iMac и модели стекла, гарантия на стекло и
          установку от 3-х до 6-и месяцев. Гарантия распространяется на само
          стекло, а также на работы по установке стекла.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады Вам оперативно помочь установить новое стекло на iMac!
        </p>
      </>
    ),
  },
];

const RepairIMacLinkDisplay = () => {
  return (
    <Page title="Замена стекла на iMac 21.5, 24, 27 дюймов">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title='Замена стекла на iMac 21, 24, 27"'
          text={
            <RepairAppleTextWithCheckMark
              paragraphUp={
                <>
                  Производим установку и замену стекла на iMac всех моделей. В
                  каких случаях требуется замена стекла на iMac:
                </>
              }
              array={textBanner}
            />
          }
        />
        <Section className="section_group">
          {text.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <Section className="section_group" style={{ paddingBottom: 18 }}>
          {textDown.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIMacLinks />
      </main>
    </Page>
  );
};

export default RepairIMacLinkDisplay;
