import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display6.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay6 = [
  {
    id: 1,
    title: "iPhone 6: замена дисплея и её особенности",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Замена дисплея iPhone 6 – это целый комплекс процедур, который
          потребует практически полной разборки устройства. Именно поэтому не
          рекомендуется заниматься этой работой самостоятельно, ведь процедура
          включает в себя следующие этапы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Извлечь сим-карту, после чего аккуратно удалить предназначенный
              для неё слот
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Открыть болты и снять USB-порт
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отделить экран, отсоединить шлейф аккумулятор
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выкрутить болты и удалить защитную планку, скрывающую шлейф
              дисплея
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отсоединить все доступные шлейфы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">06</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Снять металлическую заглушку, после чего извлечь камеру и динамик
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">07</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              На финальном этапе отсоединить датчик, микрофон, сенсор, кнопку
              «Home»
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">08</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Остается лишь снять крышку дисплея, открутить шурупы по периметру
              и извлечь подсветку.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Чтобы аккуратно отделить разбитое стекло, а потом прикрепить новый
          дисплей, потребуются специальные инструменты. С помощью сепаратора
          устройство нагревается до заданной температуры, что позволяет легко
          снять панель. В нашем сервисном центре есть необходимое оборудование и
          расходные материалы, что позволит установить новое стекло без
          пузырьков воздуха и крупиц пыли – ваш смартфон будет полностью готов к
          использованию через 1-2 часа после посещения центра.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels6 = () => {
  return (
    <Page title="Срочная замена дисплея (экрана) iPhone 6 с гарантией в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 6"
          text="iPhone 6 порадовал любителей технологических новинок увеличенным размером экрана. И хотя создатели устройства позаботились об увеличении прочности защитного стекла, 100% защиту от удара это не сможет обеспечить. Достаточно один раз неудачно уронить смартфон, чтобы его поверхность покрыла сеть трещин – мы поможем исправить эту проблему. А благодаря использованию исключительно надежных сертифицированных запчастей, можно гарантировать высокое качество ремонта."
        />
        <RepairApplePrice
          subtitle="Стоимость замены дисплея (экрана) iPhone 6"
          text="Замена дисплея"
          price="2490 P"
          paragraph="Мы не предлагаем клиентам слишком низкие цены, потому что в работе используются только качественные комплектующие, и мы предоставляем все необходимые гарантии. Но наши условия действительно лояльны и помогут вам получить полный комплекс услуг по приемлемым расценкам. Не тратьте лишние деньги на восстановление устройства из-за того, что ряд его функций был нарушен после попытки самостоятельного ремонта. Гораздо выгоднее изначально обратиться к мастерам, которые помогут устранить возникшие повреждения – в нашем сервисном центре вы получите все необходимые услуги."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay6.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels6;
