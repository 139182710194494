import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  MacProfiList,
  RepairMacBookLinks,
  Page,
  BreadCrumbs,
  Section,
  RepairAppleTableMobile,
} from "../index";
import { Link } from "react-router-dom";
import banner from "../../images/banners/mb-memory.jpeg";
import { useMedia } from "../../utils/useMedia";

const listOfMemory = [
  {
    id: 1,
    number: "01",
    subtitle: "Скорость работы.",
    textLi: (
      <>
        Если ваш MacBook постоянно подтормаживает, тяжелые приложения долго
        загружаются, в данном случае рекомендуется увеличить оперативную память.
        Данная мера, как и{" "}
        <Link
          to="/remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air"
          className="repair-mb__group-links-array"
        >
          замена SSD на MacBook
        </Link>
        , помогает увеличить скорость загрузки приложения, скорость обработки
        данных.
      </>
    ),
  },
  {
    id: 2,
    number: "02",
    subtitle: "Оперативная память вышла из строя.",
    textLi:
      "Проявляется это в виде постоянных ошибок операционной системы, зависания, черный экран. Выявить данную неисправность и произвести замену оперативной памяти в MacBook вы можете у нас в сервисном центре в самые кратчайшие сроки.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Сроки замены.",
    textLi:
      "Оперативная память меняется в течении 30 минут. За чашечкой кофе в нашем сервисном центре вы можете подождать, пока специалисты поменяют вам память на модули большего объема.",
  },
];

const DDR3 = [
  {
    service: "PC 8500 1 GB",
    time: "20 минут",
    price: "1300 руб.",
  },
  {
    service: "PC 8500 4 GB",
    time: "20 минут",
    price: "2500 руб.",
  },
  {
    service: "PC 10600 4 GB",
    time: "20 минут",
    price: "2500 руб.",
  },
  {
    service: "PC 10600 8 GB",
    time: "20 минут",
    price: "4500 руб.",
  },
  {
    service: "PC 12800S 2 GB",
    time: "20 минут",
    price: "2800 руб.",
  },
  {
    service: "PC 12800 4 GB",
    time: "20 минут",
    price: "2900 руб.",
  },
];

const DDR2 = [
  {
    service: "PC 5300 2 GB",
    time: "20 минут",
    price: "2100 руб.",
  },
  {
    service: "PC 6400 4 GB",
    time: "20 минут",
    price: "2500 руб.",
  },
];

const macBookMemoryTable = [
  {
    value: "DDR 3",
    label: "DDR 3",
    list: DDR3,
  },
  {
    value: "DDR 2",
    label: "DDR 2",
    list: DDR2,
  },
];

function ChangeTableMacBookPro() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={macBookMemoryTable}
        serviceText="Частота и объем ОП"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель MacBook</th>
          <th>Тип оперативной памяти</th>
          <th>Частота работы</th>
          <th>Объем в GB</th>
          <th>Стоимость</th>
          <th>Сроки замены</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 3</td>
          <td>PC8500</td>
          <td>1 GB</td>
          <td>1300 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 2</td>
          <td>PC5300</td>
          <td>2 GB</td>
          <td>2100 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 2</td>
          <td>PC6400</td>
          <td>2 GB</td>
          <td>2100 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td> DDR 3</td>
          <td>PC8500</td>
          <td>4 GB</td>
          <td>2500 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 3</td>
          <td>PC10600</td>
          <td>4 GB</td>
          <td>2500 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 3</td>
          <td>PC12800</td>
          <td>4 GB</td>
          <td>2900 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 3</td>
          <td>PC12800S</td>
          <td>2 GB</td>
          <td>1800 руб.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена/увеличение памяти MacBook Pro Unibody</td>
          <td>DDR 3</td>
          <td>PC10600</td>
          <td>8 GB</td>
          <td>4500 руб.</td>
          <td>20 минут</td>
        </tr>
      </tbody>
    </table>
  );
}

const RepairMacBookLinkMemory = () => {
  return (
    <Page title="Увеличение, замена оперативной памяти на MacBook, MacBook Pro">
      <main className="repair-mb__group-links-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Увеличение, замена оперативной памяти на MacBook, MacBook Pro"
          text="Увеличение (апгрейд) оперативной памяти на MacBook, MacBook Pro чаще всего нужно по следующим причинам:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfMemory.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену оперативной памяти в&nbsp;MacBook, MacBook
              Pro
            </h2>
            {ChangeTableMacBookPro()}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkMemory;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
