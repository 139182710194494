import React from "react";
import logo from "../../images/banners/mac-profi-purple.svg";
import Section from "../Section/Section";
import { useBigTabletScreen } from "../../utils/useMedia";
import { Link } from "react-router-dom";

function Image() {
  const isMobile = useBigTabletScreen();
  if (isMobile) {
    return null;
  }
  return (
    <>
      <img
        src={logo}
        className="mac-profi__apple-logo"
        alt="логотип компании"
      />
    </>
  );
}

const MacProfiApple = () => {
  return (
    <Section className="mac-profi__apple-container">
      <div className="mac-profi__apple-contant">
        {Image()}
        <div className="mac-profi__apple-wrapper">
          <h2 className="mac-profi__apple-title">
            Сервисный центр Apple - Мак Профи
          </h2>
          <p className="mac-profi__apple-text">
            Сервисный центр Мак Профи производит ремонтные работы всей линейки
            техники Apple (Эпл). Мы занимаем лидирующие позиции в Москве по
            ремонту техники Apple.
          </p>
          <button className="mac-profi__apple-button" type="button">
            <Link className="mac-profi__apple-link" to="/o-kompanii">
              Подробнее
            </Link>
          </button>
        </div>
      </div>
    </Section>
  );
};

export default MacProfiApple;

// toDo:
// h1 ?? + семантика
