import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone6.jpeg";

const textOfModel6 = [
  {
    id: 1,
    title: "Специфика ремонта Айфон 6",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          С первого дня появления нового iPhone 6 на рынке, модель преследовали
          слухи о её склонности к изгибу. Интернет наводнили фото и видео
          погнувшихся в карманах Айфонов, ставшие предлогом для многочисленных
          шуток. Как показало время - слухи, как впрочем и всегда, были сильно
          преувеличены. Для того, чтобы погнуть корпус iPhone 6 вам придётся
          изрядно постараться. Впрочем, если вы оказались, в числе
          "счастливчиков", чей телефон всё же погнулся - вы без труда устраните
          все последствия этой неприятности в нашем центре.
        </p>
        <p className="repair-iphone-display__paragraph">
          Чего нельзя отрицать так это высокую хрупкость телефона. Шикарный
          дизайн со скруглённым экраном и почти незаметными рамками имеет и свою
          обратную сторону. После падения, особенно на ребро, вашему любимому
          iPhone 6 практически со 100% вероятностью потребуется замена дисплея.
        </p>
        <p className="repair-iphone-display__paragraph">
          По сравнению с предыдущей моделью в Айфон 6 была существенна упрощена
          конструкция шлейфа для кнопки Home. Основная фишка "пятёрки" - сканер
          отпечатков, совмещенный с этой кнопкой, была и её основным слабым
          местом. Именно поломка этой кнопки была основной причиной обращения в
          сервисные центры обладателей iPhone 5s. Для iPhone 6 данная проблема
          не стоит так остро. Впрочем, совсем исключить возможность подобной
          поломки нельзя.
        </p>
        <p className="repair-iphone-display__paragraph">
          Вместе с Айфоном 6 практически ушла в прошлое и одна из наиболее
          популярных причин обращения в ремонтные мастерские в прошлом: телефон
          практически не боится влаги. Корпус и все разъемы были сделаны
          влагостойкими.
        </p>
        <p className="repair-iphone-display__paragraph">
          В завершение отметим главное: iPhone 6 воплощение сложной инженерной
          мысли и доверять его ремонт непрофессионалам непозволительно.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Как мы ремонтируем iPhone 6",
    text: (
      <>
        В начале телефон подвергается полной и всесторонней диагностике. После
        мы извещаем вас о выявленных неисправностях и вы сами выбираете, какой
        ремонт вашего Айфон 6 вы хотите произвести. Оплачиваются только эти,
        выбранные вами, работы, и только после того как вы получите исправный
        телефон на руки и убедитесь в его исправности.
      </>
    ),
  },
];

const price6 = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage6 = () => {
  return (
    <Page title="Цены ремонт iPhone 6 в Москве. Бесплатная доставка в СЦ - Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone 6"
          text={
            <>
              Специализация нашего центра - быстрый и качественный ремонт iPhone
              6 в Москве. На все отремонтированные в нашем центре телефоны
              предоставляется гарантия сроком до года, большая часть поломок
              устраняется за 20 минут. Помимо того, ваш телефон может забрать и
              привезти после ремонта наш курьер, так что вам даже не придётся
              подъезжать в наш центр.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price6}
            title="Цены на ремонт iPhone 6"
            text="* Цена включает комплектующее и услугу по установки. Все цены указаны в рублях РФ."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel6.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage6;
