import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import acerBanner from "../../../images/banners/pad-acer-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "НАЛИЧИЕ ВСЕХ КОМПЛЕКТУЮЩИХ",
    text: "Если у вас Асер, ремонт планшетов этой марки выполняется в самые короткие сроки благодаря постоянному наличию запчастей ко всем популярным моделям.",
  },
  {
    img: icon2,
    title: "ВЫЗОВ КУРЬЕРА",
    text: "Если вы ограничены во времени, для вызова курьера можно связаться с нами по телефону +7 (495) 540-43-19. Наш сотрудник оставит все необходимые документы и вернет ваш планшет сразу после его ремонта.",
  },
  {
    img: icon3,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "У нас работает зона бесплатной доставки, в границах которой вызов курьера осуществляется на бесплатной основе. Подробнее можно узнать по телефону или по электронной почте info@centre-mobile.ru.",
  },
  {
    img: icon4,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "При необходимости быстрого восстановления планшета Асер наши мастера могут выполнить срочный ремонт. В этом случае гаджет восстанавливается в присутствии его владельца.",
  },
];

const pads = [
  { name: "Aspire Switch 10E", pricelist: desireHD },
  { name: "Iconia One 7", pricelist: desire400 },
  { name: "Iconia Tab A3-A40", pricelist: desireHD },
  { name: "Iconia One B3-A30", pricelist: desire400 },
  { name: "Iconia Talk S", pricelist: desireHD },
  { name: "Iconia Tab 10", pricelist: desire400 },
  { name: "Iconia Talk 7", pricelist: desireHD },
  { name: "Predator 8", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadAcer() {
  return (
    <Page title="Ремонт планшетов Acer в Москве, услуги сервисного центра «Центр Мобайл»">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={acerBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ ACER"
          text="Многолетний опыт наших мастеров, их компетентность и современная техническая база сервисного центра позволяют в максимально сжатые сроки производить эффективный ремонт планшетов Acer в Москве. Мы прилагаем все усилия, чтобы поломка любого уровня была устранена быстро, а качество и стоимость услуги приятно порадовали нашего клиента."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Acer"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Acer:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА ACER В «ЦЕНТР МОБАЙЛ»"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ЗАКАЗАТЬ РЕМОНТ ПЛАНШЕТА ACER ?"
          text="Неисправный гаджет можно привезти к нам в центр самостоятельно. При нехватке времени можно вызвать курьера. А если поломка несущественная и не требует стационарного оборудования, то мастер может починить планшет на дому. Дополнительная информация – по телефону +7 (495) 540-43-19, опытные менеджеры будут рады ответить на ваши вопросы."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadAcer;
