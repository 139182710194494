import React from "react";
import { Dialog } from "@headlessui/react";
import styles from "./CenterMobileCallBackPopup.module.css";
import { useState } from "react";
import ok from "../../../images/icons/popup-ok.svg";
import popupCloseIcon from "../../../images/icons/popup-close.svg";
import InputMask from "react-input-mask";
import recaptcha from "../../../images/recaptcha.svg";
import titleIcon from "../../../images/icons/callback-titile-icon.svg";

function CenterMobileCallBackPopup({ isOpen, setIsOpen }) {
  const [errors, setErrors] = useState({
    username: false,
    phoneNumber: false,
  });
  const [values, setValues] = useState({
    username: "",
    phoneNumber: "",
    gadget: "",
  });
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  function isValidName(username) {
    return username.trim().length > 1;
  }

  function isValidNumber(phoneNumber) {
    // for (let i = 0; i < phoneNumber.length; i++) {
    //   if (phoneNumber[i] === "_") {
    //     return false;
    //   }
    // }
    // return true;
    const result =
      phoneNumber !== "" &&
      Array.from(phoneNumber).every((char) => char !== "_");
    return result;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isValidName(values.username)) {
      setErrors((prevErrors) => ({ ...prevErrors, username: true }));
    }
    if (!isValidNumber(values.phoneNumber)) {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
    }
    if (isValidName(values.username) && isValidNumber(values.phoneNumber)) {
      setIsSubmitSuccessful(true);
    }
  }

  const buttonClassName =
    isValidName(values.username) && isValidNumber(values.phoneNumber)
      ? styles.buttonActive
      : styles.button;

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles.backdrop} aria-hidden="true"></div>
      <div className={styles.container}>
        {isSubmitSuccessful ? (
          <div className={styles.contentSuccess}>
            <Dialog.Panel className={styles.success}>
              <img
                src={popupCloseIcon}
                alt="иконка закрытия окна"
                className="center-mobile-general-page__popup-success-close"
                onClick={() => setIsOpen(false)}
              />
              <img
                src={ok}
                className="center-mobile-general-page__popup-success-finger"
                alt="иконка успешной отправки"
              />
              <Dialog.Title className="center-mobile-general-page__popup-success-title">
                Мы вам перезвоним в течении 30 минут!
              </Dialog.Title>
              <p className="center-mobile-general-page__popup-success-subtitle">
                звонки осуществляются только в рабочее время
              </p>
            </Dialog.Panel>
          </div>
        ) : (
          <div className={styles.content}>
            <Dialog.Panel className={styles.wrapper}>
              <Dialog.Title className={styles.title}>
                <img
                  src={titleIcon}
                  alt="иконка заголовка"
                  style={{ marginRight: 10 }}
                />
                ПОЛУЧИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
              </Dialog.Title>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputWrapper}>
                  <label className={styles.label} htmlFor="username">
                    ВАШЕ ИМЯ
                  </label>
                  <input
                    className={styles.input}
                    id="username"
                    type="text"
                    placeholder="Иван"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isValidName(value) && errors.username) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          username: false,
                        }));
                      }
                      setValues((prevValue) => ({
                        ...prevValue,
                        username: value,
                      }));
                    }}
                  />
                  {errors.username ? (
                    <p className={styles.errorText}>*не указали имя</p>
                  ) : (
                    <p className={styles.hiddenErrorText}></p>
                  )}
                </div>
                <div className={styles.inputWrapper}>
                  <label className={styles.label} htmlFor="phone">
                    КОНТАКТНЫЙ ТЕЛЕФОН
                  </label>
                  <InputMask
                    autoComplete="none"
                    mask="+7 (999) 999-99-99"
                    className={styles.input}
                    id="phone"
                    type="tel"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isValidName(value) && errors.phoneNumber) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phoneNumber: false,
                        }));
                      }
                      setValues((prevValue) => ({
                        ...prevValue,
                        phoneNumber: value,
                      }));
                    }}
                  />
                  {errors.phoneNumber ? (
                    <p className={styles.errorText}>*не указали телефон</p>
                  ) : (
                    <p className={styles.hiddenErrorText}></p>
                  )}
                </div>
                <div className={styles.inputWrapper}>
                  <label className={styles.label} htmlFor="gadget">
                    ВАШЕ УСТРОЙСТВО
                  </label>
                  <input
                    className={styles.input}
                    id="gadget"
                    type="text"
                    placeholder="Iphone 11 pro"
                    onChange={(e) => {
                      const value = e.target.value;
                      setValues((prevValue) => ({
                        ...prevValue,
                        gadget: value,
                      }));
                    }}
                  />
                </div>
                <button className={buttonClassName} type="submit">
                  МЫ ВАМ ПЕРЕЗВОНИМ!
                </button>
              </form>
            </Dialog.Panel>
            <img src={recaptcha} alt="рекапча" className={styles.recaptcha} />
          </div>
        )}
      </div>
      <div></div>
    </Dialog>
  );
}

export default CenterMobileCallBackPopup;
