import react from "react";
import {
  MacProfiAboutUs,
  MacProfiAboutUsMobile,
  MacProfiApple,
  MacProfiBanner,
  MacProfiBannerMobile,
  MacProfiList,
  MacProfiListMobile,
  MacProfiReasons,
  BreadCrumbs,
} from "../index";
import { useMobileScreen, useBigTabletScreen } from "../../utils/useMedia";

function Banner() {
  const isMedia = useBigTabletScreen();

  if (isMedia) {
    return <MacProfiBannerMobile />;
  }
  return <MacProfiBanner />;
}

function AboutUs() {
  const isMedia = useMobileScreen();

  if (isMedia) {
    return <MacProfiAboutUsMobile />;
  }
  return <MacProfiAboutUs />;
}

const MacProfiPage = () => {
  return (
    <main className="mac-profi__page">
      <MacProfiList />
      <BreadCrumbs />
      {Banner()}
      {AboutUs()}
      <MacProfiApple />
      <MacProfiReasons />
    </main>
  );
};

export default MacProfiPage;
