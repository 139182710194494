import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadAir2.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Честная цена – первоначально названная стоимость останется неизменной, и она включает в себя все затраты на ремонт;",
    number: "01",
  },
  {
    id: 2,
    text: "Оперативность – все детали уже в наличии, а ремонт будет проведен в течение часа, благодаря чему вы сможете наблюдать за процедурой;",
    number: "02",
  },
  {
    id: 3,
    text: "Бесплатная диагностика – тестирование необходимо для выявления внутренних, незаметных глазу повреждений, а потому оно играет ключевую роль в восстановлении устройства;",
    number: "03",
  },
  {
    id: 4,
    text: "Гарантия качества – только качественные запчасти и профессиональный подход к работе, что обеспечивает отменный результат.",
    number: "04",
  },
];

const textOfCaseAir2 = [
  {
    id: 1,
    title: "Почему замена корпуса iPad Air 2 должна быть проведена в сервисе",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Ключевая сложность в ремонте техники Apple заключается в том, что
          конструкция довольно нестандартна. Самостоятельно разобрать устройство
          не удастся, ведь необходимо изначально растопить клей, соединяющий
          корпус, стекло и тачскрин, а это можно сделать лишь при высокой
          температуре.
        </p>
        <p className="repair-iphone-display__paragraph">
          Кроме того, неаккуратные действия или случайные ошибки, допущенные при
          замене крышки, могут стать причиной повреждения стекла. В этом случае
          замена обойдется на порядок дороже, чем пришлось бы заплатить
          изначально. Не стоит тратить лишние деньги, если есть возможность
          получить необходимые услуги по честной цене – в Мак Профи замена
          корпуса iPad Air 2 будет выполнена на выгодных условиях.
        </p>
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModelAir2 = () => {
  return (
    <Page title="Качественная замена корпуса на iPad Air 2 в Москве: ремонт по честным ценам">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Air&nbsp;2</>}
          text="Сложно уберечь гаджет от повреждений при активной ежедневной эксплуатации устройства – хрупкий iPad Air 2 легко уронить или поцарапать. И пусть царапины на корпусе лишь портят общее впечатление, но серьезные дефекты могут привести к проблемам с аккумулятором или поломке ключевых элементов системы. Если ваш планшет «украсили» сколы и другие повреждения, то мы с легкостью устраним их в день обращения – в Мак Профи для этого есть необходимые инструменты и оборудование."
        />
        <Section className="section_group">
          {textOfCaseAir2.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Преимущества замены корпуса iPad Air 2 в Мак Профи"
          text="Замена сенсорного стекла (тачскрина)"
          price="7900 P"
          paragraph={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="Если вы хотите обновить планшет перед продажей, либо же устранить недочеты, которые мешают комфортному использованию устройства, обратитесь к нам. Мы открыты для клиентов и в будние, и выходные дни, к тому же действует услуга курьерской доставки. В Мак Профи вы получите высокий уровень сервиса, что включает в себя следующие преимущества:"
                array={listOfTrouble}
                paragraphDown="Если вам необходима замена корпуса iPad Air 2, то у вас есть возможность доверить эту работу профессионалам. Специалисты Мак Профи заботятся о репутации сервиса, что дает возможность гарантировать высокое качество услуг. Приезжайте к нам в любой удобный день, включая субботу и воскресенье, чтобы получить квалифицированную помощь в ремонте поврежденного устройства."
              />
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelAir2;
