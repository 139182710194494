import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery6splus.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery6sPlus = [
  {
    id: 1,
    title: "Причины повреждения аккумулятора iPhone 6s Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Можно выделить целый ряд причин, из-за которых Айфон начинает быстро
          разряжаться, а если вовремя не принять меры, то в один из дней он и
          вовсе не включиться. Чаще всего аккумулятор выходит из строя:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Из-за попадания жидкости
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Преждевременного износа
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Работа в условиях низких температур
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Использование «сложного» программного обеспечения.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Если не будет произведена своевременная замена аккумулятора iPhone 6s
          Plus, это может привести к повреждению материнской платы. В
          результате, значительно сокращается срок службы устройства – гораздо
          дешевле обратиться к специалистам и заплатить за ремонт батареи, чем в
          дальнейшем потратить крупную сумму на покупку нового телефона.
        </p>
      </>
    ),
  },
];

const textOfBattery6sPlusDown = [
  {
    id: 1,
    title:
      "Почему стоит доверить замену аккумулятора iPhone 6s Plus мастерам Мак Профи ",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мак Профи – это качественный сервис, и комплексное сопровождение
          каждого заказа. У нас работают только квалифицированные мастера,
          которые аккуратно устранят поломку, не оставив на устройстве следов
          ремонта. Сотрудничая с нами, вы можете получить и другие преимущества:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Работа 7 дней в неделю – вы самостоятельно выбираете удобное время
              и день для посещения центра.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Курьерская доставка – сэкономим ваше время и привезем исправный
              смартфон на дом или на работу.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Гарантия качества – только качественные комплектующие и
              профессиональный ремонт.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Все работы выполняются в срочном порядке – на складе всегда есть
          необходимые для ремонта детали. Мы ждем вас ежедневно, чтобы быстро
          устранить любые поломки и восстановить стабильную работу iPhone 6s
          Plus.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels6sPlus = () => {
  return (
    <Page title="Не держит заряд iPhone 6s Plus? Замена аккумулятора на iPhone 6s Plus - Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPhone 6s Plus"
          text="Замена батареи iPhone 6s Plus потребуется спустя 2 года эксплуатации устройства. Не откладывайте обращение в сервисный центр, чтобы не испытывать неудобств из-за нестабильной работы устройства. Вам потребуется около получаса, чтобы вновь начать пользоваться смартфоном, не тратя время на постоянную подзарядку. А лояльная цена станет дополнительным бонусом для клиентов нашего сервиса."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery6sPlus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены батареи iPhone 6s Plus"
          text="Замена аккумулятора"
          price="1900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Перед началом любого ремонта наши специалисты детально исследуют
                устройство. Диагностика абсолютно бесплатна и дает возможность
                понять, что же послужило основной причиной поломки. После этого
                будет озвучена точная стоимость восстановления и замены
                комплектующих, которая останется неизменной.
              </p>
              <p className="repair-iphone-display__paragraph">
                В наличии всегда есть все необходимые детали, благодаря чему
                ремонт Айфон не займет длительное время. Работы производятся
                непосредственно при клиенте – 20-30 минут, и вы сможете покинуть
                центр с полностью исправным смартфоном.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfBattery6sPlusDown.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels6sPlus;
