import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone6s.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone6s = [
  {
    id: 1,
    title: "Быстрая замена микрофона для iPhone 6s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Профессиональный подход позволит в любом случае устранить
          неисправность, и потому переживать не стоит. Лучше подумать о будущем:
          чтобы не допустить в дальнейшем этих и других проблем, необходимо
          приучить себя к бережному отношению к устройству, аккуратно с ним
          обращаться. А еще стоит купить чехол и пользоваться им – хорошие
          защитные чехлы дают ощутимую протекцию. Однако что же делать, если
          поломка уже произошла, и вы предполагаете, что речь идет именно о
          пострадавшем микрофоне?{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          Помощь нашей мастерской – любой ремонт iPhone возможен!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels6s = () => {
  return (
    <Page title="Качественная замена микрофона iPhone 6s в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 6s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с микрофоном являются одними из типичных для Айфона
                поломок. Это высокотехнологичное оборудование отличается высокой
                надежностью, однако свои слабо защищенные и уязвимые места здесь
                все же присутствуют. Однако не стоит переживать: даже если такое
                случается, замена микрофона iPhone 6s позволяет решить проблему
                и продолжить эксплуатацию устройства. При этом крайне важно
                обеспечить профессиональный ремонт, потому как это тонкое
                оборудование не терпит дилетантских вмешательств, и последние
                обычно заканчиваются еще большими проблемами, необходимостью еще
                более дорогого и сложного профессионального ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если вы не желаете сталкиваться с такими результатами, или вовсе
                менять дорогостоящий девайс на новый, лучше сразу
                воспользоваться профессиональными услугами ремонта, чтобы
                обеспечить технике долгий срок эксплуатации. Проявляет же себя
                поломка микрофона следующим образом:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Нет слышимости вообще – абонент не может вас слышать,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Возникают посторонние шумы,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Слишком тихий звук, разговаривать с вами проблематично,
                    настройки не помогают.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все эти явления устранимы. В последних двух случаях иногда
                вопрос решает простая чистка, которая, впрочем, тоже должна
                осуществляться в профессиональных условиях. Первый же случай
                наглядно говорит о поломке микрофона. Произойти такое может
                из-за падения или удара, по причине окисления, проникновения
                воды, из-за износа.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingTop: 30 }}>
          {textOfMicrophone6s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          text="Замена микрофона"
          price="1950 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                В этом случае вашей основной задачей будет выбор хороших
                специалистов, которые смогут полноценно и с гарантией решить
                вашу проблему. Сервисный центр Мак Профи работает уже много лет,
                решая проблемы любой сложности, и ремонтируя высокотехнологичное
                оборудование на высочайшем уровне. Наши мастера справляются с
                любыми задачами, и мы даем длительный срок гарантии на их
                работу. Замена микрофона может быть выполнена по вполне
                доступной цене, с гарантиями, в короткие сроки, при
                необходимости – в срочном порядке. Обращайтесь, и ваш Айфон
                снова будет работать исправно!
              </p>
              <p className="repair-iphone-display__paragraph">
                Залогом нашего успеха становится оснащение мастерской – каждое
                рабочее место адаптировано под работу со сложной техникой и
                выполнение любых задач, ведь порой работа с такими девайсами
                требует буквально ювелирного подхода. У нас всегда имеются в
                наличии запчасти, на которые дается отдельная гарантия – они
                имеют заводское происхождение, каждая из них оригинальная,
                высококачественная. А еще у нас работают только компетентные
                исполнители, которые знают об Айфонах каждую мелочь, имеют
                профильное образование и большой опыт. Все это позволяет нам
                уверенно справляться с задачами.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Первым этапом реализации задач становится диагностика, которая
                выполняется у нас бесплатно. Она отнимет всего несколько минут,
                и затем мастер сможет сказать причину неисправности, сроки и
                стоимость ее устранения, ответить на все вопросы. Если вас все
                устроит – мы немедленно начнем работы, и уже вскоре вы сможете
                увидеть результат. Обращайтесь, и мы починим технику, создадим
                все условия для ее дальнейшей эксплуатации, и она прослужит вам
                еще много лет!
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels6s;
