import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIwatchModeslLinks,
} from "../index";
import banner from "../../images/banners/iwatchSport.jpeg";

const listOfTrouble = [
  {
    id: 1,
    text: "Перезагрузить устройство",
    number: "01",
  },
  {
    id: 2,
    text: "Разорвать соединение между часами и смартфонами",
    number: "02",
  },
  {
    id: 3,
    text: "Повторно устранить пару между Apple Watch Sport и iPhone.",
    number: "03",
  },
];

const prep = [
  {
    id: 1,
    text: "Зарядить аккумулятор на 100%;",
    number: "01",
  },
  {
    id: 2,
    text: "Разорвать пару между часами и iPhone;",
    number: "02",
  },
  {
    id: 3,
    text: "Зарядку, аксессуары и ремешок (если не в нем заключается причина обращения в сервис) лучше оставить дома.",
    number: "03",
  },
];

const textIwatchSport = [
  {
    id: 1,
    title: "Нужен ли Apple Watch Sport ремонт?",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Если в работе гаджета возникли какие-либо сбои, для начала стоит проделать базовые действия, рекомендуемые производителем – в некоторых случаях это позволяет решить проблему. Потребуется:"
          array={listOfTrouble}
          paragraphDown="Если подобные процедуры не дали ожидаемого результата – поломка достаточно серьезна. Не стоит пытаться самостоятельно вскрыть конструкцию, ведь под корпусом вы обнаружите плотно укомплектованную «начинку» - разобраться в этих хитросплетениях не так уж просто, как может показаться на первый взгляд. Лучше обратитесь в сервисный центр, в котором будет выполнен ремонт Apple Watch Sport – в Мак Профи вам будет предоставлен гарантийный талон, который лучше любых слов подтверждает отменное качество выполненного ремонта."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Подготовка Apple Watch Sport к ремонту",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Вы можете привезти устройство в сервис самостоятельно, либо воспользоваться услугой доставки. Перед тем, как отдавать гаджет специалистам, рекомендуется подготовить его к предстоящему восстановлению:"
          array={prep}
          paragraphDown="После завершения ремонта мастер продемонстрирует стабильную работу системы, подтвердив успешное завершение ремонта Apple Watch Sport. После этого потребуется вновь настроить устройство, и можно продолжать пользоваться им в привычном режиме. Важно учитывать, что гарантия действует на все услуги и комплектующие, но её продолжительность определяется в зависимости от характера поломки. Точная стоимость будет названа сразу после диагностики, как и период восстановления. Основную часть работ можно провести меньше, чем за час – и вам не придется оставлять гаджет в сервисе на длительное время."
          paragraphDown2="Обращайтесь в Мак Профи, чтобы ремонтом вашей техники занимались специалисты, прошедшие специальное обучение. Вы платите за качество и профессионализм, благодаря чему работа Apple Watch Sport будет восстановлена настолько быстро, насколько позволяют технические возможности."
        />
      </>
    ),
  },
];

const RepairIwatchModelLinkSport = () => {
  return (
    <Page title="Ремонт Apple Watch Sport в Москве по низким ценам">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Ремонт Apple&nbsp;Watch&nbsp;Sport</>}
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ремонт Apple Watch Sport: починим часы за одно посещение
                сервиса. Услугу курьерской доставки в Москве. Комплексная
                диагностика.
              </p>
              <p className="repair-iphone-display__paragraph">
                Apple Watch Sport – спортивная водонепроницаемая модель, для
                которой был разработан специальный защитный корпус повышенной
                прочности. Но даже при условии дополнительной защиты может
                потребоваться ремонт устройства – ни одна техника не
                застрахована от возможных неполадок. Мы поможем вам не лишаться
                верного «помощника», столь необходимого для тех, кто ведет
                активный образ жизни, ведь в Мак Профи работают ведущие
                московские специалисты.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIwatchSport.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIwatchModeslLinks />
      </main>
    </Page>
  );
};

export default RepairIwatchModelLinkSport;
