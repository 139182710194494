import React, { useEffect, useRef } from "react";
import { PageLinksMobile, AncorLinksMobile } from "../index";
import { Link, useLocation } from "react-router-dom";

const BurgerMenu = ({ active, setActive }) => {
  const location = useLocation();
  const menuRef = useRef();

  useEffect(() => {
    const closeErrorPopupByEscape = (event) => {
      if (event.key === "Escape") {
        setActive(false);
      }
    };
    document.addEventListener("keydown", closeErrorPopupByEscape);
    return () =>
      document.removeEventListener("keydown", closeErrorPopupByEscape);
  }, []);

  useUpdateEffect(() => {
    setActive(false);
  }, [location.pathname, setActive]);

  useEffect(() => {
    if (!active) return;

    function handleOutsideClick(event) {
      if (!menuRef.current.contains(event.target)) {
        setActive(false);
      }
    }

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [active, setActive]);

  return (
    <section
      ref={menuRef}
      className={active ? "burger-menu active" : "burger-menu"}
    >
      <div className="burger-menu-content">
        {/* <button type="button" className="burger-menu__btn">
          Личный кабинет
        </button> */}
        <Link to="/login" className="burger-menu__btn">
          Личный кабинет
        </Link>
        <ul className="burger-menu__tel-list">
          <li className="berger-menu__tel-item">
            <a href="tel:+74953745481?call" className="burger-menu__tel-link">
              +7(495)374-54-81
            </a>
          </li>
          <div className="burger-menu__tel-border"></div>
          <li className="berger-menu__tel-item">
            <a href="tel:+74955405067?call" className="burger-menu__tel-link">
              +7(495)540-50-67
            </a>
          </li>
        </ul>
        <ul className="burger-menu__navigation">
          {location.pathname === "/" ? (
            <>
              <AncorLinksMobile setActive={setActive} />
              <PageLinksMobile setActive={setActive} />
            </>
          ) : (
            <>
              <li className="burger-menu__item">
                <Link
                  onClick={() => setActive(false)}
                  to="/"
                  className="burger-menu__link"
                >
                  Главная
                </Link>
              </li>
              <PageLinksMobile />
            </>
          )}
        </ul>
      </div>
    </section>
  );
};

export default BurgerMenu;

export function useUpdateEffect(effect, deps) {
  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    if (isMountedRef.current) {
      return effect();
    }
    isMountedRef.current = true;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
