import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery4s.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery4s = [
  {
    id: 1,
    title: "Почему стоит доверить замену аккумулятора iPhone 4s профессионалам",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Естественный износ аккумулятора неизбежен, и его невозможно
          компенсировать установкой подходящего программного обеспечения или
          другими способами. Обращение в сервисный центр становится единственным
          способом решения проблемы – наши мастера выполнят эту работу в срочном
          порядке.
        </p>
        <p className="repair-iphone-display__paragraph">
          Не рекомендуется выполнять ремонт самостоятельно – и на это есть
          несколько важных доводов. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отсутствие полноценной диагностики приводит к неправильному
              определению причин возникновения поломки. Нередко из-за этого
              обладатель смартфона упускает иные, более серьезные повреждения,
              которые вскоре приведут к некорректной работе аппарата.{" "}
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Для замены комплектующих потребуются специальные инструменты.
              Использование подручных материалов может привести к появлению
              повреждений на корпусе устройства.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Неаккуратные действия становятся причиной возникновения более
              серьезных поломок.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Если ваш смартфон работает меньше 8-9 часов, часто выключается или же
          и вовсе не включается – приезжайте в сервисный центр Мак Профи. Замена
          аккумулятора iPhone 4s выполняется сразу после обращения – меньше 30
          минут, и вы сможете отправиться домой с обновленным телефоном.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Как заменить батарею iPhone 4s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Для установки нового элемента питания нужно выкрутить нижние винты и
          аккуратно подвинуть крышку. После этого снимается винт, который
          используется для крепления разъема аккумулятора к плате. Остается лишь
          бережно извлечь испорченную деталь и установить на её место новую. У
          опытного специалиста на это уйдет не больше 10-15 минут времени – все
          детали уже в наличии, а потому заказывать и ждать необходимые
          комплектующие вам не придется.
        </p>
        <p className="repair-iphone-display__paragraph">
          Стандартная стоимость работ указана на сайте, но точная цена будет
          озвучена при посещении сервисного центра и проведении диагностики. В
          некоторых случаях проблему удается решить настройкой программного
          обеспечения, что позволит сэкономить вам средства. Мы предложим самые
          выгодные и рациональные способы быстрого решения возникшей проблемы.
        </p>
        <p className="repair-iphone-display__paragraph">
          Сервисный центр Мак Профи в Москве работает ежедневно – приезжайте в
          удобное время с понедельника по воскресенье. Выполним все виды ремонта
          и предложим лучшие условия.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels4s = () => {
  return (
    <Page title="Выгодная замена аккумулятора (батареи) на iPhone 4s в Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPhone 4s "
          text="Гарантированный период использования Li-Ion аккумуляторов составляет около 2-х лет – после этого они нуждаются в обязательной замене. В противном случае повреждения этого элемента могут привести к порче и других частей устройства, например, материнской платы. Чтобы не тратить дополнительные деньги на ремонт и не подзаряжать смартфон через каждые 3-4 часа, обратитесь к нам – мы оперативно проведем замену батареи iPhone 4s, предоставив гарантии на используемые детали."
        />
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора iPhone 4s"
          text="Замена аккумулятора"
          price="900 P"
          paragraph="Цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery4s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels4s;
