import React, { useState } from "react";
import Select, { components } from "react-select";
import vector from "../../images/icons/Vector.svg";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img src={vector} alt="" style={{ marginRight: 29 }} />
  </components.IndicatorsContainer>
);

const SelectForIphoneServiceLinks = ({
  options,
  text,
  text2,
  text3,
  text4,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <Select
        components={{ IndicatorsContainer }}
        options={options}
        onChange={handleSelectChange}
        placeholder="Выберите модель"
        styles={{
          container: (styles) => ({
            ...styles,
            width: 543,
            fontFamily: "Open Sans, sans-serif",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "31px",
            color: "#88898D;",
            boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
            margin: "auto",
            "@media only screen and (max-width: 720px)": {
              ...styles["@media only screen and (max-width: 720px)"],
              width: 370,
            },
            "@media only screen and (max-width: 500px)": {
              ...styles["@media only screen and (max-width: 500px)"],
              width: 274,
              fontWeight: "400",
              fontSize: "12px",
            },
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingLeft: 32,
            "@media only screen and (max-width: 500px)": {
              ...styles["@media only screen and (max-width: 500px)"],
              paddingLeft: 21,
            },
          }),
          control: (styles) => ({
            ...styles,
            borderRadius: 19,
            borderColor: null,
            border: "none",
            boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
            "&:hover": null,
          }),
          option: (styles, state) => ({
            ...styles,
            fontFamily: "Open Sans, sans-serif",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#88898D",
            borderRadius: 6,
            marginLeft: 7,
            marginRight: 7,
            width: 440,
            backgroundColor: state.isSelected
              ? "rgba(2, 143, 232, 0.06)"
              : styles.backgroundColor,
            "@media only screen and (max-width: 720px)": {
              ...styles["@media only screen and (max-width: 720px)"],
              width: 320,
            },
            "@media only screen and (max-width: 500px)": {
              ...styles["@media only screen and (max-width: 500px)"],
              width: 255,
            },
          }),
          menuList: (styles) => ({
            ...styles,
            width: 472,
            backgroundColor: "#fff",
            borderRadius: 20,
            maxHeight: 232,
            overflow: "auto",
            "@media only screen and (max-width: 720px)": {
              ...styles["@media only screen and (max-width: 720px)"],
              width: 350,
            },
            "@media only screen and (max-width: 500px)": {
              ...styles["@media only screen and (max-width: 500px)"],
              width: 274,
            },
          }),
          menu: (styles) => ({
            ...styles,
            width: 472,
            maxHeight: 232,
            borderRadius: 6,
            boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            "@media only screen and (max-width: 720px)": {
              ...styles["@media only screen and (max-width: 720px)"],
              width: 350,
            },
            "@media only screen and (max-width: 500px)": {
              ...styles["@media only screen and (max-width: 500px)"],
              width: 270,
            },
          }),
        }}
      />
      {selectedOption ? (
        <div className="iphone-select-price">
          <p className="iphone-select-price__text">
            Замена {text}&ensp;
            <span className="iphone-select-price__span">
              {selectedOption.price}&ensp;Р
            </span>
          </p>
          {selectedOption.price2 ? (
            <p className="iphone-select-price__text">
              Замена {text2}&ensp;
              <span className="iphone-select-price__span">
                {selectedOption.price2}&ensp;Р
              </span>
            </p>
          ) : null}
          {selectedOption.price3 ? (
            <p className="iphone-select-price__text">
              Замена {text3}&ensp;
              <span className="iphone-select-price__span">
                {selectedOption.price3}&ensp;Р
              </span>
            </p>
          ) : null}
          {selectedOption.price4 ? (
            <p className="iphone-select-price__text">
              {text4}&ensp;
              <span className="iphone-select-price__span">
                {selectedOption.price4}&ensp;Р
              </span>
            </p>
          ) : null}
          <p className="repair-mb__group-links-paragraph">
            Все цены указаны в рублях РФ.
          </p>
        </div>
      ) : null}
    </>
  );
};

export default SelectForIphoneServiceLinks;
