import React, { useState } from "react";
import Select, { components } from "react-select";
import vector from "../../images/icons/Vector.svg";
import Section from "../Section/Section";
import { RepairIphonePriceList } from "../index";
import { Carousel } from "react-responsive-carousel";
import {
  ipadPriceGeneralPage,
  ipad2PriceGeneralPage,
  ipad3PriceGeneralPage,
  ipad4PriceGeneralPage,
  ipadMiniPriceGeneralPage,
  ipadAirPriceGeneralPage,
  ipadMini2PriceGeneralPage,
  ipadAir2PriceGeneralPage,
  ipadMini3PriceGeneralPage,
  ipadPro12PriceGeneralPage,
  ipadMini4PriceGeneralPage,
  ipadPro9PriceGeneralPage,
  ipad5PriceGeneralPage,
  ipadPro210PriceGeneralPage,
  ipadPro212PriceGeneralPage,
  ipad6PriceGeneralPage,
  ipadPro11PriceGeneralPage,
  ipadPro122018PriceGeneralPage,
  ipadAir3PriceGeneralPage,
  ipadMini5PriceGeneralPage,
  ipad7PriceGeneralPage,
  ipad8PriceGeneralPage,
  ipadAir4PriceGeneralPage,
  ipadPro112021PriceGeneralPage,
  ipadPro122021PriceGeneralPage,
  ipad9PriceGeneralPage,
  ipadMini6PriceGeneralPage,
} from "../../utils/ipadModels";
import { useMobileScreen } from "../../utils/useMedia";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img alt="" src={vector} style={{ marginRight: 29 }} />
  </components.IndicatorsContainer>
);

function ChangePriceList(value) {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return value ? (
      <div
        style={{
          maxWidth: 274,
          display: "flex",
          backgroundColor: "transparent",
        }}
      >
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(0, 6)} />
          </div>

          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(6, 12)} />
          </div>

          <div
            style={{
              maxWidth: 274,
              padding: "45px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RepairIphonePriceList list={value.list.slice(12)} />
          </div>
        </Carousel>
      </div>
    ) : null;
  }
  return value ? (
    <div className="repair-iphone__pricelist-container">
      <ul className="repair-iphone__pricelist-list">
        <RepairIphonePriceList list={value.list} />
      </ul>
      <div className="repair-iphone__pricelist-border"></div>
      <p className="repair-iphone__pricelist-paragraph">
        * Все цены указаны в рублях РФ.<br></br>
        Цена включает комплектующее и услугу по установке.
      </p>
    </div>
  ) : null;
}

const RepairIpadPrice = () => {
  const [value, setValue] = useState("");

  const handleSelectChange = (options) => {
    setValue(options);
  };

  const options = [
    { value: "iPad", label: "iPad", list: ipadPriceGeneralPage },
    { value: "iPad 2", label: "iPad 2", list: ipad2PriceGeneralPage },
    { value: "iPad 3", label: "iPad 3", list: ipad3PriceGeneralPage },
    { value: "iPad 4", label: "iPad 4", list: ipad4PriceGeneralPage },
    { value: "iPad Mini", label: "iPad Mini", list: ipadMiniPriceGeneralPage },
    { value: "iPad Air", label: "iPad Air", list: ipadAirPriceGeneralPage },
    {
      value: "iPad Mini 2 Retina",
      label: "iPad Mini 2 Retina",
      list: ipadMini2PriceGeneralPage,
    },
    {
      value: "iPad Air 2",
      label: "iPad Air 2",
      list: ipadAir2PriceGeneralPage,
    },
    {
      value: "iPad Mini 3",
      label: "iPad Mini 3",
      list: ipadMini3PriceGeneralPage,
    },
    {
      value: "iPad Pro 12.9",
      label: "iPad Pro 12.9",
      list: ipadPro12PriceGeneralPage,
    },
    {
      value: "iPad Mini 4",
      label: "iPad Mini 4",
      list: ipadMini4PriceGeneralPage,
    },
    {
      value: "iPad Pro 9.7",
      label: "iPad Pro 9.7",
      list: ipadPro9PriceGeneralPage,
    },
    { value: "iPad 5", label: "iPad 5", list: ipad5PriceGeneralPage },
    {
      value: "iPad Pro 2 10.5",
      label: "iPad Pro 2 10.5",
      list: ipadPro210PriceGeneralPage,
    },
    {
      value: "iPad Pro 2 12.9",
      label: "iPad Pro 2 12.9",
      list: ipadPro212PriceGeneralPage,
    },
    {
      value: "iPad 6 (2018)",
      label: "iPad 6 (2018)",
      list: ipad6PriceGeneralPage,
    },
    {
      value: "iPad Pro 11 (2018)",
      label: "iPad Pro 11 (2018)",
      list: ipadPro11PriceGeneralPage,
    },
    {
      value: "iPad Pro 12.9 (2018)",
      label: "iPad Pro 11 (2018)",
      list: ipadPro122018PriceGeneralPage,
    },
    {
      value: "iPad Air 3 (2019)",
      label: "iPad Air 3 (2019)",
      list: ipadAir3PriceGeneralPage,
    },
    {
      value: "iPad Mini 5",
      label: "iPad Mini 5",
      list: ipadMini5PriceGeneralPage,
    },
    {
      value: "iPad 7 (2019)",
      label: "iPad 7 (2019)",
      list: ipad7PriceGeneralPage,
    },
    {
      value: "iPad 8 (2020)",
      label: "iPad 8 (2020)",
      list: ipad8PriceGeneralPage,
    },
    {
      value: "iPad Air 4 (2020)",
      label: "iPad Air 4 (2020)",
      list: ipadAir4PriceGeneralPage,
    },
    {
      value: "iPad Pro 11 (2021)",
      label: "iPad Pro 11 (2021)",
      list: ipadPro112021PriceGeneralPage,
    },
    {
      value: "iPad Pro 12,9 (2021)",
      label: "iPad Pro 12,9 (2021)",
      list: ipadPro122021PriceGeneralPage,
    },
    {
      value: "iPad 9 (2021)",
      label: "iPad 9 (2021)",
      list: ipad9PriceGeneralPage,
    },
    {
      value: "iPad Mini 6",
      label: "iPad Mini 6",
      list: ipadMini6PriceGeneralPage,
    },
  ];

  return (
    <Section className="repair-iphone__price">
      <div className="repair-iphone__price-container">
        <h2 className="repair-iphone__price-title">Цены на ремонт iPad:</h2>
        <p className="repair-iphone__price-text">
          Выберите вашу модель iPad для определения стоимости услуг по ремонту:
        </p>

        <Select
          components={{ IndicatorsContainer }}
          options={options}
          onChange={handleSelectChange}
          placeholder="Выберите модель"
          styles={{
            container: (styles) => ({
              ...styles,
              width: 543,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "31px",
              color: "#88898D;",
              boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
              margin: "auto",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 370,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 274,
                fontWeight: "400",
                fontSize: "12px",
              },
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingLeft: 32,
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                paddingLeft: 21,
              },
            }),
            control: (styles) => ({
              ...styles,
              borderRadius: 19,
              borderColor: null,
              border: "none",
              boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
              "&:hover": null,
            }),
            option: (styles, state) => ({
              ...styles,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#88898D",
              borderRadius: 6,
              marginLeft: 7,
              marginRight: 7,
              width: 440,
              backgroundColor: state.isSelected
                ? "rgba(2, 143, 232, 0.06)"
                : styles.backgroundColor,
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 320,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 255,
              },
            }),
            menuList: (styles) => ({
              ...styles,
              width: 472,
              backgroundColor: "#fff",
              borderRadius: 20,
              maxHeight: 232,
              overflow: "auto",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 274,
              },
            }),
            menu: (styles) => ({
              ...styles,
              width: 472,
              maxHeight: 232,
              borderRadius: 6,
              boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 270,
              },
            }),
          }}
        />

        {ChangePriceList(value)}
      </div>
    </Section>
  );
};

export default RepairIpadPrice;
