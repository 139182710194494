import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camerax.png";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCameraX = [
  {
    id: 1,
    title: "Какие симптомы говорят о замене камеры iPhone X",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Существует определенный перечень жалоб, при возникновении которых
          можно с большей или меньшей точностью указать на камеру как на
          источник проблем. Плюс существуют определенные моменты, которые
          позволяют судить о проблемах с матрицей. В целом же, о необходимости
          обращения в сервисный центр могут свидетельствовать такие аспекты:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отказывает автоматическая фокусировка,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Камеры, или одна из них, отказывает полностью и не функционирует,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Материалы получаются низкого качества, с битыми пикселями и
              другими дефектами.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Эти и другие проблемы обычно свидетельствуют о необходимости замены
          камеры. А еще бывают проблемы, связанные с некорректной работой
          вспышки, и они, в свою очередь, могут свидетельствовать о проблемах с
          матрицей. Практика показывает, что в подавляющем большинстве случаев
          ремонт оказывается целесообразным и результативным, однако он требует
          предоставления профессионального подхода.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModelsX = () => {
  return (
    <Page title="Замена камеры на iPhone iPhone X, сколько стоит поменять камеру Айфон Икс в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone X"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Излюбленным хобби многих владельцев Айфона становится селфи, а
                также и просто фото или видеосъемка. Эти высокотехнологичные
                устройства открывают широкий спектр возможностей для любителей
                создания красивых фотографий, и последние модели устройств
                действительно полноценно заменяют хороший фотоаппарат. Однако
                при этом стоит помнить, что фотокамера, которых всего в
                устройстве имеется две, может стать и уязвимым элементом.
                Миниатюрное и высокочувствительное устройство может быть
                повреждено по целому ряду причин, среди которых наиболее частыми
                оказываются удары и механические повреждения, а также попадание
                воды. Однако каковой бы ни была причина проблемы, привести
                технику в норму в подавляющем большинстве случаев все же
                удается, ведь Айфоны славятся свей надежностью и устойчивостью к
                самым разным механическим влияниям. Как правило, вернуть технику
                в норму позволяет замена камеры iPhone X, которая должна
                проводиться в профессиональных условиях.
              </p>
              <p className="repair-iphone-display__paragraph">
                Однако еще до того, как обратиться в сервисный центр, имеет
                смысл провести некоторые манипуляции. Проблема может предстать в
                виде программного сбоя, и тогда может представиться возможность
                решить ее перезагрузкой устройства. А если замечаются проблемы с
                отснятыми материалами, иногда помогает просто протереть камеру.
                Но если все эти меры ничем не помогают, приходится подходить к
                вопросу кардинально.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="7500 P"
          priceDown="5500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вы столкнулись с неисправностями гаджета такого типа, в
                первую очередь необходимо обеспечить профессиональную
                диагностику, которая позволит судить об обстоятельствах поломки.
                Сервисный центр Мак Профи обеспечивает всем желающим бесплатную
                диагностику, которая осуществляется в экспресс-режиме и
                позволяет получить прекрасный результат. За считанные минуты вы
                сможете узнать, что же произошло с техникой, сколько будет
                стоить ремонт и за какой срок его смогут осуществить. При вашем
                согласии работы будут начаты немедленно, и в ближайшие часы, а
                может и быстрее, вы сможете получить готовый результат, с
                обязательным тестированием и длительной гарантией на работу и
                детали.
              </p>
              <p className="repair-iphone-display__paragraph">
                У нас все работы проводятся в профессиональных условиях, руками
                опытных и грамотных специалистов. Мы выполняем поставленные
                задачи быстро и качественно, используя только детали заводского
                изготовления. Ответственный подход обеспечивается на каждом из
                этапов, что и позволяет нам добиваться отличного результата и
                поддерживать достойную репутацию на протяжении многих лет.
                Обращайтесь, и мы поможем вам!
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCameraX.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModelsX;
