import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Ваш ноутбук стал медленно работать, тормозит, зависает, самостоятельно выключается или перезагружается, его вентилятор работает слишком шумно? Все это признаки сильного перегрева, представляющего серьезную угрозу работоспособности вашего мобильного компьютерного устройства.",
  "В первую очередь работоспособности его материнской платы, видеокарты и процессора, микросхемы которых легко выходят из строя при превышении допустимой температуры.",
  "Однако перед тем, как предпринять какие-либо действия в конкретном направлении, следует выполнить комплекс диагностических мер, так как эти же симптомы могут свидетельствовать и о других проблемах (например, программных сбоях).",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Самыми типичными, часто встречающимися мастерам Note Expert, причинами перегрева ноутбуков являются следующие факторы: неисправность или сильное загрязнение/ запыленность системы охлаждения, высыхание термопасты, нарушения в цепи питания, повреждение электронных компонентов.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Многие из них пользователи могут не допустить, если будут соблюдать элементарные правила эксплуатации и регулярно делать аппаратную профилактику своего мобильного устройства.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "В спектр услуг, которые могут потребоваться в ситуации, когда ноутбук сильно перегревается и выключается от перегрева, входит чистка, ремонт ноутбука и замена его неисправных компонентов.",
  },
  {
    icon: priceIcon2,
    text: "Итоговая стоимость восстановления стабильной работы компьютерной техники определяется после выполнения бесплатной диагностики и составления перечня работ и подлежащих ремонту/ замене деталей.",
  },
  {
    icon: priceIcon3,
    text: "Намного дешевле стоимости его ремонта в случае выхода из строя по причине перегрева одного-двух или сразу нескольких внутренних компонентов (видеочипа, северного моста, процессора, других важных элементов).",
  },
];

const prices = [
  {
    text: "Аппаратная диагностика",
    price: "Бесплатно",
  },
  {
    text: "Чистка от пыли с заменой термоинтерфейсов",
    price: "2000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Аккумуляторы для ноутбуков",
    price: "от 800 P",
  },
  {
    text: "Замена системы охлаждения",
    price: "от 2500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "В спектр услуг, которые могут потребоваться в ситуации, когда ноутбук сильно перегревается и выключается от перегрева, входит чистка, ремонт ноутбука и замена его неисправных компонентов.",
  "Итоговая стоимость восстановления стабильной работы компьютерной техники определяется после выполнения бесплатной диагностики и составления перечня работ и подлежащих ремонту/ замене деталей.",
];

function NoteExpertNoteSettingOverheating() {
  return (
    <Page title="Решение проблемы перегрева ноутбука от 2000 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Перегревается ноутбук"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему перегревается ноутбук"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Стоимость ремонтных работ при перегреве ноутбуков"
          subtitle="Цены на ремонт греющегося ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Стоимость ремонтных работ при перегреве ноутбуков"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingOverheating;
