import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "../NoteExpertReasonsLinks/NoteExpertInsidePage.module.css";

const callbackText = [
  "Модернизация ноутбука проводится для повышения его производительности и расширения функциональных возможностей.",
  "С этой целью в сервисном центре Note Expert выполняют апгрейд процессора, видеокарты, оперативной памяти, жесткого диска, беспроводных интерфейсов, CD/ DVD приводов и других компонентов ноутбука, которые заменяются на более мощные, объемные, функциональные и т.д.",
  "Перечень комплектующих, которые нуждаются в замене, определяется с помощью тщательной предварительной диагностики аппаратной части и согласовывается с заказчиком.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Как более сложное по сравнению со стационарным ПК, это устройство требует профессионального подхода к своей модернизации, подразумевающего ее проведение в сервисном центре, оснащенном специальным ремонтно-диагностическим оборудованием. Эксперты Note Expert производят процедуру в несколько этапов, последовательно осуществляя изучение потребностей пользователя, подбор комплектующих, их установку, адаптацию ПО, тестирование мобильного устройства в рабочем режиме.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Большой практический опыт, наличие приличной материально-технической базы и собственного склада запчастей позволяют нам обеспечить их грамотный подбор и профессиональную установку, короткие сроки и высокое качество выполнения модернизации и ремонта ноутбуков.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Апгрейд ноутбука с заменой отдельных компонентов на более мощные и производительные позволяет пользователям улучшить характеристики своего портативного ПК, подогнав его под свои требования и избавляя себя от необходимости приобретать новый. ",
  },
  {
    icon: priceIcon2,
    text: "Поэтому в большинстве случаев модернизация ноутбука в Note Expert, предлагающего пользователям адекватные цены на все виды услуг, является экономически более выгодной и удобной.",
  },
  {
    icon: priceIcon3,
    text: "Итоговая стоимость работ определяется по результатам диагностики, которая выполняется бесплатно.",
  },
];

const prices = [
  {
    text: "Замена HDD 500 GB",
    price: "от 3500 Р",
  },
  {
    text: "Добавление модуля ОЗУ 8 GB",
    price: "от 3300 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена HDD 1 TB",
    price: "от 6500 P",
  },
  {
    text: "Добавление модуля ОЗУ 16 GB",
    price: "от 5400 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Модернизацию ноутбука обычно делают, когда аппаратная часть перестает справляться с обновленными, более современными версиями ПО, появляется необходимость в повышении его технических возможностей, мощности, производительности (увеличении объема оперативной памяти, емкости жесткого диска, производительности процессора, др.).",
  "Насколько выгодным и целесообразным в сравнении с покупкой нового ноутбука будет такой комплексный апгрейд вашего мобильного устройства, вы узнаете из нашего экспертного заключения.",
];

function NoteExpertGeneralPageServiceLinkUpgrade() {
  return (
    <Page title="Модернизация ноутбука - увеличение объема HDD и ОЗУ от 3300 руб.">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Модернизация ноутбуков в Note Expert"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Профессиональный upgrade ноутбука"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Стоимость модернизации ноутбука"
          subtitle="Стоимость модернизации ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Когда ноутбуку требуется upgrade"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPageServiceLinkUpgrade;
