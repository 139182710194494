import React from 'react';
import { Page, MacProfiList, BreadCrumbs, RepairAppleLinksBanner, Section, RepairAppleLinksText, RepairIpadModelLinks, RepairIpadServiceLinks, SelectForIphoneServiceLinks } from '../index';
import banner from '../../images/banners/repair-ipadNetwork.jpeg';

const textServiceLinlNetwork = [
  {
    id: 1,
    title: 'Что делать если Айпад не видит сеть',
    text: 
      <>
        <p className='repair-iphone-display__paragraph'>Перед тем, как обращаться в сервисный центр, попробуйте произвести следующие действия, возможно они помогут вам восстановить сеть на вашем iPad:</p>
        <p className='repair-iphone-display__paragraph'>Можно попробовать перенастроить сопряжение. Зайдите в "Настройки", выберете вкладку "Основные", где найдите вкладку "Сеть", соотвественно. Выберете ту сеть, к которой не удаётся подключится и нажмите "Забыть данную сеть", после чего попробуйте переподключиться заново.</p>
        <p className='repair-iphone-display__paragraph'>Можно сбросить настройки сети. Для этого во всё тех же "настройках", во вкладке "основные" просто нажмите "сбросить настройки сети". При этом будут утеряны сохранённые пароли, но, зато, именно такой способ решает большинство проблем подключения.</p>
        <p className='repair-iphone-display__paragraph'>Возможной причиной того, что Айпад не видит сеть, может быть неверно заданный регион (вместо РФ стоит США). Проблема разрешается в настройках сети Wi-Fi: надо найти и поменять регион.</p>
        <p className='repair-iphone-display__paragraph'>Ещё одним источником проблем может быть неправильная настройка маршрутизатора. В таком случае могут помочь следующие манипуляции с роутером: 1) поменять тип шифрования: вместо WEP установить WPA/WPA2, 2) обновить айпишник, 3) обновит ПО, 4) ну и наиболее любимый совет всех служб поддержки: перезагрузить роутер.</p>
        <p className='repair-iphone-display__paragraph'>Если все эти действия остались безрезультатными, предлагаем прийти в один из наших сервисных центров Москвы и Санкт-Петербурга. Здесь вы получите всестороннюю консультацию, а наши специалисты выполнят все тонкости настройки. Возможно, также, что проблема кроется в аппаратной части. У нас ваш iPad пройдёт всестороннюю диагностику, в результате которой можно будет выявить все его скрытые поломки, и здесь же, "не отходя от кассы", быстро и недорого их устранить. Диагностика в наших центрах услуга безоговорочно бесплатная. К тому же, мы никогда не выдумывает излишние проблемы для вашего планшета, устраняя лишь те поломки, которые действительно есть.</p>
      </>
  },
]

const optionsIpadNetwork = [
  { value: 'iPad', label: 'iPad', price: '2400', price2: '1900'},
  { value: 'iPad 2', label: 'iPad 2', price: '1900', price2: '1900' },
  { value: 'iPad 3', label: 'iPad 3', price: '1600', price2: '1900' },
  { value: 'iPad 4', label: 'iPad 4', price: '1900', price2: '1900' },
  { value: 'iPad mini', label: 'iPad mini', price: '1900', price2: '1900' },
  { value: 'iPad Air', label: 'iPad Air', price: '1900', price2: '1900' },
  { value: 'iPad mini 2', label: 'iPad mini 2', price: '2350', price2: '1950' },
  { value: 'iPad Air 2', label: 'iPad Air 2', price: '2350', price2: '1900' },
  { value: 'iPad mini 3', label: 'iPad mini 3', price: '2500', price2: '1950' },
  { value: 'iPad Pro 12.9', label: 'iPad Pro 12.9', price: '1900', price2: '1900' },
  { value: 'iPad mini 4', label: 'iPad mini 4', price: '2080', price2: '2080' },
  { value: 'iPad Pro 9.7', label: 'iPad Pro 9.7', price: '4450', price2: '4450' },
  { value: 'iPad 5', label: 'iPad 5', price: '1900', price2: '1900' },
  { value: 'iPad Pro 2 10.5', label: 'iPad Pro 2 10.5', price: '1900', price2: '1900' },
  { value: 'iPad Pro 2 12.9', label: 'iPad Pro 2 12.9', price: '1900', price2: '1900' },
  { value: 'iPad 6 (2018)', label: 'iPad 6 (2018)', price: '1900', price2: '1900' },
  { value: 'iPad Pro 11 (2018)', label: 'iPad Pro 11 (2018)', price: '1900', price2: '1900' },
  { value: 'iPad Pro 12.9 (2018)', label: 'iPad Pro 12.9 (2018)', price: '1900', price2: '1900' },
  { value: 'iPad Air 3 (2019)', label: 'iPad Air 3 (2019)', price: '1600', price2: '1600' },
  { value: 'iPad mini 5', label: 'iPad mini 5', price: '1900', price2: '1900' },
  { value: 'iPad 7', label: 'iPad 7', price: '1900', price2: '1900' },
  { value: 'iPad 8', label: 'iPad 8', price: '1900', price2: '1900' },
  { value: 'iPad Air 4 (2020)', label: 'iPad Air 4 (2020)', price: '1900', price2: '1900' },
  { value: 'iPad Pro 11 (2021)', label: 'iPad Pro 11 (2021)', price: '1900', price2: '1900' },
  { value: 'iPad Pro 12.9 (2021)', label: 'iPad Pro 12.9 (2021)', price: '1900', price2: '1900' },
  { value: 'iPad 9', label: 'iPad 9', pprice: '1900', price2: '1900' },
  { value: 'iPad mini 6', label: 'iPad mini 6', price: '1900', price2: '1900' },
]

const RepairIpadServiceLinkNetwork = () => {
  return (
    <Page title='iPad не ловит сеть: возможные неисправности, стоимость восстановления в Москве'>
      <main className='repair-iphone-group-content'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner banner={banner}
          title='iPad не ловит сеть'
          text='Профессиональные специалисты Мак Профи помогут восстановить работу iPad который не ловит сеть сотовой связи и Wi-Fi. Производим восстановление модема, замену антенны и другие работы по восстановлению сети на iPad.'
        />
        <Section className='section_group' style={{backgroundColor: 'rgba(196, 196, 196, 0.09)', width: '100%', padding: '55px 0 75px', marginTop: 55}}>
          <div className='repair-mb__group-list-container'>
            <h2 className='repair-mb__group-links-subtitle'>Стоимость восстановления сети на iPad</h2>
            <SelectForIphoneServiceLinks options={optionsIpadNetwork} text='антенны' text2='Wi-Fi модуля'/>
          </div>
        </Section>
        <Section className='section_group' style={{padding: '50px 0 65px'}}>
          {textServiceLinlNetwork.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                {...item}
              />
            )
          })}
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
      </main>
    </Page>
  )
}

export default RepairIpadServiceLinkNetwork;