import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpad2.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Неисправность печатной платы",
    number: "01",
  },
  {
    id: 2,
    text: "Короткое замыкание",
    number: "02",
  },
  {
    id: 3,
    text: "Повреждения материнской платы",
    number: "03",
  },
  {
    id: 4,
    text: "Обрыв шлейфа аккумулятора.",
    number: "04",
  },
];

const listOfRecommendations = [
  {
    id: 1,
    text: "Бесплатная диагностика – определим причины поломки для быстрого и эффективного устранения неисправности",
    number: "01",
  },
  {
    id: 2,
    text: "Профессиональная консультация – рекомендации специалистов помогут вам не допустить преждевременного износа батареи",
    number: "02",
  },
  {
    id: 3,
    text: "Гарантия качества – вы получаете уверенность в стабильной и долгосрочной работе нового элемента питания.",
    number: "03",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Причины преждевременной замены аккумулятора iPad 2",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чаще всего замена аккумулятора iPad 2 связана с износом батареи. Если устройство работает в режиме нон-стоп, то его владельцу уже через 1,5-2 года предстоит обратиться к специалистам для установки нового элемента питания. Но существуют и другие причины, по которым батарея может выйти из строя, среди них:"
          array={listOfTrouble}
          paragraphDown="Как правило, все эти неисправности возникают из-за нарушений эксплуатации устройства. Например, если на протяжении 2-3 часов планшет пробудет на холоде, то в теплом помещении на элементах питания может образоваться конденсат, который и станет причиной короткого замыкания."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Оперативная замена аккумулятора iPad 2 в сервисе Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Одна из распространенных причин обращения в специализированный сервисный центр – необходимость устранения последствий самостоятельного ремонта. Например, пытаясь заменить разбитый экран, владелец планшет самостоятельно разбирает конструкцию, повреждения сопутствующие платы и разъемы из-за отсутствия навыков и набора профессиональных инструментов."
          paragraphUp2="Если вы не хотите присоединиться к числу тех, кто своими руками значительно увеличивает стоимость проведения ремонта, стоит довериться профессионалам. Мак Профи – это быстрая замена аккумулятора iPad 2 и специальные предложения для каждого клиента, ведь именно у нас:"
          array={listOfRecommendations}
          paragraphDown="Мы идем навстречу клиентам, предлагая не только лояльные расценки, но и высокий уровень сервиса. Вы можете воспользоваться услугой курьерской доставки – к вам на дом или на работу прибудет наш сотрудник, который отвезет планшет мастерам, а после завершения ремонта доставит его обратно. Более того, специалисты Мак Профи работают 7 дней в неделю, а потому вы можете без труда выбрать удобную дату для посещения сервиса – наши двери всегда открыты для клиентов."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelIpad2 = () => {
  return (
    <Page title="Быстрая замена аккумулятора iPad 2 в Москве в сервисном центре Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad 2"
          text="Приобретая качественную технику, её владелец рассчитывает на стабильную работу устройства. Вот только при активном пользовании уже через несколько лет после покупки предстоит задуматься о замене батареи, которая рассчитана на определенное количество циклов заряда. Если время автономной работы сократилось или же планшет самостоятельно начал выключаться в неожиданные моменты, то поспешите в сервисный центр Мак Профи, в котором вам помогут с решением этой проблемы."
        />
        <RepairApplePrice text="Замена аккумулятора" price="2600 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelIpad2;
