import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera6s.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera6s = [
  {
    id: 1,
    title: "Ремонт камеры iPhone 6s своими руками",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Самостоятельно чинить дорогостоящую технику не стоит – подобное
          мероприятие редко заканчивается успешно. Но владелец может выполнить
          несколько процедур, которые могут оценить серьезность проблемы.
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Сделайте снимок при хорошем освещении – для этого лучше выйти на
              улицу. Это позволит оценить качество полученной фотографии и
              понять, действительно ли резкость изображения значительно
              снизилась.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Если при попытке включить камеру вы видите, лишь черный фон,
              принудительно завершите работу приложения. После этого
              перезагрузите устройство и попробуйте снова – если проблема была
              вызвана системным сбоем, то это поможет её решить. В противном
              случае без посещения сервиса не обойтись.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Принимать иные действия не рекомендуется, а уж тем более разбирать
          устройство с помощью подручных инструментов. Нередко для решения
          проблемы требуется отнюдь не замена камеры iPhone 6s, а более
          серьезный ремонт – проблемы могут быть вызваны неполадками в работе
          материнской платы. Поэтому любым действиям должна предшествовать
          диагностика – и в Мак Профи есть необходимое для этой цели
          оборудование.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title:
      "Замена камеры iPhone 6s в сервисном центре – доверяйте профессионалам",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Любой ремонт требует не только бережного, и что самое главное,
          правильного разбора устройства – важно еще и правильно подобрать
          комплектующие, которые в дальнейшем не нарушат работу смартфона.
          Именно поэтому, стоит обратиться в московский сервис Мак Профи:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Мы используем только качественные, фирменные инструменты
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Запчасти приобретаются у постоянных поставщиков, и подбираются в
              соответствии с характеристиками аппарата
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Диагностика дает возможность определить характер неполадок,
              устранив сразу несколько поломок за одно посещение.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Наши мастера работают быстро и аккуратно, что дает возможность
          предоставлять качественный сервис по доступным ценам. Приезжайте к
          нам, если вам необходима замена камеры iPhone 6s – и эта проблема
          перестанет вас беспокоить.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels6s = () => {
  return (
    <Page title="Качественная замена камеры iPhone 6s в Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 6s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 6s: оперативно устраним любые поломки,
                поможем с ремонтом и настройкой. Грамотный сервис, качественное
                обслуживание, лояльные расценки.
              </p>
              <p className="repair-iphone-display__paragraph">
                Айфон 6s оборудован двумя независимыми камерами, для каждой из
                которых предусмотрен отдельный шлейф. Благодаря этому даже если
                одна выйдет из строя, то всегда можно продолжать пользоваться
                второй. Вот только это доставляет немало неудобств, ведь при
                поломке фронтальной камеры видеозвонки будут недоступны, а при
                неисправности основной – сделать нормальный снимок не так уж
                просто. Используйте все возможности вашего смартфона – доверьте
                его сервису Мак Профи, чтобы нормальная работа была
                возобновлена, и вам не пришлось лишиться необходимых функций.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="2500 P"
          priceDown="2000 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera6s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels6s;
