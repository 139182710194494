export const iPadListOfModels = [
  {
    model: 'Ремонт iPad',
    href: '#'
  },
  {
    model: 'Ремонт iPad 2',
    href: '/remont-apple/remont-ipad/2'
  },
  {
    model: 'Ремонт iPad 3',
    href: '/remont-apple/remont-ipad/3'
  },
  {
    model: 'Ремонт iPad 4',
    href: '/remont-apple/remont-ipad/4'
  },
  {
    model: 'Ремонт iPad Mini',
    href: '/remont-apple/remont-ipad/mini'
  },
  {
    model: 'Ремонт iPad Air',
    href: '/remont-apple/remont-ipad/air'
  },
  {
    model: 'Ремонт iPad Mini 2 Retina',
    href: '/remont-apple/remont-ipad/mini-2-retina'
  },
  {
    model: 'Ремонт iPad Air 2',
    href: '/remont-apple/remont-ipad/air-2'
  },
  {
    model: 'Ремонт iPad Mini 3',
    href: '/remont-apple/remont-ipad/mini-3'
  },
  {
    model: 'Ремонт iPad Pro 12.9',
    href: '/remont-apple/remont-ipad/pro-12'
  },
  {
    model: 'Ремонт iPad Mini 4',
    href: '/remont-apple/remont-ipad/mini-4'
  },
  {
    model: 'Ремонт iPad Pro 9.7',
    href: '/remont-apple/remont-ipad/pro-9'
  },
  {
    model: 'Ремонт iPad 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 10.5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 12.9',
    href: '#'
  },
  {
    model: 'Ремонт iPad 6 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12.9 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 3 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad 7 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 8 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 4 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12,9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 6',
    href: '#'
  },
]

export const iPadListOfModelsForBattery = [
  {
    model: 'Ремонт iPad',
    href: '#'
  },
  {
    model: 'Ремонт iPad 2',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/2'
  },
  {
    model: 'Ремонт iPad 3',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/3'
  },
  {
    model: 'Ремонт iPad 4',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/4'
  },
  {
    model: 'Ремонт iPad Mini',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/mini'
  },
  {
    model: 'Ремонт iPad Air',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/air'
  },
  {
    model: 'Ремонт iPad Mini 2 Retina',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/mini-2'
  },
  {
    model: 'Ремонт iPad Air 2',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/air-2'
  },
  {
    model: 'Ремонт iPad Mini 3',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/mini-3'
  },
  {
    model: 'Ремонт iPad Pro 12.9',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/pro-12'
  },
  {
    model: 'Ремонт iPad Mini 4',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/mini-4'
  },
  {
    model: 'Ремонт iPad Pro 9.7',
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora/pro-9'
  },
  {
    model: 'Ремонт iPad 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 10.5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 12.9',
    href: '#'
  },
  {
    model: 'Ремонт iPad 6 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12.9 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 3 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad 7 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 8 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 4 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12,9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 6',
    href: '#'
  },
]

export const iPadListOfModelsForDisplay = [
  {
    model: 'Ремонт iPad',
    href: '#'
  },
  {
    model: 'Ремонт iPad 2',
    href: '/remont-apple/remont-ipad/zamena-stekla/2'
  },
  {
    model: 'Ремонт iPad 3',
    href: '/remont-apple/remont-ipad/zamena-stekla/3'
  },
  {
    model: 'Ремонт iPad 4',
    href: '/remont-apple/remont-ipad/zamena-stekla/4'
  },
  {
    model: 'Ремонт iPad Mini',
    href: '/remont-apple/remont-ipad/zamena-stekla/mini'
  },
  {
    model: 'Ремонт iPad Air',
    href: '/remont-apple/remont-ipad/zamena-stekla/air'
  },
  {
    model: 'Ремонт iPad Mini 2 Retina',
    href: '/remont-apple/remont-ipad/zamena-stekla/mini-2'
  },
  {
    model: 'Ремонт iPad Air 2',
    href: '/remont-apple/remont-ipad/zamena-stekla/air-2'
  },
  {
    model: 'Ремонт iPad Mini 3',
    href: '/remont-apple/remont-ipad/zamena-stekla/mini-3'
  },
  {
    model: 'Ремонт iPad Pro 12.9',
    href: '/remont-apple/remont-ipad/zamena-stekla/pro-12'
  },
  {
    model: 'Ремонт iPad Mini 4',
    href: '/remont-apple/remont-ipad/zamena-stekla/mini-4'
  },
  {
    model: 'Ремонт iPad Pro 9.7',
    href: '/remont-apple/remont-ipad/zamena-stekla/pro-9'
  },
  {
    model: 'Ремонт iPad 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 10.5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 12.9',
    href: '#'
  },
  {
    model: 'Ремонт iPad 6 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12.9 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 3 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad 7 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 8 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 4 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12,9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 6',
    href: '#'
  },
]

export const iPadListOfModelsForCase = [
  {
    model: 'Ремонт iPad',
    href: '#'
  },
  {
    model: 'Ремонт iPad 2',
    href: '/remont-apple/remont-ipad/zamena-korpusa/2'
  },
  {
    model: 'Ремонт iPad 3',
    href: '/remont-apple/remont-ipad/zamena-korpusa/3'
  },
  {
    model: 'Ремонт iPad 4',
    href: '/remont-apple/remont-ipad/zamena-korpusa/4'
  },
  {
    model: 'Ремонт iPad Mini',
    href: '/remont-apple/remont-ipad/zamena-korpusa/mini'
  },
  {
    model: 'Ремонт iPad Air',
    href: '/remont-apple/remont-ipad/zamena-korpusa/air'
  },
  {
    model: 'Ремонт iPad Mini 2 Retina',
    href: '/remont-apple/remont-ipad/zamena-korpusa/mini-2'
  },
  {
    model: 'Ремонт iPad Air 2',
    href: '/remont-apple/remont-ipad/zamena-korpusa/air-2'
  },
  {
    model: 'Ремонт iPad Mini 3',
    href: '/remont-apple/remont-ipad/zamena-korpusa/mini-3'
  },
  {
    model: 'Ремонт iPad Pro 12.9',
    href: '/remont-apple/remont-ipad/zamena-korpusa/pro-12'
  },
  {
    model: 'Ремонт iPad Mini 4',
    href: '/remont-apple/remont-ipad/zamena-korpusa/mini-4'
  },
  {
    model: 'Ремонт iPad Pro 9.7',
    href: '/remont-apple/remont-ipad/zamena-korpusa/pro-9'
  },
  {
    model: 'Ремонт iPad 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 10.5',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 2 12.9',
    href: '#'
  },
  {
    model: 'Ремонт iPad 6 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12.9 (2018)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 3 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 5',
    href: '#'
  },
  {
    model: 'Ремонт iPad 7 (2019)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 8 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Air 4 (2020)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 11 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Pro 12,9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad 9 (2021)',
    href: '#'
  },
  {
    model: 'Ремонт iPad Mini 6',
    href: '#'
  },
]

export const ipadPriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad2PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad3PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad4PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMiniPriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadAirPriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMini2PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadAir2PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMini3PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro12PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMini4PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro9PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad5PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro210PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro212PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad6PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro11PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro122018PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadAir3PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMini5PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad7PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad8PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadAir4PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro112021PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadPro122021PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipad9PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]

export const ipadMini6PriceGeneralPage = [
  {
    id: 1,
    text: 'Замена сенсорного стекла (тачскрина)',
    price: '3400'
  },
  {
    id: 2,
    text: 'Замена дисплея',
    price: '5900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 3,
    text: 'Замена корпуса/задней крышки',
    price: '7900'
  },
  {
    id: 4,
    text: 'Замена аккумулятора',
    price: '3450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 5,
    text: 'Замена микрофона',
    price: '2350'
  },
  {
    id: 6,
    text: 'Замена динамика',
    price: '2450',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 7,
    text: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1950',
  },
  {
    id: 8,
    text: 'Замена кнопки включения',
    price: '2100',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 9,
    text: 'Замена антенны',
    price: '2100',
  },
  {
    id: 10,
    text: 'Ремонт Wi-Fi модуля',
    price: '5500',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 11,
    text: 'Замена датчика приближения',
    price: '2100',
  },
  {
    id: 12,
    text: 'Замена сим-держателя',
    price: '950',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 13,
    text: 'Замена основной камеры',
    price: '2400',
  },
  {
    id: 14,
    text: 'Замена передней камеры',
    price: '1900',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 15,
    text: 'Замена разъема зарядки',
    price: '2400',
  },
  {
    id: 16,
    text: 'Замена разъема наушников',
    price: '2400',
    color: '#FFFFFF',
    boxShadow: '0px 0px 134px rgba(0, 0, 0, 0.05)',
  },
  {
    id: 17,
    text: 'Замена кнопки Home (домой)',
    price: '2350',
  },
]