import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadMini3.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Бесплатную проверку работоспособности планшета – после этого будут озвучены стоимость и сроки ремонта;",
    number: "01",
  },
  {
    id: 2,
    text: "Аккуратное снятие старого корпуса и установку нового – для работы сотрудники Мак Профи используют профессиональный комплект инструментов;",
    number: "02",
  },
  {
    id: 3,
    text: "Оплату за проведенные работы – вам будет в обязательном порядке предоставлен чек и гарантийный талон.",
    number: "03",
  },
];

const listOfTextMini2 = [
  {
    id: 1,
    text: "Сложность работы;",
    number: "01",
  },
  {
    id: 2,
    text: "Цена используемых комплектующих;",
    number: "02",
  },
  {
    id: 3,
    text: "Оказание дополнительных услуг.",
    number: "03",
  },
];

const textOfCaseMini3 = [
  {
    id: 1,
    title: "Особенности замены корпуса iPad Mini 3",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Всё, что вам необходимо для того, чтобы восстановить работу устройства – обратиться в Мак Профи. Вы можете сделать это лично, либо же воспользоваться помощью курьера – курьерская доставка по Москве будет выполнена абсолютно бесплатно. Процедура замены корпуса iPad Mini 3 включает в себя:"
          array={listOfTrouble}
          paragraphDown="Наша главная задача – обеспечить качественное предоставление услуг. Сроки проведения ремонта минимальны, а большинство поломок удается устранить при первом посещении – в этот список входит замена корпуса, дисплея, аккумулятора."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModelMini3 = () => {
  return (
    <Page title="Выгодная замена корпуса iPad Mini 3 за 60 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Mini&nbsp;3</>}
          text="Основная причина поломки iPad Mini 3 – нарушение условий эксплуатации. Как правило, именно владельцы виноваты в том, что нормальная работа устройства была нарушена, ведь повреждения возникают из-за проникновения под корпус жидкости, сильных ударов и других негативных воздействий. Мы поможем вам устранить как видимые, так и внутренние дефекты – команда опытных специалистов оперативно вернут планшет к жизни."
        />
        <Section className="section_group">
          {textOfCaseMini3.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Цена и сроки замены корпуса iPad Mini 3"
          text="Замена сенсорного стекла (тачскрина)"
          price="6400 P"
          paragraph={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="Для проведения работы используется профессиональный комплект инструментов, в который входит не только базовый набор отверток, но и термофен. Кроме того, мы уделяем особое внимание, качеству расходных материалов, закупая необходимые товары у надежных поставщиков."
                paragraphUp2="Окончательная стоимость ремонта зависит от следующих факторов:"
                array={listOfTextMini2}
                paragraphDown="Наши расценки достаточно лояльны – мы не предлагаем дешево, но стремимся предоставить высокий уровень сервиса на выгодных условиях. Этого удалось достичь благодаря тому, что в сервисном центре работает команда квалифицированных инженеров, которые рационально подходят к решению проблем, возникших с техникой Apple. Если есть возможность обойтись исключительно рихтовкой, то мы позаботимся об этом – в противном случае будет выполнена полноценная замена корпуса iPad Mini 3."
                paragraphDown2="На сайте доступен онлайн калькулятор, позволяющий просчитать ориентировочную стоимость услуг. Ознакомьтесь с расценками и приезжайте в Мак Профи, чтобы отремонтировать неисправный планшет."
              />
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelMini3;
