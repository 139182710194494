import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadAir2.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Гарантия качества на все виды работ и комплектующих",
    number: "01",
  },
  {
    id: 2,
    text: "Скидки на комплексное обслуживание, включающее несколько услуг",
    number: "02",
  },
  {
    id: 3,
    text: "Бесплатная первичная диагностика устройства.",
    number: "03",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: "Особенности замены стекла iPad Air 2",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Важно понимать, что в процессе замены стекла iPad Air 2 требуется
          удалить многочисленные осколки, а также остатки старого клея.
          Действовать при этом нужно очень аккуратно, чтобы случайно не
          повредить сопутствующие элементы или разъемы.
        </p>
        <p className="repair-iphone-display__paragraph">
          Кроме того, если стекло разбилось в результате падения, то вероятнее
          всего присутствует деформация корпуса. Если его не выровнять, то
          устройство не только утратит красоту внешнего вида, но и может
          сломаться в ближайшее время из-за внешнего давления, действующего на
          внутренние элементы. Если же пренебречь этим правилом и установить
          новое стекло на кривой корпус, то даже легкого удара будет достаточно,
          чтобы поверхность вновь покрылась трещинами.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: <>Мак Профи: срочная замена стекла iPad&nbsp;Air&nbsp;2</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы используем в работе только запчасти высокого качества, которые заблаговременно приобретаем у постоянных поставщиков. Это позволяет выполнять ремонт в кратчайшие сроки – меньше, чем за сутки устройство будет полностью готово к дальнейшему использованию. Если же необходима срочная замена стекла iPad Air 2, то наши мастера справятся и за несколько часов. При этом мы готовы предложить клиентам специальные условия:"
          array={listOfTrouble}
          paragraphDown="Наши сотрудники – профессионалы, которые проведут аккуратную рихтовку корпуса и заменят поврежденное стекло. И ваш планшет вновь будет выглядеть безупречно, и ничто не помешает комфортной работе."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelAir2 = () => {
  return (
    <Page title="Быстрая замена стекла на iPad Air 2 в Москве по низким ценам">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла на iPad Air 2"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Описание: Если вам потребовалась замена стекла iPad Air 2, наши
                мастера готовы вам помочь оперативно и качественно. Сервисный
                центр Мак Профи всегда готов помочь своим клиентам.
              </p>
              <p className="repair-iphone-display__paragraph">
                Стекло – самая хрупкая часть смартфонов и планшетов, ведь при
                падениях и ударах именно оно страдает в первую очередь.
                Конструкция такова, что самостоятельно эту поломку устранить не
                удастся – для этого потребуется специальное оборудование, а
                работа займет несколько часов. Поэтому, если вам необходим
                ремонт планшета, обращайтесь в специализированный сервисный
                центр Мак Профи – гарантируем обслуживание на высоком уровне и
                оперативный ремонт вашего устройства.
              </p>
            </>
          }
        />
        <Section className="section_group">
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены стекла iPad Air 2"
          text="Замена сенсорного стекла (тачскрина)"
          price="9850 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Все расценки указаны на сайте – и вы можете с ними ознакомиться.
                Точная сумма будет названа после проведения диагностики, так как
                в процессе тестирования устройства могут быть обнаружены скрытые
                повреждения. Оплата производится только после завершения
                ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Качественной технике нужен профессиональный ремонт – доверьте
                его мастерам Мак Профи. Мы оперативно выполним поставленную
                задачу и порадуем вас выгодными условиями.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelAir2;
