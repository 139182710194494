import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadMini.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Появление белых пятен или цветных полос на экране",
    number: "01",
  },
  {
    id: 2,
    text: "Неисправность подсветки",
    number: "02",
  },
  {
    id: 3,
    text: "Черный экран при включении устройства.",
    number: "03",
  },
];

const textForPrice = [
  {
    id: 1,
    text: "Все детали в наличии – собственный склад комплектующих, позволяет нам не затягивать сроки ремонта, выполняя срочную замену стекла iPad Mini.",
    number: "01",
  },
  {
    id: 2,
    text: "Оплата по факту предоставленных услуг – вы платите за работу только после того, как она была выполнена, а заказчик убедился в работоспособности устройства.",
    number: "02",
  },
  {
    id: 3,
    text: "Гарантия качества – предоставляется на все используемые запчасти и предоставленные услуги, а потому если работа устройства в будущем будет нарушена, наши специалисты устранят возникшую поломку.",
    number: "03",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Как понять, что нужна замена стекла iPad Mini",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чтобы разобраться, что же привело к появлению неисправности, мы проведем бесплатную диагностику и оценим серьезность повреждений. Но при появлении следующих признаков, чаще всего требуется именно замена стекла:"
          array={listOfTrouble}
          paragraphDown="Самостоятельно разбирать планшет, а уж тем более менять стекло не стоит – при отсутствии опыта работы с подобной техникой, можно лишь навредить. Более того, в процессе ремонта потребуется перепайка шлейфа, что сложно выполнить при отсутствии необходимого инструмента и оборудования. Вместо того, чтобы провести несколько часов, разбираясь в сложной конструкции iPad Mini, доверьте эту задачу специалистам – и уже через сутки вы сможете забрать восстановленное устройство."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelMini = () => {
  return (
    <Page title="Срочная замена стекла (тачскрина) iPad Mini">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла iPad Mini"
          text="iPad Mini - красивый, легкий и компактный планшет, который приятно порадовал быстродействием и функциональностью. Вот только можно легко лишиться устройства, случайно уронив или ударив его – любые механические воздействия очень быстро приводят к повреждениям экрана. Но это не повод лишаться любимого планшета или спешить за новым – обращайтесь в сервисный центр Мак Профи, и замена стекла iPad Mini будет выполнена меньше, чем за сутки."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Замена стекла iPad Mini: быстрое восстановление планшета по честным ценам"
          text="Замена сенсорного стекла (тачскрина)"
          price="2900 P"
          paragraph={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="Стоимость и сроки ремонта оговариваются в индивидуальном порядке. Это связано с тем, что до проведения бесплатной диагностики невозможно оценить характер и серьезность повреждений. Более того, если стекло было разбито в результате падения, то может потребоваться не только замена экрана, но и рихтовка корпуса. В нашем сервисном центре вы сможете получить все необходимые услуги, ведь мы готовы предложить целый ряд преимуществ, доступных нашим клиентам:"
                array={textForPrice}
                paragraphDown="Стоимость замены стекла iPad Mini несколько увеличена, что обусловлено сложностью поставленной задачи. Но при этом наши расценки неизменно лояльны, а потому вы сможете отремонтировать свой планшет на выгодных условиях."
              />
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelMini;
