import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import prestigioBanner from "../../../images/banners/pad-prestigio-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "РЕМОНТ НА ДОМУ",
    text: "При несложной поломке, которая не требует специального оборудования, ремонт планшетов Престижио может выполняться на дому или даже в офисе. Для вызова мастера на дом можно связаться с нами по электронной почте zakaz@centre-mobile.ru или по телефону.",
  },
  {
    img: icon2,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "Мы понимаем, как важно для вас иметь под рукой рабочий гаджет, поэтому предлагаем срочный ремонт. В этом случае мастер оперативно устранит неисправность в вашем присутствии.",
  },
  {
    img: icon3,
    title: "ДОСТАВКА",
    text: "Если вы не можете самостоятельно доставить ваше устройство к нам по адресу: Москва, ул. Лесная, 1/2, офис 351, можно воспользоваться услугами нашего курьера. А находясь в зоне бесплатной доставки, эту услугу даже не нужно оплачивать.",
  },
  {
    img: icon4,
    title: "ГАРАНТИИ",
    text: "На все виды проведенных работ и установленные комплектующие специалисты нашей компании предоставляют гарантии. Срок гарантийного обязательства зависит от вида ремонта и типа использованных запчастей.",
  },
];

const pads = [
  { name: "Grace 5778", pricelist: desireHD },
  { name: "Wize 3161 3G", pricelist: desire400 },
  { name: "Wize 3401 3G", pricelist: desireHD },
  { name: "Grace 3157 3G", pricelist: desire400 },
  { name: "MultiPad Muze 8", pricelist: desireHD },
  { name: "Multipad Wize 3518 4G", pricelist: desire400 },
  { name: "MultiPad Wize 3131 3G", pricelist: desireHD },
  { name: "Multipad Wize 3508 4G", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadPrestigio() {
  return (
    <Page title="Профессиональный ремонт планшетов Prestigio от специалистов «Центр Мобайл» (Москва)">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={prestigioBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ PRESTIGIO"
          text="Специалисты компании «Центр Мобайл» прилагают все усилия, чтобы ремонт планшетов Prestigio был максимально быстрым и эффективным. Для этого мы используем обширную базу знаний, многолетний практический опыт и новое оборудование. Все это позволяет нам успешно восстанавливать планшеты Престижио различных моделей и с любыми поломками."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Prestigio"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Prestigio:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА «ЦЕНТР МОБАЙЛ»"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ЗАКАЗАТЬ РЕМОНТ ПЛАНШЕТА ?"
          text="Если не включается планшет Prestigio, можно принести его к нам самостоятельно, вызвать мастера на дом или вызвать курьера для доставки устройства в центр (рекомендуется при сложной поломке). В случае вызова курьера владельцу гаджета оставляются необходимые документы, а после ремонта наш сотрудник привозит устройство обратно владельцу."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadPrestigio;
