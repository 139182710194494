import React from "react";
import Page from "../../Page";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import styles from "./CenterMobileIRepairMobile.module.css";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import {
  pricelists5mini,
  pricelist2Grand,
} from "../../../utils/CenterMobileSamsungPriceList";
import samsungBanner from "../../../images/banners/center-mobile-samsung-banner.png";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ГАРАНТИЯ ОТ ПРОФЕССИОНАЛОВ",
    text: "Наш сервисный центр предоставляет гарантию на все виды ремонта и установленные детали. На оригинальные комплектующие гарантийные обязательства действуют на протяжении двух лет, на неоригинальные – в течение года.",
  },
  {
    img: icon2,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "Если поломка смартфона позволяет устранить ее в домашних условиях, наш мастер может приехать к вам домой и выполнить ремонт смартфона SAMSUNG в вашем присутствии.",
  },
  {
    img: icon3,
    title: "КУРЬЕРСКАЯ ДОСТАВКА",
    text: "При нехватке времени можно воспользоваться услугами курьера, который приедет в обозначенное время и, оформив документы, доставит телефон в сервис. Обращаем внимание, что у нас действует зона бесплатной доставки.",
  },
  {
    img: icon4,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "Мы понимаем, как вам важен ваш смартфон в рабочем состоянии, поэтому предлагаем срочный ремонт телефонов SAMSUNG GALAXY и других моделей.",
  },
];

const mobiles = [
  { name: "Galaxy S5 mini SM-G800F", pricelist: pricelists5mini },
  { name: "Galaxy Grand i9080/i9082", pricelist: pricelist2Grand },
  { name: "Galaxy S3 mini i9190", pricelist: pricelists5mini },
  { name: "Galaxy Note 2 N7100", pricelist: pricelist2Grand },
  { name: "Galaxy Note 3 SM-n900", pricelist: pricelists5mini },
  { name: "Galaxy S5", pricelist: pricelist2Grand },
  { name: "Galaxy S4 mini i9190", pricelist: pricelists5mini },
  { name: "Galaxy S4 i9500", pricelist: pricelist2Grand },
  { name: "Galaxy S3 i9300", pricelist: pricelists5mini },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileIRepairMobile() {
  return (
    <Page title="Профессиональный ремонт смартфонов Samsung в Москве, все модели по доступным ценам">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={samsungBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="Ремонт СМАРТФОНОВ SAMSUNG"
          text="Наш сервисный центр предлагает профессиональные услуги по ремонту
          телефонов SAMSUNG. Всем нашим клиентам гарантируется качественный
          сервис, надежные комплектующие и быстрые сроки выполнения работ. Мы
          знаем, как вернуть в строй смартфон SAMSUNG любой модели."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт SAMSUNG"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            SAMSUNG:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="Наши преимущества при ремонте СМАРТФОНОВ SAMSUNG"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileIRepairMobile;
