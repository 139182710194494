import React from "react";
import { RepairAppleLinksBanner, RepairAppleLinksList, RepairAppleLinksText, RepairMacBookLinks, MacProfiList, Page, BreadCrumbs, Section } from '../index';
import { Link } from 'react-router-dom';
import banner from '../../images/banners/mb-touchpad.jpeg';

const listOfTouchpad = [
  {
    id: 1,
    number: '01',
    subtitle: 'Не работает тачпад.',
    textLi: <>Причина данной проблемы может быть механическое повреждения тачпада, а также залитие MacBook (<Link to='/remont-apple/remont-macbook/zalil_macbook' className='repair-mb__group-links-array'>подробнее</Link> по залитым MacBook).</>
  },
  {
    id: 2,
    number: '02',
    subtitle: 'Не работают функциональные кнопки MacBook.',
    textLi: 'В данном случае рекомендуем проверить настройки Mac OS, если все параметры выставлены верно – скорее всего тачпад вышел из строя. Производится его ремонт или замена на новый.'
  },
  {
    id: 3,
    number: '03',
    subtitle: 'Тачпад на MacBook некорректно работает, мышь перемещается сама собой по экрану.',
    textLi: 'Причина данной проблемы вероятнее всего поврежденный шлейф тачпада или механическое повреждение сенсорной панели. В данном случае тачпад проверяется на ремонтопригодность, если возможно мы его ремонтируем, если не возможно, производится полная замена тачпада на новый.'
  }
]

const textOfTouchpad = [
  {
    id: 1,
    title: <>Как мы меняем и ремонтируем тачпад на&nbsp;MacBook</>,
    text: 'Специалист снимает тачпад с MacBook и производит оценку его ремонтопригодности. Если тачпад возможно отремонтировать, производится ремонт, меняются все необходимые элементы для восстановления его работоспособности. Если тачпад невозможно отремонтровать, производится его полная замена на новый.'
  },
    {
    id: 2,
    title: <>Сроки замены и ремонта, предоставляемая гарантия на&nbsp;тачпад для MacBook</>,
    text: 'Срок замены тачпад на MacBook составляет 1 – 2 часа в зависимости от модели MacBook. Срок ремонта тачпада от 2-х часов до 6-и часов в зависимости от степени повреждения тачпада. После ремонта предоставляется гарантия от 3-х до 6-и месяцев в зависимости от ремонтных работ или модели тачпад.'
  }
]

const RepairMacBookLinkTouchpad = () => {
  return (
    <Page title='Ремонт и замена тачпад (touchpad) на MacBook, Air, Pro, Retina'>
    <main className='repair-mb__group-links-content'>
      <MacProfiList />
      <BreadCrumbs />
      <RepairAppleLinksBanner banner={banner}
        title='Ремонт и  замена тачпад (touchpad) на&nbsp;MacBook, Air, Pro, Retina'
        text='Меняем и ремонтируем тачпад на MacBook. Производим ремонт и замену тачпада в кратчайшие сроки. Причина ремонта и замена тачпада:'/>
      <Section className='section_group'>
        <div className='repair-mb__group-list-container'>
          {listOfTouchpad.map((item) => {
            return (
              <RepairAppleLinksList
                key={item.id}
                number={item.number}
                subtitle={item.subtitle}
                textLi={item.textLi}/>
              )
          })}
        </div>
      </Section>
      <Section className='section_group'>
        <div className='repair-mb__group-list-container'>
          {textOfTouchpad.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text} />
            )
          })}
        </div>
      </Section>
      <RepairMacBookLinks />
    </main>
    </Page>
  )
};

export default RepairMacBookLinkTouchpad;

// toDo:
// вынести banner в индекс
// вынести списки в константы
