import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpadAir2.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Разогрев передней части корпуса",
    number: "01",
  },
  {
    id: 2,
    text: "Удаление шлейфов и передней панели",
    number: "02",
  },
  {
    id: 3,
    text: "Поочередное отсоединение шлейфов кнопки Home, динамиков, фронтальной камеры, микрофона, наушников и сенсора освещенности",
    number: "03",
  },
  {
    id: 4,
    text: "Разогрев задней части планшета",
    number: "04",
  },
  {
    id: 5,
    text: "Отсоединение материнской платы",
    number: "05",
  },
  {
    id: 6,
    text: "Замена аккумулятора и обратная сборка устройства.",
    number: "06",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Этапы замены аккумулятора iPad Air 2",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Среди отличительных особенностей этой модели, стоит выделить её легкость и компактность. Чтобы этого достичь, было принято решение сократить размеры корпуса, грамотно продумав расположение внутренних элементов конструкции. Вот только, чтобы заменить вышедшие из строя элементы, предстоит выполнить сложную работу, требующую поистине ювелирной точности. Процесс замены аккумулятора iPad Air 2 включает в себя следующие этапы:"
          array={listOfTrouble}
          paragraphDown="Чтобы снять неисправный аккумулятор, потребуется практически полностью разобрать планшет. Для этого необходимы специальные инструменты, и уж точно не рекомендуется нагревать корпус устройства над конфоркой или же использовать для этой цели бытовой фен. Стоит обратиться к специалистам, которые непосредственно при клиенте смогут выполнить поставленную задачу и произвести замену элемента питания – в сервисе Мак Профи, вы сможете получить полный комплекс услуг."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelAir2 = () => {
  return (
    <Page title="Замена аккумулятора iPad Air 2 без дополнительных наценок и переплат">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad Air 2"
          text="Несмотря на то, что iPad Air 2 – качественная и надежная техника, работать вечно не может ни одно устройство. Именно поэтому рано или поздно потребуется заменить определенные части конструкции, в частности аккумулятор, который рассчитан лишь на определенное количество циклов перезарядки. В сервисном центре Мак Профи вы можете воспользоваться этой услугой – квалифицированные мастера не только установят новый элемент питания, но и подробно расскажут о том, как продлить период его дальнейшего использования."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Цена замены аккумулятора на iPad Air 2"
          text="Замена аккумулятора"
          price="3600 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Подобная работа не может стоить дешево, что обусловлено
                сложностью конструкции устройства. Но в нашем сервисном центре
                действуют лояльные расценки, благодаря чему вы сможете получить
                качественный ремонт по приемлемой цене. При этом диагностика
                проводится абсолютно бесплатно – и лишь после этого клиенту
                будет озвучена точная стоимость и сроки предстоящих работ.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если вам требуется лишь замена аккумулятора iPad Air 2, то уже
                через пару часов вы сможете забрать устройство домой – при
                необходимости может быть проведен и срочный ремонт. Обращайтесь
                к нам в удобное время, чтобы получить полный комплекс услуг на
                выгодных условиях.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelAir2;
