import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../../components/index';

const User = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    return navigate('/user-page', {replace: true});
  };

  return (
    <main className='user'>
      <div className='user__container'>
        <div className='user__form-wrapper'>
          <LoginForm
            formTitle='Личный кабинет клиента'
            onSubmit={handleSubmit}
          />
          <article className='user__info'>
            <div className='user__text-container'>
              <h2 className='user__title'>Уважаемые клиенты!</h2>
              <p className='user__paragraph'>В личном кабинете вы можете:</p>
              <ul className='user__list'>
                <li className='user__list-item'>просмотреть статус вашего заказа</li>
                <li>просмотреть согласованный список работ</li>
                <li>оплатить заказ онлайн банковской картой</li>
                <li>оплатить франшизу по заказу</li>
                <li>заказать доставку и оплатить доставку банковской картой</li>
                <li>следить за статусом ремонта вашей техники</li>
              </ul>
              <p>Для авторизации используйте логин и пароль выданный вам при оформлении заказа. Логин и пароль вы можете просмотреть в диагностическом листе, выданным вам при оформлении заказа в сервисном центре, а также в смс, полученной при доставке вашей техники в сервисный центр при оформлении доставки с сайта.</p>
            </div>
          </article>
        </div>
      </div>
    </main>
  )
};

export default User;
