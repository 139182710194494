import React, { useState, useEffect, Children, cloneElement } from "react";
import { arrowLeft, arrowRight } from "../../../images/index";
import { ITEM_WIDTH } from "../../../utils/constants";

const Slider = ({ children }) => {
  const [slides, setSlides] = useState([]);
  const [offset, setOffset] = useState(0); /* стейт для сдвига элемента */

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + ITEM_WIDTH;

      return Math.min(newOffset, 0);
    });
  };

  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - ITEM_WIDTH;

      const maxOffset = -(ITEM_WIDTH * (slides.length - 1));

      return Math.max(newOffset, maxOffset);
    });
  };

  useEffect(() => {
    setSlides(
      Children.map(children, (child) => {
        return cloneElement(child, {
          style: {
            height: "100%",
            boxSizing: "border-box",
            maxWidth: `${ITEM_WIDTH}px`,
            minWidth: `${ITEM_WIDTH}px`,
          },
        });
      })
    );
  }, [children]);

  return (
    <div className="slider">
      <img
        className="slider__arrow"
        src={arrowLeft}
        alt="слайдер влево"
        onClick={handleLeftArrowClick}
      />
      <div className="slider__wrapper">
        <ul
          className="slider__items"
          style={{
            transform: `translateX(${offset}px)`,
          }}
        >
          {slides}
        </ul>
      </div>
      <img
        className="slider__arrow"
        src={arrowRight}
        alt="слайдер вправо"
        onClick={handleRightArrowClick}
      />
    </div>
  );
};

export default Slider;
