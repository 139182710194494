import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera6plus.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera6Plus = [
  {
    id: 1,
    title: "Этапы замены камеры iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Для нас важно оказывать услуги на стабильно высоком уровне, а потому
          любому, даже незначительному ремонту, в обязательном порядке
          предшествует диагностика. Это необходимо, чтобы определить алгоритм
          будущего ремонта: например, если смартфон был залит водой или же
          получил сильный удар, то важно проверить отсутствие незаметных на
          первый взгляд повреждений. Стандартная процедура замены камеры iPhone
          6 Plus включает в себя следующие этапы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Бесплатное тестирование устройства
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Бережная разборка гаджета
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Устранение неисправных запчастей
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Установка новых деталей
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Сборка смартфона и демонстрация владельцу его стабильной работы.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Только после того, как клиент убедиться, что новая камера
          функционирует безупречно, а снимки получаются яркими и качественными,
          он расплачивается за предоставленные услуги. Мы обязательно
          предоставляем гарантию и выдаем соответствующий талон – если в течение
          указанного срока вновь возникнет подобная проблема, то мы устраним её
          абсолютно бесплатно. Но благодаря тому, что у нас работают
          квалифицированные инженеры, вероятность подобной ситуации сводится к
          минимуму.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Срок замены камеры iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Обращаясь к нам, вы избавлены от неудобств, возникающих из-за
          длительного ожидания. Мы не растягиваем ремонт на недели из-за того,
          что своевременно не была осуществлена закупка деталей или же
          количество мастеров в сервисе не соответствует нагрузке.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас всегда есть запас комплектующих, благодаря чему ремонт будет
          произведен в день обращения. Более того, если требуется исключительно
          замена камеры iPhone 6 Plus, то мы справимся с этой задачей в течение
          20-30 минут – и это уже включая диагностику.
        </p>
        <p className="repair-iphone-display__paragraph">
          Ознакомиться с расценками вы можете на сайте, цена складывается из
          стоимости работы и используемых деталей. Оцените сами, насколько
          выгодно наше предложение, особенно с учетом того, что мы гарантируем
          высокое качество предоставленных услуг.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если у вас возникли дополнительные вопросы, касательно проведения
          ремонта или же работы сервиса, свяжитесь с нами по указанным
          контактным телефонам. Либо просто приезжайте в любое время согласно
          графику работы, ведь Мак Профи открыт с понедельника по воскресенье.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels6Plus = () => {
  return (
    <Page title="Замена камеры iPhone 6 Plus, быстро заменим по низким ценам">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 6 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 6 Plus: бесплатно проведем диагностику и
                предложим выгодные условия. Гарантия качественного ремонта –
                используем лучшие комплектующие.
              </p>
              <p className="repair-iphone-display__paragraph">
                iPhone 6 Plus – это две отличные камеры, которые порадуют
                прекрасными снимками и видео. Вы сможете запечатлеть каждый
                важный момент, не упуская ни секунды. Если же, одно из устройств
                вышло из строя, то в сервисном центре будет успешно решена эта
                задача. Всё, что вам нужно – обратиться в Мак Профи, и мы
                проведем разборку-сборку аппарата, установив новую деталь.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1900 P"
          priceDown="1700 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera6Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels6Plus;
