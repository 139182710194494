import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display5c.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay5c = [
  {
    id: 1,
    title:
      "3 причины доверить замену дисплея iPhone 5c специалистам «Мак Профи»",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Замена дисплея Айфон 5c – процедура, которая проста лишь в теории. Но
          на самом деле нужно разобрать устройство, аккуратно извлечь основные
          элементы, и при этом не повредить и не потерять, ни один из них. Не
          имея достаточного практического опыта, справиться с этим не так уж и
          просто – именно поэтому, лучше доверить её опытному мастеру, который
          хорошо разбирается в тонкостях и нюансах восстановления поврежденного
          устройства.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы готовы предложить своим клиентам целый ряд преимуществ, среди
          которых:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Гарантия качества – вы получите полностью исправный смартфон, без
              каких-либо дефектов, царапин или же сколов, появившихся в процессе
              ремонта.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Оперативность – не придется ждать несколько дней, пока мастер
              найдет время для выполнения необходимых процедур. Мы готовы
              устранить поломку в день посещения сервисного центра.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Лояльная ценовая политика – не переплачивайте за ненужные вам
              услуги, и получите возможность обновить испорченный дисплей без
              дополнительных финансовых затрат.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Обратитесь к нам сразу же, как только возникла поломка, чтобы не
          лишиться возможности пользоваться столь необходимым устройством – мы
          расскажем о действующих специальных предложениях и выполним работу
          настолько быстро, насколько позволяют технические возможности.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels5c = () => {
  return (
    <Page title="Замена дисплея iPhone 5c - дешевая замена дисплея на Айфон 5c в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 5c"
          text="С каждым годом смартфоны становятся всё более функциональными, открывая владельцам новые возможности. Вот только их слабым местом по-прежнему остается экран, который очень легко разбить, например, сев на устройство или же уронив его. А родители маленьких детей и вовсе становятся постоянными клиентами сервисных центров, спасая телефон от случайного попадания влаги, ударов и других типов повреждений. Наши мастера помогут исправить возникшую проблему, а замена дисплея iPhone 5c будет выполнена в течение нескольких часов после обращения."
        />
        <RepairApplePrice
          subtitle="Стоимость замены дисплея на Айфон 5c: от чего зависит цена"
          text="Замена дисплея"
          price="2450 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Прежде чем приступить к ремонту устройства, важно понять, из
                чего состоит дисплейный модуль. А именно:
              </p>
              <p className="repair-iphone-display__paragraph">
                Мы готовы предложить своим клиентам целый ряд преимуществ, среди
                которых:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Сенсорное стекло – с его помощью владелец управляет
                    смартфоном
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Экран – эта часть модуля ответственна за вывод на экран
                    изображения.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Соответственно, цена ремонта зависит от того, какой из элементов
                был поврежден. Диагностика выполняется абсолютно бесплатно,
                после чего заказчик получит подробную информацию о стоимости и
                сроках. Далеко не всегда нужно менять дисплейный модуль
                полностью – наши специалисты предложат оптимальный вариант
                восстановления поврежденного устройства.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay5c.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels5c;
