import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Специалистам Note Expert постоянно приходится сталкиваться с большим разнообразием проблем, с которыми в наш центр обращаются пользователи ноутбуков разных марок.",
  "Профессиональная настройка нового ноутбука под потребности пользователя может решить многие из них с самого начала, раскрыв все возможности функционала этой портативной техники. ",
  "Кроме установки операционной системы и ПО (приложений, драйверов, утилит), в нее входит настройка BIOS, экрана, видеокарты, микрофона, камеры, батареи, клавиатуры ноутбука, а также многих других важных для его полноценной работы систем и компонентов.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Профессиональная настройка ноутбука – залог его стабильной работы и высокой производительности. Однако, чтобы установить требующееся для этого ПО, инсталлировать драйвера, загрузить обновления, провести антивирусную профилактику, настроить ОС, BIOS, Wi-Fi, произвести настройку яркости, звука и т.д., требуются специальные знания, навыки, квалификация.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛЕЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Наши эксперты располагают большим опытом, а также полным комплектом лицензионного программного обеспечения и современным профессиональным оборудованием для качественной оперативной диагностики, ремонта, настройки ноутбуков.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Невысокая стоимость настройки Wi-Fi на ноутбуке дает нашим клиентам возможность получить безопасный, надежный, недорогой доступ в Интернет",
  },
  {
    icon: priceIcon2,
    text: "Сбалансированные цены на установку и настройку программ, производительности ноутбука, его звука, дисплея, яркости плюс многие другие виды услуг, помогающие повысить качество работы вашего устройства ",
  },
  {
    icon: priceIcon3,
    text: "Доступная по стоимости оптимизация операционной системы и другого ПО – значительно повысить производительность устройства",
  },
];

const prices = [
  {
    text: "Установка Windows XP, 7, 8",
    price: "от 1500 P",
  },
  {
    text: "Установка программ",
    price: "200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Установка MS Office 2013 Home",
    price: "от 3500 P",
  },
  {
    text: "Настройка Windows, Mac OS",
    price: "от 300 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Установка Kaspersky Antivirus",
    price: "2300 P",
  },
  {
    text: "Настройка ПО",
    price: "от 300 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Все перечисленные операции по настройке этих мобильных персональных компьютеров мы производим с новыми ноутбуками, которым это необходимо, чтобы начать функционировать с максимальной эффективностью. А также с устройствами, пользователей которых перестала устраивать скорость, производительность, корректность их работы.",
  "Заметили, что ваш ноутбук стал работать не так быстро, как раньше? Не устраивают параметры его производительности? Покажите его нашим экспертам.",
];

function NoteExpertNoteSettingLink() {
  return (
    <Page title="Ремонт ноутбуков в Москве, цены на ремонт в Ноут Эксперт">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Настройка ноутбука в сервисе Note Expert"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Преимущества профессиональной настройки"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Настройка ноутбуков в Note Expert: цены на услуги"
          subtitle="Стоимость настройки ПО ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Когда вашему ноутбуку нужна квалифицированная настройка"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingLink;
