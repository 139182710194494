import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display5.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";

const textOfDisplay5 = [
  {
    id: 1,
    title: "Замена дисплея iPhone 5: что важно знать?",
    text: "Мы настоятельно не рекомендуем заниматься ремонтом самостоятельно – нам регулярно приносят подобные смартфоны. Чаще всего стоимость устранение допущенных недочетов обходится гораздо дороже, чем исключительно замена дисплея. Многие владельцы останавливаются уже на этапе разбора устройства – одно неловкое движение и можно легко повредить контакты. Ошибки также возникают в процессе подключения шлейфов, а также других элементов. И помните, если вы завершили процесс сборки, iPhone работает, но при этом остались лишние детали – значит, вам все равно вскоре придется обратиться в сервис. Не лучше ли сделать это сразу, и уберечь себя от напрасной траты времени и денег?",
  },
];

const textOfDisplay5down = [
  {
    id: 1,
    title: "Как заменить дисплей на Айфон 5?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Замена дисплея на Айфон 5 требует соблюдения нескольких важных
          нюансов. Для работы потребуется:
        </p>
        <p className="repair-iphone-display__paragraph">
          Комплект инструментов – и это еще один довод в пользу того, чтобы
          обратиться к профессионалам, ведь это выгоднее, чем приобретать
          необходимые инструменты.
        </p>
        <p className="repair-iphone-display__paragraph">
          Антистатичный коврик – чувствительные части устройства могут легко
          выйти из строя из-за статического электричества.
        </p>
        <p className="repair-iphone-display__paragraph">
          Схема разборки – это поможет не допустить ошибок во время выполнения
          процедуры и правильно соединить все извлеченные ранее элементы.
        </p>
        <p className="repair-iphone-display__paragraph">
          Обращаясь к нам, вы получаете 100% уверенность в том, что после замены
          дисплея iPhone 5 будет нормально работать. Если же у вас остались
          дополнительные вопросы касательно проведения ремонта, стоимости,
          сроков или же других нюансов, свяжитесь с нами по указанным на сайте
          контактным телефонам – и менеджеры предоставят всю необходимую
          информацию.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels5 = () => {
  return (
    <Page title="Срочная замена дисплея iPhone 5. Цены на замену дисплея Айфон 5.">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 5"
          text="На стекле появились трещины или темные пятна, самопроизвольно срабатывает сенсор? Замена дисплея iPhone 5 поможет успешно решить эту проблему – доверьте работу нашим мастерам, и вы сможете забрать свой смартфон непосредственно в день обращения. А главное, поставленная задача будет выполнена не только быстро, но и качественно, а потому восстановленное устройство порадует безупречной работой."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfDisplay5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены дисплея iPhone 5"
          text="Замена дисплея"
          price="2450 P"
          paragraph={
            <>
              Стоимость указана в рублях РФ<br></br>
              Цена за замену дисплея Айфон 5 указаны на сайте – вы можете
              ознакомиться с действующими расценками, чтобы убедиться в том,
              насколько выгодно наше предложение. Мы поддерживаем конкурентно
              способную стоимость и готовы предложить клиентам действительно
              выгодные условия. Более того, на все работы предоставляется
              гарантия, благодаря чему вы вкладываете деньги не только в ремонт,
              но и дальнейшее комфортное пользование устройством.
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay5down.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels5;
