import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone8.png";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone8 = [
  {
    id: 1,
    title: "Как выполняется замена микрофона iPhone 8",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если техника демонстрирует симптомы, которые говорят о неблагополучии
          динамика, необходимо провести диагностику и установить, в чем
          заключается причина проблем. Иногда бывает так, что динамик достаточно
          просто прочистить – действительно, проблемы со слышимостью могут
          возникнуть из-за его засорения. Но даже чистку лучше доверить
          профессионалам, потому как Айфон – это чувствительная
          высокотехнологичная вещь, которая требует к себе особого отношения.
        </p>
        <p className="repair-iphone-display__paragraph">
          Большинство проблем с динамиком решаются путем его замены, и здесь
          крайне важно не только провести работу грамотным образом, но и выбрать
          на замену деталь хорошего качества. Впрочем, обращение в сервис, где
          работают добросовестно, позволит исключить все проблемы поиска деталей
          на замену и их проверки. Нужно будет только предоставить технику на
          ремонт, и затем забрать ее – через несколько часов, или через сутки
          как максимум. И вы сможете снова наслаждаться ее беспроблемной
          эксплуатацией, не испытывая более никаких проблем со связью или
          звукозаписью. Выбор достойного исполнителя, который действительно
          разбирается в специфике современных высокотехнологичных устройств –
          это крайне важно, и наши специалисты действительно смогут оказать вам
          полноценную помощь под длительный срок гарантии. Мы полностью берем на
          себя ответственность за выполнение поставленных задач и результат
          работ.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Гарантированный результат при замене микрофона iPhone 8",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с проблемами работы динамика Айфона, и ищете
          возможность обратиться к хорошему специалисту для их устранения –
          обращайтесь к нам, чтобы получить полноценную помощь в короткие сроки.
          Мы профессионально занимаемся оказанием данных услуг, и успешно
          устраняем поломки любой сложности и специфики.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас – только грамотные специалисты с профильным образованием, только
          профессиональный подход к решению задач, только официальные заводские
          запчасти на замену. Мы создаем особые условия для выполнения ремонтных
          работ, снабжаем мастеров всем необходимым оборудованием для тончайших
          манипуляций. Обращаясь к нам, вы доверяете технику в проверенные руки
          и исключаете риск возникновения тех же поломок в будущем. Приносите
          технику на ремонт, мы выполним его за считанные часы!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels8 = () => {
  return (
    <Page title="Замена микрофона на iPhone 8 в Москве в присутствии клиента">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 8"
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="3700 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Как специалисты, так и рядовые пользователи подчеркивают, что
                Айфоны – это крайне надежная техника, и из поколения в поколение
                они становятся все более совершенными и в этом отношении тоже.
                Однако от риска возникновения поломок не застрахован никто. В
                ситуации, когда устройство падало, подвергалось ударам, или если
                в него попадала вода, могут возникнуть различные симптомы,
                свидетельствующие о поломке. Среди таковых могут наблюдаться
                следующие явления:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Возможность совершить звонок, однако невозможность передать
                    какую-либо информацию абоненту,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Жалобы второй стороны связи на помехи и посторонние звуки,
                    которые он слышит при общении,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Жалобы на то, что вы периодически «пропадаете».
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Эти и некоторые другие симптомы, среди которых можно отметить
                также низкое качество диктофонной записи или невозможность ее
                сделать, наглядным образом свидетельствуют о том, что вам
                необходима замена микрофона iPhone 8. Подозрения на динамик
                усугубляются, если при записи видео со звуком при его
                воспроизведении также обнаруживаются проблемы – это еще раз
                подчеркивает необходимость ремонта динамика. Радует, что это
                вполне осуществимое мероприятие, которое не создает особых
                проблем, если обратиться для его осуществления к грамотному и
                ответственному исполнителю.{" "}
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone8.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels8;
