import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  SelectForIphoneServiceLinks,
} from "../index";
import banner from "../../images/banners/repair-ipadCamera.jpeg";

const listOfSign = [
  {
    id: 1,
    text: "При включенном режиме съемке вы видите только чёрный экран.",
    number: "01",
  },
  {
    id: 2,
    text: 'В режиме съемки возникает надпись "камера в режиме ожидания".',
    number: "02",
  },
  {
    id: 3,
    text: 'Приложение, связанное с камерой, "подвисает", сообщает об ошибке.',
    number: "03",
  },
  {
    id: 4,
    text: 'Нечёткие фото, различные "помехи" и "смазоности" на них.',
    number: "04",
  },
];

const listOfTrouble = [
  {
    id: 1,
    text: "Механическое повреждение. Оптика вещь прихотливая, да и электронная начинка камер iPad восприимчива к ударам, падениям и прочим механическим воздействиям.",
    number: "01",
  },
  {
    id: 2,
    text: "Попадание влаги. Вода, снег, всяческий чай и прочий кофе, при попадание внутрь Айпэда могут доставить немало неприятностей, в том числе — вывести из строя камеру.",
    number: "02",
  },
  {
    id: 3,
    text: "Повреждение шлейфа камеры.",
    number: "03",
  },
  {
    id: 4,
    text: "Проблемы на уровне ПО: особенно вероятны при установке сомнительных сторонних приложений. Здесь поможет перепрошивка.",
    number: "04",
  },
];

const textServiceLinlCamera = [
  {
    id: 1,
    title: "Признаки неисправности камеры iPad и необходимости её замены",
    text: (
      <>
        <RepairAppleTextWithCheckMark array={listOfSign} />
      </>
    ),
  },
  {
    id: 2,
    title: "Возможные причины неисправности камеры Айпад",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          array={listOfTrouble}
          paragraphDown="Какова бы не была причина поломки камеры, главный совет — не пытайтесь осуществить замену камеры iPad или другой вид ремонт дома, самостоятельно. Уже сама разборка купертиновских планшетов процесс весьма трудоёмкий и требующий наличия специального оборудования и инструмента. Всё это есть в наличии у нас. К инструменту и оборудованию прилагаются ещё и золоторукие мастера. А перед самим ремонтом мы проведём полноценную и всестороннюю диагностику, дабы точно установить причину неисправности камеры. Диагностика у нас, к тому же, бесплатная."
        />
      </>
    ),
  },
];

const optionsIpadCamera = [
  { value: "iPad", label: "iPad", price: "2400", price2: "1900" },
  { value: "iPad 2", label: "iPad 2", price: "1900", price2: "1900" },
  { value: "iPad 3", label: "iPad 3", price: "1600", price2: "1900" },
  { value: "iPad 4", label: "iPad 4", price: "1900", price2: "1900" },
  { value: "iPad mini", label: "iPad mini", price: "1900", price2: "1900" },
  { value: "iPad Air", label: "iPad Air", price: "1900", price2: "1900" },
  { value: "iPad mini 2", label: "iPad mini 2", price: "2350", price2: "1950" },
  { value: "iPad Air 2", label: "iPad Air 2", price: "2350", price2: "1900" },
  { value: "iPad mini 3", label: "iPad mini 3", price: "2500", price2: "1950" },
  {
    value: "iPad Pro 12.9",
    label: "iPad Pro 12.9",
    price: "1900",
    price2: "1900",
  },
  { value: "iPad mini 4", label: "iPad mini 4", price: "2080", price2: "2080" },
  {
    value: "iPad Pro 9.7",
    label: "iPad Pro 9.7",
    price: "4450",
    price2: "4450",
  },
  { value: "iPad 5", label: "iPad 5", price: "1900", price2: "1900" },
  {
    value: "iPad Pro 2 10.5",
    label: "iPad Pro 2 10.5",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 2 12.9",
    label: "iPad Pro 2 12.9",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad 6 (2018)",
    label: "iPad 6 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 11 (2018)",
    label: "iPad Pro 11 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 12.9 (2018)",
    label: "iPad Pro 12.9 (2018)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Air 3 (2019)",
    label: "iPad Air 3 (2019)",
    price: "1600",
    price2: "1600",
  },
  { value: "iPad mini 5", label: "iPad mini 5", price: "1900", price2: "1900" },
  { value: "iPad 7", label: "iPad 7", price: "1900", price2: "1900" },
  { value: "iPad 8", label: "iPad 8", price: "1900", price2: "1900" },
  {
    value: "iPad Air 4 (2020)",
    label: "iPad Air 4 (2020)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 11 (2021)",
    label: "iPad Pro 11 (2021)",
    price: "1900",
    price2: "1900",
  },
  {
    value: "iPad Pro 12.9 (2021)",
    label: "iPad Pro 12.9 (2021)",
    price: "1900",
    price2: "1900",
  },
  { value: "iPad 9", label: "iPad 9", pprice: "1900", price2: "1900" },
  { value: "iPad mini 6", label: "iPad mini 6", price: "1900", price2: "1900" },
];

const RepairIpadServiceLinkCamera = () => {
  return (
    <Page title="Срочная замена камеры iPad в Москве, цены на замену камеры Айпад">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPad"
          text="Мак Профи производит полный комплекс работ по устранению неисправностей камеры в iPad. Замена камеры iPad в Мак Профи займет у вас минимальное количество время."
        />
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 75px",
            marginTop: 55,
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Цены на замену камеры iPad
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsIpadCamera}
              text="основной камеры"
              text2="передней камеры"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textServiceLinlCamera.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCamera;
