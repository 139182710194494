import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import explayBanner from "../../../images/banners/pad-explay-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА",
    text: "Приняв в ремонт планшет Explay, сервисный центр предоставляет гарантию на все виды работ и комплектующие. Сроки, в течение которых мастера обязуются выполнить бесплатный ремонт планшета Эксплей, зависят от типа ремонта и используемых запчастей.",
  },
  {
    img: icon2,
    title: "БЕСПЛАТНАЯ ЗОНА ДОСТАВКИ",
    text: "Когда не работает планшет Explay, можно воспользоваться услугой бесплатной доставки, действующей в пределах МКАД. Более дальние расстояния и доставка в нерабочее время оплачиваются по тарифам компании (смотрите раздел «Доставка»).",
  },
  {
    img: icon3,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "Если у вас нет времени на поездку в наш сервисный центр, а устранение поломки не требует использования сложного оборудования, можно вызвать мастера на дом или даже в офис.",
  },
  {
    img: icon4,
    title: "СРОЧНЫЙ РЕМОНТ ПЛАНШЕТОВ ЭКСПЛЕЙ В МОСКВЕ",
    text: "При необходимости наши специалисты могут выполнить оперативный ремонт в присутствии клиента, что существенно сэкономит драгоценное время.",
  },
];

const pads = [
  { name: "Imperium 8 3G", pricelist: desireHD },
  { name: "Winner 7", pricelist: desire400 },
  { name: "Discovery", pricelist: desireHD },
  { name: "Scream 3G", pricelist: desire400 },
  { name: "Leader", pricelist: desireHD },
  { name: "Tab mini", pricelist: desire400 },
  { name: "Cosmic", pricelist: desireHD },
  { name: "Mini TV 3G", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadExplay() {
  return (
    <Page title="Услуги по ремонту планшета Explay от специалистов сервисного центра «Центр Мобайл»">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={explayBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ EXPLAY"
          text="Компания «Центр Мобайл» предлагает качественный ремонт планшетов Explay в Москве. На протяжении нескольких лет наши специалисты устраняют самые различные поломки планшета Эксплей на программном и аппаратном уровне. Профессионализм, опыт, качественные комплектующие и современное оборудование позволяют делать это быстро и эффективно."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Explay"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Explay:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="СЕРВИСНЫЙ РЕМОНТ: НАШИ ПРЕИМУЩЕСТВА"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ОТДАТЬ ПЛАНШЕТ EXPLAY В РЕМОНТ ?"
          text={
            <>
              Чтобы воспользоваться услугами нашего специализированного центра,
              можно привезти гаджет к нам в офис самостоятельно, при небольшой
              поломке – вызвать мастера на дом, а при ограниченном времени –
              заказать услуги курьера для доставки планшета в сервис и обратно.
              Больше информации можно узнать по телефону +7 (495) 540-43-19 или
              написав нам на электронную почту{" "}
              <a
                href="mailto:info@centre-mobile.ru"
                className={styles.emaillink}
              >
                info@centre-mobile.ru
              </a>
              .
            </>
          }
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadExplay;
