import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import phillipsBanner from "../../../images/banners/mobile-philips-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "В рабочее время в пределах МКАД у нас предоставляется услуга бесплатной доставки, что позволяет экономить не только ваше время, но и деньги.",
  },
  {
    img: icon2,
    title: "КУРЬЕРСКАЯ ДОСТАВКА",
    text: "Мы постоянно работаем над повышением уровня сервиса, поэтому предлагаем удобную доставку курьером. Теперь воспользоваться нашими услугами можно из дома. При этом наш сотрудник оставит клиенту полный пакет документов, подтверждающий заказ. А после ремонта телефон оперативно вернут владельцу.",
  },
  {
    img: icon3,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "«Центр Мобайл» предлагает уникальную возможность вызвать специалиста на дом. Возможно это при несложной поломке, например, когда необходима замена экрана Philips.",
  },
  {
    img: icon4,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "Мы понимаем, что ремонт телефонов Philips Xenium и других моделей может понадобиться срочно. Поэтому наши мастера специализируются на оперативном ремонте в присутствии клиента. Более подробно можно узнать по нашему номеру телефона +7 (495) 540-43-19.",
  },
];

const mobiles = [
  { name: "Xenium", pricelist: desireHD },
  { name: "Xenium E182", pricelist: desire400 },
  { name: "Xenium E580", pricelist: desireHD },
  { name: "Xenium E109", pricelist: desire400 },
  { name: "Xenium E255", pricelist: desireHD },
  { name: "Xenium S566", pricelist: desire400 },
  { name: "Xenium S266", pricelist: desireHD },
  { name: "S260", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairMobilePhillips() {
  return (
    <Page title="Ремонт смартфонов Philips от «Центр Мобайл», ремонт смартфонов Филипс – недорого">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={phillipsBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ СМАРТФОНОВ PHILIPS"
          text="Если с вашим смартфоном Филипс произошла беда, и он не работает, обращайтесь в «Центр Мобайл», где квалифицированные специалисты в самые короткие сроки устранят все поломки. В процессе ремонта наши мастера используют многолетний практический опыт, надежные комплектующие и современное оборудование, что позволяет осуществлять качественный ремонт телефонов Philips независимо от вида и сложности неисправности."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт PHILIPS"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            PHILIPS:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА СМАРТФОНА ФИЛИПС В НАШЕМ ЦЕНТРЕ"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ?"
          text="Если необходим ремонт смартфона Филипс, заказ можно сделать по нашему многоканальному телефону, на адрес электронной почты или привезти свое устройство к нам лично. Наш офис удобно расположен в центре Москвы по адресу ул. Лесная, д. 1/2, офис 351. И мы всегда рады новым клиентам!"
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairMobilePhillips;
