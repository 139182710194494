import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone6plus.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone6Plus = [
  {
    id: 1,
    title: "Замена микрофона в короткие сроки для вашего iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если у вас возникли проблемы с высокотехнологичным гаджетом, вполне
          закономерно будет предоставить работы по устранению поломок грамотным
          и компетентным специалистам. Сервисный центр Мак Профи
          специализируется на устранении любых проблем с Айфонами, и работает в
          данной сфере уже много лет, успешно справляясь с задачами любой
          сложности. Мы имеем массу положительных отзывов, достойную репутацию и
          работаем официально, с предоставлением всех гарантий, подписанием
          договоров. Наша мастерская отличается разумной ценовой политикой и
          обстоятельным подходом к решению задач – отремонтированная единожды
          техника уже не будет досаждать теми же проблемами.
        </p>
        <p className="repair-iphone-display__paragraph">
          Каждое рабочее место мастерской имеет современное оснащение,
          адаптированное под выполнение сложнейших задач. Ведь работа с
          высокотехнологичными гаджетами требует иной раз ювелирной точности. У
          нас созданы все условия для быстрого и качественного ремонта техники,
          и всегда имеются необходимые запчасти – вам не придется ждать их
          заказа или привоза. Все необходимое есть в наличии, запчасти имеют
          официальное заводское происхождение, на них предоставляется
          официальная гарантия. Работы выполняют только компетентные и грамотные
          лица, которые знают об этой технике все – это тоже становится важным
          слагаемым успеха.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас проводится быстрая и бесплатная диагностика, после которой вы
          узнаете причину проблем и стоимость ремонта. Работы начнутся после
          вашего согласия, цена на них не будет меняться после оглашения.
          Результат вы сможете получить уже через несколько часов – техника
          вновь будет радовать вас исправной работой.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels6Plus = () => {
  return (
    <Page title="Надежная замена микрофона iPhone 6 Plus в сервисном центре Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 6 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с микрофоном известны многим владельцам Айфонов.
                Несмотря на то, что это высокотехнологичное оборудование
                отличается надежностью, у него есть свои относительно слабые
                места. Микрофон может получить повреждения в силу целого ряда
                факторов – от долгой эксплуатации и выработки ресурса, после
                падения или удара, механического повреждения.
              </p>
              <p className="repair-iphone-display__paragraph">
                Кроме того, определенную опасность представляет и попадание
                воды, влаги, окисление. Но в любом случае профессиональный
                подход позволяет устранить проблему и вернуть технику в активную
                эксплуатацию. Главное при этом – доверить решение вопроса именно
                специалистам, потому как дилетантский подход может оказаться
                губительным для чувствительной аппаратуры. Если вы не желаете
                сталкиваться с еще более серьезными проблемами, с более долгим и
                дорогостоящим ремонтом, или с покупкой нового Айфона из-за
                невозможности восстановления старого, лучше сразу обратиться к
                профессионалам, которые смогут предоставить достойный результат
                ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Но по каким показателям можно судить о том, что проблема
                заключается именно в микрофоне? Существуют свои симптомы,
                которые указывают именно на эту проблему, и среди таковых стоит
                отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полная тишина – собеседник вас не слышит вообще,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Посторонние шумы при связи,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Очень тихий звук, который не поддается регулировке.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Последние два момента могут говорить о необходимости чистки,
                после которой ситуация улучшится – но ее тоже лучше доверить
                специалистам в сервисном центре. Первый же фактор прямо
                указывает на отказ микрофона. Но все эти проблемы устраняются
                вне зависимости от симптоматики, и замена микрофона iPhone 6
                Plus позволит снова наслаждаться качественной связью.
              </p>
            </>
          }
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="1700 P"
          paragraph="Также стоит указать и на некоторые моменты, которые позволят вам исключить эти и другие поломки в дальнейшем. Так, стоит бережно относиться к девайсу и приучить себя к аккуратному с ним обращению. Также стоит приобрести чехол для защиты и пользоваться им – это решит многие проблемы еще до их появления. Однако если Айфон уже сломался, что необходимо предпринять?"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone6Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels6Plus;
