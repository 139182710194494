import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  SelectForIphoneServiceLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-display.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";

const listOfDisplay = [
  {
    id: 1,
    number: "01",
    subtitle: "Некорректно работает сенсорное стекло iPhone.",
    textLi:
      "Данная проблема как правило вызвана механическим повреждением дисплея либо производственным браком.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Разбили дисплей iPhone.",
    textLi:
      "Наиболее встречающаяся проблема. Как правило, это вызвано падением iPhone на твердую поверхность.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Черный экран iPhone.",
    textLi:
      "Проблема также появляется после механического воздействия. Лечится путем замены дисплея iPhone на новый.",
  },
];

const textOfDisplay = [
  {
    id: 1,
    title: "Как мы производим замену дисплея Айфон",
    text: "Перед заменой дисплея на Айфон, производится полная диагностика устройства на наличие иных повреждений, кроме дисплея и сенсорного стекла (тачскрина). После диагностики, если нет иных повреждений, специалист приступает к замене дисплейного модуля на новый. В моделях iPhone 5, 5S дисплей меняется в течении 20-40 минут в зависимости от загруженности сервисного центра. В iPhone 4, 4S замена дисплея производится в течении 1-1,5 часов, срок замены вызван конструктивными особенностями моделей iPhone 4, 4S.После замены дисплейного модуля, специалист передает iPhone в ОТК (отдел контроля качества). На ОТК проверяется полностью все параметры работы дисплея iPhone, включая яркость, контрастность, цветопередачу. После ОТК iPhone поступает на выдачу.",
  },
  {
    id: 2,
    title:
      "По наличию и предоставляемой гарантии на замену дисплейного модуля Айфон",
    text: "Сервисный центр всегда имеет в наличии все дисплеи на Айфон всех моделей. Благодаря наличию широкой базы комплектующих, мы производим быструю замену дисплея Айфон на новый.После замены дисплея, мы предоставляем гарантию на все работы и комплектующие.",
  },
];

const optionsDisplay = [
  { value: "iPhone 4", label: "iPhone 4", price: "1500" },
  { value: "iPhone 4s", label: "iPhone 4s", price: "1700" },
  { value: "iPhone 5", label: "iPhone 5", price: "2450" },
  { value: "iPhone 5c", label: "iPhone 5c", price: "2450" },
  { value: "iPhone 5s", label: "iPhone 5s", price: "2450" },
  { value: "iPhone 6", label: "iPhone 6", price: "2490" },
  { value: "iPhone 6s", label: "iPhone 6s", price: "3500" },
  { value: "iPhone 6 Plus", label: "iPhone 6 Plus", price: "3000" },
  { value: "iPhone 6s Plus", label: "iPhone 6s Plus", price: "3700" },
  { value: "iPhone 7", label: "iPhone 7", price: "3800" },
  { value: "iPhone 7 Plus", label: "iPhone 7 Plus", price: "1111" },
  { value: "iPhone 8", label: "iPhone 8", price: "2222" },
  { value: "iPhone 8 Plus", label: "iPhone 8 Plus", price: "3333" },
  { value: "iPhone X", label: "iPhone X", price: "4444" },
  { value: "iPhone XS", label: "iPhone XS", price: "5555" },
  { value: "iPhone XS Max", label: "iPhone XS Max", price: "6666" },
  { value: "iPhone 11", label: "iPhone 11", price: "7777" },
  { value: "iPhone 11 Pro", label: "iPhone 11 Pro", price: "8888" },
  { value: "iPhone 11 Pro Max", label: "iPhone 11 Pro Max", price: "9999" },
  { value: "iPhone SE 2020", label: "iPhone SE 2020", price: "0000" },
  { value: "iPhone 12", label: "iPhone 12", price: "10000" },
  { value: "iPhone 12 mini", label: "iPhone 12 mini", price: "11000" },
  { value: "iPhone 12 Pro", label: "iPhone 12 Pro", price: "12000" },
  { value: "iPhone 12 Pro Max", label: "iPhone 12 Pro Max", price: "13000" },
  { value: "iPhone 13", label: "iPhone 13", price: "14000" },
  { value: "iPhone 13 mini", label: "iPhone 13 mini", price: "15000" },
  { value: "iPhone 13 Pro", label: "iPhone 13 Pro", price: "16000" },
  { value: "iPhone 13 Pro Max", label: "iPhone 13 Pro Max", price: "17000" },
];

const RepairIphoneServiceLinkDisplay = () => {
  return (
    <Page title="Замена дисплея (экрана) iPhone в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone"
          text="Если вам требуется замена дисплея iPhone, наш сервисный центр с радостью вам поможет. Произведем быструю замену дисплея iPhone с гарантией. В каких случаях требуется замена дисплея или стекла на iPhone:"
        />
        <Section className="section_group" style={{ paddingBottom: 59 }}>
          <div className="repair-mb__group-list-container">
            {listOfDisplay.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 75px",
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Замена дисплея iPhone цена
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsDisplay}
              text="дисплея"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {textOfDisplay.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplay;
