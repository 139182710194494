import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "../NoteExpertReasonsLinks/NoteExpertInsidePage.module.css";

const callbackText = [
  "Требуется установка на ноутбук основного или дополнительного ПО? В сервисном центре Note Expert вам установят лицензионную операционную систему нужной версии, а также все остальное программное обеспечение, необходимое для его корректной и бесперебойной работы, включая драйвера, офисные/ мультимедийные приложения, утилиты, др.",
  "Кроме того, с вашего ноутбука будут корректно удалены ненужные, бесполезные для вас программы, продлен срок действия нужного ПО, установлена эффективная антивирусная защита.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Квалифицированные специалисты корректно удалят и так же корректно установят любое программное обеспечение на ноутбуки Acer, Asus, Dell, HP, Lenovo, Samsung, Sony, Toshiba, др.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Мы используем только лицензионные программы и предлагаем клиентам для установки на ноутбук драйвера самых последних версий. Профессиональная установка и настройка ПО в сервисном центре Note Expert гарантируют вам сохранность информации и качественную работу ноутбука.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Неправильная (непрофессиональная) установка ПО на ноутбук может резко снизить его производительность и гарантированно приведет к постоянным программным сбоям в работе.",
  },
  {
    icon: priceIcon2,
    text: "Нужен ли вам такой риск, если установка драйверов, антивирусов и других программ на ноутбук в сервисном центре Note Expert имеет вполне доступные цены?",
  },
  {
    icon: priceIcon3,
    text: "Стоит ли рисковать, учитывая, что профессиональная установка и настройка Windows разных версий в нашем сервисе осуществляется по весьма комфортным ценам. Самые популярные услуги из этой категории – установка Windows XP и Windows 7, цены на которые одни из лучших в Москве.",
  },
];

const prices = [
  {
    text: "Windows XP, Vista, 7, 8",
    price: "от 1500 Р",
  },
  {
    text: "MS Office 2013 Home",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Драйвера (за 1 шт.)",
    price: "200 P",
  },
  {
    text: "Видео плеер",
    price: "200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Видео кодеки",
    price: "200 P",
  },
  {
    text: "Kaspersky Antivirus",
    price: "2300 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Ваш новый мобильный ПК имеет предустановленную операционную систему и ограниченный набор программ, приложений, драйверов, или они представляют собой всего лишь ознакомительные версии, требующие замены на полноценные? Значит, вам срочно требуется установка на ноутбук программного обеспечения, как основного, так и дополнительного.",
  "Мы грамотно и быстро установим на ваш ноутбук любую программу или приложение, настроив его под конкретные пользовательские нужды.",
];

function NoteExpertGeneralPageServicePrograms() {
  return (
    <Page title="Установка программ на ноутбук от 200 рублей за единицу">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Установка программного обеспечения на ноутбук"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему установку ПО лучше доверить экспертам"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Стоимость установки программ на ноутбук в центре Note Expert"
          subtitle="Стоимость установки программ"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Вам нужна установка драйверов, программ, утилит, приложений"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPageServicePrograms;
