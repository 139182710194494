import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker8.png";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfSpeaker8 = [
  {
    id: 1,
    title: "Когда и почему может потребоваться замена динамика iPhone 8",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Существует целый ряд ситуаций, когда страдает в первую очередь именно
          динамик. Постоянное прослушивание музыки на максимальной громкости,
          падения, удары, попадание воды или влаги – все это и многое другое
          может привести к тому, что пострадает динамик, и о качественном
          звуковоспроизведении придется забыть.
        </p>
        <p className="repair-iphone-display__paragraph">
          Однако прежде чем обращаться в сервисный центр, имеет смысл проверить
          некоторые моменты самостоятельно. Так, иногда звук пропадает из-за
          случайно сбитых настроек, и стоит проверить, на каком уровне он у вас
          поставлен. Также проверьте режимы, не стоит ли Айфон на бесшумном.
          Стоит попробовать перезагрузить устройство, если звук отключился по
          прихоти программных сбоев, это поможет. Однако если ничего в ситуации
          не изменилось, дальнейшую диагностику и ремонт необходимо доверить
          профессионалам.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена динамика iPhone 8 в Москве – поможем всегда!",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если с вашим Айфоном возникла проблема, которая связана именно с
          работой динамиков, обращайтесь к нам, в сервисный центр Мак Профи,
          чтобы получить всю необходимую помощь в полном объеме! Наши мастера
          способны выполнить ремонт за считанные часы, обеспечивая высочайшие
          показатели качества, все работы выполняются с полной ответственностью
          и с гарантией на долгий срок. Гарантия распространяется как на сами
          работы, так и на все использованные детали – мы несем полную
          ответственность и за запчасти, которые используются только заводские,
          официального производства.
        </p>
        <p className="repair-iphone-display__paragraph">
          Качество работ обеспечивается работой квалифицированного персонала,
          обеспечением наилучших условий на рабочих местах и современным
          оснащением. Многолетний опыт позволяет нам справляться с задачами
          любой сложности и добиваться прекрасного результата даже в трудных
          ситуациях.
        </p>
        <p className="repair-iphone-display__paragraph">
          Обратившись к нам, в первую очередь вы сможете получить бесплатную
          диагностику, которая выполняется за считанные минуты и позволяет найти
          истинную суть проблемы. После этого вам назовут цену и условия
          ремонта, и при вашем согласии немедленно примутся за его выполнение.
          Обращайтесь к нам, и ваш Айфон проработает еще долго!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModels8 = () => {
  return (
    <Page title="Быстрая замена разговорного динамика iPhone 8 в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена динамика iPhone 8"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Качественное звуковоспроизведение практически в любых
                обстоятельствах – это одна из стильных сторон Айфонов, и новая
                модель устройства традиционно радует этим преимуществом. В норме
                музыка воспроизводится без хрипов даже на низких частотах и при
                громком звучании, а голос абонента на другой стороне связи
                должен доноситься ровно и четко, если нет проблем со связью.
                Однако если начинают наблюдаться помехи при связи с самыми
                разными абонентами, вне зависимости от вашего и их
                местонахождения и качества связи, если наблюдаются такие
                проблемы, как нижеупомянутые, необходимо задуматься о
                целесообразности ремонта. Среди наиболее частых симптомов
                проблем с динамиком стоит отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Скачки и перепады слышимости,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Хрипы при прослушивании музыки,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полное отсутствие звука.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                В этих и ряде других случаев оптимальным выходом становится
                замена динамика iPhone 8, для осуществления которой следует
                обратиться в сервисный центр. Задачи такого рода должны
                выполняться только в профессиональных условиях, и потому при
                обнаружении перечисленных неисправностей или других аспектов,
                позволяющих задуматься над риском поломки динамика, необходимо
                обратиться к профессионалам. Тем более, что сегодня найти их
                вовсе не сложно, а откладывать ремонт не стоит уже из-за того,
                что чувствительное высокотехнологичное оборудование требует к
                себе соответствующего отношения. Игнорируя поломку, вы можете
                усугубить ситуацию, равно как и в случае, если ремонт будет
                проведен не профессионально.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="3200 P"
          priceDown="3200 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeaker8.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels8;
