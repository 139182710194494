import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphonex.png";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfModelX = [
  {
    id: 1,
    title: "Как выполняется ремонт iPhone X в Мак Профи",
    text: (
      <>
        При обращении в профессиональную мастерскую первым этапом выполнения
        работ будет экспресс-диагностика, и ее необходимо будет осуществить вне
        зависимости от специфики проблем устройства. На практике одни и те же с
        виду симптомы могут свидетельствовать о возникновении разных проблем, и
        только профессиональный подход позволит детализировать поломку и найти
        оптимальный способ ее устранения. Сразу после диагностики, которая при
        профессиональном подходе займет всего несколько минут, можно будет
        узнать причину поломки и ее суть, а также оптимальные возможности
        касаемо ее устранения. Известна будет стоимость работ, и если условия
        вас устроят, можно будет начать ремонт немедленно. Перед началом
        устранения поломки заключается договор, в котором прописываются суммы и
        условия, которые остаются в дальнейшем неизменными. После ремонта
        техника тестируется, и если все в порядке – она возвращается заказчику
        под гарантию на долгий срок. Именно так выполняется ремонт любого рода в
        профессиональной мастерской.
      </>
    ),
  },
  {
    id: 2,
    title: "Ремонт Айфон Икс в нашем сервисном центре в Москве",
    text: (
      <>
        Если ваша техника сломалась и работает некорректно, обращайтесь в
        сервисный центр Мак Профи, чтобы получить полный спектр услуг по ее
        ремонту. Мы занимаемся осуществлением ремонтных работ вне зависимости от
        сложности и специфики ситуации, всегда добиваемся необходимого
        результата, и даем гарантию на долгий срок. Компания работает уже давно
        и имеет прекрасную репутацию, у нас – только профессиональные
        исполнители, всегда качественные запчасти под отдельную гарантию,
        специальные условия выполнения ремонтных работ. Обращаясь к нам, вы
        сможете получить ремонт на простых и выгодных основаниях, с длительным
        сроком гарантии, силами ответственных и компетентных исполнителей,
        которые знают об Айфонах все!
      </>
    ),
  },
];

const priceX = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPageX = () => {
  return (
    <Page title="Дешевый ремонт iPhone X в Москве от 300 р.">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone X"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Новейшие модели Айфонов становятся объектами гордости и заботы
                своих владельцев. И заботиться о них действительно стоит, ведь
                несмотря на всю свою надежность, даже новые устройства могут
                стать жертвой поломки. Известно, что Айфоны – это надежное
                оборудование, которое не так-то и просто вывести из строя, тем
                более, в тот период, когда речь еще не пошла об износе.
                Заводской брак тоже встречается крайне редко, что также
                становится одним из плюсов данного оборудования. Однако ремонт
                iPhone X в ряде случаев может потребоваться и на первых порах.
                Обычно причинами тому становятся такие аспекты, как неаккуратное
                обращение или отказ от использования чехла, различные нелепые
                случайности. Эта техника, как и любая другая, имеет собственные
                слабые места, с проблемами которых владельцы и обращаются в
                сервисные центры. Среди проблемных моментов надо отметить
                следующие:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Быстрый разряд аккумулятора или его некорректная работа и
                    необходимость его замены,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы с динамиками или микрофоном, которые также решаются
                    обычно путем замены,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Некорректная работа камер или одной из них, которая может
                    стать следствием разных причин.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Также нередко обращаются с комплексными проблемами, требующими
                особо тщательной диагностики и принятия объективного
                профессионального решения. Среди таковых стоит отметить
                ситуации, связанные с утоплением устройства и механическими
                повреждениями, а также случаи, когда Айфон не включается вообще.
                Здесь может потребоваться детальная диагностика, а
                следовательно, к исполнителям работ выдвигаются особые
                требования как в плане их профессионализма, так и касаемо
                наличия всего необходимого оборудования, условий для
                профессиональной работы над устройством.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceX}
            title="Цены на услуги по ремонту iPhone&nbsp;X:"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModelX.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPageX;
