import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

  const navigate = useNavigate();
  const returnPreviousPage = () => navigate(-1);

  return (
    <section className='not-found'>
      <h1 className='not-found__title'>404</h1>
      <p className='not-found__subtitle'>Страница не найдена</p>
      <button
        className='note-found__link'
        onClick={returnPreviousPage}
        type='button'>
          Назад
      </button>
    </section>
  )
};

export default PageNotFound;
