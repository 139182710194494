export const listOfModel = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelforDisplay = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/zamena-displeja/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/zamena-displeja/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/zamena-displeja/5s",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/zamena-displeja/5c",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/zamena-displeja/se",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/zamena-displeja/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/zamena-displeja/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/zamena-displeja/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/zamena-displeja/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/zamena-displeja/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/zamena-displeja/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "#",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "#",
  },
  {
    model: "Ремонт iPhone X",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForWater = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForPower = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForNetwork = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForBattery = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/zamena-akkumuljatora/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForCamera = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/zamena-kamery/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/zamena-kamery/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/zamena-kamery/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/zamena-kamery/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/zamena-kamery/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/zamena-kamery/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/zamena-kamery/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/zamena-kamery/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/zamena-kamery/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/zamena-kamery/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/zamena-kamery/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/zamena-kamery/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/zamena-kamery/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/zamena-kamery/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForButtons = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForSpeaker = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/zamena-dinamika/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/zamena-dinamika/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/zamena-dinamika/5s",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/zamena-dinamika/5c",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/zamena-dinamika/SE",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/zamena-dinamika/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/zamena-dinamika/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/zamena-dinamika/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/zamena-dinamika/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/zamena-dinamika/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/zamena-dinamika/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/zamena-dinamika/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/zamena-dinamika/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/zamena-dinamika/X",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const listOfModelForMicrophone = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/5s",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/5c",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/se",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/6",
  },
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/zamena-mikrofona/x",
  },
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

export const iphone4PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "3500",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1950",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1950",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "2700",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "490",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },

  {
    id: 11,
    text: "Замена передней камеры",
    price: "2000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1900",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "3100",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "3100",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1800",
  },
];

export const iphone4sPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "1700",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1150",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "990",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1100",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "3000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "990",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },

  {
    id: 11,
    text: "Замена передней камеры",
    price: "1100",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1350",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "900",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2020",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "800",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1150",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1000",
  },
];

export const iphone5PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2450",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1450",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1300",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1200",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1250",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "3000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1900",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "3100",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "3100",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1800",
  },
];

export const iphone5cPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2450",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1400",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1450",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1400",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1300",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1450",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1900",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1200",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1200",
  },
];

export const iphone5sPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2450",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1300",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1450",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "3500",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1450",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1900",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1300",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1500",
  },
];

export const iphoneSEPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2850",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1550",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1500",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1550",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "490",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1450",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1900",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1300",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1500",
  },
];

export const iphone6PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2490",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1600",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1700",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "5000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "490",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1700",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1600",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1900",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1200",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1200",
  },
];

export const iphone6sPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "3500",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1950",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1700",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "490",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1800",
  },
];

export const iphone6PlusPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "3000",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1700",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1700",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "490",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1550",
  },
];

export const iphone6sPlusPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "3700",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1900",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1700",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1800",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2100",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1900",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1900",
  },
];

export const iphone7PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "3800",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "2500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "2500",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "2400",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "2500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "6000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "3000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2900",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "2400",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "2400",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "2400",
  },
];

export const iphone7PlusPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "5900",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "2400",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "2400",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "2400",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "2900",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "5700",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2400",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "500",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2700",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2900",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3500",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3500",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "2500",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "2400",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "2400",
  },
];

export const iphone8PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "1111",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1111",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1111",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "1111",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "1111",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1111",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1111",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2600",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1111",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1111",
  },
];

export const iphone8PlusPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2222",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "2222",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "2222",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "2222",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "2222",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2222",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "2222",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2222",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2600",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1111",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1111",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1111",
  },
];

export const iphoneXPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "2222",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "2222",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "2222",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "3333",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "3333",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "3333",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "3333",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "3333",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "3333",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "3333",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "3333",
  },
];

export const iphoneXSPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "4444",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "4444",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "4444",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "4444",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "4444",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "4444",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "4444",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "4444",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "4444",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "3333",
  },
];

export const iphoneXSMaxPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "5555",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "5555",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "5555",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "5555",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "5555",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "5555",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "4444",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "5555",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "4444",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "5555",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "5555",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "5555",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "5555",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "3333",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "4444",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "3333",
  },
];

export const iphone11PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "5555",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "6666",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "5555",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "6666",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "4444",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "6666",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "6666",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "6666",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "6666",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "6666",
  },
];

export const iphone11ProPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "7777",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "7777",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "7777",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "7777",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "7777",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "6666",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "6666",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "6666",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "7777",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "7777",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "4444",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "6666",
  },
];

export const iphone11ProMaxPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "8888",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "7777",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "7777",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "7777",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "7777",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "8888",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "8888",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "8888",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "8888",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "8888",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "8888",
  },
];

export const iphoneSE2020PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "9999",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "9999",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "9999",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "9999",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "8888",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "9999",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "9999",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "8888",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "8888",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "9999",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "8888",
  },
];

export const iphone12PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "1010",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1010",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1010",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "1010",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "1010",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "9999",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1010",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "1010",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "9999",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1010",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1010",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1010",
  },
];

export const iphone12miniPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "0000",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "0000",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "0000",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "0000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "0000",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "0000",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1010",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "0000",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1010",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "0000",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "0000",
  },
];

export const iphone12ProPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "1234",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "1234",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "1234",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "0000",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "1234",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "1234",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "1234",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "0000",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "1234",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1234",
  },
];

export const iphone12ProMaxPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "9876",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "9876",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "9876",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "9876",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "9876",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "9876",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "9876",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "9876",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "9876",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "1234",
  },
];
export const iphone13PriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "0147",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "0147",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "0147",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "0147",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "1234",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "0147",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "0147",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "9876",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "0147",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "0147",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "0147",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "0147",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "0147",
  },
];

export const iphone13miniPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "9510",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "9510",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "9510",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "9510",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "0147",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "9510",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "0147",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "0147",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "0147",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "9510",
  },
];

export const iphone13ProPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "9510",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "5252",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "9510",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "9510",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "5252",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "5252",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "5252",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "5252",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "5252",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "5252",
  },
];

export const iphone13ProMaxPriceGeneralPage = [
  {
    id: 1,
    text: "Замена дисплея",
    price: "9510",
  },
  {
    id: 2,
    text: "Замена аккумулятора",
    price: "5252",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 3,
    text: "Замена микрофона",
    price: "2209",
  },
  {
    id: 4,
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 5,
    text: "Замена кнопки включения",
    price: "9510",
  },
  {
    id: 6,
    text: "Замена антенны",
    price: "9510",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 7,
    text: "Ремонт Wi-Fi модуля",
    price: "2209",
  },
  {
    id: 8,
    text: "Замена датчика приближения",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 9,
    text: "Замена сим-держателя",
    price: "2209",
  },
  {
    id: 10,
    text: "Замена основной камеры",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 11,
    text: "Замена передней камеры",
    price: "2209",
  },
  {
    id: 12,
    text: "Замена разъема зарядки",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 13,
    text: "Замена разъема наушников",
    price: "2209",
  },
  {
    id: 14,
    text: "Замена кнопки Home (домой)",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 15,
    text: "Замена задней крышки",
    price: "2209",
  },
  {
    id: 16,
    text: "Замена корпуса",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 17,
    text: "Замена вибромотора",
    price: "2209",
  },
  {
    id: 18,
    text: "Замена слухового динамика",
    price: "2209",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 19,
    text: "Замена полифонического динамика",
    price: "2209",
  },
];
