import react from "react";
import {
  AboutCompanyBanner,
  AboutCompanyMacProfi,
  AboutCompanyTrust,
  AboutCompanyMacProfiMobile,
  Page,
} from "../index";
import useMedia from "use-media";
import { Helmet } from "react-helmet";

const AboutCompanyPage = () => {
  const isWide = useMedia({ minWidth: 840 });

  return (
    <main className="about-comp__page">
      <Page title="Информация о сервисном центре О Май Гаджет в Москве">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Информация о сервисном центре О Май Гаджет. История развития, дата основания, фотографии офиса и другая полезная информация."
          />
        </Helmet>
        <AboutCompanyBanner />
        {isWide ? <AboutCompanyMacProfi /> : <AboutCompanyMacProfiMobile />}
        <AboutCompanyTrust />
      </Page>
    </main>
  );
};

export default AboutCompanyPage;
