import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneServicesLinks,
  RepairIphoneModelsLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-button.jpeg";

const listOfButton = [
  {
    id: 1,
    number: "01",
    subtitle: "Не работает кнопка питания (блокировки) на iPhone.",
    textLi:
      "Причина данной неисправности – вышел из строя верхний шлейф iPhone. Ремонт производится путем замены верхнего шлейфа на новый. После замены верхнего шлейфа, кнопка питания iPhone работает корректно, как новая. Причиной выхода из строя верхнего шлейфа на iPhone – механическое повреждение в результате эксплуатации iPhone. Срок замены верхнего шлейфа до 1-го часа и зависит от модели iPhone.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Не работают кнопки регулировки громкости на iPhone.",
    textLi:
      "Стандартная проблема для iPhone. Решается путем замены аудио шлейфа iPhone. После замены аудио шлейфа работоспособность кнопок регулировки громкости восстанавливается в полном объеме. Причина неисправности: удар в районе кнопок регулировки громкости, залитие, механическое повреждение. Аудио шлейф меняется в течении 40 минут – 1 часа.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Не работает кнопка Home (домой).",
    textLi:
      "В данном случае решение проблемы – замена нижнего шлейфа iPhone. Процедура занимает порядка 40 минут, после чего кнопка домой на iPhone работает корректно. Как и в случае с кнопками блокировки и громкости, основная причина неисправности – механическое воздействие на кнопку в процессе эксплуатации iPhone. Нижний шлейф меняется в течении 1,5 часов, срок замены обусловлен конструктивными особенностями айфон.",
  },
];

const textOfButton = [
  {
    id: 1,
    title:
      "Немного о том, как мы производим ремонт кнопок блокировки, громкости, кнопки Home.",
    text: (
      <>
        В процессе диагностики специалист выявляет неисправность. После
        диагностики менеджер озвучивает клиенту точные сроки и точную стоимость
        замены шлейфа для восстановления работоспособности кнопок. После
        одобрения клиентом, информация передается в технический отдел,
        специалист приступает к замене шлейфа. После замены шлейфа и полной
        проверки работоспособности кнопки на iPhone, аппарат поступает на
        выдачу. Менеджер выдает аппарат клиенту, клиент проверяет iPhone и
        оплачивает стоимость ремонта.
      </>
    ),
  },
  {
    id: 2,
    title:
      "Какую гарантию мы предоставляем на замену кнопок блокировки, home, регулировки громкости.",
    text: (
      <>
        На все произведенные ремонтные работы предоставляется гарантия от 1-го
        до 3-х месяцев. В период гарантийного обслуживания, если неисправность
        вновь будет выявлены, мы бесплатно произведем гарантийный ремонт iPhone.
        Каждому клиенту предоставляется гарантийный талон и чек.
      </>
    ),
  },
];

const RepairIphoneServiceLinkButton = () => {
  return (
    <Page title="Замена кнопки питания (блокировки), громкости, Home (домой) на iPhone 5, 5S, 6, 6 Plus, 7, 7 Plus">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена кнопки питания (блокировки), кнопки громкости, кнопки Home (домой) на iPhone 5, 5S, 6, 6 Plus, 7, 7 Plus"
          text="Производим ремонт и замену кнопки питания (блокировки), кнопки громкости, кнопки Home (домой) на всех моделях iPhone.

          Основные неисправности:"
        />
        <Section className="section_group" style={{ paddingBottom: 40 }}>
          <div className="repair-mb__group-list-container">
            {listOfButton.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
            <p className="repair-mb__group-links-paragraph">
              Точную стоимость ремонта вы можете посмотреть вот{" "}
              <Link
                to="/remont-apple/remont-iphone"
                className="repair-mb__group-links-array"
              >
                тут
              </Link>
              , выбрав вашу модель iPhone. Будем рады вам помочь решить все ваши
              проблемы с вашим айфоном в кратчайшие сроки по приемлемым ценам.
            </p>
          </div>
        </Section>
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          <div className="repair-mb__group-list-container">
            {textOfButton.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkButton;
