import React from "react";
import {
  CenterMobileGeneralPageBanner,
  CenterMobileGeneralPageGadgets,
  CenterMobileGeneralPageDescription,
  CenterMobileGeneralPageRepair,
  CenterMobileGeneralPageAdvantages,
  CenterMobileGeneralPageLinks,
  CenterMobileGeneralPageLinksMobile,
  CenterMobileGeneralPageFeedbacks,
  BreadCrumbs,
  Page,
} from "../../index";
import { useMedia } from "../../../utils/useMedia";
import centerMobileBanner from "../../../images/banners/center-mobile-general-banner.svg";

function LinksPages() {
  const isBreakpoint = useMedia("(max-width: 780px)");

  if (isBreakpoint) {
    return <CenterMobileGeneralPageLinksMobile />;
  }
  return <CenterMobileGeneralPageLinks />;
}

const CenterMobileGeneralPage = () => {
  return (
    <Page title="Центр Мобайл - ремонт смартфонов и планшетов">
      <main className="center-mobile__general-page">
        <CenterMobileGeneralPageBanner
          title="ПРОФЕССИОНАЛЬНЫЙ РЕМОНТ ГАДЖЕТОВ"
          banner={centerMobileBanner}
        />
        <BreadCrumbs />
        <CenterMobileGeneralPageGadgets />
        <CenterMobileGeneralPageDescription />
        <CenterMobileGeneralPageRepair />
        <CenterMobileGeneralPageAdvantages />
        <CenterMobileGeneralPageFeedbacks />
        {LinksPages()}
      </main>
    </Page>
  );
};

export default CenterMobileGeneralPage;
