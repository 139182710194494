import React, { useState, Fragment } from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileSelect.module.css";
import { Listbox, Transition } from "@headlessui/react";
import vector from "../../../images/icons/Vector.svg";
import { Link, animateScroll as scroll } from "react-scroll";
import { useMedia } from "../../../utils/useMedia";
import { Carousel } from "react-responsive-carousel";

function CenterMobileIRepairMobileSelect({ title, text, mobiles }) {
  const [selectedMobile, setSelectedMobile] = useState(null);

  function ChangePage() {
    const isBreakpointMobile = useMedia("(max-width: 390px)");

    if (isBreakpointMobile) {
      return selectedMobile ? (
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className={styles.carouselWrapper}>
            <ul className={styles.list}>
              {selectedMobile?.pricelist.slice(0, 7).map((price, i) => {
                return (
                  <li
                    className={styles.item}
                    key={i}
                    style={{
                      backgroundColor: price.color,
                      boxShadow: price.boxShadow,
                    }}
                  >
                    <p className={styles.itemText}>{price.text}</p>
                    <p className={styles.itemPrice}>{price.price}</p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <ul className={styles.list}>
              {selectedMobile?.pricelist.slice(7, 14).map((price, i) => {
                return (
                  <li
                    className={styles.item}
                    key={i}
                    style={{
                      backgroundColor: price.color,
                      boxShadow: price.boxShadow,
                    }}
                  >
                    <p className={styles.itemText}>{price.text}</p>
                    <p className={styles.itemPrice}>{price.price}</p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <ul className={styles.list}>
              {selectedMobile?.pricelist.slice(14, 21).map((price, i) => {
                return (
                  <li
                    className={styles.item}
                    key={i}
                    style={{
                      backgroundColor: price.color,
                      boxShadow: price.boxShadow,
                    }}
                  >
                    <p className={styles.itemText}>{price.text}</p>
                    <p className={styles.itemPrice}>{price.price}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      ) : null;
    }
    return (
      <ul className={styles.list}>
        {selectedMobile?.pricelist.map((price, i) => {
          return (
            <li
              key={i}
              style={{
                backgroundColor: price.color,
                boxShadow: price.boxShadow,
              }}
              className={styles.item}
            >
              <p className={styles.itemText}>{price.text}</p>
              <p className={styles.itemPrice}>{price.price}</p>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <Section className={styles.selectSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{text}</p>
          <Listbox defaultValue={selectedMobile} onChange={setSelectedMobile}>
            <div className={styles.wrapper}>
              <Listbox.Button className={styles.button}>
                {selectedMobile ? selectedMobile.name : "Выберите модель"}
                <img src={vector} alt="" />
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className={styles.options}>
                  {mobiles.map((mobile) => (
                    <Listbox.Option
                      className={({ active, selected }) =>
                        `${styles.option} ${
                          active ? styles.optionActive : ""
                        } ${selected ? styles.optionSelected : ""}`
                      }
                      key={mobile.name}
                      value={mobile}
                    >
                      {mobile.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        {ChangePage()}

        <div className={styles.border}></div>
        <p className={styles.paragraph}>
          * Все цены указаны в рублях РФ. Цена включает комплектующее и услугу
          по установке.
        </p>
        <Link
          className={styles.downloadButton}
          to={"pricelist"}
          smooth={true}
          duration={1500}
          offset={50}
          delay={200}
        >
          Цены на другие услуги
        </Link>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileSelect;
