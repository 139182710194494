import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIwatchModeslLinks,
} from "../index";
import banner from "../../images/banners/iwatchNike.jpeg";

const listOfTrouble = [
  {
    id: 1,
    text: "Проникновение влаги под корпус. Недостаточно просто выключить устройство и оставить его в теплом сухом месте – потребуется качественная сушка, а также последующая чистка внутренних элементов конструкции.",
    number: "01",
  },
  {
    id: 2,
    text: "Повреждение стекла или тачскрина. Дисплей устойчив к появлению царапин, что не спасает его при падении – на поверхности могут появиться трещины, из-за чего функциональность гаджета будет нарушена.",
    number: "02",
  },
  {
    id: 3,
    text: "Износ батареи. Ремонт не поможет, нужно лишь менять неисправный элемент питания. После проведения этой процедуры вы сможете вновь наслаждаться длительной работой аккумулятора и забудете о необходимости постоянно подзаряжать устройство.",
    number: "03",
  },
];

const textIwatchNike = [
  {
    id: 1,
    title: "Когда требуется ремонт Apple Watch Nike+",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="При разработке устройства была поставлена задача: сократить размеры конструкции, чтобы она получилось достаточно компактной, и не доставляла владельцу неудобств. И это вполне удалось, вот только ремонт гаджета представляет собой поистине ювелирную работу, с которой не стоит пытаться справиться самостоятельно. При возникновении следующих неисправностей, лучше обратиться в сервис:"
          array={listOfTrouble}
          paragraphDown="И это далеко не полный список поломок – мы выделили лишь самые основные виды неисправности. Наши мастера успешно справятся с любой из них, ведь все запчасти уже в наличии – вам не придется ждать, пока они будут доставлены в сервис. Это позволило значительно повысить скорость ремонта Apple Watch Nike+ - мы позаботимся о том, чтобы процедура восстановления заняла минимальное количество времени."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сколько стоит ремонт Apple Watch Nike+",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          На сайте указаны расценки на определенные виды работ – стоимость
          запчастей не учтена. Окончательная цена будет озвучена исходя из
          результатов тестирования гаджета, которое необходимо для понимания
          серьезности поломки. Для клиентов нашего сервиса диагностика полностью
          бесплатна.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы восстановим стабильную работу устройства, чтобы вы вновь могли в
          полной мере пользоваться всеми его функциями. Обращайтесь в Мак Профи
          для ремонта Apple Watch Nike+ - мы заменим неисправные детали
          комплектующими отменного качества, и ваши часы будут успешно
          функционировать еще не один год.
        </p>
      </>
    ),
  },
];

const RepairIwatchModelLinkNike = () => {
  return (
    <Page title="Цены на ремонт Apple Watch Nike+">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Ремонт Apple&nbsp;Watch&nbsp;Nike+</>}
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ремонт Apple Watch Nike+: от замены батареи до установки нового
                стекла. 100% оригинальные запчасти. Специалисты Мак Профи
                устранят любые неисправности.
              </p>
              <p className="repair-iphone-display__paragraph">
                Apple Watch Nike+ – наручные часы, которые предназначены для
                активной ежедневной эксплуатации. С учетом большого числа
                функций, которые поддерживает устройство, во многих случаях оно
                успешно заменит даже смартфон, а потому подобный гаджет всегда
                должен быть на руке. Если же вы заметили неполадки в его работе,
                не стоит спешить искать замену привычной модели – большинство
                неисправностей легко исправить в специализированном сервисе.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIwatchNike.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIwatchModeslLinks />
      </main>
    </Page>
  );
};

export default RepairIwatchModelLinkNike;
