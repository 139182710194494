import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display7.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay7 = [
  {
    id: 1,
    title: "Замена дисплея Айфон 7: комплексное восстановление устройства",
    text: "Замена дисплея iPhone 7 начинается с диагностики – необходимо оценить состояние устройства, чтобы обнаружить даже незаметные глазу дефекты. Внутренние повреждения часто возникают во время падения устройства, пока ситуация не ухудшилась. Диагностика абсолютно бесплатна, а в наличии всегда есть базовый запас комплектующих элементов, что позволяет оперативно решать возникшие проблемы. В цену услуги уже включена стоимость запчастей, а также профилактика, которая должна проводиться регулярно.",
  },
  {
    id: 2,
    title: "Важные особенности замены дисплея на iPhone 7",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Отличительная особенность iPhone 7 – увеличенный угол обзора и
          минимальная толщина дисплейного модуля, что удалось достичь, склеив
          основные части дисплея между собой. И всё бы хорошо, но в процессе
          замены экрана эта особенность доставляет определенные неудобства – в
          домашних условиях практически невозможно удалить разбитое стекло, ведь
          нужно использовать профессиональное оборудование, которое поможет
          прогреть поверхность и растопить клей.
        </p>
        <p className="repair-iphone-display__paragraph">
          Процесс установки нового дисплея включает в себя несколько основных
          действий:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Снять экранный модуль
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Извлечь кнопку, камеру и динамик
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удалить пластиковую рамку
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">Срезать стекло</p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Очистить поверхность от остатков клея
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">06</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Нанести защитное покрытие и клеящий состав
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">07</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Зафиксировать новое стекло, после чего просушить поверхность.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Выполнив вышеперечисленные шаги можно вновь собирать устройство.
          Замена дисплея Айфон 7 относительно проста и не требует полного
          разбора смартфона – владельцам ранних моделей приходилось отключать
          множество различных шлейфов и разъемов, чтобы добраться до экрана. С
          другой стороны, самостоятельно поломку не устранить – придется в любом
          случае обращаться к профессионалам.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если ваш смартфон случайно упал, вы пролили на него воду или же
          возникли какие-либо сбои в работе по неизвестным причинам, посетите
          наш сервисный центр. Ознакомиться со списком предоставляемых услуг,
          как и с актуальными расценками, вы сможете на сайте – оцените все
          выгоды нашего предложения, чтобы ремонтировать Айфон 7 на
          действительно лояльных условиях без дополнительных комиссий и
          необоснованных наценок.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels7 = () => {
  return (
    <Page title="Замена дисплея iPhone (Айфон) 7 - сервисный центр Мак Профи в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 7"
          text="Темные пятна на экране, нарушенная работа сенсора – всё это не только мешает комфортному использованию устройства, но и доставляет определенные моральные неудобства, ведь мало кто с удовольствием ходит с разбитым дисплеем. Восстановите работу вашего смартфона прямо в день обращения – в нашем сервисном центре доступна эта услуга."
        />
        <RepairApplePrice
          subtitle="Стоимость замены дисплея:"
          text="Замена дисплея"
          price="3800 P"
          paragraph="Стоимость указана в рублях РФ. Цена включает полный комплекс работ по восстановлению и стоимость комплектующего."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay7.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels7;
