import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery6plus.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery6plus = [
  {
    id: 1,
    title: "Нужна ли замена батареи iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Согласно заявлениям производителя, стандартный цикл работы
          аккумулятора составляет около 500 зарядов. Но важно понимать, что это
          показатель может существенно сократиться, если на устройство попадет
          жидкость или оно будет повреждено в результате удара.
        </p>
        <p className="repair-iphone-display__paragraph">
          Понять, что батарея уже исчерпала свой срок службы, очень легко.
          Действительно, сложно не заменить, что смартфон стал очень быстро
          разряжаться и его уже давно не хватает на целый день. Стоит обратить
          внимание и на другие «симптомы», которые сообщают о том, что
          необходима замена аккумулятора. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Частые скачки заряда – индикатор может упасть с 90% до 10%, или,
              наоборот, с 20% подняться до 70%
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Замирание уровня заряда – если смартфон уже два часа демонстрирует
              40%, то это не значит, что он не отключится спустя 5-10 минут
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Деформация корпуса или пятна на выключенном экране – подобные
              проблемы возникают из-за того, чтобы батарея перегревается,
              вздувается, а соответственно создает давление на дисплейный модуль
              и заднюю часть устройства.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Наш мастер аккуратно разберет смартфон, выполнит замену батареи iPhone
          6 Plus, после чего выполнит процедуру в обратном порядке. Вы сможете
          самостоятельно убедиться в работоспособности устройства – и лишь после
          этого рассчитаться за предоставленные услуги.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels6Plus = () => {
  return (
    <Page title="Качественная замена аккумулятора на iPhone 6 Plus">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора на iPhone 6 Plus"
          text="Техника Apple отличается стабильной и продолжительной работой – подобные устройства рассчитаны на продолжительный период эксплуатации. Но даже столь функциональный и грамотно продуманный смартфон как iPhone 6 Plus, обладает некоторыми недочетами. И один из них – необходимость замены аккумулятора каждые два года. Эта услуга доступна в сервисном центре Мак Профи – вы сможете воспользоваться ей на выгодных условиях, избавившись от неудобств, связанных с частыми выключениями устройства."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery6plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость и сроки замены аккумулятора iPhone 6 Plus в Москве"
          text="Замена аккумулятора"
          price="1600 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Изначально будет проведена диагностика – для клиентов сервиса
                она выполняется абсолютно бесплатно. Благодаря этой процедуре
                владелец сможет узнать о любых неисправностях и принять решение
                об их устранении. Если же требуется исключительно замена
                аккумулятора iPhone 6 Plus, то эта процедура будет выполнена
                меньше, чем за полчаса.
              </p>
              <p className="repair-iphone-display__paragraph">
                Действующие расценки представлены на сайте. При оказании
                дополнительных услуг, стоимость работы несколько возрастет, но
                клиент будет уведомлен об этом заранее. После согласования всех
                условий, цена остается фиксированной – и не будет увеличена в
                процессе ремонта.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Приезжайте в сервисный центр Мак Профи, чтобы получить все
                необходимые услуги – вас ожидает высокий уровень сервиса и
                профессиональная помощь квалифицированных специалистов.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels6Plus;
