import React from "react";
import Section from "../Section/Section";
import bannerMacPro from "../../images/banners/repair-mb-banner.svg";

const RepairMacProBanner = () => {
  return (
    <Section>
      <img
        src={bannerMacPro}
        alt="баннер страницы"
        className="repair-mb__banner"
      />
    </Section>
  );
};

export default RepairMacProBanner;
