import React from "react";
import {
  MacProfiList,
  BreadCrumbs,
  RepairMacBookBanner,
  RepairMacBookInfo,
  RepairMacBookLinks,
  RepairMacBookPrice,
  RepairMacBookTrouble,
  RepairMacBookInfoMobile,
} from "../index";
import { useMedia } from "../../utils/useMedia";

function PageInfo() {
  const isBreakpoint = useMedia("(max-width: 720px)");
  if (isBreakpoint) {
    return <RepairMacBookInfoMobile />;
  }
  return <RepairMacBookInfo />;
}

const RepairMacBookPage = () => {
  return (
    <main className="repair-mb__page">
      <MacProfiList />
      <BreadCrumbs />
      <RepairMacBookBanner />
      {PageInfo()}
      <RepairMacBookTrouble />
      <RepairMacBookPrice />
      <RepairMacBookLinks />
    </main>
  );
};

export default RepairMacBookPage;
