import React from "react";
import { RepairAppleLinksBanner, RepairAppleLinksList, RepairMacBookLinks, MacProfiList, Page, BreadCrumbs, Section } from '../index';
import banner from '../../images/banners/mb-data.jpeg';

const listOfData = [
  {
    id: 1,
    number: '01',
    subtitle: 'Механическое повреждение SSD или HDD накопителя.',
    textLi: 'Характерная проблема, которая может случиться с каждыми.  Если не была создана резервная копия, в данном случае производится аппаратное восстановление данных. Восстанавливаем данные после механического повреждения SSD и HDD накопителей. Уважаемые клиенты, не забывайте делать резервные копии в Time Machine, это предотвратит дополнительные траты на восстановление данных в случае повреждения жесткого диска или SSD накопителя.'
  },
  {
    id: 2,
    number: '02',
    subtitle: 'Восстановление данных после программного сбоя.',
    textLi: 'Данная проблема характерна при перепаде электро напряжения, внезапном выключении MacBook и iMac. Восстановление данных в данном случае происходит программным путем и в большинстве случаев восстанавливается все вплоть до операционной системы.'
  },
  {
    id: 3,
    number: '03',
    subtitle: 'Восстановление данных после стирания.',
    textLi: 'Если вы стерли свои данные их также возможно восстановить. Сложность восстановление зависит от того, записывались другие данные на HDD или SSD после стирания или нет. '
  },
];

const priceOfData = [
  {
    id: 1,
    number: '01',
    subtitle: <>Как правило единой цены на восстановление данных с&nbsp;MacBook и&nbsp;iMac&nbsp;нет.</>,
    textLi: 'Все зависит от степени повреждения SSD или HDD. В большинстве случаев цена варьируется от 3000 до 12000 тыс рублей. Что влияет на стоимость восстановления данных:'
  },
  {
    id: 2,
    number: '02',
    subtitle: 'Степень повреждения вашего SSD или HDD.',
    textLi: 'Степень механического повреждения HHD или SSD определяется на аппаратном стенде, который вычисляет срок восстановления данных. Срок, который занимает восстановление данных, напрямую влияет на цену восстановления.'
  },
  {
    id: 3,
    number: '03',
    subtitle: 'Срок, после которого использовался SSD или HDD, после стирания данных программным методом.',
    textLi: 'Если после стирания данных SSD или HDD был неоднократно использован для перезаписи пользовательских данных, это затрудняет восстановление стертой информации. Аппаратный стенд выявляет срок восстановление стертой информации, рассчитывается стоимость восстановления. Восстановление данных после стирания программным методом наиболее дешевое, нежели восстановление данных после механического повреждения.'
  },
];

const RepairMacBookLinkData = () => {
  return (
    <Page title='Восстановление данных на MacBook и iMac в Москве'>
    <main className='repair-mb__group-links-content'>
      <MacProfiList />
      <BreadCrumbs />
      <RepairAppleLinksBanner banner={banner}
        title='Восстановление данных на&nbsp;MacBook и&nbsp;iMac в&nbsp;Москве'
        text='Производим восстановление данных на MacBook и iMac с применением профессионального оборудования. Срок восстановление данных зависит, как правило, от степени повреждения жесткого диска (HDD) или твердотельного накопителя (SSD).  Средний срок восстановления данных от 1-го до 3-х дней. В каких случаях клиенты обращаются за восстановлением данных с MacBook и iMac в наш сервисный центр:'/>
      <Section className='section_group'>
        <div className='repair-mb__group-list-container'>
          {listOfData.map((item) => {
            return (
              <RepairAppleLinksList
                key={item.id}
                number={item.number}
                subtitle={item.subtitle}
                textLi={item.textLi}/>
              )
          })}
        </div>
      </Section>
      <Section className='section_group'>
        <div className='repair-mb__group-list-container'>
          <h2 className='repair-mb__group-links-subtitle'>Цены на восстановление данных с&nbsp;MacBook и&nbsp;iMac</h2>
          {priceOfData.map((item) => {
            return (
              <RepairAppleLinksList
                key={item.id}
                number={item.number}
                subtitle={item.subtitle}
                textLi={item.textLi}/>
              )
          })}
          <p className='repair-mb__group-links-paragraph'>Уважаемые клиенты, диагностика на предмет восстановления данных в сервисном центре производится абсолютно бесплатно. После диагностики менеджеры нашей компании точно сообщат стоимость восстановления, сроки. Восстановление данных производится только по предоплате, которая составляет 50% от озвученной суммы. Если восстановление данных произвести не удалось, вся предоплата возвращается клиенту в полном объеме. Будем рады вам помочь!</p>
        </div>
      </Section>
      <RepairMacBookLinks />
    </main>
    </Page>
  )
};

export default RepairMacBookLinkData;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
