import React from 'react';
import { Page, MacProfiList, BreadCrumbs, RepairAppleLinksBanner, Section, RepairAppleIphonePrice, RepairIpadModelLinks, RepairIpadServiceLinks, RepairAppleLinksText } from '../index';
import banner from '../../images/banners/repair-ipadMiniRetina.jpeg';

const priceIpadMini2Retina = [
  {
    id: 1,
    option: 'Замена сенсорного стекла (тачскрина)',
    price: '3400 P',
  },
  {
    id: 2,
    option: 'Замена дисплея',
    price: '6000 P',
  },
  {
    id: 3,
    option: 'Замена корпуса/задней крышки',
    price: '7950 P',
  },
  {
    id: 4,
    option: 'Замена аккумулятора',
    price: '2500 P',
  },
  {
    id: 5,
    option: 'Замена микрофона',
    price: '2900 P',
  },
  {
    id: 6,
    option: 'Замена динамика',
    price: '2350 P',
  },
  {
    id: 7,
    option: 'Замена кнопки Home (домой)',
    price: '2350 P',
  },
  {
    id: 8,
    option: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1900 P',
  },
  {
    id: 9,
    option: 'Замена кнопки включения',
    price: '2100 P',
  },
  {
    id: 10,
    option: 'Замена антенны',
    price: '2100 P',
  },
  {
    id: 11,
    option: 'Ремонт Wi-Fi модуля',
    price: '5000 P',
  },
  {
    id: 12,
    option: 'Замена датчика приближения',
    price: '2100 P',
  },
  {
    id: 13,
    option: 'Замена сим-держателя',
    price: '950 P',
  },
  {
    id: 14,
    option: 'Замена основной камеры',
    price: '2350 P',
  },
  {
    id: 15,
    option: 'Замена передней камеры',
    price: '1950 P',
  },
  {
    id: 16,
    option: 'Замена разъема зарядки',
    price: '2350 P',
  },
  {
    id: 17,
    option: 'Замена разъема наушников',
    price: '2350 P',
  },
]

const textOfIpadMini2Retina = [
  {
    id: 1,
    title: 'Ремонт iPad Mini 2 Retina: характерные поломки',
    text: 
      <>
        <p className='repair-iphone-display__paragraph'>Инженеры Apple постарались на славу и Айпад Мини 2 Ретина это надежный и почти безотказный девайс. Необходимость ремонта, как правило, возникает не из-за недочётов купертиновцев, но по вине самого хозяина планшета. Удары и падения, попадание в воду - вот список наиболее вероятных источников проблем iPad Mini 2 Retina.</p>
        <p className='repair-iphone-display__paragraph'>Самая частая поломка - это разбитый или поврежденный дисплей. В зависимости от масштаба "катастрофы" может понадобится: замена рамок дисплея или замена его стекла, наиболее серьезным же, и ощутимым для кармана владельца видом ремонта в данном случае будет замена всего дисплея Айпэд.</p>
        <p className='repair-iphone-display__paragraph'>Вторая по популярности причина обратиться в сервисный центр - барахлящий аккумулятор. Он может как не держать заряд, так и вообще отказываться заряжается. Причиной такого поведения может быть поломка разъема, или же неисправность самого аккумулятора. Если первая проблема решается простой заменой разъёма, то вторая потребует поменять батарею - более сложный и, конечно же, дорогой тип ремонта.</p>
        <p className='repair-iphone-display__paragraph'>Третья по популярности поломка Айпэд Мини 2 Ретина - попадание влаги внутрь планшета. При этом возможно замыкание и различные повреждение чувствительной электроники. Если с вашим iPad Mini 2 Retina приключилась подобная неприятность первым делом надо обратится в сервисный центр, например наш. Вашему планшету понадобится всесторонняя диагностика и ремонт выявленных неисправностей.</p>
        <p className='repair-iphone-display__paragraph'>Самое главное правило, которое следует запомнить всем владельцам: ремонт iPad Mini 2 Retina дело требующее вмешательства только профессионалов и доверять его "первым встречным" ремонтникам не следует, и уж ни под каким соусом не стоит пытаться осуществить его собственными силами.</p>
        <p className='repair-iphone-display__paragraph'>Благодаря нам любая "катастрофа" вашего iPad Mini 2 Retina превратится в мелкий эпизод его долгой и исправной жизни!</p>
      </>
  },
]

const RepairIpadModelPagesIpadMini2Retina = () => {
  return (
    <Page title={'Ремонт iPad Mini 2 Retina по приемлемым ценам в Москве!'}>
      <main className='repair-iphone-group-content'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner banner={banner}
          title='Ремонт iPad Mini 2 Retina'
          text='Мы осуществляем ремонт iPad Mini 2 Retina любой сложности, по приемлемым ценам и в кратчайшие сроки. На все наши работы предоставляется гарантия.'
        />
        <Section>
          <RepairAppleIphonePrice prices={priceIpadMini2Retina} title='Все цены на ремонт iPad Mini 2 Retina'/>
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className='section_group' style={{padding: '50px 0 65px'}}>
          {textOfIpadMini2Retina.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                {...item}
              />
            )
          })}
        </Section>
      </main>
    </Page>
  )
}

export default RepairIpadModelPagesIpadMini2Retina;