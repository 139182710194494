import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import sonyBanner from "../../../images/banners/pad-sony-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ПРОФЕССИОНАЛИЗМ ПЕРСОНАЛА",
    text: "У нас работают исключительно компетентные специалисты с длительным опытом работы. Нашим мастерам известны все тонкости восстановления электронных устройств, будь то ремонт планшетов Sony Xperia или другой модели.",
  },
  {
    img: icon2,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "Если планшет Sony не включается, а ждать вы не можете, наши мастера оперативно восстановят гаджет в вашем присутствии.",
  },
  {
    img: icon3,
    title: "ВЫЗОВ КУРЬЕРА",
    text: "При необходимости для доставки планшета в сервис можно воспользоваться услугами нашего курьера. После того, как планшет будет восстановлен, его оперативно вернут владельцу.",
  },
  {
    img: icon4,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "В зависимости от вашего местонахождения, услуга доставки может быть оказана на бесплатной основе. Подробнее об условиях доставки можно узнать по многоканальному телефону +7 (495) 540-43-19.",
  },
];

const pads = [
  { name: "Xperia Z4 Tablet LTE", pricelist: desireHD },
  { name: "Xperia Z3 Tablet Compact", pricelist: desire400 },
  { name: "Xperia Tablet Z2", pricelist: desireHD },
  { name: "Vaio Duo 13", pricelist: desire400 },
  { name: "Xperia Tablet Z", pricelist: desireHD },
  { name: "VAIO Duo 11", pricelist: desire400 },
  { name: "Xperia Tablet S", pricelist: desireHD },
  { name: "Tablet P", pricelist: desire400 },
  { name: "Tablet S", pricelist: desireHD },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadSony() {
  return (
    <Page title="Услуги по сервисному ремонту планшетов Sony в Москве, компания «Центр Мобайл»">
      <main className={styles.content}>
        <CenterMobileIRepairMobileBanner image={sonyBanner} />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ SONY"
          text="Благодаря высокому профессионализму, ответственности персонала и современному оборудованию, ремонт планшетов Sony в «Центр Мобайл» возможен в рекордно короткие сроки. Индивидуальный подход к каждой поломке и надежность комплектующих гарантируют долгую стабильную работу устройства. С нами ремонт вашего гаджета будет быстрым, эффективным и приятным по цене."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Sony"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Sony:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА ПЛАНШЕТОВ В «ЦЕНТР МОБАЙЛ»"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text="Вызвать мастера на дом или курьера для доставки планшета к нам в офис можно по телефону или электронной почте, а можно привезти гаджет самостоятельно. Наш адрес: Москва, ул. Лесная, 1/2, офис 351. Гарантируем клиентоориентированный подход и приятное сотрудничество!"
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadSony;
