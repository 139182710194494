import React from "react";
import Section from "../Section/Section";
import iPodBanner from "../../images/banners/repair-mb-banner.svg";

const RepairIpodBanner = () => {
  return (
    <Section>
      <img className="repair-ipod__banner" src={iPodBanner} alt="баннер" />
    </Section>
  );
};

export default RepairIpodBanner;
