import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery6s.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery6s = [
  {
    id: 1,
    title: "Что вы получаете, доверив нам замену батареи iPhone 6s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Откладывая ремонт батареи, вы рискуете повредить устройство – из-за
          неисправного аккумулятора выходят из строя и другие системы. В
          среднем, спустя 2 года после покупки iPhone 6s, нужно обратиться в
          сервис. Доверив эту задачу нам, вы получаете следующие преимущества:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Быстрое восстановление. Несколько минут, и неисправный смартфон
              вновь будет нормально работать.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Гарантии. Мы используем только качественные запчасти, неизменно
              сотрудничая только с постоянными поставщиками.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Конкурентоспособные расценки. Вы всегда знаете, за что платите – в
              стоимость включена цена используемых комплектующих и работы
              мастера.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Благодаря замене аккумулятора, на протяжении нескольких последующих
          лет вам не придется испытывать неудобства из-за самопроизвольного
          отключения устройства. Наши мастера работают быстро и аккуратно, а
          потому на поверхности смартфона не останется ни малейших следов
          проведенного ремонта.
        </p>
        <p className="repair-iphone-display__paragraph">
          Позвоните нам по указанным на сайте контактным телефонам, чтобы
          выбрать удобный день и время для посещения сервисного центра.
          Проконсультируем и подробно ответим на все вопросы, а в указанную дату
          вас уже будет ожидать наш специалист. Сервисный центр Мак Профи – это
          высокий уровень сервиса и выгодные условия. Свяжитесь с нами, чтобы
          узнать о том, какое специальное предложение мы подготовили именно для
          вас.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels6s = () => {
  return (
    <Page title="Оперативная замена аккумулятора iPhone 6s за 20 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPhone 6s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Описание: Замена аккумулятора iPhone 6s – это доступное решение
                для всех желающих, сервисный центр Мак Профи выполнит для вас
                эту работу в наилучшем виде. Доступные цены гарантируются.
              </p>
              <p className="repair-iphone-display__paragraph">
                Самостоятельное восстановление iPhone 6s обычно приводит к
                возникновению дополнительных проблем – попытки устранить поломки
                своими руками лишь увеличивают общую стоимость ремонта. Именно
                поэтому при возникновении проблем с аккумулятором, нужно
                посетить сервисный центр – в Мак Профи вы сможете получить все
                необходимые услуги по доступным ценам.
              </p>
            </>
          }
        />
        <RepairApplePrice
          subtitle="Срочная замена аккумулятора iPhone 6s: качество гарантированно"
          text="Замена аккумулятора"
          price="1900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена аккумулятора iPhone 6s производится в присутствии
                клиента. Несколько минут мастеру потребуется на проведение
                диагностики, после чего он озвучит вероятную причину поломки и
                подберет оптимальный способ восстановления устройства. В
                некоторых случаях причина выхода батареи из строя заключается в
                использовании сложного ПО, а значит, потребуется настройка
                смартфона.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если же аккумулятор все-таки нужно заменить, то наши мастера
                подберут необходимые запчасти и быстро установят их на место
                поврежденных. На складе Мак Профи всегда есть детали для всех
                моделей Айфон, а потому если в процессе диагностики будут
                выявлены дополнительные поломки, то за короткое время удастся
                устранить любые дефекты.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery6s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels6s;
