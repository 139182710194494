import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone5s.jpeg";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfModel5s = [
  {
    id: 1,
    title: "Порядок ремонта Айфона 5с",
    text: (
      <>
        С начала - полная диагностика вашего телефона, которая позволит выявить
        все его явные и скрытые дефекты, или причины ненормального поведения. За
        диагностику вы не платите ничего. Далее мы связываемся с вами и уточняем
        список работ по ремонту, которые вы согласны оплатить, называем цену.
        Цена окончательная. Причем оплачивается только после того, как вы
        получили ваш исправный телефон обратно и убедились в качестве нашей
        работы. Вам выдаётся гарантийный талон и вы можете снова наслаждаться
        всей полнотой функционала вашего Айфона 5с!
      </>
    ),
  },
];

const price5s = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage5s = () => {
  return (
    <Page title="Ремонт iPhone 5S, цены на ремонт Айфон 5с в Москве.">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone 5s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вам потребовался ремонт iPhone 5s в Москве, то вы попали по
                адресу. Мы предлогам быстрый и качественный, а главное
                профессиональный, ремонт iPhone 5s. И, без ложной скромности,
                считаем себя доками в этом вопросе.
              </p>
              <p className="repair-iphone-display__paragraph">
                Даже яблочная продукция, которая славится своей надёжностью, не
                застрахована на 100% от поломок. К тому же сильные стороны
                iPhone 5s имеют и свои недостатки: именно сканер отпечатков,
                встроенный в данную модель, который стал революционным прорывом
                в смартофоностроении в свое время, имеет тенденцию чаще всего
                выходить из строя. Возможно именно эта причина привела на наш
                сайт и вас. Такое произведение инженерного искусства как Айфон
                5с не потерпит грубого вмешательства. А потому, дело его ремонта
                лучше поручить профессионалам дела - коими мы и являемся. Мы
                способны решить практически любые проблемы вашего телефона.
                Залогом того - точное и профессиональное оборудование, которое
                мы используем для диагностики и ремонта, наличие всех нужных
                запасных деталей, и, конечно же, наши первоклассные специалисты.
              </p>
              <p className="repair-iphone-display__paragraph">
                Обратившись за ремонтом iPhone 5s в наш центр вы получаете:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    ваш телефон на руки в максимально краткие сроки: как правило
                    на решение большинства проблем Айфона 5с у нас не уходит
                    более 20 минут времени;
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    гарантию до двенадцати месяцев;
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    возможность даже не выходить из дома: наш курьер возьмёт у
                    вас телефон по любому адресу в Москве и доставит его после
                    ремонта обратно.
                  </p>
                </li>
              </ul>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price5s}
            title="Цены на ремонт iPhone 5s"
            text="* Все цены в рублях РФ. В стоимость включена услуга по установки и комплектующее."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel5s.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage5s;
