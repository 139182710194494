import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Отказ ноутбука включаться – одна из наиболее распространенных причин обращения их владельцев в сервисные мастерские. А поскольку эту проблему довольно часто объединяют с их отказом загружаться, стоит уточнить само понятие.",
  "Ноутбук не включается – означает, что он никак не реагирует на ваши действия при нажатии кнопки включения (нет звуковых/ световых сигналов, монитор остается черным, вентиляторы не запускаются и т.д.), или, как вариант, ноутбук не включается и пищит.",
  "Причину возникновения данной проблемы и способ ее устранения специалисты сервисного центра Note Expert определят после проведения комплекса диагностических мер.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Диапазон неисправностей, которые могут послужить ему поводом для отказа включаться, очень широк. Если компьютерное устройство не подает признаков жизни или просто пищит, причиной может быть практически все, что угодно, начиная от повреждения и потери контакта кнопки включения, неисправности аккумуляторной батареи или цепи питания до короткого замыкания на материнской плате и выхода из строя ее микросхем.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Для точного определения причины требуется тщательная диагностика ноутбука с помощью специального оборудования. В тех случаях, когда ноутбук не включается, но при этом пищит, определить неисправность могут помочь звуковые сигналы (расшифровка в таблице BIOS).",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "В какую сумму вам обойдется реанимация вашего мобильного ПК, вдруг переставшего реагировать на нажатие кнопки включения, так же, как и перечень необходимых работ, зависит от результатов проведенного обследования.",
  },
  {
    icon: priceIcon2,
    text: "Мы предлагаем своим заказчикам разумные цены на разные виды ремонтных работ и бесплатную предварительную диагностику.",
  },
  {
    icon: priceIcon3,
    text: "Намного дешевле стоимости его ремонта в случае выхода из строя по причине перегрева одного-двух или сразу нескольких внутренних компонентов (видеочипа, северного моста, процессора, других важных элементов).",
  },
];

const prices = [
  {
    text: "Аппаратная диагностика",
    price: "Бесплатно",
  },
  {
    text: "Ремонт цепи питания",
    price: "от 2500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Аккумуляторы для ноутбуков",
    price: "от 800 P",
  },
  {
    text: "Замена разъема питания",
    price: "от 700 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Внешние блоки питания",
    price: "от 1000 P",
  },
  {
    text: "Ремонт материнской платы",
    price: "от 3000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Способ решения проблемы, когда ноутбук выключился и не включается, можно определить только после выявления ее источника (характера, места, степени тяжести поломки).",
  "Могут потребовать ремонта или замены разъемы, зарядное устройство, цепи питания ноутбука, отдельные компоненты материнской платы (северный/ южный мосты, микросхемы) или вся она целиком.",
  "Так же часто требуется перепрошивка BIOS.",
];

function NoteExpertNoteSettingPower() {
  return (
    <Page title="Ноутбук не включается. Ремонт от 700 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Не включается ноутбук"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему ноутбук перестает включаться"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Цены на ремонт не включающегося ноутбука"
          subtitle="Цены на ремонт не включающегося ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Ноутбук не включается, что делать?"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingPower;
