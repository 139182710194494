import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery5.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery5 = [
  {
    id: 1,
    title: "Как заменить батарею iPhone 5",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          При желании можно поменять отработавший свой срок аккумулятор и
          самостоятельно. Для удаления винтов нужно использовать специальные
          инструменты, после чего остается лишь снять батарею. Важно учитывать,
          что она приклеена, а потому придется приложить определенное усилие.
        </p>
        <p className="repair-iphone-display__paragraph">
          На первый взгляд – ничего сложного. И это действительно так, если за
          работу берется квалифицированный мастер, обладающий достаточным
          опытом. Вот только в наш сервисный центр нередко приносят аппараты, у
          которых повреждены разъемы и платы – именно так заканчиваются около
          80% ремонтов, выполненных своими силами. Стоит ли рисковать – решать
          только вам. Вот только помните, что расценки нашего центра более чем
          лояльны, а потому замена аккумулятора iPhone 5 не потребует
          значительных финансовых затрат.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels5 = () => {
  return (
    <Page title="Срочная замена аккумулятора iPhone 5, замена батареи Айфон 5 за 30 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPhone 5"
          text="Одна из распространенных причин обращения в сервис – замена батареи iPhone 5. Это связано с тем, что средний срок службы аккумулятора составляет около 2-х лет, после чего он начинает разряжаться прямо на глазах. Не нужно запускать многочисленные приложения или игры – достаточно просто включить экран, чтобы увидеть, как индикатор заряда постоянно падает. Более того, нередко устройство самостоятельно выключается даже при 20-30%, что доставляет серьезные неудобства. Обратитесь к нам для решения этой проблемы – и мастера сервиса Мак Профи помогут устранить поломку."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Замена батареи iPhone 5 в Мак Профи – максимум выгод и преимуществ"
          text="Замена аккумулятора"
          price="1450 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Доверяя восстановление смартфона профессионалам, вы получаете
                100% уверенность в качестве выполнения работы. Все-таки
                потребуется разобрать аппарат, после чего собрать его – и при
                этом не повредить ни поверхность, ни основные элементы системы.
                Кроме того, мы готовы предложить ряд преимуществ нашим клиентам,
                среди которых:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Бесплатная диагностика. Если помимо неисправной батареи
                    существуют и другие скрытые повреждения, например, возникшие
                    после падения, то мы обязательно их обнаружим и предложим
                    устранить на выгодных условиях.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Подбор комплектующих. Работаем только с качественной
                    продукцией, устанавливая аккумуляторы соответствующие
                    характеристикам устройства.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Гарантия качества. Вам не придется беспокоиться о том, что
                    при выполнении работы были допущены ошибки или же был
                    установлен бракованный элемент питания. После замены
                    аккумулятора наши мастера соберут устройство, включат его и
                    продемонстрируют стабильную работу смартфона.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down repair-iphone-display__margin-bottom">
                Всего 20-30 минут – и iPhone 5 больше не будет разряжаться за
                2-3 часа или постоянно выключаться. Все работы производятся
                непосредственно при клиенте, благодаря чему вы сможете
                проконтролировать процесс и забрать устройство непосредственно в
                день обращения.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels5;
