import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery5s.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBattery5s = [
  {
    id: 1,
    title: "Почему нужна регулярная замена батареи iPhone 5s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Казалось бы, нет ничего сложного в том, чтобы самостоятельно заменить
          неисправный элемент. Но на практике всё гораздо сложнее, ведь
          потребуется аккуратно извлечь хрупкие части устройства, после чего
          собрать его в обратном порядке. Малейшее повреждение – нормальная
          работа смартфона будет нарушена.
        </p>
        <p className="repair-iphone-display__paragraph">
          Более того, нередко замена аккумулятора iPhone 5s необходима не только
          из-за его износа, но и по другим причинам. Среди которых:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Последствия механического воздействия
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Короткое замыкание
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Проникновение влаги
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Длительная эксплуатация устройства на холоде.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          В любом из этих случаев, повреждения могли затронуть не только
          батарею, но и другие элементы. Именно поэтому лучше обратиться в
          специализированный сервис, в котором будет проведена диагностика – это
          позволит определить реальное состояние устройства, и выявить ранее
          неизвестные дефекты, которые будут устранены при минимальных
          финансовых затратах.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels5s = () => {
  return (
    <Page title="Надежная замена батареи (аккумулятора) iPhone 5s в течении 25 минут">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора (батареи) iPhone 5s"
          text="Обладателям iPhone 5s наверняка знакомы подобные проблемы: спустя определенное время после покупки, смартфон начинает плохо держать заряд, может неожиданно отключиться, перегревается. А ведь это не свидетельствует о поломке устройства – это лишь свидетельство нормального износа батареи. Элемент питания рассчитан на службу на протяжении двух лет, после чего его необходимо заменить. И вы можете воспользоваться этой услугой в московском сервисном центре Мак Профи."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery5s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Замена аккумулятора iPhone 5s в сервисном центре Мак Профи"
          text="Замена аккумулятора"
          price="1500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Не стоит терпеть неудобства, связанные с постоянными
                выключениями устройства – эта проблема легко решаема. Все
                манипуляции наши специалисты проводят непосредственно при
                клиенте, а вся процедура замены батареи iPhone 5s, включая
                диагностику, займет не больше 20 минут. Обращаясь к нам, вы
                получаете полный комплекс услуг на выгодных условиях:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Гарантируем качество ремонта – используем только
                    качественные запчасти, которые рассчитаны на продолжительную
                    эксплуатацию.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Оперативно устраняем неполадки – все необходимые
                    комплектующие уже находятся на складе компании, что и
                    позволяет нам проводить ремонт в срочном порядке.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Бесплатная консультация – вы сможете задать все интересующие
                    вопросы, а также получить рекомендации касательно правильной
                    эксплуатации iPhone 5s. Это поможет значительно продлить
                    период использования новой батареи, даже пользуясь
                    смартфоном в активном режиме.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down repair-iphone-display__margin-bottom">
                Позвоните нам уже сейчас, чтобы узнать подробнее, либо сразу
                приезжайте в сервисный центр – не тратьте время на ожидание,
                ведь работы устройства будет восстановлена максимально быстро.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels5s;
