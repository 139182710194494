import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone7.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone7 = [
  {
    id: 1,
    title: "Срочно заменим микрофон для вашего iPhone 7",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если поломка уже произошла, и вы нуждаетесь в ее устранении, главной
          задачей становится поиск достойного исполнителя, который сможет
          выполнить для вас эти работы на достойном уровне, предоставив хороший
          результат. Сервисный центр Мак Профи имеет многолетний опыт и работает
          именно с современными высокотехнологичными девайсами, добиваясь
          идеального результата в ремонте. Хорошая репутация и отзывы, широкий
          спектр услуг и вполне доступные расценки – все это является
          отличительной чертой работы данной мастерской.
        </p>
        <p className="repair-iphone-display__paragraph">
          Современное оснащение каждого рабочего места под выполнение задач
          любой специфики позволяет выполнять поистине ювелирные задачи – быстро
          и качественно. На все работы дается длительный срок гарантии, ведь
          сомневаться в их результативности не приходится.
        </p>
        <p className="repair-iphone-display__paragraph">
          В наличии всегда есть полный спектр деталей – это ускоряет процесс
          работы, не вынуждает подолгу ждать результата. На все детали есть
          отдельная гарантия, они имеют оригинальное заводское происхождение и
          высокое качество. К работам допускается только квалифицированный
          персонал с профильным образованием, соответствующим опытом – это тоже
          является важным слагаемым успеха. Если вы желаете получить ремонт
          достойного качества и на удобных условиях – это будет идеальным
          решением.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым же этапом проводится бесплатная и немедленная диагностика,
          после которой вы узнаете причину поломки и стоимость ремонта. Если
          условия вас устроят, ремонт можно будет начать немедленно, и уже через
          несколько часов ваш Айфон будет исправным. Обращайтесь, ремонт
          возможен!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels7 = () => {
  return (
    <Page title="Быстрая замена микрофона iPhone 7 в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 7"
          text="Айфон – это привычный для множества людей гаджет, который делает жизнь комфортнее и создает определенный имидж. Однако несмотря на всю его надежность, он тоже может прийти в негодность. Одной из весьма частых поломок является отказ микрофона, это довольно уязвимый элемент, и в мастерских приходится регулярно сталкиваться с необходимостью его ремонта, замены. Потребоваться замена микрофона iPhone 7 может после того, как девайс упадет или получит удар, после попадания воды, из-за окисления, длительного использования и по другим причинам."
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="2500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Иногда владелец не может точно указать, после чего и почему
                произошла поломка, но это не так уж и важно. Главное, что
                техника после этого восстановима, и профессиональный ремонт
                позволит быстро и недорого вернуть ее в норму. Однако крайне
                важно обеспечить именно профессиональный подход к решению такой
                проблемы, потому как тонкая и чувствительная техника данного
                типа не переносит дилетантских вмешательств, и даже вскрытие в
                домашних условиях может усугубить ситуацию и дать новые
                проблемы. Если вы не желаете платить еще больше из-за
                необходимости выполнения более сложного ремонта, или даже вовсе
                менять технику, лучше сразу обратиться к грамотным специалистам,
                которые смогут обеспечить профессиональный подход.
              </p>
              <p className="repair-iphone-display__paragraph">
                Однако по каким же признакам можно судить о том, что пострадал
                именно микрофон? Имеется свой перечень симптомов, наглядно
                свидетельствующих о такой проблеме:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Абоненты, которым вы пытаетесь позвонить, вас не слышат
                    вообще,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Звук очень слабый и не регулируется в лучшую сторону,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Есть посторонние шумы.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все это может говорить о поломке микрофона, которую надо
                устранять, доверив работу мастеру. Самостоятельно вы можете
                только принять к сведению некоторые моменты, которые помогут вам
                исключить проблемы в дальнейшем. Так, для исключения этих и
                других неполадок эксплуатируйте технику аккуратно, не забывайте
                о ее высокой чувствительности. Также пользуйтесь защитными
                чехлами, они исключают многие неприятности и действительно
                полезны. Однако что же вы должны предпринять, если техника уже
                сломалась?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone7.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels7;
