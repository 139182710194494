import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIphoneModelLinksForServiceMobile,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone6splus.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const RepairIphoneServiceLinkMicrophoneModels6sPlus = () => {
  return (
    <Page title="Оперативная замена микрофона iPhone 6s Plus по приемлемой цене!">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 6s Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Отказ любимого девайса – это в любом случае неприятное явление.
                Бывает так, что в целом устройство работает нормально, однако
                при попытке позвонить кому-то и поговорить вас просто не слышат.
                Либо же звук оказывается очень слабым, едва уловимым, и
                настройками что-то решить не удается. Порой мешают говорить
                шумы, посторонние звуки. Все эти ситуации указывают на
                возможность проблем с микрофоном, либо на то, что необходимо
                провести чистку устройства.
              </p>
              <p className="repair-iphone-display__paragraph">
                При шумах или глухой связи часто помогает чистка, но если звука
                нет вообще, то здесь однозначно приходится судить об отказе
                микрофона. По счастью, в профессиональных условиях все эти
                проблемы решаются легко, и замена микрофона iPhone 6s Plus может
                быть осуществлена в короткие сроки, за считанные часы и по
                невысокой цене. Крайне важно обеспечить именно профессиональный
                ремонт такой техники, потому как дилетантское вмешательство
                может привести к еще большим проблемам, устранение которых
                потребует новых вложений. Бывает и так, что техника просто
                выходит из строя полностью, и тогда вам придется покупать новый
                Айфон. Чтобы не сталкиваться с такими осложнениями, просто
                доверьте работу мастерам..
              </p>
              <p className="repair-iphone-display__paragraph">
                Проблема может возникнуть по целому ряду причин:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Падение устройства, любые механические повреждения, удар,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадание влаги или воды, окисление,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Износ по срокам и ресурсу.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Но в любом случае можно сделать многое для того, чтобы
                предупредить это явление. Стоит покупать чехлы защитного типа и
                пользоваться ими, они уберегают от множества рисков. А еще стоит
                научиться аккуратно пользоваться устройством, чтобы не
                подвергать его дополнительным рискам. Эти меры существенно
                продлят срок его беспроблемной эксплуатации. Однако что же
                делать, если проблема уже дала о себе знать, и ее следует
                устранять?
              </p>
            </>
          }
        />
        <RepairApplePrice
          subtitle="Замена микрофона квалифицированным специалистом на вашем iPhone 6s Plus"
          text="Замена микрофона"
          price="1900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если проблема уже имеет место быть, наши специалисты охотно
                возьмутся за ее решение, и предоставят вам профессиональный
                результат в короткие сроки. Сервисный центр Мак Профи уже много
                лет специализируется на работе с современными
                высокотехнологичными устройствами, обеспечивая их качественный
                ремонт любой сложности и срочности. Компания имеет прекрасную
                репутацию, многолетний опыт работы, массу достижений и
                положительных откликов. Мы работаем даже тогда, когда другие
                мастерские отказывают – и успешно восстанавливаем технику, так
                что замена микрофона не станет для нас проблемой. При высоком
                качестве оказания услуг мы придерживаемся доступной ценовой
                политики, даем все гарантии, работаем официально и ответственно.
              </p>
              <p className="repair-iphone-display__paragraph">
                Достигать высоких результатов нам помогает современное оснащение
                мастерской, где каждое рабочее место адаптировано для выполнения
                любых, даже сложнейших, ювелирных задач. Все необходимые
                запчасти всегда есть в наличии, они предоставляются по выгодным
                расценкам, с отдельной гарантией. Мы используем только
                качественные детали заводского происхождения. Также отдельно
                стоит сказать о персонале – у нас работают только профессионалы
                со стажем и опытом, которым известны все особенности устройства
                гаджетов. Все эти аспекты становятся слагаемыми нашего успеха.
              </p>
              <p className="repair-iphone-display__paragraph">
                Первым этапом выполнения любых работ становится диагностика – у
                нас ее выполняют бесплатно и немедленно, она отнимает несколько
                минут времени. Установив причину поломки, мастер сможет назвать
                стоимость ремонта, которая в дальнейшем меняться не будет. При
                вашем согласии мы сможем немедленно начать выполнение работ в
                срочном или штатном режиме, и уже вскоре вы сможете наслаждаться
                беспроблемной эксплуатацией исправного оборудования. Обращайтесь
                к нам, и ваша техника будет работать идеально, принося вам
                только лучшие впечатления от эксплуатации.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels6sPlus;
