import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/batteryx.png";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfBatteryX = [
  {
    id: 1,
    title: "Сложность замены аккумулятора на iPhone X",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          На первый взгляд процедура замены батареи выглядит довольно просто.
          Необходимо открыть корпус устройства, убрать элемент, который перестал
          работать корректно, поставить взамен новый, закрыть корпус. Но на
          практике даже вскрытие этого высокотехнологичного оборудования должно
          выполняться в особых профессиональных условиях, и потому не стоит
          пытаться решить проблему самостоятельно. Замена батареи – это
          недорогая услуга, и ждать ее выполнения долго не придется, так что
          причин отказываться от услуг профессионального сервисного центра
          просто нет.
        </p>
        <p className="repair-iphone-display__paragraph">
          Но в каких же ситуациях необходимо выполнять замену такого рода? На
          практике проблемы с батареей выдают себя в виде укорочения того срока,
          на протяжении которого устройство способно держать заряд, А в
          некоторых случаях активировать Айфон удается только при подключенной к
          сети зарядке. Внезапные сбросы заряда и многие другие явления и
          аномалии того же рода однозначно свидетельствуют о том, что
          аккумулятор пора менять. Кроме того, современные модели Айфонов могут
          и самостоятельно сообщить владельцу, что аккумулятор стал проблемным и
          его пора менять – соответствующая запись появляется на экране, для
          этого есть специализированная программа. Как бы то ни было, но вы
          наверняка не упустите тот момент, когда необходимо будет выполнить
          замену.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена аккумулятора Айфон Икс в Мак Профи в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если ваш Айфон перестал работать корректно, и в процессе его
          эксплуатации появились те или иные проблемы, которые позволяют думать
          об аккумуляторе как о виновнике их происхождения – сервисный центр Мак
          Профи готов взяться за устранение этих сложностей. Наша компания уже
          много лет работает в сфере ремонта и восстановления современной
          высокотехнологичной аппаратуры, и наши специалисты с большим опытом и
          соответствующим образованием без труда выполнят для вас эту работу. Мы
          работаем официально, с предоставлением гарантий и всей необходимой
          документации, и вы можете не сомневаться в результатах нашего труда.
        </p>
        <p className="repair-iphone-display__paragraph">
          Перед началом работ будет выполнена экспресс-диагностика, которая
          осуществляется бесплатно. Далее специалисты назовут вам проблему,
          стоимость ремонта и его условия, и если вас все устроит, аккумулятор
          смогут заменить буквально немедленно. Это не слишком сложная
          манипуляция, которая отнимет минимум времени и обойдется недорого, и
          после ее осуществления ваша техника вновь станет работать как раньше.
          Обращаясь к нам, вы получите гарантию на услуги и запчасти, и сможете
          еще долго наслаждаться работой девайса!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModelsX = () => {
  return (
    <Page title="Замена аккумулятора iPhone X за 20 минут в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner banner={banner} title="" text="" />
        <RepairApplePrice
          subtitle="Замена аккумулятора iPhone X"
          text="Замена аккумулятора"
          price="4500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Айфоны признаются надежным оборудованием, пригодным для долгой
                эксплуатации. И на практике они действительно доставляют своим
                владельцам лишь минимум хлопот. Однако ситуации случаются
                разные, и порой даже эта техника проявляет склонность к сбоям и
                поломкам. Нередко в сервисные центры обращаются с такой
                необходимостью, как замена аккумулятора iPhone X, потому как
                данный элемент действительно относится к категории уязвимых.
                Даже если говорить о новом устройстве, которое было куплено
                недавно, возникают определенные риски касаемо аккумулятора, и
                проблемы возникают обычно в следующих случаях:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Если не были соблюдены условия запуска нового аккумулятора в
                    эксплуатацию – на этот случай имеются особые рекомендации,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    При попадании влаги, воды, утоплении – в этих случаях
                    аккумулятор страдает нередко в первую очередь,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    При механических повреждениях, из-за заводского брака и в
                    других случаях.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Существует целый ряд ситуаций, когда под ударом оказывается
                именно аккумулятор, однако радует, что в подавляющем большинстве
                случаев эта проблема легко решается. Если поломка не является
                осложненной, банальная замена аккумулятора займет всего
                несколько минут, после чего можно будет вновь вернуться к
                привычной эксплуатации техники в ее обычном режиме.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryX.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModelsX;
