import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIphoneBanner,
  RepairIphoneInfo,
  RepairIphonePrice,
  RepairIphoneAboutUs,
  RepairIphoneServicesLinks,
  RepairIphoneAdvantages,
  RepairIphoneModelsLinks,
  RepairIphoneContacs,
  MacProfiListMobile,
  RepairIphoneModelsLinksMobile,
} from "../index";
import { useMobileScreen, useMedia } from "../../utils/useMedia";

function PageList() {
  const isBreakpoint = useMedia("(max-width: 680px)");
  if (isBreakpoint) {
    return <MacProfiListMobile />;
  }
  return <MacProfiList />;
}

function PageModelLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIphoneModelsLinksMobile />;
  }
  return <RepairIphoneModelsLinks />;
}

const RepairIphonePage = () => {
  return (
    <main className="repair-iphone__page">
      <Page title="Ремонт iPhone в Москве - срочный ремонт Айфонов от 300 руб.">
        {PageList()}
        <BreadCrumbs />
        <RepairIphoneBanner />
        <RepairIphoneInfo />
        <RepairIphonePrice />
        <RepairIphoneAboutUs />
        <RepairIphoneServicesLinks />
        <RepairIphoneAdvantages />
        {PageModelLinks()}
        <RepairIphoneContacs />
      </Page>
    </main>
  );
};

export default RepairIphonePage;
