import React from 'react';
import { SectionTitle, BlockTitle, Section } from '../index';
import phone from '../../images/logos/logo-phone-mobile.svg';

const aboutText = [
  {
    span: 'Это просто.',
    text: '«Oh!MyGadget!» - современная служба быстрого восстановления любых гаджетов.',
  },
  {
    span: 'Это быстро.',
    text: 'Работа в «Oh!MyGadget!» устроена так, что мы восстанавливаем любой гаджет в срок от 10 минут. ',
  },
  {
    span: 'Это инновационно.',
    text: 'В "Oh!MyGadget!" входит целая сеть технокампусов: технических центров быстрого реагирования.',
  },
]

const AboutUsMobile = () => {
  return (
    <Section id='omg'>
      <div className='about-mobile__container'>
        <h2 className='about-mobile__title'>Сломался гаджет?</h2>
        <div className='about-mobile__border-title'></div>
        <img className='about-mobile__logo' src={phone} alt='картинка разбитого смартфона' />
        <div className='about-mobile__text-container'>
          <h3 className='about-mobile__subtitle'>Почему?</h3>
          <p className='about-mobile__paragraph'>Потому что «Oh!MyGadget!» быстро решит вашу проблему, и снова услышите родное "Бзззз!" или "Дзынь!"</p>
        </div>
        <article className='about-mobile__content'>
          <h3 className='about-mobile__subtitle'>Хотите знать, что такое «Oh!MyGadget!»?</h3>
          <ul className='about-mobile__list'>
            {aboutText.map((item, i) => {
              return (
                <li key={i} className='about-mobile__item'>
                  <span className='about-mobile__span'>{item.span}</span>
                  <p className='about-mobile__paragraph'>{item.text}</p>
                </li>
              )
            })}
            <div className='about-mobile__border-text'></div>
          </ul>
        </article>
        <p className='about-mobile__paragraph'>Каждый кампус работает в соответствии с инновационными стандартами восстановления и последующей точной настройкой современных гаджетов. У нас заняты лучшие специалисты, а наши клиенты пользуются самыми удобными сервисами для отслеживания выполнения своих заказов в режиме реального времени. "Oh!MyGadget!". Такого еще не было.</p>
      </div>
    </Section>
  )
};

export default AboutUsMobile;