import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Шум внутри ноутбука создается вращением вентилятора или вентиляторов системы охлаждения, функциональным назначением которой является качественный и эффективный отвод тепла от всех нагревающихся компонентов устройства (процессора, видеокарты, др.) за пределы его корпуса.",
  "При нормальной работе этого мобильного ПК кулер шумит едва слышно, несколько повышаясь в моменты сильной нагрузки процессора и видеокарты (во время запуска требовательных игр, приложений).",
  "Если ноутбук начал шуметь значительно сильнее и при этом нагревается, это свидетельствует о проблемах, необходима диагностика.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Сильная запыленность снижает эффективность теплообмена, а повышающаяся температура и срабатывающий термодатчик заставляют кулер увеличивать обороты.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Износ деталей (в первую очередь втулки подшипника) заставляет кулер шуметь и вибрировать даже при обычных оборотах.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "В спектр услуг, которые могут потребоваться в ситуации, когда ноутбук сильно перегревается и выключается от перегрева, входит чистка, ремонт ноутбука и замена его неисправных компонентов.",
  },
  {
    icon: priceIcon2,
    text: "Итоговая стоимость восстановления стабильной работы компьютерной техники определяется после выполнения бесплатной диагностики и составления перечня работ и подлежащих ремонту/ замене деталей.",
  },
  {
    icon: priceIcon3,
    text: "Намного дешевле стоимости его ремонта в случае выхода из строя по причине перегрева одного-двух или сразу нескольких внутренних компонентов (видеочипа, северного моста, процессора, других важных элементов).",
  },
];

const prices = [
  {
    text: "Чистка от пыли с заменой термоинтерфейсов",
    price: "2000 Р",
  },
  {
    text: "Ремонт системы охлаждения",
    price: "от 2000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Ремонт/замена корпуса ноутбука и его деталей",
    price: "от 1000 P",
  },
];

const infoTexts = [
  "Первое, что нужно сделать в этом случае, произвести чистку от пыли внутри корпуса. Способы чистки могут быть разными (с разборкой, без разборки устройства).",
  "Кроме чистки может потребоваться ремонт системы охлаждения (замена кулера, его смазка, замена термопасты, др.) и обновление BIOS для изменения параметров скорости вращения.",
  "Если вы не имеете практических навыков для самостоятельного выполнения этих процедур, доверьте их профессионалам. В Note Expert вам помогут устранить повышенную шумность, последствия перегрева мобильного устройства, проконсультируют о мерах их профилактики на будущее.",
];

function NoteExpertNoteSettingLoud() {
  return (
    <Page title="Устранение излишнего шума при работе ноутбука">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Ноутбук шумит при работе"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему ноутбук сильно шумит: причины"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Сколько стоит устранить шумность работы ноутбука"
          subtitle="Стоимость устранения посторонних шумов ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Шумит ноутбук: что делать?"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingLoud;
