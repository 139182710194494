import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";

const callbackText = [
  "Плохое изображение на экране ноутбука или его полное отсутствие – повод обратиться к экспертам сервисного центра Note Expert.",
  "Мы оперативно произведем ремонт, исправив или заменив вышедшие из строя компоненты: лампу подсветки, шлейф, инвертор, дешифратор или LCD-матрицу.",
  "При необходимости замены матрицы экрана ноутбука подберем для вашего мобильного ПК максимально подходящую модель и после демонтажа неисправной установим ее на высокопрофессиональном уровне и с предоставлением длительных гарантий.",
];

const advantages = [
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "В сервисном центре Note Expert, осуществляющем ремонт ноутбуков, замена матрицы – услуга весьма востребованная, поскольку это один из наиболее уязвимых ее компонентов, чаще других получающий механические повреждения. Наши мастера имеют большой опыт в выполнении работ такого вида, поэтому произведенные ими демонтаж и установка экранных матриц являются абсолютной гарантией, что функционал устройства будет восстановлен полностью и без последствий.",
    icon: advantagesIcon1,
  },
  {
    title: "ПОЛНЫЙ АРСЕНАЛ РЕМОНТНО-ДИАГНОСТИЧЕСКОГО ОБОРУДОВАНИЯ",
    text: "В нашем распоряжении находится полный арсенал ремонтно-диагностического оборудования и большой выбор матриц разных производителей для практически любой модели ноутбука. Заказчикам не приходится долго ждать доставки нужных запчастей и окончания ремонтных работ.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Сервисный центр Note Expert предоставляет квалифицированные услуги по замене матрицы ноутбука в Москве на самых выгодных для их владельцев условиях.",
  },
  {
    icon: priceIcon2,
    text: "Нет необходимости приобретать новый ноутбук при выходе из строя всего лишь одного компонента, стоимость замены матрицы значительно дешевле нового ПК, даже несмотря на то, что этот компонент один из самых дорогостоящих.",
  },
  {
    icon: priceIcon3,
    text: "Мы предлагаем самые оптимальные в столице цены на комплекс услуг по замене матриц, после чего ваш ноутбук сможет прослужить вам еще весьма приличный срок.",
  },
];

const prices = [
  {
    text: "Замена ламп подсветки матрицы",
    price: "от 3000 P",
  },
  {
    text: "Ремонт/замена дешифратора матрицы",
    price: "от 1500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена матрицы включая саму матрицу",
    price: "от 1500 P",
  },
  {
    text: "Ремонт/замена инвертора матрицы",
    price: "от 1000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Эта процедура целесообразна в следующих ситуациях: на матрице присутствуют царапины, сколы, трещины, пятна и другие повреждения; полностью или частично отсутствует изображение; полосы на экране; не работает подсветка.",
  "Если есть возможность ремонта, и он оказывается целесообразным, наши специалисты устраняют неполадки в работе экрана ноутбука с помощью замены отдельных элементов.",
  "Возникли проблемы с изображением или экран получил механические повреждения, несовместимые с его работоспособностью? Позвоните нашим экспертам или сразу приезжайте в наш центр!",
];

function NoteExpertNoteSettingMatrix() {
  return (
    <Page title="Ремонт и замена матрицы ноутбука - от 1000 рублей">
      <main className={styles.main}>
        <NoteExpertInsidePageBanner banner={banner} />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Профессиональная замена матриц ноутбуков"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Особенности замены матрицы в Note Expert"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Сколько стоит замена матрицы ноутбука"
          subtitle="Стоимость ремонта и замены мартицы"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Когда вашему ноутбуку нужна квалифицированная настройка"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingMatrix;
