import React from "react";
import { comfort } from "../../images/index";

const FeedbackCard = ({ feedback }) => {
  return (
    <li className="feedback-card">
      <img
        src={comfort}
        className="feedback-card__image"
        alt="иконка для отзыва"
      />
      <div className="feedback-card__tail"></div>
      <div className="feedback-card__container">
        <p className="feedback-card__text">{feedback.text}</p>
      </div>
    </li>
  );
};

export default FeedbackCard;
