import React from "react";
import Section from "../Section/Section";
import iphoneBanner from "../../images/banners/repaip-iphone-banner.svg";

const RepairIphoneBanner = () => {
  return (
    <Section>
      <img className="repair-iphone__banner" src={iphoneBanner} alt="баннер" />
    </Section>
  );
};

export default RepairIphoneBanner;
