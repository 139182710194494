import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display7plus.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay7Plus = [
  {
    id: 1,
    title: "Почему нельзя выполнять замену дисплея Айфон 7 Plus своими силами?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Попытки самостоятельного ремонта не только не решают проблему, но и
          наоборот, ухудшают ситуацию. Не стоит пытаться исправить поломку
          самостоятельно, и на это есть целый ряд причин. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Нет никаких гарантий – если работа мастера станет причиной
              нарушения работы устройства, то допущенные недочеты будут
              моментально устранены. Своими силами не просто провести даже
              стандартную процедуру замены экрана, восстановить
              работоспособность неисправных элементов и вовсе практически
              невозможно.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Затраты на инструменты – нет смысла тратить деньги на
              самостоятельную покупку инструментов и необходимых материалов,
              если дешевле обратиться в сервис и восстановить смартфон в течение
              1-2 часов.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Риск покупки некачественных запчастей – из-за отсутствия опыта в
              этом вопросе, можно легко купить низкопробные подделки, переплатив
              за это крупную сумму. Сложно подобрать и хороший клей, который
              обеспечит надежное сцепление с поверхностью и не потечет в
              дальнейшем.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Хотите сэкономить на ремонте – обращайтесь в специализированный
          сервисный центр. Если просчитать все затраты на предстоящий ремонт, а
          также реально оценить возможные риски, можно обнаружить, что
          профессиональный ремонт обойдется на порядок дешевле домашнего
          восстановления.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels7Plus = () => {
  return (
    <Page title="Замена дисплея (экрана) iPhone 7 Plus - быстрый ремонт Айфон 7 Plus в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 7 Plus"
          text="Если на дисплее появились даже небольшие трещины, то выход смартфона из строя – исключительно вопрос времени. Постепенно, сенсор будет всё хуже реагировать, а использование устройства значительно осложниться. Чтобы этого не произошло, замена дисплея iPhone 7 Plus должна быть выполнена сразу после возникновения повреждений – это позволит быстро устранить поломку, а владельцу не придется тратить уйму времени на ожидание."
        />
        <RepairApplePrice
          subtitle="Выгодные условия от Мак Профи: замена дисплея iPhone 7 Plus по честным ценам"
          text="Замена дисплея"
          price="5900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                В стоимость включена работа по установке дисплея, а также сам
                дисплей. Стоимость окончательная.
              </p>
              <p className="repair-iphone-display__paragraph">
                Мы стремимся достичь высокой удовлетворенности клиентов,
                обеспечивая стабильно высокий уровень сервиса. Что вы получаете,
                сотрудничая с нами:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Бесплатная диагностика на начальном этапе, позволяющая
                    оценить реальное состояние устройства
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Обязательная проверка устройства после завершения ремонтных
                    работ
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Лояльная стоимость услуг, в которую уже включена цена
                    запчастей и профилактики смартфона
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">04</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Гарантия качества, которые предоставляются не только на
                    используемые запчасти, но и на выполненную работу.
                    Профессиональная замена дисплея Айфон 7 Plus – это быстрое и
                    безопасное решение возникшей проблемы. Выбирайте удобный
                    день для посещения сервисного центра, а мы оперативно
                    проведем все необходимые работы и восстановим неисправное
                    устройство.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Профессиональная замена дисплея Айфон 7 Plus – это быстрое и
                безопасное решение возникшей проблемы. Выбирайте удобный день
                для посещения сервисного центра, а мы оперативно проведем все
                необходимые работы и восстановим неисправное устройство.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay7Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels7Plus;
