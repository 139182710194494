import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display5s.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfDisplay5s = [
  {
    id: 1,
    title: "Как выполняется замена дисплея iPhone 5s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Смартфон – это не просто телефон, это одновременно электронная книга,
          фотоаппарат, органайзер, а также способ общения. Лишившись его даже на
          1 день, владелец испытывает серьезные неудобства, а потому наша
          основная задача – оперативно устранить возникшую поломку. Чтобы
          заменить дисплей потребуется выполнить следующие действия:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Изначально нужно отключить устройство, извлечь сим-карту
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удалить винты в нижней части и аккуратно поднять экран
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отсоединить все шлейфы и разъемы, снять защитную металлическую
              пластину
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отключить оставшиеся разъемы и снять экран
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отсоединить динамик, кнопку Home и датчик отпечатков пальцев
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">06</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выкрутить винты, удерживающие металлическую термопластину, и снять
              её.
            </p>
          </li>
        </ul>
        <p>
          После этого можно приступать к установке нового дисплея и последующей
          сборки устройства. Но, несмотря на кажущуюся простоту процедуры, на
          практике всё не так уж легко, а любое неправильное действие может
          стать фатальным – после включения устройство больше не будет работать.
          Поэтому стоит все-таки обратиться к профессионалам – ремонт в любом
          случае дешевле покупки нового iPhone 5S.
        </p>
      </>
    ),
  },
];

const textOfDisplay5sdown = [
  {
    id: 1,
    title: "Особенности замены дисплея Айфон 5s",
    text: (
      <>
        Очень важно соблюдать осторожность при работе с устройством,
        устанавливая кнопку Home, а также отсоединяя и подключая основные
        элементы. Более того, можно легко потерять пластиковое кольцо, которое
        находится вокруг вспышки, болты и другие миниатюрные элементы. Именно
        поэтому самостоятельное проведение ремонта просто-напросто
        нецелесообразно.<br></br>
        Если вы хотите, чтобы замена дисплея Айфон 5s была выполнено быстро и
        качественно, позвоните нам прямо сейчас – наши сотрудники сориентируют
        по срокам и ценам, в зависимости от сложности поставленной задачи и
        необходимости в получении дополнительных услуг.
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels5s = () => {
  return (
    <Page title="Замена дисплея iPhone 5s - стоимость замены экрана Айфон 5s в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена дисплея iPhone 5s"
          text="Если вам необходима замена дисплея iPhone 5s, то мы выполним ремонт непосредственно в день обращения, при этом диагностика будет проведена абсолютно бесплатно. Трещины на стекле или проблемы с сенсором – самая частая причина обращений в сервисный центр, а потому наши мастера готовы выполнить работу в кратчайшие сроки."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfDisplay5s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена дисплея iPhone 5s"
          text="Замена дисплея"
          price="2450 P"
          paragraph="Все расценки представлены на сайте – у нас нет никаких дополнительных комиссий и платежей. Только честная стоимость, которая в полной мере соответствует действительности – обращаясь к нам, вы застрахованы от необоснованного повышения цены. А главное, мы работаем только с качественными комплектующими, которые заказываем у проверенных поставщиков, что позволяет предоставить заказчикам необходимые гарантии."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay5sdown.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels5s;
