import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  SelectForIphoneServiceLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-speaker.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";

const listOfSpeaker = [
  {
    id: 1,
    number: "01",
    subtitle:
      "Плохая работа при прослушивании музыки без наушников, проблемы с громкостью или передачей звука,",
    textLi: "",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Хриплые звуки при низких частотах,",
    textLi: "",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Проблемы со слышимостью речи собеседника.",
    textLi: "",
  },
];

const textOfSpeaker = [
  {
    id: 1,
    title: "Грамотная замена динамика iPhone",
    text: (
      <>
        Если возникли проблемы такого рода, следует в первую очередь обратиться
        в мастерскую. Только грамотный специалист сможет полноценно решить
        задачу такого рода, и, учитывая всю хрупкость и высокотехнологичность
        Айфонов, надо понимать, что любое вмешательство в их устройство должно
        быть только профессиональным. А еще для того, чтобы гарантировать
        нормальную работу устройства, необходимо обеспечить ремонт в
        профессиональных условиях, где не будет риска попадания пыли и других
        нежелательных моментов. Большинство попыток самостоятельно решить
        проблему, как и доверие полупрофессиональным исполнителям, обречено на
        провал. Айфон – это дорогая техника, и едва ли кому-то захочется тратить
        существенную сумму на покупку нового, когда можно просто воспользоваться
        услугами профессионального ремонта, доверить работу грамотным людям, и
        получить результат в виде нормально работающего аппарата быстро и
        недорого.
      </>
    ),
  },
  {
    id: 2,
    title: "Мастерская по замене динамика iPhone",
    text: (
      <>
        Если вы столкнулись с поломкой такой техники, и предполагаете, что все
        проблемы происходят именно из-за динамика, не стоит переживать, равно
        как и не стоит мириться с тем, что телефон стал «глухим», или же пропала
        возможность прослушивания музыки. Специалисты сервисного центра Мак
        Профи готовы оказать вам помощь в такой ситуации, и выполнить ремонт,
        произвести замену динамика, чтобы вы вновь смогли наслаждаться
        эксплуатацией девайса. Мы выполняем ремонт, как в штатном режиме, так и
        в срочном порядке, при необходимости замена может быть осуществлена в
        считанные часы. Мы уважаем своих клиентов и не вынуждаем их подолгу
        ждать результата, однако даже при выполнении самых срочных работ мы не
        забываем о качестве и полноценно его обеспечиваем. Все работы
        выполняются с гарантией на долгий срок, а компания работает официальным
        образом, договор и прочие документы заключаются в любом случае.<br></br>
        Успех в работах и высочайшее качество их выполнения складываются из
        множества факторов. Так, все работы у нас проводятся в профессиональных
        условиях, с обеспечением современного оснащения рабочих мест. У мастеров
        под рукой есть все необходимое для ремонта оборудование, оно позволяет
        осуществлять буквально ювелирные работы по пайке и прочие манипуляции.
        Также под рукой у мастеров всегда имеются запчасти – качественные,
        оригинального заводского производства, и мы даем на них гарантию.
        Наличие всех нужных деталей ускоряет процесс выполнения ремонтных работ.
        <br></br>К работам у нас допускаются только проверенные, грамотные,
        квалифицированные специалисты, которые знают об Айфонах все – это еще
        одно важное слагаемое успеха. Обеспечивая внимание ко всем деталям, они
        уверенно справляются с ремонтом любой сложности, и им можно довериться.
        Мы приложим все усилия для того, чтобы выполнить ремонт полноценно и
        качественно, уложиться в самые сжатые сроки, и обеспечить результат
        навсегда. Наша компания имеет прекрасную репутацию, и нас охотно
        рекомендуют из-за качественного подхода. А еще мы придерживаемся
        политики разумных цен, ремонт будет выполнен без переплат. Обращайтесь к
        нам, и ваш Айфон снова отлично заработает!
      </>
    ),
  },
];

const optionsSpeaker = [
  { value: "iPhone 4", label: "iPhone 4", price: "2400" },
  { value: "iPhone 4s", label: "iPhone 4s", price: "1150" },
  { value: "iPhone 5", label: "iPhone 5", price: "2450" },
  { value: "iPhone 5c", label: "iPhone 5c", price: "2450" },
  { value: "iPhone 5s", label: "iPhone 5s", price: "2450" },
  { value: "iPhone 6", label: "iPhone 6", price: "2490" },
  { value: "iPhone 6s", label: "iPhone 6s", price: "3500" },
  { value: "iPhone 6 Plus", label: "iPhone 6 Plus", price: "3000" },
  { value: "iPhone 6s Plus", label: "iPhone 6s Plus", price: "3700" },
  { value: "iPhone 7", label: "iPhone 7", price: "3800" },
  { value: "iPhone 7 Plus", label: "iPhone 7 Plus", price: "1111" },
  { value: "iPhone 8", label: "iPhone 8", price: "2222" },
  { value: "iPhone 8 Plus", label: "iPhone 8 Plus", price: "3333" },
  { value: "iPhone X", label: "iPhone X", price: "4444" },
  { value: "iPhone XS", label: "iPhone XS", price: "5555" },
  { value: "iPhone XS Max", label: "iPhone XS Max", price: "6666" },
  { value: "iPhone 11", label: "iPhone 11", price: "7777" },
  { value: "iPhone 11 Pro", label: "iPhone 11 Pro", price: "8888" },
  { value: "iPhone 11 Pro Max", label: "iPhone 11 Pro Max", price: "9999" },
  { value: "iPhone SE 2020", label: "iPhone SE 2020", price: "0000" },
  { value: "iPhone 12", label: "iPhone 12", price: "10000" },
  { value: "iPhone 12 mini", label: "iPhone 12 mini", price: "11000" },
  { value: "iPhone 12 Pro", label: "iPhone 12 Pro", price: "12000" },
  { value: "iPhone 12 Pro Max", label: "iPhone 12 Pro Max", price: "13000" },
  { value: "iPhone 13", label: "iPhone 13", price: "14000" },
  { value: "iPhone 13 mini", label: "iPhone 13 mini", price: "15000" },
  { value: "iPhone 13 Pro", label: "iPhone 13 Pro", price: "16000" },
  { value: "iPhone 13 Pro Max", label: "iPhone 13 Pro Max", price: "17000" },
];

const RepairIphoneServiceLinkSpeaker = () => {
  return (
    <Page title="Не работает динамик на iPhone? Замена динамика iPhone в Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена динамика iPhone"
          text="Возникли проблемы с функционированием Айфона? Появились проблемы со звуком, его воспроизведением и передачей? В этом случае можно судить о том, что требуется замена динамика iPhone. Практика показывает, что такая проблема является одной из самых распространенных, и при грамотном подходе к вопросу она легко устраняется. Главное, что необходимо сделать для ее устранения – это обратиться к грамотным профессионалам, а не пытаться решить проблему своими силами, или же путем привлечения некомпетентных лиц – такой подход обычно заканчивается необходимостью менять дорогостоящую технику, которую вполне можно было бы вернуть в рабочее состояние малыми затратами.

          Среди жалоб, о которых приходится слышать при возникновении проблем с динамиком:"
        />
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {listOfSpeaker.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
            <p className="repair-mb__group-links-paragraph">
              Стоит знать, что данные девайсы имеют отдельный динамик для
              звуковоспроизведения при прослушивании музыки, и отдельный – для
              того, чтобы передавать речь при разговоре по телефону, и
              пострадать может любой из них. Причин для поломки может быть
              значительное число, начиная от попадания жидкости в динамик, и
              заканчивая механическим износом, повреждением при неаккуратном
              обращении либо падении, и так далее. Ремонт при таких проблемах
              осуществляется обычно путем замены пострадавшего элемента. Однако
              в первую очередь все равно необходимо продиагностировать
              устройство, чтобы убедиться, что проблемы возникли именно из-за
              динамика и его повреждения.
            </p>
          </div>
        </Section>
        <Section
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "75px 0 75px",
          }}
        >
          <div className="repair-mb__group-list-container">
            <SelectForIphoneServiceLinks
              options={optionsSpeaker}
              text="дисплея"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {textOfSpeaker.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeaker;
