import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera5c.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera5c = [
  {
    id: 1,
    title: "Когда нужна замена камеры iPhone 5с?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Даже, если вы достаточно редко пользуетесь камерой, стоит позаботиться
          о её своевременной замене. В-первых, рано или поздно возникнет момент,
          когда понадобиться сделать снимок, и будет очень обидно, если не будет
          этой возможности. А во-вторых, неисправность одного элемента, может
          отразиться на функциональных иных частей системы. Поэтому, если вы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Пролили на смартфон воду, попали под дождь или брали аппарат
              мокрыми руками;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Уронили, придавили, сильно ударили или же сели на устройство;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Устанавливали новые приложения, игры и другое программное
              обеспечение;
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Стоит как можно скорее поспешить в специализированный сервисный центр.
          Большинство поломок происходят по вине владельца, но, как правило, они
          легко устранимы. Не стоит усугублять ситуацию, откладывая ремонт на
          длительный срок – в результате, он обойдется гораздо дороже, чем
          потребовалось бы заплатить изначально.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена камеры iPhone 5с в Мак Профи – гарантируем качество",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы гарантируем качественное выполнение поставленной задачи, ведь вы
          можете самостоятельно проследить за процессом, контролируя каждый
          этап. При отсутствии сложных повреждений замена камеры iPhone 5с
          занимает примерно 20-30 минут – это время включает в себя и
          диагностику устройства. У нас работают только квалифицированные
          сотрудники, которые устранят поломку быстро и аккуратно – и на
          поверхности корпуса не останется ни малейших следов.
        </p>
        <p className="repair-iphone-display__paragraph">
          Качество используемых запчастей неоднократно проверено нашими
          мастерами на практике. Мы успешно восстановили сотни различных
          смартфонов и получили множество положительных отзывов о выполненной
          работе. Поэтому, если вы заинтересованы в том, чтобы Айфон стабильно
          работал, а фотографии вновь были красочными и четкими – не
          откладывайте посещение сервисного центра Мак Профи.
        </p>
        <p className="repair-iphone-display__paragraph">
          Расценки на все услуги представлены на сайте – мы регулярно обновляем
          данные, чтобы предоставлять клиентам актуальную и достоверную
          информацию. Если у вас появятся дополнительные вопросы, свяжитесь с
          нами по контактным телефонам – и менеджеры проведут подробную
          консультацию.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels5c = () => {
  return (
    <Page title="Замена камеры iPhone 5с срочно с гарантией в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 5с"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 5с в Мак Профи: профессиональный сервис,
                современное оборудование. Гарантия качества на все работы,
                оригинальные комплектующие в наличии.
              </p>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 5с актуальна в том случае, если качество
                фотографий значительно ухудшилось, перестала работать вспышка
                или же устройство перезагружается при попытке сделать снимок.
                Обычно такие симптомы появляются после того, как устройство
                получает сильные повреждения или же оказывается залито
                жидкостью. Если вас не устраивает работа смартфона, посетите
                сервис Мак Профи – поменяем неисправные запчасти на выгодных
                условиях.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1400 P"
          priceDown="1300 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera5c.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels5c;
