import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone5.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophone5 = [
  {
    id: 1,
    title: "Замена микрофона на вашем iPhone 5 в сервисном центре Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Однако если поломка уже произошла, необходимо принять все меры для ее
          устранения. Для этого стоит обратиться к грамотным специалистам,
          действительно способным справляться с такими задачами. Сервисный центр
          Мак Профи специализируется на ремонте современных высокотехнологичных
          устройств, и многолетний опыт позволяет нам уверенно справляться с
          задачами любой сложности, замена динамика не станет для нас проблемой.
          За годы работы мы получили грандиозный опыт и положительную репутацию,
          массу благодарственных отзывов, что является наглядным доказательством
          качества нашего труда.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас созданы профессиональные условия и имеется все оборудование,
          позволяющее работать эффективно и качественно, исключая любые риски.
          Даже пайка может выполняться с ювелирной точностью. Также в наличии
          всегда имеются запчасти в полном ассортименте, включая и динамики –
          вам не придется долго ждать появления деталей, а ремонт может быть
          реализован и срочно, и штатно. На запчасти дается отдельная гарантия,
          все они имеют заводское официальное происхождение и отличаются
          качеством.
        </p>
        <p className="repair-iphone-display__paragraph">
          Выполнением работ занимаются только компетентные специалисты, для
          которых нет невыполнимых задач. Мы допускаем к работе только
          образованных и опытных людей, которые знают об Айфонах все.
        </p>
        <p className="repair-iphone-display__paragraph">
          Тем, как начать работу, мы выполним бесплатную и быструю диагностику –
          это займет всего несколько минут. После проведения такой процедуры вы
          сможете узнать стоимость ремонта и все прочие аспекты, которые вас
          заинтересуют, задать мастеру любые вопросы. Если все условия вас
          устроят – мы немедленно приступим к работе, и уже вскоре ваш гаджет
          будет в норме.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels5 = () => {
  return (
    <Page title="Замена микрофона iPhone 5 в Москве - низкие цены">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone 5"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с микрофоном – это одно из довольно типичных явлений,
                характерных для множества Айфонов. Это одна из тех поломок, с
                которыми приходится сталкиваться довольно часто. Выражается она
                в том, что собеседник просто не может вас слышать – в этом
                случае можно говорить об отказе микрофона практически с полной
                уверенностью. В других случаях могут быть проблемы слышимости в
                виде посторонних шумов и прочих нежелательных явлений, слишком
                тихого звука.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если вы столкнулись с подобными проблемами, это значит, что
                вашему Айфону необходим профессиональный ремонт – дилетантский
                подход в таких ситуациях не помогает. И даже более того,
                попробовав починить тонкую и чувствительную современную технику
                своими силами, вы рискуете усугубить ситуацию, что приведет к
                необходимости еще более сложного и дорогостоящего ремонта, либо
                окажется, что технику нужно менять по причине того, что она не
                восстановима. Существует целый ряд причин, из-за которых может
                стать необходимой замена микрофона iPhone 5, и в частности, этот
                уязвимый элемент может:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Пострадать от удара, при падении гаджета,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Окислиться, сломаться от попадания воды,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Оказаться изношенным, выработать свой ресурс.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Однако во всех случаях техника остается вполне ремонтопригодной,
                и для того, чтобы восстановить ее нормальную работоспособность,
                необходимо просто произвести замену микрофона. Профессиональное
                и качественное выполнение таких работ позволит вам еще долго
                пользоваться устройством, а для того, чтобы не столкнуться с
                этой или другими проблемами вновь, вам будет достаточно знать
                несколько важных правил обращения. Так, в частности, необходимо
                пользоваться хорошими защитными чехлами для того, чтобы
                минимизировать риск технических неполадок и повреждений. Следует
                стараться аккуратно обращаться с устройством, не допуская его
                ударов и падений. Эти простые общие правила позволят
                эксплуатировать приборчик максимально долго.
              </p>
            </>
          }
        />
        <RepairApplePrice text="Замена микрофона" price="1300 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels5;
