import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speakerx.png";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfSpeakerX = [
  {
    id: 1,
    title: "Что можно предпринять самостоятельно до замены динамика iPhone X",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Иногда динамик начинает работать с хрипами и шипением, особенно на
          низких частотах или при повышении звука, и этот случай определенно
          говорит о необходимости замены, своими силами вы едва ли сможете
          что-то сделать. Бывает и другая проблема – звук просто исчезает, и вы
          либо не слышите абонента при разговоре, либо не можете слышать музыку,
          звуки при видео, звонки, и так далее. В этом случае стоит проверить
          настройки, ведь может быть, вы случайно сбили звук. Однако если
          никакие манипуляции так и не принесли желаемого результата, и тем
          более, если вы знаете о том, что Айфон до появления проблемы падал,
          или с ним происходило еще что-либо, необходимо безотлагательно
          отправляться в мастерскую за помощью.
        </p>
        <p className="repair-iphone-display__paragraph">
          Стоит отказаться от попыток самостоятельно вскрыть устройство или
          выполнить замену – современные высокотехнологичные приборы требуют к
          себе особого отношения, и даже их разборка требует обеспечения особых
          условий. А кроме того, найти официальные запчасти на замену с
          гарантией тоже бывает вовсе не просто. Зато профессиональный подход
          позволит вам получить гарантированный результат за считанные часы, а
          порой и за минуты, и продолжить уверенное и безотказное использование
          любимого девайса.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModelsX = () => {
  return (
    <Page title="Замена динамика iPhone X дешево с гарантией за 1 час">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена динамика iPhone X"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Айфоны полюбились многим ценителям фирменных высокотехнологичных
                продуктов – наряду с широким спектром возможностей они могут
                порадовать достойными показателями качества, способностью к
                длительной службе. Эта техника действительно редко выходит из
                строя, однако такое все же периодически случается. Как и любые
                другие устройства, это оборудование имеет собственные уязвимые
                места, и при эксплуатации нередко страдает динамик – ведь корпус
                защищает его не слишком сильно, обеспечивая свободное
                распространение звуковых волн в окружающую среду. Существует
                целый ряд причин, приводящих к поломке динамика, и они могут
                иметь как внутренний, так и внешний характер. Как правило,
                проблемы возникают после:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Падения или удара устройства,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадания жидкости внутрь, утопления, соприкосновения с
                    влагой,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Механических повреждений, заводского брака, который хоть
                    весьма редко, но все же случается.{" "}
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                А еще замена динамика iPhone X может потребоваться в ситуации,
                когда человек имеет привычку подолгу слушать музыку на всю
                громкость – это тоже может привнести свою лепту в ускоренный
                износ. Как бы то ни было, но подобные проблемы случаются, и если
                неприятность уже произошла, следует принять определенные меры.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeakerX.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairAppleDoublePrice
          title="Лучше делать замену динамика iPhone X в сервисном центре Мак Профи"
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="3200 P"
          priceDown="3400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вы столкнулись с симптомами отказа динамика, или
                предполагаете другие проблемы устройства, обращайтесь к
                проверенным профессионалам – в сервисный центр Мак Профи,
                который уже много лет занимается профессиональным ремонтом
                подобного оборудования. Мы специализируемся именно на
                современной аппаратуре и высоких технологиях, и в штате
                мастерской можно увидеть только опытных и компетентных
                работников, которые знают буквально все об особенностях такого
                оборудования.{" "}
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Перед ремонтом мы проводим бесплатную диагностику, которая
                позволяет выявить все проблемы, и далее, при согласии клиента,
                заключается договор и выполняется полный спектр необходимых
                работ. Мы даем гарантию, как на услуги ремонта, так и на детали,
                обеспечиваем внимание к каждой мелочи, и в результате вы сможете
                пользоваться отремонтированной техникой еще много лет.
                Обращайтесь к нам, чтобы получить быстрый и качественный ремонт
                по умеренным ценам! Мы реализуем задачи любой сложности, поможем
                в любой ситуации!
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModelsX;
