import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleLinksText,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  SelectForIphoneServiceLinks,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/repair-ipadSim.jpeg";

const listOfTrouble = [
  {
    id: 1,
    text: 'Ресет. Как бы мы не смеялись над извечным советом всех служб поддержки и ремонтников "перезагрузить" устройство, это действительно помогает!Нажмите одновременно и удерживайте некоторое время кнопки Home и Power. Дождитесь появления фирменного "яблочного" логотипа.',
    number: "01",
  },
  {
    id: 2,
    text: 'Вы перегрузились, а ваш iPad всё ещё наотрез отказывается видеть сим карту? Тогда следующий шаг - обновление. Apple предлагает обновление iOS как панацею почти от любых бед своих устройств. Но, опять же таки, чаще всего это действительно помогает. Зайдите в "Настройки", выберете вкладку "Основные" и нажмите "Обновление ПО". Таким образом вы накатите последнюю версию iOS, в которой, возможно, исчезнут и ваши проблемы с сим картой.',
    number: "02",
  },
  {
    id: 3,
    text: 'Также может помочь сброс настроек сети: в той же вкладке "Основные" из "Настроек" найдите "Сброс" и тапните "Сбросить настройки сети".',
    number: "03",
  },
];

const listOfDecisions = [
  {
    id: 1,
    text: "Возможно iPad не видит сим карты из-за самой симки. Вы могли как приобрести бракованную sim-карту, так и повредить её при установке. Проверить симку можно простым способом - вставить другую.",
    number: "01",
  },
  {
    id: 2,
    text: "Если вы убедились, что дело не в sim-карту, следующий возможный претендент на источник проблем GSM-модуль.",
    number: "02",
  },
  {
    id: 3,
    text: 'Также может быть повреждён шлейф соединяющий модуль с материнской платой. Так как внутри iPad этот шлейф находится в натянутом состоянии, велика вероятность его разрыва при падении. Поэтому, если вы недавно роняли ваш планшет, и после того столкнулись с проблемой, то именно шлейф - ваш основной "подозреваемый".',
    number: "03",
  },
];

const textServiceLinkSim = [
  {
    id: 1,
    title: "Причины по которым iPad может не видеть сим карты",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp='Все современные "умные" электронные устройства имеют две самые главные составляющие - программную и аппаратную часть, и iPad здесь не исключение. Соотвественно проблема, почему iPad не видит сим карты, может иметь или программное, или аппаратное происхождение.'
          paragraphUp2='Разберёмся с начала с возможными программными ошибками. Есть ряд простых манипуляций, которыми обычный "смертный" может решить большинство проблем iPad, в том числе, и с сим картой:'
          array={listOfTrouble}
        />
      </>
    ),
  },
  {
    id: 2,
    title:
      "Теперь передам к возможным аппаратным источником проблем и путям их решения:",
    text: (
      <>
        <RepairAppleTextWithCheckMark array={listOfDecisions} />
      </>
    ),
  },
];

const optionsIpadSim = [
  { value: "iPad", label: "iPad", price: "2400" },
  { value: "iPad 2", label: "iPad 2", price: "1900" },
  { value: "iPad 3", label: "iPad 3", price: "1600" },
  { value: "iPad 4", label: "iPad 4", price: "1900" },
  { value: "iPad mini", label: "iPad mini", price: "1900" },
  { value: "iPad Air", label: "iPad Air", price: "1900" },
  { value: "iPad mini 2", label: "iPad mini 2", price: "2350" },
  { value: "iPad Air 2", label: "iPad Air 2", price: "2350" },
  { value: "iPad mini 3", label: "iPad mini 3", price: "2500" },
  { value: "iPad Pro 12.9", label: "iPad Pro 12.9", price: "1900" },
  { value: "iPad mini 4", label: "iPad mini 4", price: "2080" },
  { value: "iPad Pro 9.7", label: "iPad Pro 9.7", price: "4450" },
  { value: "iPad 5", label: "iPad 5", price: "1900" },
  { value: "iPad Pro 2 10.5", label: "iPad Pro 2 10.5", price: "1900" },
  { value: "iPad Pro 2 12.9", label: "iPad Pro 2 12.9", price: "1900" },
  { value: "iPad 6 (2018)", label: "iPad 6 (2018)", price: "1900" },
  { value: "iPad Pro 11 (2018)", label: "iPad Pro 11 (2018)", price: "1900" },
  {
    value: "iPad Pro 12.9 (2018)",
    label: "iPad Pro 12.9 (2018)",
    price: "1900",
  },
  { value: "iPad Air 3 (2019)", label: "iPad Air 3 (2019)", price: "1600" },
  { value: "iPad mini 5", label: "iPad mini 5", price: "1900" },
  { value: "iPad 7", label: "iPad 7", price: "1900" },
  { value: "iPad 8", label: "iPad 8", price: "1900" },
  { value: "iPad Air 4 (2020)", label: "iPad Air 4 (2020)", price: "1900" },
  { value: "iPad Pro 11 (2021)", label: "iPad Pro 11 (2021)", price: "1900" },
  {
    value: "iPad Pro 12.9 (2021)",
    label: "iPad Pro 12.9 (2021)",
    price: "1900",
  },
  { value: "iPad 9", label: "iPad 9", price: "1900" },
  { value: "iPad mini 6", label: "iPad mini 6", price: "1900" },
];

const RepairIpadServiceLinkSim = () => {
  return (
    <Page title="iPad не видит сим карты, причина поломки, сроки ремонта">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="iPad не видит сим карты"
          text="Ваш Айпад не видит вставленной сим карты? Произведем восстановление модема, замену сим лотка, достанем застрявшую сим карту, а также поможем решить други проблемы с Айпад который не видит сим карту."
        />
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 105px",
            marginTop: 55,
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Айпад не видит сим карты стоимость восстановления
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsIpadSim}
              text="сим-держателя"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textServiceLinkSim.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkSim;
