import React from "react";
import Section from "../Section/Section";

const RepairAppleLinksBanner = ({ banner, title, text }) => {
  return (
    <Section className="section_group">
      <div className="repair-mb__group-banner-container">
        <img style={{ maxWidth: 993, width: "100%" }} src={banner} />
        {title ? (
          <h1 className="repair-mb__group-links-title">{title}</h1>
        ) : null}
        <div className="repair-mb__group-links-border"></div>
        {text ? (
          <div className="repair-mb__group-links-text">{text}</div>
        ) : null}
      </div>
    </Section>
  );
};

export default RepairAppleLinksBanner;
