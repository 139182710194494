import React from "react";
import Section from "../Section/Section";
import iPadBanner from "../../images/banners/repaip-iphone-banner.svg";

const RepairIpadBanner = () => {
  return (
    <Section>
      <img className="repair-ipad__banner" src={iPadBanner} alt="баннер" />
    </Section>
  );
};

export default RepairIpadBanner;
