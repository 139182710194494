import React from "react";
import Section from "../../Section/Section";
import { Carousel } from "react-responsive-carousel";
import styles from "./CenterMobileIRepairMobilePriceMobile.module.css";

function CenterMobileIRepairMobilePriceMobile({
  samsungMobilePrice,
  samsungMobilePrice2,
}) {
  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.title} id="pricelist">
          ВСЕ ЦЕНЫ
        </h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
              <p className={styles.textService}>Услуги</p>
              <div className={styles.headerTextWrapper}>
                <p className={styles.textPrice}>Цена</p>
                <p className={styles.textTime}>Время ремонта</p>
              </div>
            </div>
            <h4 className={styles.subtitle}>Ремонт и замена деталей</h4>
            <ul className={styles.list}>
              {samsungMobilePrice.map((item, i) => {
                return (
                  <li
                    className={styles.item}
                    key={i}
                    style={{
                      background: item.color,
                      boxShadow: item.boxShadow,
                    }}
                  >
                    <p className={styles.itemText}>{item.text}</p>
                    <div className={styles.itemWrapper}>
                      <p className={styles.itemPrice}>{item.price}</p>
                      <p className={styles.itemTime}>{item.time}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
              <p className={styles.textService}>Услуги</p>
              <div className={styles.headerTextWrapper}>
                <p className={styles.textPrice}>Цена</p>
                <p className={styles.textTime}>Время ремонта</p>
              </div>
            </div>
            <h4 className={styles.subtitle}>Профилактика и чистка</h4>
            <ul className={styles.list}>
              {samsungMobilePrice2.map((item, i) => {
                return (
                  <li
                    className={styles.item}
                    key={i}
                    style={{
                      background: item.color,
                      boxShadow: item.boxShadow,
                    }}
                  >
                    <p className={styles.itemText}>{item.text}</p>
                    <div className={styles.itemWrapper}>
                      <p className={styles.itemPrice}>{item.price}</p>
                      <p className={styles.itemTime}>{item.time}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobilePriceMobile;
