import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone4s.jpeg";

const textOfModel4s = [
  {
    id: 1,
    title: "Порядок ремонта iPhone 4S в нашем сервисном центре",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          После того, как вы пришли в нас центр или же ваш телефон привёз
          курьер, за дело берутся наши специалисты: телефон проходит полную и
          исчерпывающую диагностику. Причём данная услуга у нас бесплатна. Далее
          мы оговариваем с вами тип и стоимость ремонта. После того как ремонт
          сделан, вы получаете ваш Айфон 4с назад и можете убедится в его
          работоспособности, и уже только после этого производится оплата. Ещё
          раз подчеркнём - у нас нет никакой предоплаты, тип и стоимость
          ремонтных работ заранее оговариваются с вами.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады помочь вам в ремонте iPhone 4S, вернув ему прежний вид или
          функциональность!
        </p>
      </>
    ),
  },
];

const price4s = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage4s = () => {
  return (
    <Page title="Цены на ремонт iPhone 4S в сервисном центре в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPhone 4S"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ремонт iPhone 4S - казалось бы, зачем? Повальное поветрие нашего
                времени менять телефоны буквально каждый год, в погоне за всё
                новыми моделями и новыми характеристиками. Так зачем
                ремонтировать Айфон 4с, со времён выхода на рынок которого уже
                сменилось три поколения "яблочных" телефонов? Помимо прозаичных
                финансовых соображений, на то могут быть и более важные причины.
                iPhone 4S - это последний телефон который был создан при
                непосредственном участии Стива Джобса. Его дизайн еще
                непосредственно воплощает тот легко узнаваемый шарм, который был
                присущ продуктам Стива. Который, к сожалению, стал утрачивается
                в последующих айфонах: с каждой новой моделью их все труднее
                отличить от продукции конкурентов.
              </p>
              <p className="repair-iphone-display__paragraph">
                Ностальгия ли по Стиву Джобсу, нежелание расставаться с
                полюбившимся телефоном, или банальный недостаток средств на
                покупку нового - чтобы вами не двигало, однако, раз вы на нашем
                сайте, значит с вашим iPhone 4S приключилась неприятность. Наши
                специалисты ремонтировали Айфоны 4с буквально с моменты их
                появления на российском рынке и с блеском справляются с этой
                задачей и сегодня. На все произведённые работы, а также на новые
                детали, в наших центрах мы выдаём гарантию сроком до двенадцати
                месяцев. Ремонт производится оперативно - большинство поломок и
                неисправностей чинится менее получаса. Офисы расположены в
                центрах Москвы, а при необходимости вы можете воспользоваться
                услугами нашего курьера.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price4s}
            title="Прайс лист на ремонт iPhone 4S"
            text="* Цены указаны в рублях РФ. Стоимость включает комплектующее и услугу по установки."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel4s.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage4s;
