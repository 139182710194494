import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIphoneModelLinksForServiceMobile,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker6s.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const RepairIphoneServiceLinkSpeakerModels6s = () => {
  return (
    <Page title="Грамотная замена динамика iPhone 6s в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена динамика iPhone 6s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с динамиком Айфона распространены довольно широко – это
                одна из наиболее распространенных поломок, с которой часто
                обращаются в сервисные центры. Иногда отказ динамика, или его
                проблемная работа, которая сопровождается шумами, неприятными
                фонами при прослушивании музыки, позволяет первое время
                оставаться равнодушным к проблеме. Однако решать ее в любом
                случае стоит как можно скорее, и это касается как полного отказа
                техники, так и тех проблем со звучанием, которые носят только
                частичный характер.
              </p>
              <p className="repair-iphone-display__paragraph">
                В любом случае необходимо принять все меры для возвращения
                оборудования в норму, хотя оно может не только отказать, он
                также и воспроизводить музыку или разговор с собеседником на
                неудовлетворительном уровне. Многих людей интересует, почему же
                вообще может возникнуть такая поломка, однако на деле ситуаций
                для подобного происшествия может появиться немало. Среди таковых
                стоит отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Падение устройства, его механическое повреждение,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадание влаги, воды,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Износ, нелепая случайность.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Существует немало причин, из-за которых может потребоваться
                замена динамика iPhone 6s, ведь в большинстве случаев подобная
                операция позволяет полностью ликвидировать поломку. Однако в
                любом случае не стоит пробовать выполнение такой задачи своими
                силами, поскольку даже сам процесс разборки такой техники
                требует особых условий. Этот фактор, а кроме того,
                профессиональный подход становятся гарантами успеха, в противном
                же случае ремонт нередко оборачивается дополнительными
                проблемами, или даже вовсе необходимостью замены девайса на
                новый. Нет желания сталкиваться с такими вещами, и хочется
                добиться результата без лишних проблем? В таком случае стоит
                пользоваться именно услугами мастерской и компетентных
                исполнителей.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          title="Стоимость замены динамика на Айфон 6s"
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1800 P"
          priceDown="1800 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если у вас возникли проблемы с Айфоном, не стоит переживать.
                Специалисты сервисного центра Мак Профи готовы выполнить любые
                услуги по ремонту такого оборудования, включая и ремонт, замену
                его динамика. Многолетний опыт работы позволяет нам работать
                оперативно и безошибочно, компания работает официально, с
                предоставлением всех гарантий и заключением договора, и
                свидетельствовать о качестве выполнения работ могут
                многочисленные положительные отзывы. Мы имеем прекрасную
                репутацию, всячески поддерживаем и сохраняем ее, ежедневно
                выполняя свою работу на высочайшем уровне.
              </p>
              <p className="repair-iphone-display__paragraph">
                Наша мастерская оснащена по последнему слову техники, здесь есть
                все необходимое для выполнения ремонтных работ на высочайшем
                уровне. Специально оборудованные рабочие места позволяют
                выполнять работы не только быстро, но и качественно. Все
                необходимые запчасти и детали также всегда есть в наличии, что
                существенно ускоряет процесс выполнения работ и позволяет
                реализовывать их даже в экспресс-режиме. Все запчасти имеют
                официальное фирменное происхождение, и мы даем на них гарантию,
                как и на сами работы. Выполнением всех поставленных задач
                занимаются только компетентные исполнители, работами занимаются
                только опытные и грамотные специалисты с солидным стажем работы,
                и это тоже является одной из составляющих нашего успеха.
                Обращаясь к нам, вы передаете технику в исключительно надежные
                руки.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Первым этапом работ становится диагностика, которая проводится
                бесплатно и оперативно. После ее проведения, которое отнимает
                порой только минуты, становится понятной причина поломки и
                стоимость ремонта, и если наши условия вас устроят, можно будет
                немедленно начинать ремонт. Обращайтесь к нам, чтобы получить
                отличный результат и еще долго пользоваться любимой техникой!
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels6s;
