import react from "react";
import styles from "./InsuranceClients.module.css";

const InsuranceClients = () => {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <h1 className={styles.title}>Клиентам страховых компаний</h1>
        <h4 className={styles.subtitle}>
          Добро пожаловать в наш сервисный центр!
        </h4>
        <h4 className={styles.subtitle_two}>
          Если вы являетесь клиентом страховой компании и ищете надежного
          партнера для ремонта вашего смартфона, планшета, ноутбука или
          моноблока, вы попали по адресу!
        </h4>
        <h2 className={styles.title}>Наши услуги</h2>
        <ul className={styles.list}>
          <li className={styles.text}>
            Профессиональный ремонт: Наши специалисты обладают глубокими
            знаниями и многолетним опытом в ремонте электронных устройств. Мы
            гарантируем качественное обслуживание и быстрое восстановление
            вашего устройства.
          </li>
          <li className={styles.text}>
            Полное сопровождение страхового случая: Мы берем на себя всю
            необходимую документацию и коммуникацию с вашей страховой компанией,
            чтобы упростить процесс ремонта для вас.
          </li>
          <li className={styles.text}>
            Оригинальные запчасти: Мы используем только оригинальные запчасти
            для гарантии долговечности и надежности ремонта.
          </li>
          <li className={styles.text}>
            Гарантия на ремонт: На все выполненные работы предоставляется
            гарантия.
          </li>
        </ul>
        <h2 className={styles.title}>Как это работает:</h2>
        <ul className={styles.list_with_number}>
          <li className={styles.item}>
            <label className={styles.number}>01</label>
            <p className={styles.paragraph}>
              Подача документов через личный кабинет: Вы подаете все необходимые
              документы для страховой компании через личный кабинет на нашем
              сайте.
            </p>
          </li>
          <li className={styles.item}>
            <label className={styles.number}>02</label>
            <p className={styles.paragraph}>
              Проверка документов и организация доставки: Наш сотрудник
              проверяет документы на полноту. Если комплект документов полный,
              мы отправляем к вам курьера для забора устройства.
            </p>
          </li>
          <li className={styles.item}>
            <label className={styles.number}>03</label>
            <p className={styles.paragraph}>
              Доставка устройства в Москву: Независимо от вашего местоположения
              в России, наша курьерская служба безопасно доставит ваше
              устройство в наш сервисный центр в Москве.
            </p>
          </li>
          <li className={styles.item}>
            <label className={styles.number}>04</label>
            <p className={styles.paragraph}>
              Диагностика и передача документов в страховую компанию: После
              получения устройства мы проводим диагностику и отправляем
              документы в вашу страховую компанию для рассмотрения страхового
              случая.
            </p>
          </li>
          <li className={styles.item}>
            <label className={styles.number}>05</label>
            <p className={styles.paragraph}>
              Решение страховой компании: Страховая компания рассматривает
              страховой случай и отправляет нам свое решение.
            </p>
          </li>
          <li className={styles.item}>
            <label className={styles.number}>06</label>
            <p className={styles.paragraph}>
              Ремонт или возврат устройства: После получения решения от
              страховой компании, мы либо выполняем ремонт вашего устройства,
              либо, в случае отказа страховой компании, отправляем устройство
              обратно вам. После завершения ремонта, мы также отправляем
              устройство обратно к вам надежным и безопасным способом.
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.footer_container}>
        <div className={styles.border}></div>
        <p className={styles.footer_text}>
          Мы гарантируем высокое качество обслуживания и безопасность на каждом
          этапе работы с вашим устройством, от начала до конца.
        </p>
      </div>
    </section>
  );
};

export default InsuranceClients;
