import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadMini2.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";

const listOfTrouble = [
  {
    id: 1,
    text: "Тестирование гаджета для выявления повреждений ",
    number: "01",
  },
  {
    id: 2,
    text: "Разбор устройства с помощью фирменных инструментов",
    number: "02",
  },
  {
    id: 3,
    text: "Установка нового корпуса",
    number: "03",
  },
  {
    id: 4,
    text: "Проверка планшета и демонстрация владельцу его работы.",
    number: "04",
  },
];

const textOfCaseMini2 = [
  {
    id: 1,
    title: <>Этапы замены корпуса iPad&nbsp;Mini&nbsp;2</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чаще всего к нам обращаются из-за повреждений планшета, возникших в результате механического воздействия. Нередко требуется комплексный ремонт, который включает в себя установку не только нового корпуса, но и замену испорченного стекла. Из-за удара могут перестать работать кнопки, либо же возникнут проблемы с аккумулятором, а потому проведение диагностики является обязательным."
          paragraphUp2="После вашего прибытия в сервисный центр, будут проведены следующие работы:"
          array={listOfTrouble}
          paragraphDown="При наличии в сервисе свободных мастеров, замена корпуса iPad Mini 2 занимает 20-30 минут, включая предварительную диагностику. Все запчасти уже в наличии, а потому вам не придется неделями ожидать ближайшей поставки, лишившись устройства. Мы выполним ремонт в присутствии владельца – и уже в день обращения вы сможете забрать восстановленный планшет."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Можно ли заменить корпус iPad Mini 2 самостоятельно?",
    text: "Важно понимать, что самостоятельный ремонт обычно приводит к ухудшению ситуации. Чтобы добраться до корпуса, нужно аккуратно снять стекло, а это возможно лишь при нагревании. Отсутствие необходимого оборудования, способного обеспечить требующуюся температуру, приведет к появлению трещин на хрупком дисплее – и сумма финансовых затрат многократно возрастет.",
  },
];

const RepairIpadServiceLinkCaseModelMini2 = () => {
  return (
    <Page title="Повредили корпус Айпад Мини 2? Замена корпуса iPad Mini 2 при клиенте в день обращения">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Mini&nbsp;2</>}
          text="iPad Mini 2 – надежная и удобная в работе техника, которая рассчитана на продолжительный период эксплуатации. И даже если падения нарушили нормальную функциональность планшета, нужно лишь обратиться в специализированный сервисный центр, специалисты которого помогут восстановить первоначальный вид устройства, а также устранить сопутствующие поломки."
        />
        <Section className="section_group">
          {textOfCaseMini2.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены корпуса Айпад Мини 2"
          text="Замена сенсорного стекла (тачскрина)"
          price="7950 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Ориентировочную цену вы сможете рассчитать на сайте, но точная
                стоимость будет названа только после диагностики. Тестирование
                производится абсолютно бесплатно. Мы гарантируем, что названная
                изначально цена остается неизменной до конца ремонта – и после
                завершения необходимых работ, вы заплатите именно ту сумму, на
                которую рассчитывали.
              </p>
              <p className="repair-iphone-display__paragraph">
                Обратитесь к нам для замены корпуса iPad Mini 2 – и над
                восстановлением вашего планшета будут трудиться опытные
                инженеры. Это позволяет гарантировать высокое качество
                проведенного ремонта, а также стабильную работу устройства после
                завершения работ.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelMini2;
