import React from 'react';
import { Page, MacProfiList, BreadCrumbs, RepairAppleLinksBanner, Section, RepairAppleIphonePrice, RepairIpadModelLinks, RepairIpadServiceLinks, RepairAppleLinksText } from '../index';
import banner from '../../images/banners/repair-ipad2.jpeg';

const priceIpad2 = [
  {
    id: 1,
    option: 'Замена сенсорного стекла (тачскрина)',
    price: '2550 P',
  },
  {
    id: 2,
    option: 'Замена дисплея',
    price: '3500 P',
  },
  {
    id: 3,
    option: 'Замена корпуса/задней крышки',
    price: '3500 P',
  },
  {
    id: 4,
    option: 'Замена аккумулятора',
    price: '2600 P',
  },
  {
    id: 5,
    option: 'Замена микрофона',
    price: '2350 P',
  },
  {
    id: 6,
    option: 'Замена динамика',
    price: '2450 P',
  },
  {
    id: 7,
    option: 'Замена кнопки Home (домой)',
    price: '1450 P',
  },
  {
    id: 8,
    option: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1450 P',
  },
  {
    id: 9,
    option: 'Замена кнопки включения',
    price: '1700 P',
  },
  {
    id: 10,
    option: 'Замена антенны',
    price: '1700 P',
  },
  {
    id: 11,
    option: 'Ремонт Wi-Fi модуля',
    price: '4000 P',
  },
  {
    id: 12,
    option: 'Замена датчика приближения',
    price: '1700 P',
  },
  {
    id: 13,
    option: 'Замена сим-держателя',
    price: '950 P',
  },
  {
    id: 14,
    option: 'Замена основной камеры',
    price: '1900 P',
  },
  {
    id: 15,
    option: 'Замена передней камеры',
    price: '1900 P',
  },
  {
    id: 16,
    option: 'Замена разъема зарядки',
    price: '2400 P',
  },
  {
    id: 17,
    option: 'Замена разъема наушников',
    price: '2400 P',
  },
  {
    id: 18,
    option: 'Замена кнопки Home (домой)',
    price: '1450 P',
  },
]

const textOfIpad2 = [
  {
    id: 1,
    title: 'Наиболее частые поломки и виды ремонта Айпад 2',
    text: 'Самая частая причина обращения в наш сервисный центр - необходимость замены стекла или экрана iPad 2. Вторая по популярности неисправность, также связанная с механическим воздействием - нарушение геометрии корпуса, требующие его замены. Также, возможно попадание внутрь айпада пыли, песка и влаги. В первых двух случаях дело решается чаще всего банальной очисткой, после же контакта с водой потребуется более серьёзный ремонт. Впрочем - для нас и такой не проблема. Помимо того, вашему iPad 2 также может понадобится замена нижнего шлейфа, если он перестал заряжаться, или же - wi-fi модуля, если он перестал находить сеть, соотвественно.',
  },
  {
    id: 2,
    title: 'Последовательность ремонта iPad 2 в нашем центре',
    text: 
      <>
        <p className='repair-iphone-display__paragraph'>Сначала - диагностика: наши специалисты разберут ваш айпад и всесторонне изучат на момент выявления всех поломок. За данную услугу вы не платите ни копейки. После того как с вами оговорены нужные ремонтные работы и их стоимость, мы приступаем к устранению неисправностей. Ваш исправно функционирующий айпад будет в ваших руках уже минут через 20. На все работы предоставляется гарантийный талон действительный до 12 месяцев. И только когда вы получили ваш  iPad 2 назад и убедились в качестве нашего ремонта, происходит оплата.</p>
        <p className='repair-iphone-display__paragraph'>В завершение напомним: продукция Apple отличается особой технической сложностью и ремонт её следует доверять только профессионалам. Например таким - как наши сотрудники!</p>
      </>
  }
]

const RepairIpadModelPagesIpad2 = () => {
  return (
    <Page title={'Срочный ремонт iPad 2. Ремонт Айпэда 2 в Москве!'}>
      <main className='repair-iphone-group-content'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner banner={banner}
          title='Ремонт iPad 2'
          text={<>Айпад вещь удивительная и трудно заменимая: Интернет-сёрфинг, чтение электронных книг, просмотр фотографий, прочие неизбежные радости современного цифрового человека на айпаде отличаются особым комфортом и удобством. Поэтому, если ваш iPad 2 сломался - это, конечно, неприятно. Тут на помощь вам можем прийти мы. Ремонт iPad 2 в Москве - это наша специализация и наше дело. Все что вам необходимо, это прийти в один из наших офисов, или же позвонить по указанному на сайте телефону, чтобы за вашим Айпадом приехал наш курьер.</>}
        />
        <Section>
          <RepairAppleIphonePrice prices={priceIpad2} title='Стоимость ремонта iPad 2' text='* Все цены указаны в рублях. В стоимость входит комплектующее и работа.'/>
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className='section_group' style={{padding: '50px 0 65px'}}>
          {textOfIpad2.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                {...item}
              />
            )
          })}
        </Section>
      </main>
    </Page>
  )
}

export default RepairIpadModelPagesIpad2;