import React from 'react';
import { Page, MacProfiList, BreadCrumbs, RepairIpodBanner, RepairIpodTitle, RepairIpodText, RepairIpodModelLinks, RepairIpodTable } from '../index';

const RepairIpodPage = () => {
  return (
    <main className='repair-ipod__page'>
      <Page title='Ремонт iPod в Москве'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairIpodBanner />
        <RepairIpodTitle />
        <RepairIpodText />
        <RepairIpodModelLinks />
        <RepairIpodTable />
      </Page>
    </main>
  )
}

export default RepairIpodPage;