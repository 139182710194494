import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/batteryIpadPro9.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";

const listOfRecommendations = [
  {
    id: 1,
    text: "Появление пятен на экране – возникает из-за дополнительного давления, которое создает вздувшаяся батарея.",
    number: "01",
  },
  {
    id: 2,
    text: "Выход из строя других элементов – чрезмерный перегрев устройства может привести к проблемам с материнской платой. В этом случае ремонт обойдется на порядок дороже, чем установка нового аккумулятора.",
    number: "02",
  },
  {
    id: 3,
    text: "Частные выключения планшета – непредсказуемое «поведение» электронного помощника помешает комфортной работе и создаст немало неудобств.",
    number: "03",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Как часто нужно менять аккумулятор iPad Pro 9.7",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Средний срок службы батареи составляет 2 года. Соответственно, чем
          активнее используется устройство, тем быстрее он сокращается, но это
          отнюдь не повод приобретать новый планшет, избавившись от старого.
          Достаточно одного посещения сервиса, чтобы на ближайшие годы вновь
          забыть об этой проблеме – наши специалисты выполнят замену
          аккумулятора iPad Pro 9.7, потратив на это минимум времени и вернут
          владельцу полностью исправное устройство.
        </p>
        <p className="repair-iphone-display__paragraph">
          Не стоит пытаться «спасти» батарею, используя всевозможное программное
          обеспечение или же иные сомнительные методы. На практике они
          обеспечивают лишь краткосрочный результат, но избежать замены элемента
          питания всё равно не удается.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: (
      <>Почему важно вовремя провести замену аккумулятора iPad Pro&nbsp;9.7</>
    ),
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Откладывая своевременное обращение в сервисный центр, вы подвергаете свой планшет дополнительным рискам. Это может обернуться следующими проблемами:"
          array={listOfRecommendations}
          paragraphDown="В сервисном центре мастер проведет диагностику, определит причины поломки: естественный износ источника питания или же неправильная эксплуатация, а также определит сроки и стоимость текущего ремонта. Как правило, для замены аккумулятора iPad Pro 9.7 достаточно 30 минут – за это время специалист разберет и вскроет устройство, установив новую батарею. Оплата за работу производится только после проверки работы планшета, обязательной на финальном этапе."
          paragraphDown2="Сервисный центр Мак Профи работает ежедневно – мы рады клиентам с понедельника по воскресенье. Если же в вашем графике нет свободного времени, воспользуйтесь услугой курьерской доставки, и наш сотрудник самостоятельно доставит вашу технику в сервис, после чего привезет обратно. Только лучшие условия и специальные предложения – сотрудничайте с профессионалами, чтобы получить максимум преимуществ."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelPro9 = () => {
  return (
    <Page title="Замена аккумулятора (батареи) iPad Pro 9.7 в сервисном центре в Москве">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad Pro 9.7"
          text="Ваш планшет плохо держит заряд? iPad неожиданно выключается? Приходится постоянно носить с собой зарядное устройство? Наши специалисты помогут в решении этой проблемы – в сервисном центре Мак Профи вы можете получить все необходимые услуги по честным ценам."
        />
        <RepairApplePrice text="Замена аккумулятора" price="8500 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelPro9;
