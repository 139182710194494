import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphonex.png";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfMicrophoneX = [
  {
    id: 1,
    title: "Когда точно нужна замена микрофона iPhone X",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Проблема решается довольно легко – обычно необходима замена микрофона
          iPhone X, после чего техника вновь начинает работать корректно. А
          свидетельствовать о том, что проблема возникла именно с микрофоном,
          могут такие симптомы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Абонент вас просто не слышит – не единственный, а буквально все,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              При любом звонке абоненты жалуются на перепады звучания,
              посторонние шумы или помехи на линии,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Те же проблемы возникают при попытке звукозаписи или записи видео.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          При этом стоит предварительно проверить настройки, возможно, вы
          самостоятельно отключили микрофон по случайности. Но если проблема не
          устраняется даже после перезагрузки, значит, пришло время обращаться в
          мастерскую за ремонтом.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          На практике ремонт при таких проблемах осуществляется в несколько
          этапов. В первую очередь необходимо провести диагностику, чтобы
          локализировать проблему и ее источник. Далее, при установлении
          проблемы клиент может услышать стоимость ее решения, все
          обстоятельства, и принять собственное решение касаемо согласия или
          отказа от услуг. Если условия устраивают, подписывается договор и
          мастер начинает выполнять свою работу. Если случай не осложненный,
          работы могут быть выполнены за час-другой, и долго ждать не придется,
          можно даже остаться в сервисе и наблюдать за выполнением процесса.
          Далее техника тестируется, и если все в порядке – возвращается
          владельцу вместе с гарантиями, которые касаются как услуг, так и
          детали, которая была использована на замену.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена микрофона iPhone X в любой момент",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с неисправностями устройства и нуждаетесь в
          ремонте, наши специалисты будут готовы оказать вам помощь в любое
          время. У нас вы сможете получить широкий спектр услуг разной
          направленности, и в частности, мы выполняем замену микрофонов.
          Диагностика в нашем центре проводится в экспресс-порядке, и ее
          результаты станут вам известны уже через несколько минут. Выполнение
          ремонтных работ возможно как в штатном режиме, так и срочно, все
          зависит от ваших потребностей. И в любом случае вам будет
          предоставлена длительная гарантия на все.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас работают только грамотные исполнители, которые прекрасно
          разбираются в современном высокотехнологичном оборудовании. Все задачи
          осуществляются в профессиональных условиях сервисного центра, где
          каждое из рабочих мест имеет необходимое оснащение. У нас в наличии
          всегда есть нужные детали, всегда оригинального заводского
          происхождения. Обратившись к нам, вы сделаете правильный выбор!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModelsX = () => {
  return (
    <Page title="Оперативная замена микрофона iPhone X за 50 минут в Мак Профи">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена микрофона iPhone X"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Айфон высоко ценится за свои базовые рабочие качества, за
                широкий перечень современных дополнительных возможностей. А еще
                многим пользователям нравится чистота его звукопередачи, начиная
                от работы динамиков, и заканчивая микрофоном, который позволяет
                успешно делать запись видео, и просто общаться, обеспечивая
                второму абоненту отличную слышимость при любых условиях связи.
                Особенно чуток микрофон у последних моделей, новинки позволяют
                передавать или записывать буквально любой звук.
              </p>
              <p className="repair-iphone-display__paragraph">
                Обычно микрофоны остаются чувствительными на протяжении долгого
                срока, и владельцев новых Айфонов проблемы с ними и вовсе не
                должны беспокоить. Однако порой бывает так, что этот элемент все
                же выходит из строя – или перестает работать корректно.
                Существует целый ряд причин, которые могут привести к подобному
                итогу, и наиболее часто приходится видеть подобный результат
                после утоплений и попадания влаги, механических повреждений.
                Более старые модели могут пострадать от износа, а еще
                встречается, хоть и крайне редко, заводской брак.
              </p>
            </>
          }
        />
        <RepairApplePrice
          subtitle="Стоимость замены микрофона iPhone X:"
          text="Замена микрофона"
          price="2500 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophoneX.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModelsX;
