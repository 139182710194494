import React from "react";
import { Section, PopupAddFeedback } from "../index";
import { feedbacksList /*, ESC*/ } from "../../utils/constants";
import { Carousel } from "react-responsive-carousel";
import usePopup from "../../hooks/usePopup";

const FeedbacksMobile = () => {
  const { isPopupOpen, closePopup, togglePopup } = usePopup();

  return (
    <Section id="otzyvy" className="feedbacks-mobile">
      <div className="feedbacks-mobile__contant">
        <h2 className="feedbacks-mobile__title">Отзывы</h2>
        <span className="feedbacks-mobile__border"></span>
        <h4 className="feedbacks-mobile__subtitle">
          Мы стремимся выполнять свою работу качественно и несем полную
          ответственность за каждое свое действие. Поэтому с благодарностью
          принимаем как положительные, так и критические замечания.
        </h4>
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          centerMode
          centerSlidePercentage={("centerSlidePercentage", 86)}
        >
          {feedbacksList.map((item) => {
            return (
              <div className="feedbacks-mobile__carousel-box" key={item.id}>
                <p className="feedbacks-mobile__carousel-text">{item.text}</p>
              </div>
            );
          })}
        </Carousel>
        <button
          className="feedbacks-mobile__btn"
          type="button"
          onClick={togglePopup}
        >
          Разместить отзыв
        </button>
      </div>
      <PopupAddFeedback isOpen={isPopupOpen} onClose={closePopup} />
    </Section>
  );
};

export default FeedbacksMobile;
