import React from 'react';
import { Page, MacProfiList, BreadCrumbs, RepairAppleLinksBanner, Section, RepairAppleIphonePrice, RepairIpadModelLinks, RepairIpadServiceLinks, RepairAppleLinksText } from '../index';
import banner from '../../images/banners/repair-ipadMini.jpeg';

const priceIpadMini = [
  {
    id: 1,
    option: 'Замена сенсорного стекла (тачскрина)',
    price: '2900 P',
  },
  {
    id: 2,
    option: 'Замена дисплея',
    price: '4300 P',
  },
  {
    id: 3,
    option: 'Замена корпуса/задней крышки',
    price: '6950 P',
  },
  {
    id: 4,
    option: 'Замена аккумулятора',
    price: '2950 P',
  },
  {
    id: 5,
    option: 'Замена микрофона',
    price: '2350 P',
  },
  {
    id: 6,
    option: 'Замена динамика',
    price: '2350 P',
  },
  {
    id: 7,
    option: 'Замена кнопки Home (домой)',
    price: '2350 P',
  },
  {
    id: 8,
    option: 'Замена кнопки вибро/Замена кнопок громкости',
    price: '1900 P',
  },
  {
    id: 9,
    option: 'Замена кнопки включения',
    price: '2100 P',
  },
  {
    id: 10,
    option: 'Замена антенны',
    price: '2100 P',
  },
  {
    id: 11,
    option: 'Ремонт Wi-Fi модуля',
    price: '4000 P',
  },
  {
    id: 12,
    option: 'Замена датчика приближения',
    price: '2100 P',
  },
  {
    id: 13,
    option: 'Замена сим-держателя',
    price: '950 P',
  },
  {
    id: 14,
    option: 'Замена основной камеры',
    price: '1900 P',
  },
  {
    id: 15,
    option: 'Замена передней камеры',
    price: '1900 P',
  },
  {
    id: 16,
    option: 'Замена разъема зарядки',
    price: '2350 P',
  },
  {
    id: 17,
    option: 'Замена разъема наушников',
    price: '2350 P',
  },
]

const textOfIpadMini = [
  {
    id: 1,
    title: 'Порядок ремонта Айпада Мини у нас.',
    text: 
      <>
        <p className='repair-iphone-display__paragraph'>Вы обращаетесь к нам. Это можно сделать и придя в один из наших сервисных центров, которые весьма удобно расположились в Москве, или же позвонив по указанным на сайте телефонам, в таком случае, можно даже не выходить из дома: ваш "заболевший" айпэд заберет у вас, и привезёт его назад, но уже совершенно исправный, наш курьер. Далее наступает стадия диагностики. Выявив все скрытые и явные неисправности вашего iPad Mini, мы извещаем о них вас. До данного момента, кстати, вам нет нужды платить нам ни копейки -- все перечисленное входит в бесплатные услуги наших центров. Стоимость же ремонта мы оговариваем после диагностики, и вы всегда можете выбрать тот спектр услуг который вы готовы оплатить. После этого цена уже не изменится. За дело берутся наши специалисты. Кстати: абсолютное большинство работ по ремонту Айпэд Мини мы производим не более чем за 20 минут. То есть вы даже не успеете всерьёз заскучать по вашему любимому гаджету, как он, полностью исправный, уже окажется опять в ваших руках. На все работы и замененные детали вы получаете гарантию сроком до года.</p>
        <p className='repair-iphone-display__paragraph'>Будем рады помочь вам и вашему iPad Mini!</p>
      </>
  },
]

const RepairIpadModelPagesIpadMini = () => {
  return (
    <Page title={'Цены на ремонт iPad Mini. Ремонт Айпада Мини Москва!'}>
      <main className='repair-iphone-group-content'>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner banner={banner}
          title='Ремонт iPad Mini'
          text='Не надо быть сыщиком с Бейкер-стрит, чтобы понять - на наш сайт и эту его конкретную страницу вас привела нужда в ремонте iPad Mini. Если так, то вы попали по адресу. Подобно тому, как тот самый Шерлок Холмс был мастером своего дедуктивного дела, мы -- профессионалы  в ремонте любых неисправностей Айпэдов. Будь то разбитый экран, или нарушенная геометрия корпуса, необходимость замены кнопок, или аккумулятора, или даже какая-нибудь другая, более экзотичная "хворь", приключившаяся с вашим Айпэдом Мини -- все эти поломки и неприятности мы можем устранить качественно, надёжно и в кратчайший срок. Основанием для такого бахвальства -- опыт и профессионализм наших сотрудников, первоклассное и современное оборудование, и склады, надёжно укомплектованные всеми необходимыми деталями.'
        />
        <Section>
          <RepairAppleIphonePrice prices={priceIpadMini} title='Цены на ремонт iPad Mini'/>
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className='section_group' style={{padding: '50px 0 65px'}}>
          {textOfIpadMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                {...item}
              />
            )
          })}
        </Section>
      </main>
    </Page>
  )
}

export default RepairIpadModelPagesIpadMini;