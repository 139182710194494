import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera5.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const textOfCamera5 = [
  {
    id: 1,
    title: "Можно ли самостоятельно заменить камеру iPhone 5?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Чтобы самостоятельно заменить поврежденный элемент: нужно лишь снять
          крышку, защитную пластину, а также основные платы и шлейфы, после чего
          установить новую деталь. Стоит попробовать выполнить эту работу
          самостоятельно, если вы готовы к следующим рискам:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Повреждение корпуса устройства
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Появление трещин на стекле из-за неаккуратного выполнения работы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Повреждение шлейфов или внутренней платы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Нарушение работы аккумулятора.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          При отсутствии опыта и достаточных знаний о ремонте подобной техники,
          не стоит пытаться сэкономить. В результате это может привести к
          значительным финансовым затратам. Сравните расценки на сайте, чтобы
          убедиться: замена камеры iPhone 5 обойдется дешевле, чем установка
          нового дисплея, основных разъемов, аккумулятора или других частей
          устройства.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Мак Профи: замена камеры iPhone 5 за 30 минут",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы хотите получить действительно качественный ремонт, то и
          обращаться нужно к профессионалам. В специализированном сервисе есть
          полный пакет инструментов и требующееся оборудование, что позволяет
          быстро заменить испорченную деталь. Кроме того, у нас работают
          квалифицированные сотрудники – инженеры, которые обладают достаточным
          опытом в ремонте подобных устройств. А предоставлять отменный сервис
          на лояльных условиях нам помогают следующие преимущества:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Диагностика и курьерская доставка по Москве полностью бесплатна
              для наших клиентов;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              На все работы распространяется гарантия качества, что подтверждает
              высокое качество предоставляемых услуг;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Ремонтируем все виды техники Apple – вы можете доверить нам не
              только Айфон, но и iPad или Apple Watch, а постоянным клиентам мы
              предложим выгодные скидки и специальные бонусы;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Все комплектующие в наличии – на нашем складе всегда есть детали
              для устранения, как распространенных поломок, так и проведения
              сложного ремонта.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Сервисный центр Мак Профи работает без перерывов и выходных – вы
          можете обратиться к нам тогда, когда это удобно именно для вас. Если у
          вас возникнут дополнительные вопросы, вы можете задать их по
          контактным телефонам или же приехать к нам и проконсультироваться со
          специалистами.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels5 = () => {
  return (
    <Page title="Замена камеры iPhone 5, цена замены камеры Айфон 5">
      <main className="repair-iphone-group-content">
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена камеры iPhone 5"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 5 в автоматизированном сервисном центре.
                Быстрый ремонт за 30 минут. Бесплатная диагностика и
                долгосрочная гарантия качества на все услуги.
              </p>
              <p className="repair-iphone-display__paragraph">
                iPhone 5 радует функциональностью и быстродействием – это
                отличный смартфон с качественной камерой. С его помощью можно
                наслаждаться видеозвонками, делать красочные фотографии, снимать
                видео. Если же камера вышла из строя, то достаточно её заменить
                – стоит эта услуга не слишком дорого, а при обращении в хороший
                сервис займет не больше получаса.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1300 P"
          priceDown="1300 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels5;
