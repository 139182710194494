import React from "react";
import banner from "../../images/banners/mac-profi_banner.svg";
import Section from "../Section/Section";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import icon1 from "../../images/icons/mac-profi-icon1-mobile.svg";
import icon2 from "../../images/icons/mac-profi-icon2-mobile.svg";
import icon3 from "../../images/icons/mac-profi-icon3-mobile.svg";
import vector from "../../images/icons/mac-profi-mobile-select.svg";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img alt="" src={vector} />
  </components.IndicatorsContainer>
);

const bannerMobileLink = [
  {
    img: icon1,
    link: "#",
    text: "Наш сервисный центр в Москве",
  },
  {
    img: icon2,
    link: "/dostavka",
    text: "Курьерская доставка",
  },
];

const MacProfiBannerMobile = () => {
  const handleSelectChange = (options) => {
    console.log("жопа");
  };

  const options = [
    { value: "iPhone 4", label: "iPhone 4" },
    { value: "iPhone 4s", label: "iPhone 4s" },
  ];

  return (
    <Section>
      <div className="mac-profi-mobile__container">
        <img className="mac-profi-mobile__banner" src={banner} alt="баннер" />
        <div className="mac-profi-mobile__content">
          <h1 className="mac-profi-mobile__title">
            Сервисный центр Мак Профи - Ремонт Apple
          </h1>
          <div className="mac-profi-mobile__border"></div>
          <ul className="mac-profi-mobile__list">
            {bannerMobileLink.map((link, i) => {
              return (
                <li key={i} className="mac-profi-mobile__item">
                  <img
                    src={link.img}
                    alt="иконка ссылки"
                    className="mac-profi-mobile__icon"
                  />
                  <Link className="mac-profi-mobile__link" to={link.link}>
                    {link.text}
                  </Link>
                </li>
              );
            })}
            <li className="mac-profi-mobile__item">
              <img
                src={icon3}
                alt="иконка расчета стоимости"
                className="mac-profi-mobile__icon"
              />
              <p className="mac-profi-mobile__paragraph">
                Быстрый расчет стоимости ремонта
              </p>
            </li>
          </ul>
          <Select
            components={{ IndicatorsContainer }}
            options={options}
            onChange={handleSelectChange}
            placeholder="Выберите модель"
            styles={{
              container: (styles) => ({
                ...styles,
                width: "100%",
                fontFamily: "Open Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "31px",
                color: "#88898D;",
                margin: "25px auto 0",
                borderBottom: "1px solid #88898D",
                background: "transparent",
              }),
              valueContainer: (styles) => ({
                ...styles,
                backgroundColor: "transparent",
                padding: 0,
              }),
              control: (styles) => ({
                ...styles,
                borderColor: null,
                border: "none",
                // boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                // "&:hover": null,
                backgroundColor: "transparent",
              }),
              option: (styles, state) => ({
                ...styles,
                fontFamily: "Open Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#88898D",
                borderRadius: 6,
                marginLeft: 7,
                marginRight: 7,
                width: 440,
                // backgroundColor: state.isSelected
                //   ? "rgba(2, 143, 232, 0.06)"
                //   : styles.backgroundColor,
              }),
              menuList: (styles) => ({
                ...styles,
                width: 472,
                backgroundColor: "#fff",
                borderRadius: 20,
                maxHeight: 232,
                overflow: "auto",
              }),
              menu: (styles) => ({
                ...styles,
                width: 472,
                maxHeight: 232,
                borderRadius: 6,
                boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }),
            }}
          />
          <Select
            components={{ IndicatorsContainer }}
            options={options}
            onChange={handleSelectChange}
            placeholder="Выберите модель"
            styles={{
              container: (styles) => ({
                ...styles,
                width: "100%",
                fontFamily: "Open Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "31px",
                color: "#88898D;",
                margin: "25px auto 0",
                borderBottom: "1px solid #88898D",
                background: "transparent",
              }),
              valueContainer: (styles) => ({
                ...styles,
                backgroundColor: "transparent",
                padding: 0,
              }),
              control: (styles) => ({
                ...styles,
                borderColor: null,
                border: "none",
                // boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                // "&:hover": null,
                backgroundColor: "transparent",
              }),
              option: (styles, state) => ({
                ...styles,
                fontFamily: "Open Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "19px",
                color: "#88898D",
                borderRadius: 6,
                marginLeft: 7,
                marginRight: 7,
                width: 440,
                // backgroundColor: state.isSelected
                //   ? "rgba(2, 143, 232, 0.06)"
                //   : styles.backgroundColor,
              }),
              menuList: (styles) => ({
                ...styles,
                width: 472,
                backgroundColor: "#fff",
                borderRadius: 20,
                maxHeight: 232,
                overflow: "auto",
              }),
              menu: (styles) => ({
                ...styles,
                width: 472,
                maxHeight: 232,
                borderRadius: 6,
                boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }),
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default MacProfiBannerMobile;
